import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import Title from '../../components/MWS/Title'
import { ProductionHandler } from '../../hooks/specific/useProductionHandler'
import { articleUtils } from '../../utils/articleUtils'

export default function ProductionOutProcessMode(props: { handler: ProductionHandler; style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()

  function handleProcessModeInput(quantity: number) {
    if (quantity <= 0) return
    props.handler.handleProcessModeInput(quantity).catch(console.error)
  }

  return (
    <View>
      <Title>{i18n.t('ProductionProducedAmount')}</Title>
      <VKUnitAmountSelector
        value={props.handler.production?.production.quantityOpen ?? 1}
        setValue={handleProcessModeInput}
        style={props.style}
        usedUnit={articleUtils.getUnitById(props.handler.production?.production.article, props.handler.production?.production.unitId)}
      />
    </View>
  )
}
