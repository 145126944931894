import { Utils } from '@infominds/react-native-components'

import api from '../apis/apiCalls'
import { InventoryPostRequest } from '../apis/apiRequestTypes'
import {
  ArticleDTO,
  ArticleMeasurementUnit,
  InventoryAccounting,
  InventoryEntry,
  InventoryPostResult,
  OngoingInventory,
  SerialNumber,
  StockPosition,
} from '../apis/apiTypes'
import { InventoryCreationQuantity } from '../types'
import stockPositionUtils from './stockPositionUtils'

const inventoryUtils = {
  getTitle(inventory?: InventoryEntry, stockPosition?: StockPosition) {
    let title = `${inventory?.description ?? ''}`
    if (title && stockPosition) title += ` - ${stockPositionUtils.getTitle(stockPosition)}`
    return title
  },
  createPostRequest(
    inventory: InventoryEntry,
    article: ArticleDTO,
    stockPosition: StockPosition | undefined,
    amount: number,
    usedUnit: ArticleMeasurementUnit | undefined,
    serialNumber?: SerialNumber
  ) {
    return {
      inventoryCodeId: inventory.id,
      inventoryDate: new Date(),
      articleId: article.id,
      articleCode: article.code,
      serialnumberId: serialNumber?.id ?? '',
      serialnumber: serialNumber?.number ?? '',
      unitId: usedUnit?.id ?? '',
      quantity: amount,
      stockpositionId: stockPosition?.id,
      stockpositionText: stockPosition?.code,
    } as InventoryPostRequest
  },
  createInventoryQuantity(inventoryPostRequest: InventoryPostRequest, inventoryPostResult?: InventoryPostResult): InventoryCreationQuantity {
    return {
      inventoryPositionId: inventoryPostResult?.inventorypositionId ?? '',
      quantity: inventoryPostRequest.quantity,
      serialNumber: inventoryPostRequest.serialnumber,
      unitId: inventoryPostRequest.unitId,
    }
  },
  quantitySorter(a: InventoryCreationQuantity, b: InventoryCreationQuantity) {
    return a?.serialNumber > b?.serialNumber ? 1 : -1
  },
  deleteInventoryEntries(items: InventoryAccounting[]) {
    return Promise.all(items.map(q => api.deleteInventory({ id: q.id })))
  },
  createStorageKey(inventory?: InventoryEntry, stockPosition?: StockPosition) {
    return `inventory[${inventory?.code ?? ''}][${stockPosition?.id ?? 'noStockPos'}]`
  },
  sortOngoingInventoryListByArticle(a: OngoingInventory, b: OngoingInventory) {
    let result = Utils.compareStringsForSort(a.article?.code, b.article?.code)
    if (result) return result
    result = Utils.compareStringsForSort(a.stockposition?.code, b.stockposition?.code)
    if (result) return result
    return Utils.compareStringsForSort(a.date, b.date)
  },
  sortOngoingInventoryListByStockposition(a: OngoingInventory, b: OngoingInventory) {
    let result = Utils.compareStringsForSort(a.stockposition?.code, b.stockposition?.code)
    if (result) return result
    result = Utils.compareStringsForSort(a.article?.code, b.article?.code)
    if (result) return result
    return Utils.compareStringsForSort(a.date, b.date)
  },
  sortOngoingInventoryList(a: OngoingInventory, b: OngoingInventory) {
    let result = Utils.compareStringsForSort(a.date, b.date)
    if (result) return result
    result = Utils.compareStringsForSort(a.stockposition?.code, b.stockposition?.code)
    if (result) return result
    return Utils.compareStringsForSort(a.article?.code, b.article?.code)
  },
}

export default inventoryUtils
