import React, { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import { Deposit } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'

export interface SelectorViewProps<T> {
  item: T | undefined
  setItem: (item: T | undefined) => void
  itemList?: T[]
  getItemList?: () => T[] | Promise<T[]>
  preLoad?: boolean
  loading?: boolean
  quantityExtractor?: (item: T) => string
  quantityAvailabilityExtractor?: (item: T) => string
}

export default function DepositSelectorView(props: SelectorViewProps<Deposit>) {
  const [items, setItems] = useState(props.itemList ?? [])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.preLoad || !props.item) {
      loadItems().catch(console.error)
    }
  }, [])

  useEffect(() => {
    if (props.itemList) setItems(props.itemList)
  }, [props.itemList])

  async function loadItems() {
    if (!props.getItemList) return
    setLoading(true)
    try {
      const result = await props.getItemList()
      if (result) setItems(result)
    } catch (ex) {
      console.error(ex)
    }
    setLoading(false)
  }

  const isLoading = loading || !!props.loading

  return (
    <View>
      {props.item && (
        <DepositCard
          deposit={props.item}
          onDelete={
            items?.length > 1
              ? () => {
                  props.setItem(undefined)
                  loadItems().catch(console.error)
                }
              : undefined
          }
          quantity={props.quantityExtractor ? props.quantityExtractor(props.item) : undefined}
          quantityNet={props.quantityAvailabilityExtractor ? props.quantityAvailabilityExtractor(props.item) : undefined}
        />
      )}
      {!props.item && (
        <FlatList
          data={items}
          renderItem={({ item }) => (
            <DepositCard
              deposit={item}
              onPress={() => props.setItem(item)}
              quantity={props.quantityExtractor ? props.quantityExtractor(item) : undefined}
              quantityNet={props.quantityAvailabilityExtractor ? props.quantityAvailabilityExtractor(item) : undefined}
            />
          )}
          refreshControl={
            <IMRefreshControl
              refreshing={isLoading}
              onRefresh={() => {
                loadItems().catch(console.error)
              }}
            />
          }
          ListFooterComponent={!isLoading && items.length === 0 ? <NoEntriesTag /> : <ListSpacer />}
        />
      )}
    </View>
  )
}
