import { useLanguage, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo, useRef } from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { Collo } from '../../apis/apiTypes'
import SubmitButton from '../../components/SubmitButton'
import { useDoubleClick } from '../../contexts/DoubleClickContext'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import ColloCompletionModal from '../../modals/ColloCompletionModal'
import { Error_Utils } from '../../utils/ErrorUtils'
import packingListUtils from '../../utils/packingListUtils'

export default function PackingListSubmitButtonsView() {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const { colloSelector, packingListArticle, article, selectedQuantity, totalQuantity, deposit, availableCollos, anyOpenCollosSelected } =
    usePackingListMovement()

  const { colloMode, userSettings, isPreConsignment, isProductionConsignment, updateCollosOf, reloadPackingListMovements, activeColloSelector } =
    usePackingList()
  const navigation = useNavigation()

  const colloCompletionModal = useModalController<Collo[]>()
  const anyCollosToConclude = useMemo(() => colloSelector.items.some(item => !item.id), [colloSelector.items])
  const goBackOnModalClose = useRef(false)

  useDoubleClick(handleDoubleClick)

  function handleDoubleClick() {
    if (!colloSelector.any || !anyCollosToConclude) return
    completeMovement().catch(console.error)
  }

  async function completeMovement(concludeCollo?: boolean) {
    goBackOnModalClose.current = false
    try {
      if (!packingListArticle) return
      if (!(await prePostCheck())) return

      const newCollos = colloSelector.items.filter(c => !c.id && c.quantity > 0)
      if (newCollos.length) {
        loader.setLoading(true)
        const result = await packingListUtils.postPackingListMovements(
          packingListArticle,
          newCollos,
          !!article?.isSerialNumberActive,
          userSettings,
          activeColloSelector.activeCollos,
          isPreConsignment,
          isProductionConsignment
        )

        if (result && result.length > 0) {
          if (colloMode) {
            for (const element of result) {
              if (!element.packingListId) continue
              activeColloSelector.setActiveColloNumber(element.assignedColloNumber, element.packingListId)
            }
          }

          await loadColloToComplete(result.map(c => c.assignedColloNumber).filter(Boolean) as string[], concludeCollo)
          return
        }
      } else if (concludeCollo) {
        const collosToConclude = availableCollos.reduce<Collo[]>((result, avc) => {
          const activeColloNumber = activeColloSelector.activeCollos[avc.packingList.id]
          const foundCollo = !!activeColloNumber && activeColloNumber !== '0' && avc.collos.find(c => c.number === activeColloNumber)
          if (!foundCollo) return result
          if (!result.some(r => r.number === foundCollo.number)) result.push(foundCollo)
          return result
        }, [])
        if (collosToConclude.length) colloCompletionModal.show(collosToConclude)
        return
      }
      await onDone()
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToCreateCollo'), Error_Utils.extractErrorMessageFromException(error))
    }
  }

  function prePostCheck() {
    if (!packingListArticle) return false
    if (selectedQuantity <= totalQuantity || !colloSelector.items.find(item => !item.id)) return true
    if (isPreConsignment || !userSettings?.isPackingListCheckDifferencesOverCommissioningValid) {
      alert.error(i18n.t('PackingListQuantityExceedsRequiredError'), selectedQuantity, totalQuantity)
      return false
    }
    return alert.yesNo(i18n.t('PackingListQuantityExceedsRequiredWarning'), selectedQuantity, totalQuantity)
  }

  async function onDone(goBack = true) {
    try {
      const result = await updateCollosOf(packingListArticle)
      if (!result) reloadPackingListMovements().catch(console.error)
    } catch (reason) {
      console.error(reason)
      reloadPackingListMovements().catch(console.error)
    } finally {
      loader.setLoading(false)
      if (goBack) navigation.goBack()
    }
  }

  async function loadColloToComplete(colloNumbers: string[], conclude?: boolean) {
    if (!colloNumbers.length || !conclude) {
      await onDone()
      return
    }

    loader.setLoading(true)
    try {
      const result = await Promise.all(colloNumbers.map(colloNumber => api.getCollo({ number: colloNumber })))
      const collosToConclude = Utils.keepUniques(result.flat(), c => c.number)
      if (!result || result.length === 0) {
        failedToConcludeCollo(colloNumbers.join(', '))
        return
      }
      goBackOnModalClose.current = true
      colloCompletionModal.show(collosToConclude)
    } catch (reason) {
      failedToConcludeCollo(colloNumbers.join(', '), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  function failedToConcludeCollo(colloNumber: string, reason?: string) {
    alert.error(i18n.t('FailedToConcludeCollo'), colloNumber, reason)
  }

  async function handleColloConcluded(concludedCollos: Collo[]) {
    activeColloSelector.setActiveCollos(prev => {
      for (const key of Object.keys(prev)) {
        if (concludedCollos.some(c => c.number === prev[key])) {
          delete prev[key]
        }
      }

      return prev
    })

    await reloadPackingListMovements()
      .catch(console.error)
      .finally(() => navigation.goBack())
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <SubmitButton
        style={{ flex: 2, marginRight: 0, paddingHorizontal: 0 }}
        hide={!colloSelector.any || !colloMode || isPreConsignment || isProductionConsignment}
        disabled={(!anyOpenCollosSelected && !anyCollosToConclude) || !!loader.loading}
        hideFromKeyBoard
        title={anyCollosToConclude ? i18n.t('ConfirmAndCompleteCollo') : i18n.t('ConcludeCollo')}
        onPress={() => completeMovement(true)}
      />
      <SubmitButton
        style={{ flex: 1, paddingHorizontal: 0 }}
        hide={!colloSelector.any}
        disabled={!anyCollosToConclude || !!loader.loading}
        hideFromKeyBoard
        title={i18n.t('Confirm')}
        onPress={completeMovement}
      />
      <ColloCompletionModal
        controller={colloCompletionModal}
        onAborted={() => {
          onDone(goBackOnModalClose.current).catch(console.error)
        }}
        onCompleted={concludedCollos => {
          handleColloConcluded(concludedCollos).catch(console.error)
        }}
        deposit={deposit}
        selectCollosBeforeConclusion
        packingListArticleMovements={packingListArticle?.movements}
      />
    </View>
  )
}
