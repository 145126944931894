import { Utils } from '@infominds/react-native-components'
import React from 'react'

import { ProductionHandler } from '../../hooks/specific/useProductionHandler'
import productionUtils from '../../utils/ProductionUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import ProductionItemQuantitySelectorView from './ProductionItemQuantitySelectorView'

interface ProductionHandlerProps {
  handler: ProductionHandler
}

export default function ProductionOutDetailInputView({ handler }: ProductionHandlerProps) {
  function getProposedQuantity() {
    if (handler.production?.isDefectiveMaterialProduction) return undefined
    if (handler.selectedPart?.selectedQuantities?.length) {
      return Utils.sum(handler.selectedPart.selectedQuantities, q => q.quantity)
    }
    return productionUtils.getRemainingQuantity(handler.selectedPart)
  }

  if (!handler.selectedPart) return <></>

  return (
    <ProductionItemQuantitySelectorView
      handler={handler}
      article={handler.selectedPart?.article}
      part={handler.selectedPart}
      proposedQuantity={getProposedQuantity()}
      checkAvailability={
        !handler.production?.stockTemplateOut?.deactivateAvailabilityCheck && handler.selectedPart.article.isAvailabilityCheckEnabled()
      }
      deposit={handler.production?.production.depositOutgoing}
      stockPosition={handler.selectedPart?.part.stockposition}
      preSelectedSerialNumber={handler.preSelectedSerialNumber}
      filterSerialNumbers={serialnumberUtils.getSerialNumbersFrom(handler.selectedPart.selectedQuantities)}
    />
  )
}
