/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import KeyEvent from 'react-native-keyevent'

import { CONSTANTS, StorageKeys } from '../constants/Constants'
import useAsyncStorage from '../hooks/useAsyncStorage'

type DoubleClickListenerCallback = (keyCode: number) => void
type DoubleClickListener = { id: number; callback: DoubleClickListenerCallback; configMode?: boolean }

interface DoubleClickContextType {
  allowedKeyCodes: number[]
  addKeyCode: (keyCode: number) => Promise<void>
  removeKeyCode: (keyCode: number) => Promise<void>
  addListener: (listener: DoubleClickListenerCallback, configMode?: boolean) => number
  removeListener: (id: number) => void
}

export const DoubleClickContext = React.createContext<DoubleClickContextType>({
  allowedKeyCodes: [],
  addKeyCode: () => Promise.resolve(),
  removeKeyCode: () => Promise.resolve(),
  addListener: () => 0,
  removeListener: () => {},
})
type keyEventType = 'KeyUp' | 'KeyDown'
interface KeyEvent {
  keyCode: number
  timestamp: number //timestamp of keyEvent [ms]
  type: keyEventType
}
export function DoubleClickContextProvider(props: { children: ReactNode }) {
  const firstRender = useRef(true)
  const [allowedKeyCodes, setAllowedKeyCodes] = useState<number[]>([])

  const asyncStorage = useAsyncStorage(StorageKeys.doubleClickConfig)
  const lastKeyDownEvents = useRef<KeyEvent[]>([])
  const registeredListeners = useRef<DoubleClickListener[]>([])

  useEffect(() => {
    if (firstRender.current) {
      asyncStorage
        .load()
        .then((result: number[] | undefined | null) => {
          if (result?.length) setAllowedKeyCodes(result)
        })
        .catch(console.error)
      firstRender.current = false
      return
    }

    asyncStorage.save(allowedKeyCodes).catch(console.error)
  }, [allowedKeyCodes])

  useEffect(() => {
    if (Platform.OS !== 'android') return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KeyEvent.onKeyUpListener((keyEvent: any) => {
      addKeyEvent(keyEvent.keyCode, 'KeyUp')
      if (!registeredListeners.current?.length) return
      const listener = registeredListeners.current.slice(-1)[0]
      if (!listener || !keyEvent?.keyCode || (!listener.configMode && !allowedKeyCodes.find(kc => kc === keyEvent.keyCode))) return

      if (detectDoubleClick(keyEvent.keyCode)) {
        listener.callback(keyEvent.keyCode)
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    KeyEvent.onKeyDownListener((keyEvent: any) => {
      addKeyEvent(keyEvent.keyCode, 'KeyDown')
    })

    return () => {
      KeyEvent.removeKeyDownListener()
      KeyEvent.removeKeyUpListener()
    }
  })

  function addKeyEvent(keyCode: number, type: keyEventType) {
    if (!keyCode) return
    lastKeyDownEvents.current = [{ keyCode, timestamp: Math.floor(Date.now()), type }, ...lastKeyDownEvents.current].slice(0, 10)
  }

  function detectDoubleClick(keyCode: number) {
    if (lastKeyDownEvents.current?.length < 4) return false
    if (
      lastKeyDownEvents.current[0].type !== 'KeyUp' ||
      lastKeyDownEvents.current[1].type !== 'KeyDown' ||
      lastKeyDownEvents.current[2].type !== 'KeyUp' ||
      !!lastKeyDownEvents.current.slice(0, 4).find(e => e.keyCode !== keyCode)
    ) {
      return false
    }
    if (lastKeyDownEvents.current[0].timestamp - lastKeyDownEvents.current[2].timestamp < CONSTANTS.DOUBLE_CLICK_DETECTION_WINDOW) return true
    return false
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async function addKeyCode(keyCode: number) {
    setAllowedKeyCodes(prev => {
      if (!prev.find(kc => kc === keyCode)) prev.push(keyCode)
      return [...prev]
    })
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async function removeKeyCode(keyCode: number) {
    setAllowedKeyCodes(prev => {
      prev = prev.filter(kc => kc !== keyCode)
      return [...prev]
    })
  }

  function addListener(callback: DoubleClickListenerCallback, configMode?: boolean) {
    const id = Math.max(0, ...registeredListeners.current.map(l => l.id)) + 1
    registeredListeners.current.push({ callback, configMode, id })
    return id
  }

  function removeListener(id: number) {
    registeredListeners.current = registeredListeners.current.filter(l => l.id !== id)
  }

  return (
    <DoubleClickContext.Provider value={{ allowedKeyCodes, addKeyCode, removeKeyCode, addListener, removeListener }}>
      {props.children}
    </DoubleClickContext.Provider>
  )
}

/**
 * Hook to detect double click of hardware buttons.
 * Warning!!: For some reason it does not work if a modal is open. see https://github.com/kevinejohn/react-native-keyevent/issues/63
 * @param callback function is called when double click is detected
 * @param configMode determines if ALL HW-Buttons are used in the detection or only configured ones
 */
export function useDoubleClick(callback: (keyCode: number) => void, configMode?: boolean) {
  const doubleClickContext = useContext(DoubleClickContext)
  const [doubleClickCounter, setDoubleClickCounter] = useState(0)
  const [keyCode, setKeyCode] = useState(0)

  useEffect(() => {
    const id = doubleClickContext.addListener(handleDoubleClick, configMode)

    return () => {
      doubleClickContext.removeListener(id)
    }
  }, [configMode])

  function handleDoubleClick(code: number) {
    setKeyCode(code)
    setDoubleClickCounter(prev => prev + 1)
  }

  //useEffect is used to decouple callback from listener
  useEffect(() => {
    if (!doubleClickCounter) return
    callback(keyCode)
  }, [doubleClickCounter])
}
