import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import MWS_Screen from '../../components/MWS/MWS_Screen'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { PackingListMovementContextProvider, usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import useAlert from '../../hooks/useAlert'
import PackingListMovementView from '../../views/PackingList/PackingListMovementView'

export default function PackingListMovementScreen() {
  return (
    <PackingListMovementContextProvider>
      <Content />
    </PackingListMovementContextProvider>
  )
}

function Content() {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const { isPreConsignment } = usePackingList()
  const { hasUnsavedChanges } = usePackingListMovement()

  async function preNavigationCheck(type: 'goBack' | 'popToTop') {
    if (type !== 'goBack' || __DEV__) return false

    if (hasUnsavedChanges) {
      const result = await alert.yesNo(i18n.t('LEAVING_ALERT'))
      if (!result) return true
    }

    return false
  }

  return (
    <MWS_Screen noTopPadding title={isPreConsignment ? i18n.t('PreConsignment') : i18n.t('Consignment')} preNavigationCallback={preNavigationCheck}>
      <PackingListMovementView />
    </MWS_Screen>
  )
}
