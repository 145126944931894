import { useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Collo, PackingList } from '../../apis/apiTypes'
import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ColloCard(props: {
  collo: Collo | undefined
  packingList?: PackingList | string
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  quantity?: string
  info?: string
  endIcon?: IMIconName
}) {
  const { theme } = useTheme()

  const packingListText = useMemo(
    () => !!props.packingList && (typeof props.packingList === 'string' ? props.packingList?.trim() : props.packingList?.number?.trim()),
    [props.packingList]
  )

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={'box-taped'}
      cardStyle={props.style}
      style={[{ alignItems: 'center' }]}
      endButton={
        props.endIcon
          ? {
              icon: props.endIcon,
              disabled: true,
              buttonStyle: { backgroundColor: theme.card.background },
              color: theme.text,
            }
          : undefined
      }>
      <View style={{ flex: 1 }}>
        <IMText numberOfLines={1} primary style={{ flex: 1 }}>
          {props.collo?.number}
        </IMText>
        {!!packingListText && <IMTextWithIcon secondary text={packingListText} icon={'clipboard-check-list'} />}
      </View>
      {props.info && (
        <IMText secondary numberOfLines={1}>
          {props.info}
        </IMText>
      )}
      {props.quantity && <IMText>{props.quantity}</IMText>}
    </MWS_BaseCard>
  )
}
