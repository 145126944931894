import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleMeasurementUnit, Deposit, Item, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import PositionCard from '../../cards/StockPosition/PositionCard'
import { Article } from '../../classes/Article'
import { ProductionPartCollection } from '../../classes/ProductionData'
import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { ProductionHandler } from '../../hooks/specific/useProductionHandler'
import useAlert from '../../hooks/useAlert'
import { articleUtils } from '../../utils/articleUtils'
import { Error_Utils } from '../../utils/ErrorUtils'
import productionUtils from '../../utils/ProductionUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import SerialnumberQuantitySelectionView from '../InputViews/SerialnumberQuantitySelectionView'
import StockPositionWithAvailabilitySelectionView from '../InputViews/StockPositionWithAvailabilitySelectionView'

export interface ItemQuantitySelectorResult {
  item: Item
  quantity: number
  unit: ArticleMeasurementUnit | undefined
}

interface ProductionItemQuantitySelectorViewProps {
  handler: ProductionHandler
  article: Article | undefined
  part: ProductionPartCollection
  deposit?: Deposit
  stockPosition?: StockPosition
  proposedQuantity?: number
  checkAvailability?: boolean
  isHistoricalSerialNumberActive?: boolean
  filterSerialNumbers?: SerialNumber[]
  style?: StyleProp<ViewStyle>
  preSelectedSerialNumber?: SerialNumber
}

export default function ProductionItemQuantitySelectorView(props: ProductionItemQuantitySelectorViewProps) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()

  const selectedArticle = props.article
  const handler = props.handler
  const deposit = props.part.part.deposit ?? handler.production?.production.depositOutgoing
  const alreadySelectedQuantity = handler.selectedPart?.selectedQuantities.find(q => !!q.quantity)
  const [selectedPosition, setSelectedPosition] = useState<StockPositionAvailability | undefined>(
    props.stockPosition || alreadySelectedQuantity
      ? stockPositionUtils.createStockPositionAvailability(
          selectedArticle?.info,
          alreadySelectedQuantity?.deposit ?? deposit,
          alreadySelectedQuantity?.stockPosition ?? props.stockPosition,
          props.proposedQuantity
        )
      : undefined
  )
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<StockPositionAvailability | undefined>()
  const loadStockPositionsOnMount = useRef(!selectedPosition)

  const isSerialNumberArticle = !!selectedArticle?.isSerialNumberActive
  const stockPositionSelectionRequired = !props.stockPosition && (!isSerialNumberArticle || !!selectedArticle.isHistoricalSerialNumber)
  const showStockPositionSelector = stockPositionSelectionRequired && !selectedPosition
  const showQuantitySelector = (isSerialNumberArticle && !!selectedSerialNumber) || (!isSerialNumberArticle && !!selectedPosition)
  const showSerialSelector = isSerialNumberArticle && (!selectedArticle.isHistoricalSerialNumber || !!selectedPosition) && !selectedSerialNumber
  const allowStockPosDeletion = !props.stockPosition && (!isSerialNumberArticle || !selectedSerialNumber)

  useEffect(() => {
    //if stockPosition has missing information, reload that stockposition
    if (!selectedPosition?.stockposition?.id || selectedPosition.deposit) return
    api
      .getStockPosition({ id: selectedPosition.stockposition.id })
      .then(result => {
        if (!result?.length) return
        setSelectedPosition(stockPositionUtils.createStockPositionAvailability(selectedArticle?.info, deposit, result[0], props.proposedQuantity))
      })
      .catch(console.error)
  }, [])

  // @ts-ignore todo
  useEffect(() => {
    if (!props.preSelectedSerialNumber) return undefined
    if (selectedArticle?.isHistoricalSerialNumber) {
      if (selectedArticle?.isLottoSerialNumber) {
        setSelectedSerialNumber({
          serialnumber: props.preSelectedSerialNumber,
          article: selectedArticle.info,
          deposit: props.deposit,
          stockposition: props.stockPosition,
          quantity: 1,
        })
      } else {
        handler.addQuantity(props.part.part, {
          quantity: 1,
          serialNumber: props.preSelectedSerialNumber,
          unit: selectedArticle?.getUsedUnit(),
          stockPosition: props.stockPosition,
        })
      }
      return
    }
    loader.setLoading(true)
    api
      .getSerialnumberQuantities({
        serialnumberId: props.preSelectedSerialNumber.id,
        articleId: selectedArticle?.info.id,
        depositId: props.deposit?.id,
        stockPositionId: props.stockPosition?.id,
      })
      .then(result => {
        if (!result?.length) return
        if (selectedArticle?.isLottoSerialNumber) {
          setSelectedSerialNumber(result[0])
        } else {
          handler.addQuantity(props.part.part, {
            quantity: 1,
            serialNumber: result[0]?.serialnumber,
            unit: selectedArticle?.getUsedUnit(),
            stockPosition: result[0].stockposition,
          })
        }
      })
      .catch(console.error)
      .finally(() => loader.setLoading(false))
  }, [])

  async function handleQuantityInput(quantity: number) {
    if (!props.part || !selectedArticle) return
    const selection: StockPositionAvailability = {
      quantity,
      stockposition: selectedPosition?.stockposition,
      deposit: props.deposit,
      article: selectedArticle.info,
      serialnumber: undefined,
    }
    loader.setLoading(true)
    try {
      if (!(await checkAvailability(selection))) return
    } catch (exception) {
      console.error(exception)
      return
    } finally {
      loader.setLoading(false)
    }

    if (!props.part.part.id) {
      await addNewPart(selection)
      return
    }

    //SerialNumber Article
    if (isSerialNumberArticle) {
      handler.addQuantity(props.part.part, {
        quantity: quantity,
        serialNumber: selectedSerialNumber?.serialnumber,
        unit: selectedArticle?.getUsedUnit(),
        stockPosition: selectedSerialNumber?.stockposition ?? selectedPosition?.stockposition,
      })
      setSelectedSerialNumber(undefined)
    }
    //Non SerialNumber article
    else {
      if (quantity <= 0) {
        handler.setPartQuantities(props.part.part, [])
      } else {
        handler.setPartQuantities(props.part.part, [
          {
            quantity: quantity,
            serialNumber: undefined,
            unit: selectedArticle?.getUsedUnit(),
            stockPosition: selectedPosition?.stockposition,
          },
        ])
      }
      handler.setSelectedPart(undefined)
    }
  }

  async function handleSerialSelection(selection: StockPositionAvailability) {
    if (
      !props.part ||
      (props.part.selectedQuantities.find(q => serialnumberUtils.compare(q.serialNumber, selection.serialnumber)) &&
        !selectedArticle?.isHistoricalSerialNumber) ||
      (props.part.selectedQuantities.find(q => serialnumberUtils.compareByNumber(q.serialNumber, selection.serialnumber)) &&
        !!selectedArticle?.isHistoricalSerialNumber)
    ) {
      return
    }
    loader.setLoading(true)
    try {
      if (!(await checkAvailability(selection))) return
    } catch (exception) {
      console.error(exception)
      return
    } finally {
      loader.setLoading(false)
    }

    if (!props.part.part.id) {
      await addNewPart(selection)
      return
    }
    if (selectedArticle?.isLottoSerialNumber) {
      setSelectedSerialNumber(selection)
      return
    }
    handler.addQuantity(props.part.part, {
      quantity: 1,
      serialNumber: selection.serialnumber,
      unit: selectedArticle?.getUsedUnit(),
      stockPosition: selection.stockposition,
    })
  }

  async function addNewPart(serialSelection: StockPositionAvailability) {
    try {
      loader.setLoading(true)
      const newProductionPart = await api.postProductionPart({
        articleId: props.article?.info.id ?? '',
        productionId: handler.production?.production.id ?? '',
        quantity: serialSelection.quantity ?? 0,
        stockpositionId: serialSelection.stockposition?.id,
        isCreateOutgoing: true,
        isCheckAvailability: true,
      })
      if (!newProductionPart) return
      handler.parts.add(
        productionUtils.createPartCollection(newProductionPart, [
          {
            quantity: serialSelection.quantity ?? 0,
            unit: selectedArticle?.getUsedUnit(),
            stockPosition: serialSelection.stockposition,
            serialNumber: serialSelection.serialnumber,
            deposit: props.deposit,
          },
        ])
      )
      handler.setSelectedPart(undefined)
    } catch (reason) {
      alert.error(i18n.t('FailedToAddProductionPart'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  async function checkAvailability(selection: StockPositionAvailability) {
    if (!props.checkAvailability || selection.quantity === 0) return true

    const availability = await api.getItemAvailability({
      articleId: selectedArticle?.info.id,
      depositId: deposit?.id,
      stockPositionId: selectedPosition?.stockposition?.id,
      serialNumberId: selectedArticle?.isHistoricalSerialNumber ? undefined : selection.serialnumber?.id, //Historical SN are only checked for Article-Availability
    })

    if (articleUtils.quantityValidator(selection.quantity, availability)) return true
    if (selectedArticle?.isSerialNumberActive && !selectedArticle.isLottoSerialNumber && !selectedArticle.isHistoricalSerialNumber) {
      alert.info(i18n.t('SelectedSerialNotAvailable'), selection.serialnumber?.number)
    } else if (selectedArticle?.isHistoricalSerialNumber) {
      alert.info(i18n.t('StockPositionNotAvailableArticle'), stockPositionUtils.getTitle(selectedPosition?.stockposition), availability)
    } else {
      alert.info(i18n.t('SelectedAmountInvalidAlert'), availability)
    }

    return false
  }

  function getProposedQuantity() {
    if (handler.production?.isDefectiveMaterialProduction) return undefined
    let proposition = props.part.part.quantity
    if (selectedSerialNumber && !selectedArticle?.isHistoricalSerialNumber) {
      proposition = Math.min(proposition, selectedSerialNumber.quantity ?? 0)
    } else if (!selectedSerialNumber && selectedPosition?.quantity) {
      proposition = Math.min(proposition, selectedPosition.quantity)
    }
    return proposition
  }

  return (
    <View style={props.style}>
      {!!selectedPosition && stockPositionSelectionRequired && (
        <PositionCard
          deposit={selectedPosition.deposit}
          stockPosition={selectedPosition.stockposition}
          quantity={selectedArticle?.getQuantityWithUnitText(selectedPosition.quantity)}
          onDelete={allowStockPosDeletion ? () => setSelectedPosition(undefined) : undefined}
        />
      )}

      {showStockPositionSelector && (
        <StockPositionWithAvailabilitySelectionView
          article={selectedArticle}
          deposit={deposit}
          onSelected={selection => {
            setSelectedPosition(selection)
            loadStockPositionsOnMount.current = false
          }}
          showResultInModal
          allowEmptyInput
          loadOnMount={loadStockPositionsOnMount.current}
          filterOtherDeposits
          productionStockPositions={false}
        />
      )}
      {showQuantitySelector && (
        <>
          {isSerialNumberArticle && !!selectedSerialNumber && (
            <SerialnumberCard
              serialnumber={selectedSerialNumber?.serialnumber}
              stockPosition={selectedSerialNumber?.stockposition}
              onDelete={() => setSelectedSerialNumber(undefined)}
            />
          )}
          <VKUnitAmountSelector
            value={getProposedQuantity()}
            setValue={value => {
              handleQuantityInput(value).catch(console.error)
            }}
            autoFocus
            usedUnit={selectedArticle?.getUsedUnit()}
            style={styles.quantitySelector}
            confirmButtonCaption={i18n.t('Add')}
          />
        </>
      )}
      {showSerialSelector && (
        <SerialnumberQuantitySelectionView
          article={selectedArticle?.info}
          onSelected={value => {
            handleSerialSelection(value).catch(console.error)
          }}
          showResultInModal
          showAvailability
          allowNewSerialCreation
          deposit={deposit}
          stockPosition={selectedPosition?.stockposition}
          allowEmptyInput={!selectedArticle.isHistoricalSerialNumber}
          filterSerialNumbers={serialnumberUtils.getSerialNumbersFrom(props.part?.selectedQuantities)}
          historicalSerialNumberMode={{
            active: selectedArticle?.info.isSerialnumberOnlyForHistory,
            deposit: deposit,
            defaultStockPosition: selectedPosition?.stockposition,
            SerialNumberMustExist: true,
          }}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  quantitySelector: {
    marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS,
  },
})
