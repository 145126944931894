import { useLanguage } from '@infominds/react-native-components'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList } from 'react-native'

import { PackingList } from '../../apis/apiTypes'
import PackingListCard from '../../cards/Packinglist/PackingListCard'
import ElusiveButton from '../../components/ElusiveButton'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import useItemSelector from '../../hooks/useItemSelector'
import PackingListSelectionView from '../../views/InputViews/PackingListSelectionView'

export default function ProductionConsignmentListSelectionScreen() {
  const { i18n } = useLanguage()

  const navigation = useNavigation()
  const listSelector = useItemSelector<PackingList>([], (a, b) => a.id === b.id)

  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) return
    listSelector.clear()
  }, [isFocused])

  function handlePackingListSelection(packingLists: PackingList[]) {
    listSelector.add(...packingLists)
  }

  function beginConsignment() {
    navigation.navigate('PackingListStack', {
      screen: 'PackingList',
      params: { packingLists: listSelector.items, isPreConsignment: false, isProductionConsignment: true },
    })
  }

  return (
    <MWS_Screen>
      <PackingListSelectionView
        onSelected={selection => handlePackingListSelection([selection])}
        onMultiSelect={handlePackingListSelection}
        productionConsignment
        showResultInModal
        allowEmptyInput
        filterResultByOrder
      />
      {listSelector.any && <Title>{i18n.t('SelectedPackingLists')}</Title>}
      <FlatList
        data={listSelector.items}
        renderItem={({ item }) => <PackingListCard packingList={item} onDelete={() => listSelector.remove(item)} />}
      />
      <ElusiveButton hideFromKeyBoard hide={!listSelector.any} title={i18n.t('BeginConsignment')} onPress={beginConsignment} />
    </MWS_Screen>
  )
}
