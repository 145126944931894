import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp'
import { faArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowDown'
import { faArrowDownShortWide } from '@fortawesome/pro-light-svg-icons/faArrowDownShortWide'
import { faArrowDownWideShort } from '@fortawesome/pro-light-svg-icons/faArrowDownWideShort'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight'
import { faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightFromBracket'
import { faArrowRightToBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightToBracket'
import { faArrowsDownToLine } from '@fortawesome/pro-light-svg-icons/faArrowsDownToLine'
import { faArrowUp } from '@fortawesome/pro-light-svg-icons/faArrowUp'
import { faArrowUp19 } from '@fortawesome/pro-light-svg-icons/faArrowUp19'
import { faArrowUpArrowDown } from '@fortawesome/pro-light-svg-icons/faArrowUpArrowDown'
import { faAt } from '@fortawesome/pro-light-svg-icons/faAt'
import { faBarcode } from '@fortawesome/pro-light-svg-icons/faBarcode'
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons/faBarcodeRead'
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons/faBarcodeScan'
import { faBarsSort } from '@fortawesome/pro-light-svg-icons/faBarsSort'
import { faBoxCircleCheck } from '@fortawesome/pro-light-svg-icons/faBoxCircleCheck'
import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons/faBoxesStacked'
import { faBoxTaped } from '@fortawesome/pro-light-svg-icons/faBoxTaped'
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar'
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays'
import { faCartFlatbedBoxes } from '@fortawesome/pro-light-svg-icons/faCartFlatbedBoxes'
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck'
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck'
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog'
import { faConveyorBelt } from '@fortawesome/pro-light-svg-icons/faConveyorBelt'
import { faCubesStacked } from '@fortawesome/pro-light-svg-icons/faCubesStacked'
import { faDumpster } from '@fortawesome/pro-light-svg-icons/faDumpster'
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV'
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter'
import { faFilterCircleXmark } from '@fortawesome/pro-light-svg-icons/faFilterCircleXmark'
import { faHandHoldingBox } from '@fortawesome/pro-light-svg-icons/faHandHoldingBox'
import { faHashtag } from '@fortawesome/pro-light-svg-icons/faHashtag'
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage'
import { faInboxOut } from '@fortawesome/pro-light-svg-icons/faInboxOut'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle'
import { faInputNumeric } from '@fortawesome/pro-light-svg-icons/faInputNumeric'
import { faLaptopBinary } from '@fortawesome/pro-light-svg-icons/faLaptopBinary'
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup'
import { faListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck'
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl'
import { faLocationDot } from '@fortawesome/pro-light-svg-icons/faLocationDot'
import { faMapPin } from '@fortawesome/pro-light-svg-icons/faMapPin'
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus'
//Light
import { faNotdef } from '@fortawesome/pro-light-svg-icons/faNotdef'
import { faPause } from '@fortawesome/pro-light-svg-icons/faPause'
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen'
import { faPersonToDoor } from '@fortawesome/pro-light-svg-icons/faPersonToDoor'
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay'
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus'
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint'
import { faRectangleBarcode } from '@fortawesome/pro-light-svg-icons/faRectangleBarcode'
import { faRefresh } from '@fortawesome/pro-light-svg-icons/faRefresh'
import { faScannerGun } from '@fortawesome/pro-light-svg-icons/faScannerGun'
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import { faShelves } from '@fortawesome/pro-light-svg-icons/faShelves'
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart'
import { faSquareQuestion } from '@fortawesome/pro-light-svg-icons/faSquareQuestion'
import { faSwap } from '@fortawesome/pro-light-svg-icons/faSwap'
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation'
import { faTruckArrowRight } from '@fortawesome/pro-light-svg-icons/faTruckArrowRight'
import { faTruckRampBox } from '@fortawesome/pro-light-svg-icons/faTruckRampBox'
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie'
import { faWarehouseFull } from '@fortawesome/pro-light-svg-icons/faWarehouseFull'
import { faWeightHanging } from '@fortawesome/pro-light-svg-icons/faWeightHanging'
//solid
import { faSquareA } from '@fortawesome/pro-solid-svg-icons/faSquareA'
import { faSquareL } from '@fortawesome/pro-solid-svg-icons/faSquareL'
import { faSquareU } from '@fortawesome/pro-solid-svg-icons/faSquareU'

library.add(
  faSquareA,
  faSquareU,
  faSquareL,
  faNotdef,
  faPlus,
  faMinus,
  faPlay,
  faTrash,
  faExclamationTriangle,
  faChevronDown,
  faChevronUp,
  faEllipsisV,
  faBarcodeRead,
  faCog,
  faInfoCircle,
  faCheck,
  faTimes,
  faSync,
  faSearch,
  faAt,
  faCalendar,
  faCalendarDays,
  faPause,
  faFilter,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faPersonToDoor,
  faTruckArrowRight,
  faShoppingCart,
  faClipboardListCheck,
  faListCheck,
  faWarehouseFull,
  faWeightHanging,
  faConveyorBelt,
  faBoxCircleCheck,
  faBoxesStacked,
  faCubesStacked,
  faBoxTaped,
  faHandHoldingBox,
  faShelves,
  faBarcodeScan,
  faScannerGun,
  faLayerGroup,
  faUserTie,
  faBuilding,
  faListUl,
  faHashtag,
  faBarcode,
  faRectangleBarcode,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faArrowsDownToLine,
  faCartFlatbedBoxes,
  faLocationDot,
  faImage,
  faPen,
  faDumpster,
  faTruckRampBox,
  faInboxOut,
  faBarsSort,
  faArrowUp19,
  faSquareQuestion,
  faInputNumeric,
  faCircleCheck,
  faTriangleExclamation,
  faSwap,
  faLaptopBinary,
  faMapPin,
  faArrowDownShortWide,
  faArrowUpArrowDown,
  faArrowDownWideShort,
  faChevronRight,
  faFilterCircleXmark,
  faPrint,
  faRefresh
)
