import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { Customer, Item } from '../../apis/apiTypes'
import CustomerCard from '../../cards/ShoppingCart/CustomerCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Separator from '../../components/Separator'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import ShoppingCartListView from '../../views/ShoppingCart/ShoppingCartListView'

export default function ShoppingCartScreen() {
  const customer = useRouteParam<Customer>('customer')
  const navigation = useNavigation()

  function navigateToShoppingCartCreation(article: Item) {
    navigation.navigate(ScreenType.ShoppingCartCreation, { customer, article: article.article, serialNumber: article.serialnumber })
  }

  return (
    <MWS_Screen>
      <CustomerCard customer={customer} />
      <Separator />
      <ItemSelectionView onSelected={navigateToShoppingCartCreation} showResultInModal />
      <ShoppingCartListView customer={customer} />
    </MWS_Screen>
  )
}
