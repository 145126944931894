import { useItemSelector, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { StockPosition } from '../../apis/apiTypes'
import KanbanBoxCard from '../../cards/Kanban/KanbanBoxCard'
import ElusiveButton from '../../components/ElusiveButton'
import ListSpacer from '../../components/MWS/ListSpacer'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import { IMLayout } from '../../constants/Styles'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import useStockTemplate from '../../hooks/useStockTemplate'
import { KanbanBox } from '../../types'
import { KanbanUtils } from '../../utils/KanbanUtils'
import { Utils } from '../../utils/Utils'
import KanbanBoxSelectionView from '../../views/InputViews/KanbanBoxSelectionView'

export default function KanbanCreateListScreen() {
  const { i18n } = useLanguage()

  const navigation = useNavigation()
  const userSettings = useUserSettings()
  const alert = useAlert()
  const boxes = useItemSelector<KanbanBox>([], KanbanUtils.compare)
  const sortedBoxes = useMemo(() => boxes.items.sort(KanbanUtils.sortBySuggestion), [boxes])

  const { stockTemplate } = useStockTemplate(
    () => {
      alert.error(i18n.t('StockTemplateFailedToLoad'))
      navigation.goBack()
    },
    undefined,
    true
  )
  const [kanbanStockPosition, setKanbanStockPosition] = useState<StockPosition | null>(null)

  function handleNavigation() {
    if (!boxes.any || !kanbanStockPosition || !stockTemplate) return
    navigation.navigate('KanbanWorkList', { boxes: [...sortedBoxes], kanbanStockPosition, stockTemplate })
    boxes.clear()
  }

  useEffect(() => {
    api
      .getStockPosition({ id: userSettings?.stockpositionIdKanban })
      .then(result => {
        if (!result.length) throw new Error('No stockPosition found')
        setKanbanStockPosition(result[0])
      })
      .catch(handleKanbanStockpositionLoadingFailure)
  }, [])

  function handleKanbanStockpositionLoadingFailure(reason: unknown) {
    alert.error(i18n.t('KANBAN_LOAD_STOCKPOSITION_ERROR'), reason)
    navigation.goBack()
  }

  return (
    <MWS_Screen>
      <View>
        <KanbanBoxSelectionView onSelected={item => boxes.add(item)} showResultInModal kanbanStockPosition={kanbanStockPosition} />
      </View>
      <ListSpacer />
      <View style={IMLayout.flex.f1}>
        <Title>{Utils.stringValueReplacer(i18n.t('SELECTED_BOXES'), boxes.count)}</Title>
        <FlatList data={sortedBoxes} renderItem={({ item }) => <KanbanBoxCard box={item} onDelete={() => boxes.remove(item)} />} />
      </View>
      <ElusiveButton
        title={!kanbanStockPosition || !stockTemplate ? i18n.t('LOADING_STOCKPOSITION_INFO') : i18n.t('Continue')}
        disabled={!boxes.any || !kanbanStockPosition || !stockTemplate}
        onPress={handleNavigation}
        hideFromKeyBoard
      />
    </MWS_Screen>
  )
}
