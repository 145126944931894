import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LayoutChangeEvent, LayoutRectangle, StyleSheet, View, ViewProps } from 'react-native'

export type GridProps<T> = {
  data: T[]
  renderItem: (item: T, index: number, data: T[]) => JSX.Element
  maxElementsPerRow?: number
  elementWidth?: number
  maxNumberOfRows?: number
  hiddenElementsIndicator?: (hiddenElements: T[]) => JSX.Element
}

export default function Grid<T>({
  data,
  renderItem,
  maxElementsPerRow,
  maxNumberOfRows,
  elementWidth,
  style,
  hiddenElementsIndicator,
  ...viewProps
}: GridProps<T> & ViewProps) {
  const elementCount = data.length
  const [elementsPerRow, setElementsPerRow] = useState(maxElementsPerRow ?? 1)
  const rows = useMemo(
    () => Array.from({ length: Math.min(maxNumberOfRows ?? 1e100, Math.ceil(elementCount / elementsPerRow)) }).map((_q, index) => index),
    [data, elementsPerRow, maxNumberOfRows]
  )
  const layout = useRef<LayoutRectangle | null>(null)
  const hiddenElementCount = Math.max(elementCount - elementsPerRow * rows.length, 0)

  useEffect(() => {
    calcElementsPerRow()
  }, [elementWidth, maxElementsPerRow])

  function handleLayoutChanged(event: LayoutChangeEvent) {
    layout.current = event.nativeEvent.layout
    calcElementsPerRow()
  }

  function calcElementsPerRow() {
    if (!elementWidth || !layout.current) {
      setElementsPerRow(maxElementsPerRow ?? 1e100)
      return
    }
    let newElementsPerRow = Math.floor(layout.current.width / elementWidth)
    if (maxElementsPerRow && elementsPerRow > maxElementsPerRow) newElementsPerRow = maxElementsPerRow
    setElementsPerRow(Math.max(newElementsPerRow, 1))
  }

  return (
    <View style={[styles.main, style]} onLayout={handleLayoutChanged} {...viewProps}>
      {rows.map(row => (
        <View key={`GridRow${row}`} style={styles.row}>
          {data
            .slice(row * elementsPerRow, row * elementsPerRow + elementsPerRow)
            .map((item, index) => renderItem(item, row * elementsPerRow + index, data))}
        </View>
      ))}
      {!!hiddenElementCount && !!hiddenElementsIndicator && hiddenElementsIndicator(data.slice(-hiddenElementCount))}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {},
  row: {
    flexDirection: 'row',
  },
})
