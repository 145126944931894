import React from 'react'
import { RefreshControl, RefreshControlProps } from 'react-native'

import { Colors } from '../constants/Colors'

export default function IMRefreshControl(props: RefreshControlProps) {
  const { colors, tintColor, ...otherProps } = props

  return <RefreshControl colors={colors ?? [Colors.tint]} tintColor={tintColor ?? Colors.tint} {...otherProps} />
}
