import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { ArticleMeasurementUnit } from '../../../apis/apiTypes'
import { Article } from '../../../classes/Article'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import UnitPicker from '../Inputs/UnitPicker'

export default function ArticleUnitPicker(props: {
  article: Article
  onChange?: (unit: ArticleMeasurementUnit) => void
  style?: StyleProp<ViewStyle>
}) {
  const { i18n } = useLanguage()

  const article = props.article

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
        },
        props.style,
      ]}>
      <UnitPicker
        title={`${i18n.t('Availability')} - `}
        unit={article.getUsedUnit()}
        units={article.info.measurementUnits}
        onUnitSelected={unit => {
          article.setUsedUnit(unit)
          if (props.onChange) props.onChange(unit)
        }}
        captionExtractor={unit => article.getUnitText(unit)}
      />
    </View>
  )
}
