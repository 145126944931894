import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { View } from 'react-native'

import { Deposit, PackingList } from '../../apis/apiTypes'
import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { Colors } from '../../constants/Colors'
import { MWS_COLORS } from '../../constants/Constants'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard, { MWSBaseCardProps } from '../MWS_BaseCard'

export type PackingListCardProps = {
  packingList: PackingList
  showDate?: boolean
  showDepositContra?: boolean
  endButtonIcon?: IMIconName
  showStateColor?: boolean
  colloInfo?: { colloCount?: number; weight?: number } | false
} & MWSBaseCardProps

export default function PackingListCard({
  packingList,
  showDate,
  showDepositContra,
  style,
  colloInfo,
  endButtonIcon,
  showStateColor = true,
  ...inputProps
}: PackingListCardProps) {
  const { i18n } = useLanguage()

  const backgroundColor = packingList.countOfColli > 0 && showStateColor ? MWS_COLORS.PARTIALLY_OK : MWS_COLORS.NOT_STARTED
  const orders = packingList.orders?.filter(q => !!q.number?.trim())
  const colloInfoText = useMemo(
    () =>
      !!colloInfo &&
      colloInfo?.colloCount &&
      [
        `${colloInfo.colloCount} ${colloInfo.colloCount > 1 ? i18n.t('COLLOS') : i18n.t('Collo')}`,
        colloInfo.weight ? `${colloInfo.weight} ${i18n.t('WeightUnit')}` : '',
      ]
        .filter(Boolean)
        .join(' - '),
    [colloInfo]
  )

  function getDepositText(deposit?: Deposit) {
    if (!deposit) return ''
    return `${deposit?.code} - ${deposit?.description}`
  }

  return (
    <MWS_BaseCard
      icon={'clipboard-check-list'}
      style={[{ justifyContent: 'flex-start', flexDirection: 'column', backgroundColor }, style]}
      endButton={
        endButtonIcon
          ? { icon: endButtonIcon, buttonStyle: { backgroundColor: backgroundColor }, color: Colors.light.text, disabled: true }
          : undefined
      }
      {...inputProps}>
      <View style={{ flexDirection: 'row' }}>
        <IMText style={{ flex: 1, color: Colors.light.text }} primary>
          {packingList.number}
        </IMText>
        {!!showDate && !!packingList.deliverydate && (
          <IMText style={{ color: Colors.light.text }}>{Utils.FormatDate(packingList.deliverydate, 'DD.MM.YYYY')}</IMText>
        )}
      </View>
      <IMTextWithIcon
        icon="building"
        numberOfLines={1}
        text={packingList.customer}
        iconColor={Colors.light.textDetail}
        style={{ color: Colors.light.textDetail }}
      />
      {!!packingList.deliveryaddress && packingList.deliveryaddress.length > 0 && (
        <IMTextWithIcon
          icon="location-dot"
          text={packingList.deliveryaddress}
          iconColor={Colors.light.textDetail}
          style={{ color: Colors.light.textDetail }}
        />
      )}
      {showDepositContra && !!packingList.depositContra && packingList.internalPackinglist && (
        <IMTextWithIcon
          icon="location-dot"
          text={getDepositText(packingList.depositContra)}
          iconColor={Colors.light.textDetail}
          style={{ color: Colors.light.textDetail }}
        />
      )}
      {!!packingList.freighter && (
        <IMTextWithIcon
          icon="truck-arrow-right"
          text={packingList.freighter.description}
          iconColor={Colors.light.textDetail}
          style={{ color: Colors.light.textDetail }}
        />
      )}
      {!!orders?.length && (
        <IMTextWithIcon
          icon="clipboard-check-list"
          text={Utils.join(orders, o => o.number?.trim() ?? '', ', ')}
          iconColor={Colors.light.textDetail}
          style={{ color: Colors.light.textDetail }}
        />
      )}
      {!!colloInfoText && (
        <IMTextWithIcon icon="box-taped" text={colloInfoText} iconColor={Colors.light.textDetail} style={{ color: Colors.light.textDetail }} />
      )}
    </MWS_BaseCard>
  )
}
