import { Customer, Deposit, StockPosition } from '../apis/apiTypes'
import { Utils } from './Utils'

export const shoppingCartUtils = {
  generateListName(customer: Customer) {
    return `${customer?.number} - ${Utils.FormatDate(new Date(), 'DD.MM.yyyy') ?? ''}`.replace(/\s/g, '')
  },
  isStockposition(deposit: Deposit | undefined, stockPosition: StockPosition | undefined) {
    if (!deposit || !stockPosition) return false
    if (deposit.isStockpositionActive && stockPosition) return true
    return false
  },
}
