import { useLanguage, useToastMessage, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'

import api from '../../apis/apiCalls'
import { PackingListConclusionType } from '../../apis/apiRequestTypes'
import { PrintingType } from '../../apis/apiTypes'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { PackingListToConclude, ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import useAlert from '../useAlert'

type PackingListConclusionParams = {
  closeOnSuccess: boolean
}

export default function usePackingListConclusion() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { isPreConsignment, isProductionConsignment, userSettings } = usePackingList()
  const loader = useLoadingIndicator()
  const toast = useToastMessage()

  async function concludePackingLists(packingListsToConclude: PackingListToConclude[], params: PackingListConclusionParams) {
    try {
      loader.setLoading(true)
      if (isPreConsignment) {
        await concludePreConsignment(packingListsToConclude)
      } else if (isProductionConsignment) {
        await concludeProductionConsignment(packingListsToConclude)
      } else {
        await concludePackingListPacking(packingListsToConclude, params)
      }
      onConcludeDone(params.closeOnSuccess)
    } catch (exception) {
      showErrorAlert(exception)
    } finally {
      loader.setLoading(false)
    }
  }

  async function concludePackingListPacking(packingLists: PackingListToConclude[], _: PackingListConclusionParams) {
    for (const element of packingLists) {
      const { collos, packingList, freighter, colloCount, isPrinting, isCreateDeliveryNote } = element
      let weight = element.weight
      if (collos?.length) {
        const colloPackingRequest = {
          packinglistId: packingList?.id ?? '',
          collaboratorId: userSettings?.employeeId,
          printLabel: true,
          colli: collos,
        }
        // only if NOT collo consignment: send collos (packages) to be created
        await api.colloPacking(colloPackingRequest)

        // update the weight of the packingList
        weight = Utils.sum(collos, c => c.weight ?? 0)
      }
      const request = {
        collaboratorId: userSettings?.employeeId,
        conclusionTyp: PackingListConclusionType.concludeAndCreateTransfer,
        freighterId: freighter?.id,
        packages: colloCount,
        packingListId: packingList?.id,
        weight: weight,
        printing: isPrinting ? PrintingType.printing : PrintingType.notPrinting,
        createDeliveryNote: isCreateDeliveryNote,
      }

      await api.postPackingListConclude(request)
    }
  }

  async function concludePreConsignment(packingListsToConclude: PackingListToConclude[]) {
    const requests =
      packingListsToConclude?.map(pl => {
        return api.postPackingListConclude({
          packingListId: pl.packingList.id,
          conclusionTyp: PackingListConclusionType.preCommissioning,
          collaboratorId: userSettings?.employeeId,
        })
      }) ?? []
    await Promise.allSettled(requests)
  }

  async function concludeProductionConsignment(packingListsToConclude: PackingListToConclude[]) {
    const requests =
      packingListsToConclude?.map(pl => {
        return api.postPackingListConclude({
          packingListId: pl.packingList.id,
          conclusionTyp: PackingListConclusionType.concludeAndCreateTransfer,
          collaboratorId: userSettings?.employeeId,
        })
      }) ?? []
    await Promise.allSettled(requests)
  }

  function onConcludeDone(closeOnSuccess: boolean | undefined) {
    toast.show(i18n.t('PackingListConclusionSuccessful'))

    if (!closeOnSuccess) return

    if (isProductionConsignment) {
      navigation.navigate(ScreenType.ProductionConsignmentListSelection, {
        loadList: !!userSettings?.isShowPackingListAfterConclude,
      })
      return
    }

    navigation.navigate(isProductionConsignment ? ScreenType.ProductionConsignmentListSelection : ScreenType.PackingListSelection, {
      loadList: !!userSettings?.isShowPackingListAfterConclude,
      isPreConsignment,
    })
  }

  function showErrorAlert(reason?: unknown) {
    console.error(reason)
    alert.error(i18n.t('FailedToConcludePackingList'), Error_Utils.extractErrorMessageFromException(reason))
  }

  return {
    concludePackingLists,
  }
}
