/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { screenConfig } from '../navigation'
import { ScreenType } from '../types'

const screenUtils = {
  getConfig(screenType: ScreenType) {
    if (!screenType) return undefined
    return screenConfig.find(m => m.type === screenType)
  },
  showSettings(screenType?: ScreenType) {
    if (!screenType) return false
    return !!this.getConfig(screenType)?.showSettings || false
  },
  getScreenType(type: string) {
    return Object.keys(ScreenType).find(k => k.toLowerCase() === type.toLowerCase()) as ScreenType
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getParamFromRoute(route: any, key: string) {
    if (!route || !route.params) return undefined
    const foundKey = Object.keys(route.params).find(k => k.toLowerCase() === key.toLowerCase())
    if (!foundKey) return undefined
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return route.params[foundKey]
  },
}

export default screenUtils
