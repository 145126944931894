import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import { PackingListOrder } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import MWS_BaseCard, { MWSBaseCardProps } from '../MWS_BaseCard'

export default function PackingListOrderCard(props: { order: PackingListOrder; quantity?: string; articles?: string } & MWSBaseCardProps) {
  const { i18n } = useLanguage()

  const { order, style, quantity, ...inputProps } = props

  return (
    <MWS_BaseCard icon={'box-taped'} style={[{ justifyContent: 'flex-start' }, style]} {...inputProps}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <IMText primary={!!order.number}>{order.number || i18n.t('NoOrder')}</IMText>
        {!!props.articles && <IMTextWithIcon numberOfLines={1} icon="article" secondary text={props.articles} />}
      </View>

      {!!quantity && <IMText>{quantity}</IMText>}
    </MWS_BaseCard>
  )
}
