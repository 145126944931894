import { IM } from '@infominds/react-native-components'
import React from 'react'
import { Platform } from 'react-native'
import AutoHeightWebView from 'react-native-autoheight-webview'

export type HtmlViewProps = { html?: string }

export default function HtmlView({ html }: HtmlViewProps) {
  if (!html || Platform.OS === 'web') return null

  return (
    <IM.View>
      <AutoHeightWebView
        style={{ width: '100%' }}
        source={{
          html: html,
        }}
        viewportContent={'width=device-width, user-scalable=no'}
      />
    </IM.View>
  )
}
