import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { SerialNumber, StockPosition } from '../../apis/apiTypes'
import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function SerialnumberCard(props: {
  serialnumber: SerialNumber | string | undefined
  infoText?: string | false
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: false | (() => void)
  quantity?: string | false
  reservedQuantity?: string | false
  showArticle?: boolean
  showInfoTexts?: boolean
  selected?: boolean
  hideIcon?: boolean
  hideManufacturer?: boolean
  stockPosition?: StockPosition
  isMarked?: boolean | IMIconName
  showWarrantyDate?: boolean
  showIncomingDate?: boolean
  macAddressLabel?: string
}) {
  const { i18n } = useLanguage()

  const serialnumber = useMemo(() => {
    switch (typeof props.serialnumber) {
      case 'object':
        return props.serialnumber
      case 'string':
        return { number: props.serialnumber } as SerialNumber
      default:
        return undefined
    }
  }, [])

  const showArticle = !!(props.showArticle && serialnumber?.article?.code)
  const serialNumberString = useMemo(() => {
    let sn = serialnumberUtils.getSerialNumberString(props.serialnumber, props.hideManufacturer, props.macAddressLabel)
    if (props.showInfoTexts) sn = `${i18n.t('SerialNumberShort')}: ${sn}`
    return sn
  }, [props.serialnumber])

  const warrantyDate = props.showWarrantyDate ? Utils.parseDate(serialnumber?.warrantySupplier) : undefined
  const incomingDate = props.showIncomingDate ? Utils.parseDate(serialnumber?.incomingDate) : undefined

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={!props.hideIcon ? 'barcode' : undefined}
      isSelected={props.selected}
      cardStyle={props.style}
      isMarked={props.isMarked}
      style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        {showArticle && (
          <IMText primary>{`${props.showInfoTexts ? String(i18n.t('Article')) + ': ' : ''}${serialnumber?.article?.code?.trim()}`}</IMText>
        )}
        <IMText primary>{serialNumberString}</IMText>
        {!!props.infoText && <IMText secondary>{props.infoText}</IMText>}
        {!!props.stockPosition && <IMTextWithIcon icon="shelf" text={stockPositionUtils.getTitle(props.stockPosition)} />}
        {!!warrantyDate && <IMTextWithIcon icon="calendar" text={Utils.FormatDate(warrantyDate, 'DD.MM.YYYY') ?? ''} />}
        {!!incomingDate && (
          <IMText secondary>{`${i18n.t('LastWarehouseEntrance')}: ${Utils.FormatDate(incomingDate, 'DD.MM.yyyy') ?? ''}` ?? ''}</IMText>
        )}
      </View>
      {props.quantity && (
        <View style={{ justifyContent: 'center' }}>
          <IMText style={{ textAlign: 'right' }}>{props.quantity}</IMText>
          {!!props.reservedQuantity && <IMText secondary>{i18n.t('RESERVIERT') + ': ' + props.reservedQuantity}</IMText>}
        </View>
      )}
    </MWS_BaseCard>
  )
}
