/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */

import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

type RuntimeStorageContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storage: any
  setStorage: Dispatch<SetStateAction<{}>>
}

const RuntimeStoringContext = createContext<RuntimeStorageContextType>({ storage: {}, setStorage: () => {} })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RuntimeStoringContextProvider(props: { children: any }) {
  const [storage, setStorage] = useState({})
  return <RuntimeStoringContext.Provider value={{ storage, setStorage }}>{props.children}</RuntimeStoringContext.Provider>
}

export function useRuntimeStorage(globalStorageKey?: string) {
  const { storage, setStorage } = useContext(RuntimeStoringContext)

  function getKey(storageKey?: string) {
    if (!storageKey && !globalStorageKey) throw new SyntaxError('one of globalStorageKey or storageKey has to be set')
    return (storageKey ?? globalStorageKey)?.toUpperCase() ?? ''
  }

  function hasValue(storageKey?: string) {
    const key = getKey(storageKey)
    return Object.keys(storage).includes(key)
  }

  function getValue<T>(storageKey?: string) {
    if (!hasValue(storageKey)) return undefined
    const key = getKey(storageKey)
    return storage[key] as T
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function setValue(value: any, storageKey?: string) {
    const key = getKey(storageKey)
    setStorage(prev => ({ ...prev, [key]: value }))
  }

  return { hasValue, getValue, setValue }
}
