import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Colors } from '../constants/Colors'

export type CustomAlertButton<T> = {
  id: keyof T
  type: 'confirm' | 'deny' | 'cancel'
  text: string
}

export function useCustomAlert<T>(title: string, message: string, buttons?: CustomAlertButton<T>[], _cancelable?: boolean) {
  const alert = withReactContent(Swal)

  function show() {
    return new Promise<keyof T>((resolve, reject) => {
      const confirmButton = buttons?.find(b => b.type === 'confirm')
      const denyButton = buttons?.find(b => b.type === 'deny')
      const cancelButton = buttons?.find(b => b.type === 'cancel')

      alert
        .fire({
          title,
          text: message,
          confirmButtonText: confirmButton?.text,
          showCancelButton: !!cancelButton,
          cancelButtonText: cancelButton?.text,
          denyButtonText: denyButton?.text,
          showDenyButton: !!denyButton,
          reverseButtons: true,
          confirmButtonColor: Colors.grey,
          denyButtonColor: Colors.grey,
        })
        .then(result => {
          if (result.isConfirmed && confirmButton) resolve(confirmButton?.id)
          else if (result.isDismissed && cancelButton) resolve(cancelButton?.id)
          else if (result.isDenied && denyButton) resolve(denyButton?.id)
          else reject()
        })
        .catch(reject)
    })
  }

  return { show }
}
