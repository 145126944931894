import React from 'react'
import { View } from 'react-native'

import { ProductionMaster } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { Colors } from '../../constants/Colors'
import MWS_BaseCard, { MWSBaseCardProps } from '../MWS_BaseCard'

export default function ProductionMasterCard(props: { production: ProductionMaster } & MWSBaseCardProps) {
  const { production: productionMasterList, style, ...inputProps } = props

  return (
    <MWS_BaseCard icon={'clipboard-check-list'} style={[{ justifyContent: 'flex-start', flexDirection: 'column' }, style]} {...inputProps}>
      <View style={{ flexDirection: 'row' }}>
        <IMText style={{ flex: 1, color: Colors.light.text }} primary>
          {productionMasterList.code}
        </IMText>
      </View>
      {productionMasterList.customer && (
        <IMTextWithIcon
          icon="building"
          numberOfLines={1}
          text={productionMasterList.customer.description}
          iconColor={Colors.light.textDetail}
          style={{ color: Colors.light.textDetail }}
        />
      )}
      {productionMasterList.article && (
        <IMTextWithIcon
          icon="article"
          numberOfLines={1}
          text={`${productionMasterList.article.code} - ${productionMasterList.article.searchtext}`}
          iconColor={Colors.light.textDetail}
          style={{ color: Colors.light.textDetail }}
        />
      )}
    </MWS_BaseCard>
  )
}
