import React, { useEffect, useRef } from 'react'
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import PackingListColloGroupCard from '../../cards/Packinglist/PackingListColloGroupCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import ListSpacer from '../../components/MWS/ListSpacer'
import Title from '../../components/MWS/Title'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import useGroupedCollos, { PackingListColloGroup } from '../../hooks/packingLists/useGroupedCollos'
import colloUtils from '../../utils/colloUtils'

export type PackingListMovementCollosViewProps = {
  show: boolean
  packingListArticle: PackingListArticle | undefined
  title?: string
  onDelete: (collo: Collo[]) => void
  onPress: (collo: Collo) => void
  style?: StyleProp<ViewStyle>
}

export default function PackingListMovementCollosView({
  show,
  packingListArticle,
  title,
  onDelete,
  onPress,
  style,
}: PackingListMovementCollosViewProps) {
  const listRef = useRef<FlatList<PackingListColloGroup | string> | null>(null)
  const { colloSelector } = usePackingListMovement()

  const items = useGroupedCollos(colloSelector.items, packingListArticle?.movements)

  useEffect(() => {
    if (!listRef?.current) return
    listRef.current.scrollToEnd()
  }, [items])

  function handleColloQuantityChanged(quantity: number, collo: Collo | undefined) {
    if (!collo) return
    colloSelector.set(prev => prev.map(c => (colloUtils.compare(c, collo) ? { ...c, quantity } : c)))
  }

  if (!show) return <></>
  return (
    <View style={[styles.list, style]}>
      {title && items && <Title>{title}</Title>}
      <FlatList
        ref={listRef}
        data={items}
        renderItem={({ item }) => (
          <>
            {typeof item === 'string' && <Title style={styles.title}>{item}</Title>}
            {typeof item !== 'string' && (
              <PackingListColloGroupCard
                group={item}
                showSerialWarranty
                onDelete={!!onDelete && (collosToDelete => onDelete(collosToDelete?.map(c => c.collo)))}
                onEdit={onPress}
                onQuantityChanged={handleColloQuantityChanged}
              />
            )}
          </>
        )}
        ListHeaderComponent={<ListSpacer />}
        ListFooterComponent={<ListSpacer />}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  title: {
    marginTop: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS,
  },
})
