import { useLanguage, useModalController, Utils, View } from '@infominds/react-native-components'
import { cloneDeep } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList } from 'react-native'

import { Deposit, PackingList } from '../../apis/apiTypes'
import SplitColloCard from '../../cards/Collo/SplitColloCard'
import ElusiveButton from '../../components/ElusiveButton'
import FullScreenModal from '../../components/FullScreenModal'
import IMText from '../../components/IMText'
import TextWithIconButton from '../../components/TextWithIconButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { ModalController } from '../../hooks/useModalController'
import { ColloPackingSelection } from '../../views/PackingList/ColloPackingView'
import ColloPackingModal from '../ColloPackingModal'

export type PackingListColloDetailModalProps = {
  controller: ModalController<ColloPackingSelection[]>
  packingList: PackingList
  onCollosSelected: (collos: ColloPackingSelection[]) => void
  proposedQuantity?: number
  proposedWeight?: number
}

export default function PackingListColloDetailModal({ controller, ...props }: PackingListColloDetailModalProps) {
  const { i18n } = useLanguage()

  return (
    <FullScreenModal
      isVisible={controller.isShown}
      close={() => {
        controller.close()
      }}
      title={i18n.t('COLLO_DETAILS')}>
      <Content controller={controller} {...props} />
    </FullScreenModal>
  )
}

function Content({ controller, packingList, onCollosSelected, proposedQuantity, proposedWeight }: PackingListColloDetailModalProps) {
  const { i18n } = useLanguage()
  const { packingLists, userSettings, movements, colloMode } = usePackingList()
  const packingModalController = useModalController<ColloPackingSelection>()
  const autoOpenModal = useRef(false)
  const [collos, setCollos] = useState<ColloPackingSelection[]>(() => cloneDeep(controller.data ?? []))
  const articleCount = useMemo(
    () =>
      Utils.keepUniques(
        movements
          ?.map(m => m.movements)
          .flat()
          .filter(m => m.packingList.id === packingList.id) ?? [],
        q => q.movement.article.id
      ).length,
    [movements]
  )

  useEffect(() => {
    if (!colloMode && !collos.length && userSettings?.isShowAddColloNumberOnConcludePackingListWithInactivePalletPicking) {
      packingModalController.show({ quantity: proposedQuantity ?? 1, weight: proposedWeight })
      autoOpenModal.current = true
    }
  }, [])

  function handleColloPressed(collo: ColloPackingSelection | undefined) {
    if (collo) {
      packingModalController.show(collo)
      return
    }
    const anyCollo = collos.find(s => s?.packagingType)
    packingModalController.show({ ...anyCollo, quantity: 1, id: undefined })
  }

  function handlePackingEndEdit(packing: ColloPackingSelection) {
    autoOpenModal.current = false
    setCollos(prev => {
      if (packing.id) {
        return prev.map(p => (p.id !== packing.id ? p : packing))
      }
      if (!packing.quantity || packing.quantity < 0) return prev
      for (let c = 0; c < packing.quantity; c++) {
        const id = Utils.getUid()
        prev.push({ ...packing, id, quantity: 1 })
      }

      return [...prev]
    })
  }

  function handleConfirm() {
    onCollosSelected(collos)
    controller.close()
  }

  function getDepositText(deposit?: Deposit) {
    if (!deposit) return ''
    return `${i18n.t('Target')}: ${deposit?.code} - ${deposit?.description}`
  }

  return (
    <View style={{ flex: 1 }}>
      {!colloMode && (
        <TextWithIconButton
          icon={'plus'}
          style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          onIconPressed={() => handleColloPressed(undefined)}>
          {Utils.stringValueReplacer(i18n.t('ArticlesAndCollos'), articleCount, collos.length)}
        </TextWithIconButton>
      )}
      <FlatList
        data={collos}
        renderItem={({ item, index }) => (
          <SplitColloCard
            collo={item}
            index={index + 1}
            onPress={colloMode ? undefined : () => handleColloPressed(item)}
            onDelete={
              colloMode
                ? undefined
                : () => {
                    setCollos(prev => {
                      prev = prev.filter(q => q.id !== item.id)
                      return [...prev]
                    })
                  }
            }
          />
        )}
      />
      {packingLists !== undefined && packingLists.length > 0 && packingLists[0].internalPackinglist && packingLists[0].depositContra && (
        <IMText primary style={{ marginLeft: 10 }}>
          {getDepositText(packingLists[0].depositContra)}
        </IMText>
      )}

      <ElusiveButton style={{ marginTop: 0 }} hide={colloMode} title={i18n.t('Confirm')} onPress={() => handleConfirm()} />
      <ColloPackingModal
        controller={packingModalController}
        onConfirm={handlePackingEndEdit}
        onAborted={() => {
          if (autoOpenModal.current) controller.close()
        }}
      />
    </View>
  )
}
