import { useEffect, useState } from 'react'

import api from '../apis/apiCalls'
import { CONSTANTS, StorageKeys } from '../constants/Constants'
import useAsyncStorage from './useAsyncStorage'

export default function usePrinter() {
  const [printerNumber, setPrinterNumber] = useState<string | null>(null)
  const storage = useAsyncStorage(StorageKeys.printerNr)

  useEffect(() => {
    if (printerNumber === null) {
      storage
        .load<string>()
        .then(result => setPrinterNumber(result ?? '0'))
        .catch(console.error)
    } else {
      storage.save(printerNumber).catch(console.error)
    }
  }, [printerNumber])

  async function isEnabled() {
    const menu = await api.getMenu()
    return !!menu.find(element => CONSTANTS.PRINTER_ENABLED_IF_MENU_EXISTS.includes(element.actionType))
  }

  return { printerNumber, setPrinterNumber, isEnabled }
}
