import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../../constants/Constants'
import useAlert from '../../hooks/useAlert'
import { IMIconButton } from '../IMIconButton'
import Title from './Title'

export default function TitleWithInfoButton(props: { text: string; infoText: string | undefined; style?: StyleProp<ViewStyle> }) {
  const alert = useAlert()
  const { text, infoText, style } = props

  return (
    <View style={[{ flexDirection: 'row', marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }, style]}>
      <View style={{ flex: 1 }} />
      <Title numberOfLines={2} style={{ flex: 10, justifyContent: 'center' }}>
        {text}
      </Title>
      <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-start' }}>
        {!!infoText && (
          <IMIconButton
            style={{ justifyContent: 'center' }}
            iconSize={20}
            icon={'infoCircle'}
            onPress={() => {
              alert.info(infoText)
            }}
          />
        )}
      </View>
    </View>
  )
}
