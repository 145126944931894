import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'

import ElusiveButton from '../components/ElusiveButton'
import FullScreenModal from '../components/FullScreenModal'
import ListSpacer from '../components/MWS/ListSpacer'
import LoadingIndicator from '../components/old/LoadingIndicator'
import useItemSelector from '../hooks/useItemSelector'

export default function SelectionModalFullScreen<T>(props: {
  show: boolean
  title?: string
  items: T[]
  onMultiSelect?: (items: T[]) => void
  autoSelectAll?: boolean
  close: (item?: T | undefined) => void
  renderItem: (item: T, index: number, isSelected?: boolean) => JSX.Element
  showCancelButton?: boolean
  onButtonNewPressed?: () => void
  loading?: boolean
  disabled?: boolean
}) {
  const { i18n } = useLanguage()

  const itemSelector = useItemSelector<T>([])

  useEffect(() => {
    itemSelector.clear()
    if (!props.show) return
    if (props.autoSelectAll) itemSelector.set(props.items)
  }, [props.show])

  function handleItemSelection(item: T) {
    if (!props.onMultiSelect) return props.close(item)
    if (props.items.length === 1) {
      props.onMultiSelect(props.items)
      props.close()
      return
    }
    itemSelector.toggle(item)
  }

  function handleSubmit() {
    if (props.onMultiSelect) props.onMultiSelect(itemSelector.items)
    props.close()
  }

  return (
    <FullScreenModal isVisible={props.show} close={props.close} title={props.title ?? i18n.t('SearchResults')}>
      {!!props.loading && (
        <View style={{ flex: 1 }}>
          <LoadingIndicator isVisible={true} />
        </View>
      )}
      {!props.loading && (
        <FlatList
          style={{ flex: 1 }}
          data={props.items}
          renderItem={({ item, index }) => (
            <TouchableOpacity onPress={() => handleItemSelection(item)} disabled={props.disabled}>
              {props.renderItem(item, index, itemSelector.includes(item))}
            </TouchableOpacity>
          )}
          ListFooterComponent={<ListSpacer />}
        />
      )}
      <View style={{ flexDirection: 'row' }}>
        {props.showCancelButton && <ElusiveButton style={{ flex: 1, marginRight: 0 }} title={i18n.t('CANCEL')} onPress={() => props.close()} />}
        {!!props.onButtonNewPressed && (
          <ElusiveButton
            style={{ flex: 1 }}
            title={i18n.t('New')}
            onPress={() => {
              if (props.onButtonNewPressed) props.onButtonNewPressed()
            }}
          />
        )}
        <ElusiveButton
          style={{ flex: 1 }}
          hide={!props.onMultiSelect}
          title={itemSelector.any ? i18n.t('Add') : i18n.t('Close')}
          onPress={handleSubmit}
        />
      </View>
    </FullScreenModal>
  )
}
