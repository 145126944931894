import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import * as React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { IMIconName } from './IMIconNames'

function getIcon(name: IMIconName): IconProp {
  if (name === 'plus') return ['fal', 'plus']
  else if (name === 'minus') return ['fal', 'minus']
  else if (name === 'arrow-left') return ['fal', 'arrow-left']
  else if (name === 'arrow-right') return ['fal', 'arrow-right']
  else if (name === 'arrow-up') return ['fal', 'arrow-up']
  else if (name === 'arrow-down') return ['fal', 'arrow-down']
  else if (name === 'play') return ['fal', 'play']
  else if (name === 'pause') return ['fal', 'pause']
  else if (name === 'trash') return ['fal', 'trash']
  else if (name === 'exclamationTriangle') return ['fal', 'exclamation-triangle']
  else if (name === 'chevronDown') return ['fal', 'chevron-down']
  else if (name === 'chevronUp') return ['fal', 'chevron-up']
  else if (name === 'chevronRight') return ['fal', 'chevron-right']
  else if (name === 'ellipsisV') return ['fal', 'ellipsis-v']
  else if (name === 'barcodeRead') return ['fal', 'barcode-read']
  else if (name === 'cog') return ['fal', 'cog']
  else if (name === 'infoCircle') return ['fal', 'info-circle']
  else if (name === 'check') return ['fal', 'check']
  else if (name === 'times') return ['fal', 'times']
  else if (name === 'sync') return ['fal', 'sync']
  else if (name === 'search') return ['fal', 'search']
  else if (name === 'at') return ['fal', 'at']
  else if (name === 'calendar') return ['fal', 'calendar']
  else if (name === 'calendar-days') return ['fal', 'calendar-days']
  else if (name === 'filter') return ['fal', 'filter']
  else if (name === 'angle-up') return ['fal', 'angle-up']
  else if (name === 'angle-down') return ['fal', 'angle-down']
  else if (name === 'angle-right') return ['fal', 'angle-right']
  else if (name === 'angle-left') return ['fal', 'angle-left']
  else if (name === 'logout') return ['fal', 'person-to-door']
  else if (name === 'notDef') return ['fal', 'notdef']
  //MobileWareHouse
  else if (name === 'truck-arrow-right') return ['fal', 'truck-arrow-right']
  else if (name === 'shopping-cart') return ['fal', 'shopping-cart']
  else if (name === 'clipboard-check-list') return ['fal', 'clipboard-list-check']
  else if (name === 'warehouse-full') return ['fal', 'warehouse-full']
  else if (name === 'weight-hanging') return ['fal', 'weight-hanging']
  else if (name === 'conveyor-belt-box') return ['fal', 'conveyor-belt']
  else if (name === 'box-taped') return ['fal', 'box-taped']
  else if (name === 'box-circle-check') return ['fal', 'box-circle-check']
  else if (name === 'boxes-stacked') return ['fal', 'boxes-stacked']
  else if (name === 'cubes-stacked') return ['fal', 'cubes-stacked']
  else if (name === 'hand-holding-box') return ['fal', 'hand-holding-box']
  else if (name === 'shelf') return ['fal', 'shelves']
  else if (name === 'barcode-read') return ['fal', 'barcode-read']
  else if (name === 'barcode-scan') return ['fal', 'barcode-scan']
  else if (name === 'barcode') return ['fal', 'barcode']
  else if (name === 'barcode-rectangle') return ['fal', 'rectangle-barcode']
  else if (name === 'scanner-gun') return ['fal', 'scanner-gun']
  else if (name === 'article') return ['fal', 'layer-group']
  else if (name === 'user-tie') return ['fal', 'user-tie']
  else if (name === 'building') return ['fal', 'building']
  else if (name === 'list-ul') return ['fal', 'list-ul']
  else if (name === 'hashtag') return ['fal', 'hashtag']
  else if (name === 'arrow-right-to-bracket') return ['fal', 'arrow-right-to-bracket']
  else if (name === 'arrow-right-from-bracket') return ['fal', 'arrow-right-from-bracket']
  else if (name === 'arrows-down-to-line') return ['fal', 'arrows-down-to-line']
  else if (name === 'cart-flatbed-boxes') return ['fal', 'cart-flatbed-boxes']
  else if (name === 'A') return ['fas', 'square-a']
  else if (name === 'U') return ['fas', 'square-u']
  else if (name === 'L') return ['fas', 'square-l']
  else if (name === 'square-question') return ['fal', 'square-question']
  else if (name === 'location-dot') return ['fal', 'location-dot']
  else if (name === 'image') return ['fal', 'image']
  else if (name === 'pen') return ['fal', 'pen']
  else if (name === 'dumpster') return ['fal', 'dumpster']
  else if (name === 'truck-ramp-box') return ['fal', 'truck-ramp-box']
  else if (name === 'inbox-out') return ['fal', 'inbox-out']
  else if (name === 'sort') return ['fal', 'bars-sort']
  else if (name === 'sortNumber') return ['fal', 'arrow-up-1-9']
  else if (name === 'keyCode') return ['fal', 'input-numeric']
  else if (name === 'list-check') return ['fal', 'list-check']
  else if (name === 'circle-check') return ['fal', 'circle-check']
  else if (name === 'triangle-exclamation') return ['fal', 'triangle-exclamation']
  else if (name === 'swap') return ['fal', 'swap']
  else if (name === 'laptop-binary') return ['fal', 'laptop-binary']
  else if (name === 'map-pin') return ['fal', 'map-pin']
  else if (name === 'filter-circle-xmark') return ['fal', 'filter-circle-xmark']
  else if (name === 'print') return ['fal', 'print']
  else if (name === 'refresh') return ['fal', 'refresh']
  //default
  else return ['fal', 'notdef']
}

export default function IMIcon(props: {
  icon: IMIconName
  size?: number
  color?: string
  style?: StyleProp<ViewStyle>
  iconStyle?: FontAwesomeIconStyle
}) {
  const icon = React.useMemo(() => getIcon(props.icon), [props.icon])

  return (
    <View style={props.style}>
      <FontAwesomeIcon style={[styles.icon, props.iconStyle]} color={props.color} size={props.size} icon={icon} />
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    // @ts-ignore this is needed for web
    outlineStyle: 'none', // web
  },
})
