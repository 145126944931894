import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import { ArticleMeasurementUnit } from '../../../apis/apiTypes'
import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { articleUtils } from '../../../utils/articleUtils'
import { Utils } from '../../../utils/Utils'
import BaseTextInput from '../../BaseTextInput'
import IMIcon from '../../IMIcon'
import { IMIconName } from '../../IMIconNames'
import IMText from '../../IMText'
import CardBasic from '../../old/CardBasic'
import { TextInputProps } from '../../old/Input'

interface QuantityInputProps {
  value?: number
  onValueChanged?: (value: number) => void
  onSubmit?: (value?: number) => void
  onEndEditing?: (value?: number) => void
  icon?: IMIconName
  text?: string
  unit?: ArticleMeasurementUnit | string
  style?: StyleProp<ViewStyle>
  autoFocus?: boolean
  placeHolder?: string
  inputRef?: React.LegacyRef<TextInput>
  editable?: boolean
  detail?: string
  onDetailPressed?: () => void
  textStyle?: TextInputProps['style']
}

export default function QuantityInput(props: QuantityInputProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { i18n } = useLanguage()
  const [value, setValue] = useState<string>('0')

  const headerExists = !!props.icon || !!props.text

  const unitText = props.unit && (typeof props.unit === 'object' ? articleUtils.getUnitText(props.unit) : props.unit?.trim())

  useEffect(() => {
    const inValue = Utils.convertNumberToString(props.value)
    if (!!inValue && inValue !== value && (!!value || (!!inValue && inValue !== '0'))) setValue(inValue)
  }, [props.value])

  function onTextChange(text: string) {
    const formattedText = Utils.formatNumberString(text)
    setValue(formattedText)
    if (props.onValueChanged) props.onValueChanged(parseText(formattedText))
  }

  function onSubmit() {
    if (props.onSubmit) props.onSubmit(parseText(Utils.formatNumberString(value)))
  }

  function onEndEditing() {
    if (props.onEndEditing) props.onEndEditing(parseText(Utils.formatNumberString(value)))
  }

  function parseText(text: string) {
    let parsedValue = Utils.parseFloatFromText(text)
    if (Number.isNaN(parsedValue) || !Number.isFinite(parsedValue)) parsedValue = 0
    return parsedValue
  }

  return (
    <View style={[styles.main, props.style]}>
      <View style={{ flexDirection: 'row' }}>
        {headerExists && (
          <CardBasic
            style={{ width: props.icon ? undefined : '30%', margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}>
            {props.icon && (
              <IMIcon
                style={{ justifyContent: 'center' }}
                size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
                icon={props.icon}
                color={theme.textDetail}
              />
            )}
            {props.text && (
              <IMText numberOfLines={1} style={{ textAlign: 'center' }}>
                {props.text}
              </IMText>
            )}
          </CardBasic>
        )}
        <BaseTextInput
          inputRef={props.inputRef}
          viewStyle={[{ flex: 2 }, headerExists && styles.noBorderLeft, (!!props.unit || !!props.detail) && styles.noBorderRight]}
          style={[{ textAlign: 'center' }, props.textStyle]}
          placeholder={props.placeHolder ?? i18n.t('Amount')}
          value={value}
          onChangeText={onTextChange}
          onSubmitEditing={onSubmit}
          onEndEditing={onEndEditing}
          onBlur={onEndEditing}
          keyboardType={'decimal-pad'}
          selectTextOnFocus
          editable={props.editable}
        />
        {!!unitText && (
          <CardBasic
            style={{
              width: '15%',
              margin: 0,
              padding: 2,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeftWidth: 0,
              justifyContent: 'center',
            }}>
            <IMText numberOfLines={1} style={{ textAlign: 'center' }}>
              {unitText}
            </IMText>
          </CardBasic>
        )}
        {!!props.detail && (
          <CardBasic
            style={{
              margin: 0,
              padding: 2,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeftWidth: 0,
              justifyContent: 'center',
              backgroundColor: Colors.tint,
            }}>
            <Pressable
              onPress={props.onDetailPressed}
              disabled={!props.onDetailPressed}
              style={{ paddingHorizontal: 5 }}
              hitSlop={{ top: 15, bottom: 15, right: 20 }}>
              <IMText numberOfLines={1} style={{ textAlign: 'center', color: '#fff' }}>
                {props.detail}
              </IMText>
            </Pressable>
          </CardBasic>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
})
