import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Keyboard, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'
import IMStyles from '../constants/Styles'
import SelectionModal from '../modals/SelectionModal'
import IMIcon from './IMIcon'
import CardBasic from './old/CardBasic'
import Text from './old/Text'

export default function PickerModal<T>(props: {
  data: T[]
  captionExtractor?: (item: T) => string
  selectedCaptionExtractor?: (item: T) => string
  renderItem?: (item: T, isSelected: boolean) => JSX.Element
  onDelete?: () => void
  value: T | undefined
  onSelect: (item: T) => void
  placeholder?: string
  disabled?: boolean
  hideIcon?: boolean
  style?: StyleProp<ViewStyle>
  selectedViewStyle?: StyleProp<ViewStyle>
  asCard?: boolean
  title?: string
}) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const [showSelectorModal, setShowSelectorModal] = useState(false)

  function getText() {
    if (!props.value) return ''
    if (props.selectedCaptionExtractor) return props.selectedCaptionExtractor(props.value)
    if (props.captionExtractor) return props.captionExtractor(props.value)
    if (typeof props.value === 'string') return props.value
    if (props.placeholder) return props.placeholder
    return i18n.t('SELECT_PLACEHOLDER')
  }

  const Content = (
    <>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        {props.title && <Text style={[IMStyles.text]}>{props.title}</Text>}
        {props.renderItem && props.value ? (
          props.renderItem(props.value, true)
        ) : (
          <View
            style={[
              {
                justifyContent: 'center',
              },
              props.selectedViewStyle,
            ]}>
            <Text style={[IMStyles.text, { marginVertical: 0 }]}>{getText()}</Text>
          </View>
        )}
        {!props.hideIcon && <IMIcon icon={'angle-down'} size={20} color={theme.text} style={{ justifyContent: 'center' }} />}
      </View>
      <SelectionModal
        show={showSelectorModal}
        items={props.data}
        close={() => setShowSelectorModal(false)}
        renderItem={item => (
          <TouchableOpacity
            onPress={() => {
              props.onSelect(item)
              setShowSelectorModal(false)
            }}>
            <CardBasic>
              {props.renderItem ? (
                props.renderItem(item, false)
              ) : (
                <Text style={[IMStyles.text, { textAlign: 'center', flex: 1 }]}>{props.captionExtractor ? props.captionExtractor(item) : ''}</Text>
              )}
            </CardBasic>
          </TouchableOpacity>
        )}
      />
    </>
  )

  return (
    <TouchableOpacity
      onPress={() => {
        Keyboard.dismiss()
        setShowSelectorModal(true)
      }}
      disabled={props.disabled}>
      {props.asCard ? <CardBasic style={[{ margin: 0, flex: 1 }, props.style]}>{Content}</CardBasic> : <View style={props.style}>{Content}</View>}
    </TouchableOpacity>
  )
}
