import React from 'react'

import { ArticleDTO, Deposit } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import useRouteParam from '../../hooks/useRouteParam'
import SerialnumberQuantitySelectionView from '../../views/InputViews/SerialnumberQuantitySelectionView'

export default function SerialnumberQuantitySelectionScreen() {
  const article = useRouteParam<ArticleDTO>('article')
  const deposit = useRouteParam<Deposit>('deposit')

  return (
    <MWS_Screen>
      <SerialnumberQuantitySelectionView
        style={{ flex: 1 }}
        article={article}
        deposit={deposit}
        allowEmptyInput
        loadOnMount
        showAvailability
        onSelected={() => null}
      />
    </MWS_Screen>
  )
}
