import React, { PropsWithChildren, useState } from 'react'

import { Deposit, StockAccounting, StockPosition } from '../apis/apiTypes'

export type TransferContextResult = {
  savedDeposit: Deposit | undefined
  setSavedDeposit: React.Dispatch<React.SetStateAction<Deposit | undefined>>
  savedStockPosition: StockPosition | undefined
  setSavedStockPosition: React.Dispatch<React.SetStateAction<StockPosition | undefined>>
  savedDepositTarget: Deposit | undefined
  setSavedDepositTarget: React.Dispatch<React.SetStateAction<Deposit | undefined>>
  savedStockPositionTarget: StockPosition | undefined
  setSavedStockPositionTarget: React.Dispatch<React.SetStateAction<StockPosition | undefined>>
  clear(mode?: 'target' | 'source' | 'all'): void
  stockAccounts: StockAccounting[] | undefined
  setStockAccounts: React.Dispatch<React.SetStateAction<StockAccounting[] | undefined>>
}

export const TransferContext = React.createContext<TransferContextResult | null>(null)

export function TransferContextProvider({ children }: PropsWithChildren) {
  const [savedDeposit, setSavedDeposit] = useState<Deposit | undefined>(undefined)
  const [savedStockPosition, setSavedStockPosition] = useState<StockPosition | undefined>(undefined)
  const [savedDepositTarget, setSavedDepositTarget] = useState<Deposit | undefined>(undefined)
  const [savedStockPositionTarget, setSavedStockPositionTarget] = useState<StockPosition | undefined>(undefined)
  const [stockAccounts, setStockAccounts] = useState<StockAccounting[] | undefined>([])

  function clear(mode: 'target' | 'source' | 'all' = 'all') {
    if (mode !== 'source') {
      setSavedDepositTarget(undefined)
      setSavedStockPositionTarget(undefined)
    }
    if (mode !== 'target') {
      setSavedDeposit(undefined)
      setSavedStockPosition(undefined)
    }

    if (mode === 'all') {
      setStockAccounts([])
    }
  }

  return (
    <TransferContext.Provider
      value={{
        savedDeposit: savedDeposit,
        setSavedDeposit: setSavedDeposit,
        savedStockPosition: savedStockPosition,
        setSavedStockPosition: setSavedStockPosition,
        savedDepositTarget: savedDepositTarget,
        setSavedDepositTarget: setSavedDepositTarget,
        savedStockPositionTarget: savedStockPositionTarget,
        setSavedStockPositionTarget: setSavedStockPositionTarget,
        clear,
        stockAccounts: stockAccounts,
        setStockAccounts: setStockAccounts,
      }}>
      {children}
    </TransferContext.Provider>
  )
}

export function useTransfer() {
  const value = React.useContext(TransferContext)

  if (!value) throw new Error('useTransfer() can only be used inside of TransferContextProvider')

  return value
}
