import { useItemSelector, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ProductionMaster, ProductionPartOfMasterProduction } from '../../apis/apiTypes'
import ProductionMasterCard from '../../cards/Production/ProductionMasterCard'
import ProductionPartAllocationCard from '../../cards/Production/ProductionPartAllocationCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import SubmitButton from '../../components/SubmitButton'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useRouteParam from '../../hooks/useRouteParam'
import useToastMessage from '../../hooks/useToastMessage'
import { Error_Utils } from '../../utils/ErrorUtils'

export default function ProductionAllocationPreProductionScreen() {
  const navigation = useNavigation()
  const alert = useAlert()
  const { i18n } = useLanguage()

  const loader = useLoadingIndicator()
  const toast = useToastMessage()
  const productionMaster = useRouteParam<ProductionMaster>('productionMaster')
  const [productionParts, setProductionParts] = useState<ProductionPartOfMasterProduction[]>()
  const productionPartsToAllocate = useItemSelector<string>([])
  const productionPartsUnselected = useMemo(
    () => productionParts?.filter(q => !productionPartsToAllocate.includes(q.productionPartId)) ?? [],
    [productionParts, productionPartsToAllocate]
  )
  const productionPartsSelected = useMemo(
    () => productionParts?.filter(q => productionPartsToAllocate.includes(q.productionPartId)) ?? [],
    [productionParts, productionPartsToAllocate]
  )

  useEffect(() => {
    if (productionMaster && !productionParts?.length) {
      loadParts(productionMaster.id)
    }
  }, [])

  function setSelectedPart(part: ProductionPartOfMasterProduction | undefined) {
    if (!part) return
    productionPartsToAllocate.toggle(part.productionPartId)
    return
  }

  function loadParts(productionId: string) {
    if (!productionId) {
      setProductionParts([])
      return
    }
    loader.setLoading(true)
    api
      .getPartsOfMasterProduction({
        productionId: productionId,
      })
      .then(result => {
        setProductionParts(result)
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  async function handleConfirm() {
    if (!productionMaster) return
    if (!productionPartsSelected?.length) return
    try {
      loader.setLoading(true)
      await Promise.all(
        productionPartsSelected.map(part =>
          api.patchPartToPreProduction({
            preProductionId: part.preProductionId,
            productionPartId: part.productionPartId,
          })
        )
      )
      toast.show(i18n.t('ASSIGNMENTWASSUCCESSFULLYCOMPLETED'))
      navigation.goBack()

      loader.setLoading(false)
    } catch (reason: unknown) {
      alert.error(Error_Utils.extractErrorMessageFromException(reason))
      console.error(reason)
      loadParts(productionMaster.id)
    }
  }

  return (
    <MWS_Screen title={i18n.t('ReallocationPreProduction')}>
      <View style={{ flex: 1 }}>
        {!!productionMaster && <ProductionMasterCard production={productionMaster} />}
        <View style={[{ flex: 1 }]}>
          {!!productionPartsUnselected.length && <Title>{i18n.t('OpenArticleWithPreProduction')}</Title>}
          <FlatList
            style={{ flex: 1 }}
            data={[...productionPartsUnselected, ...productionPartsSelected]}
            renderItem={({ item, index }) => (
              <>
                {index === productionPartsUnselected.length && <Title>{i18n.t('ToBeConfirmed')}</Title>}
                <ProductionPartAllocationCard
                  part={item}
                  onPress={() => {
                    setSelectedPart(item)
                  }}
                />
              </>
            )}
          />
        </View>
        <SubmitButton title={i18n.t('Confirm')} onPress={handleConfirm} hideFromKeyBoard hide={!productionPartsSelected.length} />
      </View>
    </MWS_Screen>
  )
}
