import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ArticleMeasurementUnit } from '../../../apis/apiTypes'
import PickerModal from '../../PickerModal'

export default function UnitPicker(props: {
  unit: ArticleMeasurementUnit | undefined
  units: ArticleMeasurementUnit[] | undefined
  onUnitSelected: (unit: ArticleMeasurementUnit) => void
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  asCard?: boolean
  captionExtractor?: (unit: ArticleMeasurementUnit) => string
  selectedCaptionExtractor?: (unit: ArticleMeasurementUnit) => string
  title?: string
}) {
  const unitSelectable = !props.disabled && props.units && props.units.length > 1

  return (
    <PickerModal
      style={props.style}
      value={props.unit}
      onSelect={props.onUnitSelected}
      data={(props.units ?? []).sort(a => (a.conversation === 1 ? -1 : 1))}
      disabled={!unitSelectable}
      hideIcon={!unitSelectable}
      captionExtractor={item => (props.captionExtractor ? props.captionExtractor(item) : item.code)}
      selectedCaptionExtractor={
        props.selectedCaptionExtractor ? item => (props.selectedCaptionExtractor ? props.selectedCaptionExtractor(item) : item.code) : undefined
      }
      asCard={props.asCard}
      title={props.title}
    />
  )
}
