import { useLanguage } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'

import api from '../../apis/apiCalls'
import { StockAccountingPostRequest } from '../../apis/apiRequestTypes'
import { ArticleMeasurementUnit, Deposit, SerialNumber, StockAccounting, StockAccountingPostResult } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Separator from '../../components/Separator'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useTransfer } from '../../contexts/TransferContext'
import useTransferHandler from '../../hooks/specific/useTransferHandler'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import useToastMessage from '../../hooks/useToastMessage'
import TransferSerialEditModal from '../../modals/TransferSerialEditModal'
import { Position, ScreenType } from '../../types'
import { depositUtils } from '../../utils/depositUtils'
import { Error_Utils } from '../../utils/ErrorUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import transferUtils from '../../utils/transferUtils'
import { Utils } from '../../utils/Utils'
import TransferArticleWeightInput from '../../views/Transfer/TransferArticleWeightInput'
import TransferPositionView from '../../views/Transfer/TransferPositionView'

export default function TransferPositionSelectionScreen() {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const handler = useTransferHandler()
  const loader = useLoadingIndicator()
  const toastMessage = useToastMessage()
  const navigation = useNavigation()
  const route = useRoute()
  const serialEditModal = useModalController()
  const transferContext = useTransfer()

  const positionsOk = !!handler.sourcePositionHandler.isOk && !!handler.targetPositionHandler.isOk

  function createStockAccounting(
    stockId: string,
    stockAccountingId: string,
    serialNumber: SerialNumber | undefined,
    quantity: number,
    unit: ArticleMeasurementUnit | undefined
  ): StockAccounting {
    return {
      stockId: stockId,
      stockAccountingId: stockAccountingId,
      deposit: handler.sourcePositionHandler?.deposit,
      stockPosition: handler.sourcePositionHandler?.stockPosition,
      depositTarget: handler.targetPositionHandler?.deposit,
      stockPositionTarget: handler.targetPositionHandler?.stockPosition,
      article: handler.article?.info,
      serialNumber: serialNumber,
      quantity: quantity,
      unit,
    }
  }

  useEffect(() => {
    if ((!handler.sourcePositionHandler.quantity && !handler.targetPositionHandler.quantity) || !positionsOk) return
    completeTransfer().catch(console.error)
  }, [handler.sourcePositionHandler.quantity, handler.targetPositionHandler.quantity])

  async function completeTransfer() {
    if (!handler.articleQuantities.any || !handler.stockTemplate || !handler.userSettings || !handler.article) return

    loader.setLoading(true)
    const checkResult = await preTransferCheck({
      deposit: handler.targetPositionHandler.deposit,
      stockPosition: handler.targetPositionHandler.stockPosition,
    })
    if (!checkResult) {
      loader.setLoading(false)
      return
    }
    if (handler.weightInputHandler.required) {
      try {
        await handler.weightInputHandler.patch()
      } catch (exception: unknown) {
        console.error('completeTransfer error', exception)
        alert.error(i18n.t('FailedToUpdateNetWeight'), Error_Utils.extractErrorMessageFromException(exception))
      }
    }

    let firstResult: StockAccountingPostResult | undefined
    for (const quantityEntry of handler.articleQuantities.items) {
      const request = transferUtils.createTransferRequest(
        handler.stockTemplate,
        handler.userSettings,
        handler.article,
        quantityEntry,
        handler.sourcePositionHandler,
        handler.targetPositionHandler,
        firstResult?.stockId ?? handler.stockId
      )
      if (!request) continue
      try {
        const result = await doTransfer(request)
        if (!result) continue
        if (!firstResult) firstResult = result
        // add stockaccounting to history
        const stockAccount = createStockAccounting(
          result.stockId,
          result.stockaccountingId,
          quantityEntry.serialNumber,
          quantityEntry.quantity || handler.sourcePositionHandler?.quantity || handler.targetPositionHandler?.quantity || 0,
          handler.article.getUsedUnit()
        )
        transferContext.setStockAccounts(prev => (prev ? [...prev, stockAccount] : [stockAccount]))
      } catch (err: unknown) {
        console.error(err)
        loader.setLoading(false)
        alert.error(Error_Utils.extractErrorMessageFromException(err) || i18n.t('TransferFailed'))
        return
      }
    }
    toastMessage.show(i18n.t('TransferOk'))
    loader.setLoading(false)

    // save selected Positions
    if (handler.stockTemplate.isIncoming) {
      transferContext.setSavedDeposit(handler.targetPositionHandler.deposit)
      transferContext.setSavedStockPosition(
        handler.targetPositionHandler.stockPositionPinned ? handler.targetPositionHandler.stockPosition : undefined
      )
    } else if (handler.stockTemplate.isOutgoing) {
      transferContext.setSavedDeposit(handler.sourcePositionHandler.deposit)
      transferContext.setSavedStockPosition(
        handler.sourcePositionHandler.stockPositionPinned ? handler.sourcePositionHandler.stockPosition : undefined
      )
    } else {
      transferContext.setSavedDeposit(handler.sourcePositionHandler.deposit)
      transferContext.setSavedStockPosition(
        handler.sourcePositionHandler.stockPositionPinned ? handler.sourcePositionHandler.stockPosition : undefined
      )
      transferContext.setSavedDepositTarget(handler.targetPositionHandler.deposit)
      transferContext.setSavedStockPositionTarget(
        handler.targetPositionHandler.stockPositionPinned ? handler.targetPositionHandler.stockPosition : undefined
      )
    }
    navigation.navigate(ScreenType.TransferArticleSelection, { ...route.params, stockId: firstResult?.stockId, transferOk: true })
  }

  async function preTransferCheck(target: Position | undefined) {
    if (!target || !handler.articleQuantities) return false
    if (!handler.isSerialNumberActive) return true
    if (handler.stockTemplate?.isIncoming) return true
    const serials = Utils.keepUniques(handler.articleQuantities.items, item => `${item.serialNumber?.id ?? ''} - ${item.deposit?.id ?? ''}`)
    for (const serial of serials) {
      const quantity = Utils.sum(
        handler.articleQuantities.items.filter(
          item =>
            serialnumberUtils.compare(item.serialNumber, serial.serialNumber) &&
            stockPositionUtils.comparePosition(item.deposit, serial.deposit, undefined, undefined)
        ),
        item => item.quantity
      )
      const result = await checkSerialAvailability(serial.serialNumber, serial.deposit, target.deposit, quantity)
      if (!result) return false
    }
    return true
  }

  function checkSerialAvailability(
    serialNumber: SerialNumber | undefined,
    sourceDeposit: Deposit | undefined,
    targetDeposit: Deposit | undefined,
    quantity: number
  ) {
    return new Promise<boolean>(resolve => {
      if (!serialNumber || !sourceDeposit || !quantity) {
        resolve(false)
        return
      }
      api
        .getSerialNumberAvailability({ serialNumberId: serialNumber.id, depositId: sourceDeposit.id, useUserDepositFilter: false })
        .then(result => {
          if (result.depositquantities && result.depositquantities.length > 0) {
            const availability = depositUtils.getAvailableNetQuantity(result.depositquantities[0])
            if (quantity <= availability) {
              resolve(true)
              return
            }
            if (targetDeposit && sourceDeposit.id !== targetDeposit.id) {
              alert.error(i18n.t('SelectedSerialIsReservedAlert'), serialNumber.number, availability)
              resolve(false)
            }
            resolve(true)
          }
          resolve(false)
        })
        .catch(() => {
          alert.error(i18n.t('NoSerialAvailability'), serialNumber?.number)
          resolve(false)
        })
    })
  }

  function doTransfer(request: StockAccountingPostRequest) {
    return api.postStockAccounting(request)
  }

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: handler.item })
  }

  if (!handler.article) return <></>
  return (
    <MWS_Screen noTopPadding title={transferUtils.getScreenTitle(handler.stockTemplate) ?? ''}>
      <ScrollView style={{ flex: 1 }}>
        <ArticleCard
          style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          article={handler.article?.info}
          endButton={handler.isSerialNumberActive && handler.articleQuantities.any ? { icon: 'barcode', onPress: serialEditModal.show } : undefined}
          serialNumber={
            handler.isSerialNumberActive && handler.articleQuantities.count === 1 ? handler.articleQuantities.items[0].serialNumber : undefined
          }
          quantity={handler.quantity || undefined}
          unit={handler.article?.getUsedUnit()}
          onPress={showArticleInfoScreen}
        />
        <Separator />
        <TransferPositionView handler={handler} />
        <TransferSerialEditModal controller={serialEditModal} handler={handler} />
      </ScrollView>

      {positionsOk && <TransferArticleWeightInput handler={handler.weightInputHandler} />}
      <SubmitButton
        hide={!positionsOk}
        hideFromKeyBoard
        title={i18n.t('CompleteTransfer')}
        onPress={completeTransfer}
        disabled={!handler.weightInputHandler.ok || loader.loading}
      />
    </MWS_Screen>
  )
}
