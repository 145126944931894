import { useLanguage, View } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ArticleMeasurementUnit, Item, SerialNumber, StockPosition } from '../../../apis/apiTypes'
import StockPositionCard from '../../../cards/StockPosition/StockPositionCard'
import { ProductionPartToTransfer } from '../../../classes/ProductionData'
import { useLoadingIndicator } from '../../../contexts/LoadingIndicatorContext'
import useAlert from '../../../hooks/useAlert'
import useArticle from '../../../hooks/useArticle'
import { articleUtils } from '../../../utils/articleUtils'
import { serialnumberUtils } from '../../../utils/serialnumberUtils'
import SerialnumberSelectionView from '../../../views/InputViews/SerialnumberSelectionView'
import StockPositionSelectionView from '../../../views/InputViews/StockPositionSelectionView'
import VKUnitAmountSelector from '../Inputs/VKUnitAmountSelector'

export interface ItemQuantitySelectorResult {
  item: Item
  quantity: number
  unit: ArticleMeasurementUnit | undefined
}

interface ProductionTransferDetailQuantitySelectionViewProps {
  item: Item | undefined
  partToTransfer: ProductionPartToTransfer
  onTransfer: (result: ProductionPartToTransfer) => void
  style?: StyleProp<ViewStyle>
}

export default function ProductionTransferDetailQuantitySelectionView(props: ProductionTransferDetailQuantitySelectionViewProps) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()

  const [selectedItem, setSelectedItem] = useState<Item | undefined>()
  const [selectedStockPosition, setSelectedStockPosition] = useState<StockPosition | undefined>()

  const article = useArticle(selectedItem?.article, { serialNumber: selectedItem?.serialnumber }, [selectedItem])

  const unit = useMemo(() => {
    if (article) return article.getUsedUnit()
    return undefined
  }, [selectedItem?.article?.id, article?.info?.id])

  const showAmountSelector = article && (!article.isSerialNumberActive || selectedItem?.serialnumber)

  useEffect(() => {
    if (!props.item) {
      return
    }
    setValues()
  }, [props.item])

  function setValues() {
    setSelectedStockPosition(undefined)
    setSelectedItem(props.item)
    if (selectedItem?.article && props.partToTransfer?.deposit) {
      loader.setLoading(true)
      articleUtils
        .getArticleAvailability(selectedItem?.article, props.partToTransfer?.deposit)
        .then(result => {
          if (result && result.depositquantities.length === 1) {
            const stockPositions = result.depositquantities[0].stockpositionquantities?.filter(
              q => !q.stockposition?.exhibit && !q.stockposition?.production && !q.stockposition?.deliveryStock
            )
            if (stockPositions.length === 1) {
              const stockPosition = result.depositquantities[0].stockpositionquantities[0].stockposition
              if (stockPosition) setSelectedStockPosition(stockPosition)
            }
          }
        })
        .finally(() => {
          loader.setLoading(false)
        })
        .catch(console.error)
    }
  }

  function handleSerialSelection(serial: SerialNumber) {
    if (articleUtils.isSerialNumberActive(serial.article, false) && !serial.article.isLottoSerialnumber) {
      const item = serialnumberUtils.createItemFromSerial(serial, article?.info)
      if (item) handleResult({ item: item, quantity: 1, unit: undefined })
      return
    }
    setSelectedItem(serialnumberUtils.createItemFromSerial(serial, article?.info))
  }

  function handleQuantitySelection(quantity: number) {
    const resultItem = selectedItem ?? ({ article: article?.info, serialnumber: undefined } as Item)
    handleResult({ item: resultItem, unit: undefined, quantity: quantity })
  }

  function handleResult(result: ItemQuantitySelectorResult) {
    checkAvailability(result)
  }

  function checkAvailability(result: ItemQuantitySelectorResult) {
    if (articleUtils.isSerialNumberActive(result.item.article, false) && result.item.article.isSerialnumberOnlyForHistory) {
      return
    }
    loader.setLoading(true)
    articleUtils
      .getItemAvailability(result.item, selectedStockPosition?.deposit, selectedStockPosition)
      .then(availability => {
        if (!articleUtils.quantityValidator(result.quantity, availability)) {
          if (articleUtils.isSerialNumberActive(result.item.article, false) && !result.item.article.isLottoSerialnumber) {
            alert.info(i18n.t('SelectedSerialNotAvailable'), result.item.serialnumber?.number)
          } else {
            alert.info(i18n.t('SelectedAmountInvalidAlert'), availability)
          }

          return
        }
        const part = props.partToTransfer
        if (!part.quantities) part.quantities = []
        part.quantities.push({
          depositId: selectedStockPosition?.deposit.id,
          stockPositionId: selectedStockPosition?.id,
          serialNumber: result.item.serialnumber,
          quantity: result.quantity,
        })
        props.onTransfer(part)
      })
      .finally(() => loader.setLoading(false))
      .catch(console.error)
  }

  return (
    <View style={props.style}>
      {!!selectedStockPosition && (article || selectedItem) && <StockPositionCard stockPosition={selectedStockPosition} />}
      {!selectedStockPosition && (
        <StockPositionSelectionView onSelected={setSelectedStockPosition} withAvailableArticleId={selectedItem?.article.id} />
      )}
      {!!selectedStockPosition && showAmountSelector && (
        <VKUnitAmountSelector
          value={undefined}
          setValue={handleQuantitySelection}
          autoFocus
          usedUnit={unit}
          confirmButtonCaption={selectedItem ? i18n.t('Add') : undefined}
        />
      )}
      {!showAmountSelector && !!selectedStockPosition && (
        <View>
          {article && (
            <SerialnumberSelectionView
              article={article.info}
              onSelected={handleSerialSelection}
              showResultInModal
              loadOnListButtonPressed={() =>
                serialnumberUtils.getSerialsOnPosition(article?.info, selectedStockPosition.deposit, selectedStockPosition)
              }
            />
          )}
        </View>
      )}
    </View>
  )
}
