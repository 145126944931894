import { useLanguage, Utils } from '@infominds/react-native-components'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { Colors } from '../constants/Colors'

export default function useAlert() {
  const { i18n } = useLanguage()
  const alert = withReactContent(Swal)

  const alertPolyfill = (title: string, description: string) => {
    return new Promise<boolean>(resolve => {
      alert
        .fire({ text: description, confirmButtonColor: Colors.grey, showCancelButton: true, cancelButtonText: i18n.t('CANCEL') })
        .then(result => resolve(!!result.isConfirmed))
        .catch(() => resolve(false))
    })
  }

  function info(message: string, ...values: unknown[]) {
    alertPolyfill(i18n.t('INFO_TITLE'), Utils.stringValueReplacer(message, values)).catch(console.error)
    // alert(`${i18n.t('INFO_TITLE}\n${Utils.stringValueReplacer(message, values)}`)
  }

  function error(message: string, ...values: unknown[]) {
    alertPolyfill(i18n.t('INFO_TITLE'), Utils.stringValueReplacer(message, values)).catch(console.error)
  }

  function yesNo(message: string, ...values: unknown[]) {
    return alertPolyfill(i18n.t('INFO_TITLE'), Utils.stringValueReplacer(message, values))
  }

  return {
    info,
    error,
    yesNo,
  }
}
