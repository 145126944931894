import { useTheme } from '@infominds/react-native-components'
import React, { LegacyRef } from 'react'
import { Platform, StyleProp, StyleSheet, TextInput, TextInputProps, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { IMIconButton } from './IMIconButton'
import { IMIconName } from './IMIconNames'

interface BaseTextInputProps {
  inputRef?: LegacyRef<TextInput>
  viewStyle?: StyleProp<ViewStyle>
  onClearPressed?: () => void
  endButton?: {
    icon: IMIconName
    onPress: () => void
  }
  hideButtons?: boolean
}

export default function BaseTextInput(props: BaseTextInputProps & TextInputProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const { viewStyle, style, onClearPressed, endButton, hideButtons, ...otherProps } = props

  return (
    <View
      style={[
        styles.searchSection,
        {
          backgroundColor: theme.inputBackground,
          borderColor: theme.inputBorder,
        },
        viewStyle,
      ]}>
      <TextInput
        ref={props.inputRef}
        value={props.value}
        style={[styles.input, { color: theme.text }, style]}
        underlineColorAndroid="transparent"
        placeholderTextColor={theme.textPlaceholder}
        {...otherProps}
      />
      {!!props.value && !endButton && !hideButtons && otherProps.editable !== false && (
        <IMIconButton
          icon={'times'}
          style={styles.searchIcon}
          iconSize={20}
          onPress={() => {
            if (onClearPressed) onClearPressed()
            else if (props.onChangeText) props.onChangeText('')
          }}
          iconColor={theme.textDetail}
          hitSlop={{ top: 15, bottom: 15, left: 5, right: 5 }}
        />
      )}
      {!!endButton && !hideButtons && (
        <IMIconButton icon={endButton.icon} style={styles.searchIcon} iconSize={20} onPress={endButton.onPress} iconColor={theme.textDetail} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    flex: 1,
    paddingHorizontal: 12,
    paddingVertical: Platform.OS === 'web' ? 12 : undefined,
    height: '100%',
  },
  searchSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    paddingRight: 5,
  },
  searchIcon: {
    padding: 5,
  },
})
