import { ArticleDepositQuantity, Deposit, StockPosition, StockPositionAvailability } from '../apis/apiTypes'
import { Utils } from './Utils'

export const depositUtils = {
  compare(a: Deposit | undefined, b: Deposit | undefined) {
    return !!a && !!b && a.id === b.id
  },
  sort(a: Deposit | undefined, b: Deposit | undefined) {
    return Utils.compareStringsForSort(a?.code, b?.code)
  },
  getDepositTitle(deposit: Deposit | undefined) {
    if (!deposit) return ''
    return deposit.code?.trim() + ' - ' + deposit.description?.trim()
  },
  getTitleWithStockPosition(deposit: Deposit | undefined, stockPosition?: StockPosition) {
    if (!deposit) return ''
    let title = deposit.code?.trim()
    if (stockPosition?.code) title += ` (${stockPosition.code.trim()})`
    return title
  },
  getAvailableNetQuantity(depositQuantity: ArticleDepositQuantity | undefined) {
    if (!depositQuantity) return 0
    return Math.max(0, depositQuantity.quantity - depositQuantity.quantityOrdered - depositQuantity.quantityCommissioned)
  },
  createArticleDepositQuantity(
    deposit: Deposit | undefined,
    stockpositionquantities?: StockPositionAvailability[],
    quantity?: number,
    quantityCommissioned?: number,
    quantityOrdered?: number,
    quantityPurchasing?: number,
    quantityFreeInputs?: number
  ): ArticleDepositQuantity {
    return {
      deposit,
      quantity,
      quantityCommissioned,
      quantityOrdered,
      quantityPurchasing,
      quantityFreeInputs,
      stockpositionquantities,
    } as ArticleDepositQuantity
  },
  getDepositsFromAvailability(availability: StockPositionAvailability[] | undefined) {
    if (!availability) return []
    const deposits = availability.filter(a => !!a.deposit).map(a => a.deposit) as Deposit[]
    Utils.keepUniques(deposits, deposit => deposit?.code)
    return deposits
  },
  reduceStockPositionAvailability(availability: StockPositionAvailability[] | undefined) {
    if (!availability) return []
    return availability.reduce<StockPositionAvailability[]>((result, current) => {
      const foundDeposit = result.find(q => q.deposit?.id === current.deposit?.id)
      if (foundDeposit) {
        foundDeposit.quantity = (foundDeposit.quantity ?? 0) + (current.quantity ?? 0)
        foundDeposit.quantityCommissioned = (foundDeposit.quantityCommissioned ?? 0) + (current.quantityCommissioned ?? 0)
      } else {
        result.push({ ...current, serialnumber: undefined })
      }
      return result
    }, [])
  },
}
