import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { Deposit, Item } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Separator from '../../components/Separator'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'

export default function OrderProposalScreen() {
  const navigation = useNavigation()
  const deposit = useRouteParam<Deposit>('deposit')
  const { i18n } = useLanguage()

  function navigateToOrderProposalCreation(article: Item) {
    navigation.navigate(ScreenType.OrderProposalCreation, { deposit, article: article.article })
  }

  return (
    <MWS_Screen title={i18n.t('CreateOrderProposal')}>
      <DepositCard deposit={deposit} />
      <Separator />
      <ItemSelectionView onSelected={navigateToOrderProposalCreation} showResultInModal />
    </MWS_Screen>
  )
}
