import { ModalController, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import FullScreenModal from '../../components/FullScreenModal'
import PackingListColloUnloadView, { PackingListColloUnloadViewProps } from '../../views/PackingList/PackingListColloUnloadView'

export type PreConsignmentColloUnloadModalProps = { controller: ModalController } & PackingListColloUnloadViewProps

export default function PreConsignmentColloUnloadModal({ controller, onDone, ...viewProps }: PreConsignmentColloUnloadModalProps) {
  const { i18n } = useLanguage()

  return (
    <FullScreenModal
      isVisible={controller.isShown}
      close={() => {
        controller.close()
      }}
      title={i18n.t('Unload')}>
      <PackingListColloUnloadView
        onDone={reload => {
          controller.close()
          onDone(reload)
        }}
        {...viewProps}
      />
    </FullScreenModal>
  )
}
