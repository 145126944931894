import { IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { TouchableOpacity as DefaultTouchableOpacity, StyleSheet } from 'react-native'

import { Colors } from '../../constants/Colors'
import Text from './Text'

export enum ButtonType {
  primary,
  danger,
  info,
  neutral,
}

type Props = {
  title?: string
  type?: 'primary' | 'danger' | 'info' | 'neutral'
  disabled?: boolean
}

export type ButtonProps = Props & DefaultTouchableOpacity['props']

export default function Button(props: ButtonProps) {
  const { style, ...otherProps } = props

  let typeStyling = {}

  if (props.type) {
    switch (props.type) {
      case 'primary':
        typeStyling = { backgroundColor: Colors.tint }
        break
      case 'danger':
        typeStyling = { backgroundColor: Colors.red }
        break
      case 'info':
        typeStyling = { backgroundColor: Colors.yellow }
        break
      case 'neutral':
        typeStyling = { backgroundColor: Colors.neutral }
        break
      default:
        typeStyling = { backgroundColor: Colors.tint }
        break
    }
  }

  return (
    <DefaultTouchableOpacity
      style={[
        { marginHorizontal: IMLayout.horizontalMargin, marginVertical: IMLayout.verticalMargin },
        styles.button,
        style,
        typeStyling,
        {
          opacity: props.disabled ? 0.5 : 1,
        },
      ]}
      activeOpacity={0.5}
      {...otherProps}>
      <Text style={styles.title}>{props.title}</Text>
    </DefaultTouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    padding: 12,
    borderRadius: 10,
    backgroundColor: Colors.tint,
  },
  title: {
    alignSelf: 'center',
    color: '#fff',
  },
})
