import { useLanguage, Utils } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { ProductionPartOfMasterProduction } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { Colors } from '../../constants/Colors'
import { CONSTANTS } from '../../constants/Constants'
import useArticle from '../../hooks/useArticle'
import { articleUtils } from '../../utils/articleUtils'
import ArticleCard from '../Article/ArticleCard'

export default function ProductionPartAllocationCard(props: {
  part: ProductionPartOfMasterProduction | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
}) {
  const { part, ...otherProps } = props
  const { i18n } = useLanguage()

  const article = useArticle(
    part?.article,
    {
      isHistoricalSerialNumberActive: false,
      useUnit: articleUtils.createMeasurementUnit(part?.article?.measurementUnitCode, part?.article?.measurementUnitId, 1),
    },
    [part?.article.id]
  )

  function quantityText(item: ProductionPartOfMasterProduction) {
    return `${Utils.roundToPrecision(item.quantity, CONSTANTS.NUMBER_PRECISION)}`
  }

  if (!part) return <></>
  return (
    <ArticleCard
      article={article?.info}
      quantityText={quantityText(part)}
      unit={article?.getUsedUnit()}
      maxLinesDescription={2}
      navigateToArticleInfoOnPress
      textColor={Colors.light.text}
      {...otherProps}
      subComponent={
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
              <IMText>{`${i18n.t('Position')}: ${part.position}`}</IMText>
            </View>
            <IMText>{`${part.preProductionKey} (${part.preProductionPercent} %)`}</IMText>
          </View>
        </View>
      }
    />
  )
}
