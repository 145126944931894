import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import FilterTag from '../../components/filter/FilterTag'
import useFilter from '../../hooks/filter/useFilter'

export default function FilterFilterView() {
  const { filters } = useFilter()
  const { i18n } = useLanguage()

  const filtersToShow = useMemo(() => filters.filter(filter => filter.values.length > 0), [filters])

  const noFilter = (
    <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}>
      {i18n.t('NO_FILTER_FOUND')}
    </IM.Text>
  )

  return (
    <IM.View spacing={['top', 'horizontal']}>
      {filtersToShow.length === 0 && noFilter}
      {filtersToShow.map(filter => {
        return (
          <IM.View key={filter.id}>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}>
              {typeof filter.textKey === 'string' ? i18n.t(filter.textKey) : filter.textKey.text}
            </IM.Text>
            <IM.View style={styles.filterContainer}>
              {filter.values.map(el => (
                <FilterTag key={el.id} el={el} icon={filter.options?.icon} />
              ))}
            </IM.View>
          </IM.View>
        )
      })}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  filterContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 10,
  },
})
