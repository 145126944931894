import api from '../apis/apiCalls'
import {
  ArticleDTO,
  Deposit,
  Item,
  Production,
  ProductionPart,
  ProductionPartStockAccounting,
  SerialNumber,
  StockTemplate,
  UserSettings,
} from '../apis/apiTypes'
import productionUtils from '../utils/ProductionUtils'
import { ArticleAmountSelectionResult } from '../views/Article/ArticleAmountSelectorV2'
import { Article } from './Article'

export interface ProductionPartToTransfer {
  production: string
  productionId: string
  articleProduction?: ArticleDTO
  productionPartId?: string
  deposit?: Deposit
  article?: ArticleDTO
  unitId: string
  quantity: number
  quantityTransferred: number
  quantities: ProductionPartToTransferQuantities[]
}

export interface ProductionPartToMove {
  productionId: string
  production: string
  articleProduction: ArticleDTO
  article: ArticleDTO
  unitId: string
  quantity: number
  stockAccountings: ProductionPartStockAccounting[]
  productionIdTarget?: string
  serialnumberId?: string
}

export interface ProductionPartToTransferQuantities {
  depositId?: string
  stockPositionId?: string
  serialNumber?: SerialNumber
  quantity: number
}

export interface ProductionPartCollection {
  part: ProductionPart
  article: Article
  selectedQuantities: ArticleAmountSelectionResult[]
  autoCompleted?: boolean //part was automatically completed after process input was given (used to filter parts that are displayed)
}

export default class ProductionData {
  public production: Production
  public parts: ProductionPartCollection[] = []
  public employeeTimeTypeId: string | undefined
  public isDefectiveMaterialProduction: boolean
  public stockTemplateOut: StockTemplate | undefined
  public stockTemplateDefective: StockTemplate | undefined

  constructor(production: Production, employeeTimeTypeId?: string, isDefectiveMaterialProduction?: boolean) {
    this.production = production
    this.isDefectiveMaterialProduction = !!isDefectiveMaterialProduction
    this.employeeTimeTypeId = employeeTimeTypeId
  }

  public loadParts(useEmployeeTimeTypeId: boolean, userSettings?: UserSettings) {
    return new Promise((resolve, reject) => {
      api
        .getProductionParts({
          productionId: this.production.id,
          employeeTimeTypeId: useEmployeeTimeTypeId ? this.employeeTimeTypeId : undefined,
          loadCompletedParts: this.isDefectiveMaterialProduction,
        })
        .then(result => {
          if (!result) {
            reject(result)
            return
          }
          this.parts = result.map<ProductionPartCollection>(part => productionUtils.createPartCollection(part, undefined, userSettings))
          resolve(true)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }

  public loadStockTemplates() {
    loadStockTemplate(this.production.stocktemplateIdOutgoing)
      .then(result => (this.stockTemplateOut = result))
      .catch(error => {
        console.error(error)
      })
    loadStockTemplate(this.production.stocktemplateIdDefective)
      .then(result => (this.stockTemplateDefective = result))
      .catch(error => {
        console.error(error)
      })
  }

  public partsFindItem(item: Item) {
    if (!item || !this.parts) return undefined
    return this.parts.find(p => p.article.info.id === item.article.id)
  }
}

function loadStockTemplate(id: string) {
  return new Promise<StockTemplate | undefined>(resolve => {
    if (!id) {
      resolve(undefined)
      return
    }
    api
      .getStockTemplate({ id: id })
      .then(result => {
        if (!result || result.length === 0) {
          resolve(undefined)
          return
        }
        resolve(result[0])
      })
      .catch(() => {
        resolve(undefined)
      })
  })
}
