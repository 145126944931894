import { useTheme } from '@infominds/react-native-components'
import React, { ReactNode, useState } from 'react'
import { View as DefaultView, Dimensions, ImageBackground, Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'

import { Colors } from '../../constants/Colors'

type Props = {
  children: ReactNode
  scrollable?: boolean
}

export type ScreenProps = Props & DefaultView['props']

export default function Screen(props: ScreenProps) {
  const { colorScheme } = useTheme()

  const { style, ...otherProps } = props

  const [background] = useState('none')
  const [backgroundImage] = useState(undefined)

  return (
    <DefaultView
      style={[
        styles.container,
        {
          backgroundColor: Colors[colorScheme].background,
          height: Platform.OS === 'web' ? Dimensions.get('window').height - 100 : '100%',
        },
        style,
      ]}
      {...otherProps}>
      {props.scrollable ? (
        <ScrollView style={{ backgroundColor: Colors[colorScheme].background }}>
          {background ? (
            <ImageBackground
              source={backgroundImage}
              imageStyle={{
                opacity: colorScheme === 'dark' ? 0.8 : 1,
              }}
              style={{
                flex: 1,
                height: '100%',
                opacity: colorScheme === 'dark' ? 0.1 : 1,
                backgroundColor: Colors[colorScheme].background,
              }}>
              <SafeAreaView style={{ flex: 1 }}>
                <View style={[styles.content, style]}>{props.children}</View>
              </SafeAreaView>
            </ImageBackground>
          ) : (
            <SafeAreaView style={{ flex: 1 }}>
              <View style={[styles.content, style]}>{props.children}</View>
            </SafeAreaView>
          )}
        </ScrollView>
      ) : (
        <View
          style={{
            backgroundColor: Colors[colorScheme].background,
            flex: 1,
          }}>
          {background ? (
            <ImageBackground
              source={backgroundImage}
              imageStyle={{
                opacity: colorScheme === 'dark' ? 0.8 : 1,
              }}
              style={{
                flex: 1,
                height: '100%',
                backgroundColor: Colors[colorScheme].background,
              }}>
              <SafeAreaView style={{ flex: 1 }}>
                <View style={[styles.content, style]}>{props.children}</View>
              </SafeAreaView>
            </ImageBackground>
          ) : (
            <SafeAreaView style={{ flex: 1 }}>
              <View style={[styles.content, style]}>{props.children}</View>
            </SafeAreaView>
          )}
        </View>
      )}
    </DefaultView>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    flex: 1,
  },
  content: {
    height: '100%',
    padding: 10,
    flex: 1,
  },
})
