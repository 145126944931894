import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import IMText from '../../components/IMText'
import MWS_BaseCard from '../MWS_BaseCard'

export default function BarcodeCard(props: {
  barcode: string | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  selected?: boolean
  hideIcon?: boolean
}) {
  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={!props.hideIcon ? 'barcode-read' : undefined}
      isSelected={props.selected}
      cardStyle={props.style}
      style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <IMText primary>{props.barcode}</IMText>
      </View>
    </MWS_BaseCard>
  )
}
