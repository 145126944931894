import { useApi } from '@infominds/react-api'
import { useEffect } from 'react'

import api from '../apis/apiCalls'
import { StockTemplate } from '../apis/apiTypes'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import useRouteParam from './useRouteParam'

export default function useStockTemplate(
  onError: (reason?: unknown) => void,
  onLoadedCallBack?: (template: StockTemplate | undefined) => void,
  invisibleLoading?: boolean
) {
  const id = useRouteParam<string>('menuCode')
  const [items, load, loading] = useApi(api.getStockTemplate, [])
  const loader = useLoadingIndicator()

  useEffect(() => {
    if (!invisibleLoading) loader.setLoading(true)
    load({ id: id })
      .then(result => {
        loader.setLoading(false)
        if (onLoadedCallBack) {
          if (result && result.length > 0) onLoadedCallBack(result[0])
          else onLoadedCallBack(undefined)
        }
        if (!result || result.length < 1) onError()
      })
      .catch(reason => {
        loader.setLoading(false)
        onError(reason)
      })
  }, [id])

  return { stockTemplate: items && items.length > 0 ? items[0] : undefined, reLoad: load, loadingStockTemplate: loading }
}
