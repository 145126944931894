import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import { Colors } from '../constants/Colors'

type Props = {
  value?: boolean
  onValueChange?: ((value: boolean) => void) | null
  onColor?: string
  offColor?: string
}

export type SwitchProps = Props

export default function Switch(props: SwitchProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const onColor = props.onColor ? props.onColor : Colors.tint
  const offColor = props.offColor ? props.offColor : theme.inputBackground

  return (
    <TouchableOpacity
      onPress={() => {
        if (props.value !== undefined && props.onValueChange) props.onValueChange(!props.value)
        else if (props.onValueChange) props.onValueChange(false)
      }}>
      <View
        style={{
          backgroundColor: props.value ? onColor : offColor,
          ...styles.background,
        }}>
        <View style={[styles.forground, props.value && styles.on]} />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  background: {
    height: 23,
    width: 45,
    borderRadius: 40,
  },
  forground: { backgroundColor: '#fff', height: 17, width: 17, borderRadius: 25, margin: 3 },
  on: {
    alignSelf: 'flex-end',
  },
})
