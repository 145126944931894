/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import api from '../apis/apiCalls'
import { ArticleDTO, Deposit, Item, SerialNumber, StockPosition, StockPositionAvailability } from '../apis/apiTypes'
import { ArticleAmountSelectionResult } from '../views/Article/ArticleAmountSelectorV2'
import { depositUtils } from './depositUtils'
import stockPositionUtils from './stockPositionUtils'
import { Utils } from './Utils'

type SerialType = SerialNumber | string | undefined

export const serialnumberUtils = {
  getSerialNumberString(serialNumber: SerialType, hideManufacturer?: boolean, macAddressLabel?: string) {
    if (!serialNumber) return ''
    if (typeof serialNumber === 'string') return serialNumber.trim()
    let sn = serialNumber.number?.trim()
    if (!hideManufacturer && serialNumber.numberManufactor) {
      sn += ` (${serialNumber.numberManufactor.trim()})`
    }
    if (macAddressLabel && serialNumber.alternativeIdMonitoringMapping) {
      sn += ` ${macAddressLabel}:${serialNumber.alternativeIdMonitoringMapping.trim()}`
    }
    return sn
  },
  sort(a: SerialType, b: SerialType) {
    if (!a) return -1
    if (!b) return 1
    const serialA = (typeof a === 'string' ? a : (a.number ?? '')).trim().toLowerCase().padStart(20, '0')
    const serialB = (typeof b === 'string' ? b : (b.number ?? '')).trim().toLowerCase().padStart(20, '0')
    return serialA > serialB ? 1 : -1
  },
  create(number: string, id?: string, article?: ArticleDTO) {
    return { number: number, id: id, article } as SerialNumber
  },
  createItemFromSerial(serial: SerialNumber | undefined, article?: ArticleDTO): Item | undefined {
    if (!serial) return undefined
    return { article: article ?? serial.article, serialnumber: serial }
  },
  createAvailability(
    serialNumber: string | SerialNumber,
    quantity?: number,
    quantityOrdered?: number,
    quantityCommissioned?: number,
    article?: ArticleDTO,
    deposit?: Deposit,
    stockPosition?: StockPosition
  ): StockPositionAvailability {
    return {
      serialnumber: typeof serialNumber === 'object' ? serialNumber : this.create(serialNumber),
      quantity: quantity ?? 1,
      quantityOrdered: quantityOrdered ?? 0,
      quantityCommissioned: quantityCommissioned ?? 0,
      article: article,
      deposit: deposit,
      stockposition: stockPosition,
    } as StockPositionAvailability
  },
  compare(a: SerialNumber | undefined, b: SerialNumber | undefined, defaultIfUndefined?: boolean) {
    if (defaultIfUndefined !== undefined && !a && !b) return defaultIfUndefined
    return !!a && !!b && a.id === b.id
  },
  compareByNumber(a: SerialType, b: SerialType) {
    if (!a || !b) return false
    const serialA = typeof a === 'string' ? a : (a.number ?? '')
    const serialB = typeof b === 'string' ? b : (b.number ?? '')
    return Utils.compareStrings(serialA, serialB)
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  compareAny(a: any, b: any): boolean {
    if (
      !a ||
      !b ||
      !this.compare(!!a.id && !!a.number ? a : (a.serialnumber ?? a.serialNumber), !!b.id && !!b.number ? b : (b.serialnumber ?? b.serialNumber))
    ) {
      return false
    }
    if (!a.deposit && !b.deposit) return true
    if (!depositUtils.compare(a.deposit, b.deposit)) return false
    if (!a.stockposition && !a.stockPosition && !b.stockposition && !b.stockPosition) return true
    return stockPositionUtils.compare(a.stockposition ?? a.stockPosition, b.stockposition ?? b.stockPosition)
  },
  getSerialsOnPosition(article: ArticleDTO | undefined, deposit?: Deposit, stockPosition?: StockPosition, filterSerials?: SerialNumber[]) {
    return new Promise<SerialNumber[]>((resolve, reject) => {
      if (!article) {
        resolve([])
        return
      }
      api
        .getSerialnumberQuantities({
          articleId: article.id,
          depositId: deposit?.id,
          stockPositionId: stockPosition?.id,
        })
        .then(result => {
          if (!result) {
            resolve([])
            return
          }
          const filteredSerials = this.getSerialNumbersFrom(result)
          if (!filterSerials) resolve(filteredSerials)
          else resolve(filteredSerials.filter(serial => !filterSerials.find(fs => fs.id === serial.id)))
        })
        .catch(reject)
    })
  },
  filterSerialNumberAvailabilities(
    availability: StockPositionAvailability[] | undefined,
    serialsToFilter?: SerialNumber[],
    filterNotProposedInPackingLists?: boolean
  ) {
    if (!availability?.length) return []
    if (!serialsToFilter?.length) return availability
    return availability.filter(
      av =>
        !serialsToFilter.find(
          fs => this.compare(fs, av.serialnumber) && (!filterNotProposedInPackingLists || !av.stockposition?.notProposedInPackinglists)
        )
    )
  },
  async getSerialNumberAvailabilitiesOnPosition(
    articleId: string | undefined,
    depositId?: string,
    stockPositionId?: string,
    filterSerials?: SerialNumber[]
  ) {
    if (!articleId) return []
    const availability = await api.getSerialnumberQuantities({ articleId, depositId, stockPositionId })
    return this.filterSerialNumberAvailabilities(availability, filterSerials)
  },
  getSerialNumbersFrom(items: ArticleAmountSelectionResult[] | StockPositionAvailability[] | undefined) {
    if (!items) return []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (items as any[])
      .filter(item => !!item.serialnumber || !!item.serialNumber)
      .map(item => (item.serialnumber ? item.serialnumber : item.serialNumber) as SerialNumber)
  },
  serialNumberInList(serialNumber: SerialNumber, list: SerialNumber[]) {
    if (!serialNumber || !list.length) return false
    return !!list.find(item => this.compare(item, serialNumber))
  },
  async getSerialnumberQuantities(
    article: ArticleDTO | undefined,
    serialNumber: SerialNumber | undefined,
    deposit?: Deposit,
    stockPosition?: StockPosition,
    filterNotProposedInPackingLists?: boolean,
    readQuantitiesForCalculationAvailability?: boolean
  ) {
    if (!article || !serialNumber) return []
    const quantities = await api.getSerialnumberQuantities({
      articleId: article.id,
      serialnumberId: serialNumber.id,
      depositId: deposit?.id,
      stockPositionId: stockPosition?.id,
      readQuantitiesForCalculationAvailability: readQuantitiesForCalculationAvailability,
    })

    if (!filterNotProposedInPackingLists) return quantities
    return quantities.filter(q => !q.stockposition?.notProposedInPackinglists)
  },
  acceptSerialNumberIfNotHistorical(serialNumber: SerialNumber | undefined | null) {
    if (!serialNumber || !!serialNumber.article?.isSerialnumberOnlyForHistory) return undefined
    return serialNumber
  },
  getKeyFromSerialNumberAvailability(serialNumberAvailability: StockPositionAvailability) {
    return `SN${serialNumberAvailability.serialnumber?.id ?? 'NO_SN'};${serialNumberAvailability.deposit?.id ?? 'NO_DEPOSIT'};${
      serialNumberAvailability.stockposition?.id ?? 'NO_STOCKPOSITION'
    }`
  },
  /**
   * Group serialnumber availability by deposit and SN. Quantity & QuantityCommissioned are the sum of all matching entries
   */
  groupSerialNumberAvailability(availability: StockPositionAvailability[]) {
    const depositSN = Utils.keepUniques(availability, q => `${q.deposit?.id ?? ''}#${q.serialnumber?.id ?? ''}`)
    return depositSN.map<StockPositionAvailability>(d => ({
      ...d,
      quantity: Utils.sum(
        availability.filter(q => q.deposit?.id === d.deposit?.id && q.serialnumber?.id === d.serialnumber?.id),
        q => q.quantity ?? 0
      ),
      quantityCommissioned: Utils.sum(
        availability.filter(q => q.deposit?.id === d.deposit?.id && q.serialnumber?.id === d.serialnumber?.id),
        q => q.quantityCommissioned ?? 0
      ),
    }))
  },
}
