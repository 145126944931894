import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Customer, ShoppingCartEntry } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'
import Text from '../../components/old/Text'
import SubmitButton from '../../components/SubmitButton'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useItemSelector from '../../hooks/useItemSelector'
import useToastMessage from '../../hooks/useToastMessage'
import { articleUtils } from '../../utils/articleUtils'
import { Error_Utils } from '../../utils/ErrorUtils'
import { shoppingCartUtils } from '../../utils/shoppingCartUtils'

export default function ShoppingCartListView(props: { customer: Customer | undefined }) {
  const { i18n } = useLanguage()
  const isFocused = useIsFocused()
  const toast = useToastMessage()
  const alert = useAlert()

  const loader = useLoadingIndicator()
  const [shoppingCartItems, loadShoppingCartItems, loadingShoppingCartItems] = useApi(api.getShoppingCart, [])
  const selector = useItemSelector<ShoppingCartEntry>([])

  useEffect(() => {
    if (isFocused) {
      loadShoppingCart()
    }
  }, [isFocused])

  function loadShoppingCart() {
    if (!props.customer) return
    selector.clear()
    loadShoppingCartItems({
      listName: shoppingCartUtils.generateListName(props.customer),
      customerId: props.customer.id,
      readSalesPrice: true,
    }).catch(console.error)
  }

  async function deleteSelected() {
    try {
      loader.setLoading(true)
      await Promise.all(selector.items.map(item => api.deleteShoppingCartEntry({ id: item.id })))
      toast.show(i18n.t('EntriesDeleted'), selector.count)
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToDeleteEntry'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
      loadShoppingCart()
    }
  }

  return (
    <View style={styles.main}>
      <Text style={{ textAlign: 'center', marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }}>
        {i18n.t('CurrentShoppingCart')}
      </Text>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          <FlatList
            data={shoppingCartItems ?? []}
            renderItem={({ item }) => (
              <ArticleCard
                article={item.article}
                quantity={item.quantity}
                unit={item.measurementUnit}
                price={articleUtils.formatQuantity(item.price, '€')}
                onPress={() => selector.toggle(item)}
                selected={selector.includes(item)}
                serialNumber={item.serialNumber}
              />
            )}
            refreshing={loadingShoppingCartItems}
            refreshControl={<IMRefreshControl refreshing={loadingShoppingCartItems} onRefresh={loadShoppingCart} />}
            ListFooterComponent={!loadingShoppingCartItems && !shoppingCartItems?.length ? <NoEntriesTag /> : <ListSpacer />}
          />
        </View>

        <SubmitButton
          hide={!selector.any}
          style={{ backgroundColor: Colors.error, marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
          title={i18n.t('DeleteSelected')}
          onPress={deleteSelected}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
  },
})
