import { StockAccountingPostRequest } from '../apis/apiRequestTypes'
import { Deposit, StockPosition, StockTemplate, UserSettings } from '../apis/apiTypes'
import { Article } from '../classes/Article'
import { TransferPositionHandler } from '../hooks/specific/useTransferPositionHandler'
import { ArticleAmountSelectionResult } from '../views/Article/ArticleAmountSelectorV2'

const transferUtils = {
  createTransferRequest(
    stockTemplate: StockTemplate,
    userSettings: UserSettings,
    article: Article,
    selection: ArticleAmountSelectionResult,
    source?: TransferPositionHandler,
    target?: TransferPositionHandler,
    stockId?: string
  ): StockAccountingPostRequest | undefined {
    let useSource
    let useTarget
    if (stockTemplate.isIncoming) {
      useSource = target
    } else if (stockTemplate.isOutgoing) {
      if (selection.deposit) useSource = selection
      else useSource = source
    } else {
      if (selection.deposit) useSource = selection
      else if (source?.deposit) useSource = source
      useTarget = target
    }
    return {
      stockId: stockId,
      articleId: article.info.id,
      unitId: article.getUsedUnit().id ?? selection.unit?.id ?? '',
      serialnumberId: selection.serialNumber?.id,
      quantity: selection.quantity || source?.quantity || target?.quantity || 0,
      depositId: useSource?.deposit?.id ?? '',
      stockpositionId: useSource?.stockPosition?.id ?? '',
      depositIdTarget: useTarget?.deposit?.id,
      stockpositionIdTarget: useTarget?.stockPosition?.id,
      stockTempleteId: stockTemplate.id,
      documentDate: new Date(),
      collaboratorId: userSettings.employeeId,
    }
  },
  getScreenTitle(stockTemplate: StockTemplate | undefined) {
    if (!stockTemplate) return
    return stockTemplate?.code + (stockTemplate?.description ? ` - ${stockTemplate.description}` : '')
  },
  loadRequiredArticleData: async (
    article: Article,
    stockTemplate: StockTemplate,
    savedDeposit: Deposit | undefined,
    savedStockPosition: StockPosition | undefined
  ) => {
    if (!article) return 0
    try {
      if (stockTemplate.isIncoming) {
        if (article.isSerialNumberActive) {
          await article.loadSerialNumbers()
          return 1
        }
        return 1
      }

      if (article.isSerialNumberActive && !article.isHistoricalSerialNumber) {
        await article.loadSerialNumberQuantities(
          transferUtils.getDeposit(stockTemplate, savedDeposit),
          transferUtils.getStockPosition(stockTemplate, undefined),
          undefined,
          undefined,
          undefined,
          true,
          true
        )
        return article.getSerialQuantity()
      }

      await article.loadAvailability(
        false,
        transferUtils.getDeposit(stockTemplate, savedDeposit)?.id,
        transferUtils.getStockPosition(stockTemplate, savedStockPosition)?.id
      )
      return article.getQuantity()
    } catch (exception) {
      console.error(exception)
      return 0
    }
  },
  getDeposit(stockTemplate: StockTemplate | undefined, deposit: Deposit | undefined) {
    if (stockTemplate && stockTemplate.deposit) return stockTemplate.deposit
    return deposit
  },
  getDepositTarget(stockTemplate: StockTemplate | undefined, deposit: Deposit | undefined) {
    if (stockTemplate && stockTemplate.depositTarget) return stockTemplate.depositTarget
    return deposit
  },
  getStockPosition(stockTemplate: StockTemplate | undefined, stockPosition: StockPosition | undefined) {
    if (stockTemplate && stockTemplate.stockposition) return stockTemplate.stockposition
    return stockPosition
  },
  getStockPositionTarget(stockTemplate: StockTemplate | undefined, stockPosition: StockPosition | undefined) {
    if (stockTemplate && stockTemplate.stockpositionTarget) return stockTemplate.stockpositionTarget
    return stockPosition
  },
}

export default transferUtils
