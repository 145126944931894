import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'

import TransferHistoryCard from '../../cards/Transfer/TransferHistoryCard'
import { useTransfer } from '../../contexts/TransferContext'
import useModalController from '../../hooks/useModalController'
import TransferHistoryModal from '../../modals/TransferHistoryModal'

type TransferHistoryViewProps = {
  style?: StyleProp<ViewStyle>
}

export default function TransferHistoryView({ style }: TransferHistoryViewProps) {
  const { stockAccounts } = useTransfer()
  const historyModal = useModalController()
  const { i18n } = useLanguage()

  const lastStockAccount = useMemo(() => (stockAccounts?.length ? stockAccounts[stockAccounts.length - 1] : undefined), [stockAccounts])

  if (!lastStockAccount) return <></>

  return (
    <TouchableOpacity onPress={() => historyModal.show()} style={style}>
      <TransferHistoryCard
        stockAccount={lastStockAccount}
        cardTitle={i18n.t('LASTSTOCKACCOUNT')}
        endButtonIcon="list-ul"
        onPressEndButton={() => historyModal.show()}
      />
      <TransferHistoryModal controller={historyModal} />
    </TouchableOpacity>
  )
}
