import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { FlatList, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import { IMLayout } from '../constants/Styles'
import IMLoadingIndicator from './IMLoadingIndicator'
import IMRefreshControl from './IMRefreshControl'
import ListSpacer from './MWS/ListSpacer'
import NoEntriesTag from './NoEntriesTag'
import Separator from './Separator'

export interface MultiLevelSelectorProps<T1, T2> {
  loadFirstLevelItems: () => T1[] | Promise<T1[]>
  filterFirstLevelItems?: (item: T1) => boolean
  firstLevelRenderItem: (item: T1, isSelected: boolean) => JSX.Element
  loadSecondLevelItems: (input: T1 | undefined) => T2[] | Promise<T2[]>
  filterSecondLevelItems?: (item: T2) => boolean
  secondLevelRenderItem: (itemT2: T2, itemT1: T1, isSelected: boolean) => JSX.Element
  preselectedFirstLevelItem?: T1
  preSelectedSecondLevelItem?: T2
  onSelected?: (item1: T1, item2: T2 | undefined) => void
  onChanged?: (item1: T1 | undefined, item2: T2 | undefined) => void
  itemHasSecondLevel?: (item: T1) => boolean
  style?: StyleProp<ViewStyle>
  noItemsTextT1?: string
  noItemsTextT2?: string
  hideLoadingSpinner?: boolean
  children?: ReactNode
  loadingChildren?: boolean
}

export default function MultiLevelSelector<T1, T2>(props: MultiLevelSelectorProps<T1, T2>) {
  const [itemsT1, setItemsT1] = useState<T1[]>([])
  const [loadingT1, setLoadingT1] = useState(false)
  const [loadingT2, setLoadingT2] = useState(false)
  const [itemsT2, setItemsT2] = useState<T2[]>([])
  const [selectedT1, setSelectedT1Item] = useState<T1 | undefined>(undefined)
  const [selectedT2, setSelectedT2Item] = useState<T2 | undefined>(undefined)
  const selectedT1HasSecondLevel = useMemo(
    () => !!selectedT1 && (!props.itemHasSecondLevel || props.itemHasSecondLevel(selectedT1)),
    [selectedT1, props.itemHasSecondLevel]
  )

  useEffect(() => {
    setItemsT1([])
    setItemsT2([])
    if (props.preselectedFirstLevelItem) {
      setSelectedT1Item(props.preselectedFirstLevelItem)
      if (props.preSelectedSecondLevelItem) {
        setSelectedT2Item(props.preSelectedSecondLevelItem)
      } else {
        loadT2(props.preselectedFirstLevelItem).catch(console.error)
      }
      return
    }
    setSelectedT1Item(undefined)
    loadT1().catch(console.error)
  }, [])

  useEffect(() => {
    if (!props.onChanged) return
    props.onChanged(selectedT1, selectedT2)
  }, [selectedT1, selectedT2])

  async function loadT1() {
    try {
      if (itemsT1?.length) {
        setItemsT2([])
        return itemsT1
      }
      setLoadingT1(true)
      let result = await props.loadFirstLevelItems()
      if (props.filterFirstLevelItems) result = result.filter(props.filterFirstLevelItems)
      setItemsT1(result ?? [])
      setItemsT2([])
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingT1(false)
    }
    return
  }

  async function loadT2(useT1Item: T1) {
    try {
      setLoadingT2(true)
      let result = await props.loadSecondLevelItems(useT1Item ?? selectedT1)
      if (props.filterSecondLevelItems) result = result.filter(props.filterSecondLevelItems)
      setItemsT2(result ?? [])
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingT2(false)
    }
  }

  function handleItemT1Selected(item: T1) {
    if (props.onChanged) {
      setSelectedT1Item(item)
      loadT2(item).catch(console.error)
    }
    if (props.itemHasSecondLevel && !props.itemHasSecondLevel(item)) {
      if (props.onSelected) props.onSelected(item, undefined)
      return
    }
    setSelectedT1Item(item)
    loadT2(item).catch(console.error)
  }

  function handleItemT1UnSelected() {
    setSelectedT1Item(undefined)
    loadT1().catch(console.error)
  }

  function handleItemT2Selected(item: T2) {
    if (!selectedT1) return
    if (props.onSelected) props.onSelected(selectedT1, item)
    if (props.onChanged) setSelectedT2Item(item)
  }

  function handleItemT2UnSelected() {
    setSelectedT2Item(undefined)
    if (!selectedT1) loadT1().catch(console.error)
    else loadT2(selectedT1).catch(console.error)
  }

  return (
    <View style={[IMLayout.flex.f1, props.style]}>
      {selectedT1 && (
        <>
          <TouchableOpacity onPress={handleItemT1UnSelected}>{props.firstLevelRenderItem(selectedT1, true)}</TouchableOpacity>
          {selectedT2 && (
            <TouchableOpacity onPress={handleItemT2UnSelected}>{props.secondLevelRenderItem(selectedT2, selectedT1, true)}</TouchableOpacity>
          )}
          <Separator />
          {((!selectedT2 && loadingT2) || (!!selectedT2 && props.loadingChildren)) && (
            <IMLoadingIndicator isVisible={true} style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }} />
          )}
          {!selectedT2 && selectedT1HasSecondLevel && !loadingT2 && (
            <FlatList
              style={IMLayout.flex.f1}
              data={itemsT2}
              renderItem={({ item }) => (
                <TouchableOpacity onPress={() => handleItemT2Selected(item)}>{props.secondLevelRenderItem(item, selectedT1, false)}</TouchableOpacity>
              )}
              refreshControl={
                <IMRefreshControl
                  refreshing={false}
                  onRefresh={() => {
                    loadT2(selectedT1).catch(console.error)
                  }}
                />
              }
              ListFooterComponent={
                !!itemsT2.length || loadingT2 || (props.itemHasSecondLevel && !props.itemHasSecondLevel(selectedT1)) ? (
                  <ListSpacer />
                ) : (
                  <NoEntriesTag text={props.noItemsTextT2} />
                )
              }
            />
          )}
        </>
      )}
      {!selectedT1 && (
        <FlatList
          style={IMLayout.flex.f1}
          data={itemsT1}
          renderItem={({ item }) => (
            <TouchableOpacity onPress={() => handleItemT1Selected(item)}>{props.firstLevelRenderItem(item, false)}</TouchableOpacity>
          )}
          refreshControl={
            <IMRefreshControl
              refreshing={false}
              onRefresh={() => {
                loadT1().catch(console.error)
              }}
            />
          }
          ListHeaderComponent={
            <IMLoadingIndicator isVisible={loadingT1} style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }} />
          }
          ListFooterComponent={!!itemsT1.length || loadingT1 ? <ListSpacer /> : <NoEntriesTag text={props.noItemsTextT1} />}
        />
      )}
      {props.children}
    </View>
  )
}
