/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Alert } from 'react-native'

import api from '../../apis/apiCalls'
import { InventoryAccounting, InventoryEntry, StockPosition } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import DeletableList from '../../components/DeletableList'
import ListSpacer from '../../components/MWS/ListSpacer'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useItemSelector from '../../hooks/useItemSelector'
import useRouteParam from '../../hooks/useRouteParam'
import { articleUtils } from '../../utils/articleUtils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function InventorySerialScreen(props: { navigation: any }) {
  const { i18n } = useLanguage()

  const inventory = useRouteParam<InventoryEntry>('inventory')
  const stockPosition = useRouteParam<StockPosition>('stockPosition')
  const routeItems = useRouteParam<InventoryAccounting[]>('accounting')
  const article = useMemo(() => routeItems?.find(item => !!item.article)?.article, [])
  const [items, loadItems] = useApi(api.getInventoryAccounting, routeItems)

  const selector = useItemSelector<InventoryAccounting>()
  const loader = useLoadingIndicator()

  function load() {
    loader.setLoading(true)
    loadItems({ inventoryCodeId: inventory?.id, stockPositionId: stockPosition?.id, articleId: article?.id })
      .then(result => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!result || result.length === 0) props.navigation.goBack()
      })
      .finally(() => loader.setLoading(false))
      .catch(console.error)
  }

  function deleteSelected() {
    if (!selector?.items || selector.items.length === 0) return
    loader.setLoading(true)

    Promise.all(selector.items.map(item => api.deleteInventory({ id: item.id })))
      .then(() => {
        selector.clear()
        load()
      })
      .catch(err => {
        Alert.alert(i18n.t('INFO_TITLE'), i18n.t('FailedToDeleteInventory'))
        console.error(err)
      })
      .finally(() => loader.setLoading(false))
  }

  return (
    <MWS_Screen>
      {items && (
        <>
          <Title>{i18n.t('SerialNumbers')}</Title>
          <DeletableList
            data={items}
            renderItem={({ item }) => (
              <SerialnumberCard
                serialnumber={item.serialnumber}
                quantity={articleUtils.formatQuantity(item.quantity, item.measurementUnit)}
                onPress={() => {
                  selector.toggle(item)
                }}
                selected={selector.includes(item)}
              />
            )}
            onDeletePressed={deleteSelected}
            anySelected={selector.any}
            ListFooterComponent={<ListSpacer />}
          />
        </>
      )}
    </MWS_Screen>
  )
}
