import { useApi } from '@infominds/react-api'
import React, { useEffect, useState } from 'react'
import { Image, ImageStyle, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import LoadingIndicator from '../old/LoadingIndicator'

export default function MWS_Image(props: {
  imageUri?: string
  id?: string
  style?: StyleProp<ViewStyle>
  showLoadingIndicator?: boolean
  imageStyle?: StyleProp<ImageStyle>
}) {
  const [img, loadImg, loading, setImg] = useApi(api.getArticleGraphic, '')

  const [loadedImgForID, setLoadedImgForID] = useState('')
  const [size, setSize] = useState<{ width: number; height: number }>()

  useEffect(() => {
    if (props.imageUri) {
      setImg(props.imageUri)
      getImageSize(props.imageUri)
    } else if (props.id && loadedImgForID !== props.id) {
      loadImg({ id: props.id })
        .then(result => {
          getImageSize(result)
          setLoadedImgForID(props.id ?? '')
        })
        .catch(console.error)
    }
  }, [props.id])

  function getImageSize(img: string) {
    Image.getSize(
      img,
      (width, height) => {
        setSize({ width: width, height: height })
      },
      console.error
    )
  }

  return (
    <View style={[styles.view, props.style]}>
      {loading && props.showLoadingIndicator && <LoadingIndicator style={styles.loadingIndicator} isVisible={true} />}
      {!loading && !!img && (
        <Image
          style={[styles.image, { width: size?.width, height: size?.height }, props.imageStyle]}
          source={{
            uri: img,
          }}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  view: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  loadingIndicator: {
    margin: 0,
    padding: 0,
    flex: 1,
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})
