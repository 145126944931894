import { IM, IMLayout, ModalController } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import { PackingList } from '../../apis/apiTypes'
import CardBasic from '../../components/old/CardBasic'
import Text from '../../components/old/Text'
import SelectionModal from '../SelectionModal'

export type ColloPackingListSelectionModalProps = {
  controller: ModalController
  packingLists: PackingList[]
  onSelected: (selection: PackingList) => void
  selectedId?: string
}

export default function ColloPackingListSelectionModal({ controller, packingLists, onSelected, selectedId }: ColloPackingListSelectionModalProps) {
  const RenderItem = useCallback(
    (item: PackingList) => (
      <CardBasic>
        <IM.View style={[IMLayout.flex.row, { justifyContent: 'space-between', alignItems: 'center' }]}>
          <Text>{item.number}</Text>
          {!!selectedId && item.id === selectedId && <IM.Icon icon={['fal', 'check']} />}
        </IM.View>
      </CardBasic>
    ),
    [selectedId]
  )

  return (
    <SelectionModal
      items={packingLists}
      close={selected => {
        controller.close()
        !!selected && onSelected(selected)
      }}
      show={controller.isShown}
      renderItem={RenderItem}
      closeIcon="times"
    />
  )
}
