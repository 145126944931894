import React from 'react'

import { ProductionsEmployeeTimeType } from '../apis/apiTypes'
import IMText from '../components/IMText'
import MWS_BaseCard, { MWSBaseCardProps } from './MWS_BaseCard'

export default function EmployeeTimeTypeCard(props: { employeeTimeType: ProductionsEmployeeTimeType } & MWSBaseCardProps) {
  const { employeeTimeType, ...otherProps } = props

  const code = employeeTimeType?.code?.trim()
  const description = employeeTimeType?.description?.trim()

  return (
    <MWS_BaseCard {...otherProps} style={{ flexDirection: 'column', height: 50 }}>
      {!!code && !description && <IMText>{code}</IMText>}
      {!!description && <IMText>{description}</IMText>}
    </MWS_BaseCard>
  )
}
