/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { Utils } from '@infominds/react-native-components'
import { useRef } from 'react'
import { Platform, StatusBar } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { ToastOptions } from 'react-native-toast-notifications/lib/typescript/toast'

const defaultOptions: ToastOptions = {
  duration: __DEV__ ? 5000 : 1500,
  placement: 'bottom',
  style: { marginTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0 },
}

/**
 * Toast Message Provider
 * @returns function to show a toast message
 */
export default function useToastMessage(options?: ToastOptions) {
  const toast = useToast()
  const toastIds = useRef<string[]>([])

  return {
    /**
     * Show ToastMessage.
     * @param values values can be given to replace ${value}, ${value2},... tokens in the text
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    show: (text: string, ...values: any[]) => {
      toastIds.current.forEach(id => toast.hide(id))
      const messageId = toast.show(Utils.stringValueReplacer(text, ...values), options ? { ...defaultOptions, ...options } : defaultOptions)
      toastIds.current.push(messageId)
    },
  }
}
