import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList } from 'react-native'

import api from '../apis/apiCalls'
import { StockAccounting } from '../apis/apiTypes'
import TransferHistoryCard from '../cards/Transfer/TransferHistoryCard'
import FullScreenModal from '../components/FullScreenModal'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { useTransfer } from '../contexts/TransferContext'
import useAlert from '../hooks/useAlert'
import { ModalController } from '../hooks/useModalController'
import { Error_Utils } from '../utils/ErrorUtils'

export type TransferHistoryModalProps = {
  controller: ModalController
}

export default function TransferHistoryModal({ controller }: TransferHistoryModalProps) {
  const transferContext = useTransfer()
  const { i18n } = useLanguage()

  const loader = useLoadingIndicator()
  const alert = useAlert()

  async function handleOnDelete(itemToRemove: StockAccounting) {
    if (itemToRemove && itemToRemove.stockAccountingId) {
      try {
        loader.setLoading(true)
        await Promise.resolve(api.deleteStockAccounting({ stockaccountingId: itemToRemove.stockAccountingId }))
        const filteredStockAccounts = transferContext.stockAccounts?.filter(item => item !== itemToRemove)
        transferContext.setStockAccounts(filteredStockAccounts)
      } catch (error) {
        loader.setLoading(false)
        alert.error(i18n.t('FailedToDeleteEntry'), Error_Utils.extractErrorMessageFromException(error))
      } finally {
        loader.setLoading(false)
      }
    }
  }

  return (
    <FullScreenModal isVisible={controller.isShown} close={controller.close} title={i18n.t('BUCHUNGEN')}>
      <FlatList
        data={transferContext.stockAccounts ?? []}
        renderItem={({ item }) => (
          <TransferHistoryCard
            stockAccount={item}
            onDelete={() => {
              handleOnDelete(item).then().catch(console.error)
            }}
          />
        )}
      />
    </FullScreenModal>
  )
}
