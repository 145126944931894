import api from '../apis/apiCalls'
import {
  Collo,
  Deposit,
  PackagingType,
  PackingList,
  PackingListColloState,
  PackingListMovement,
  SerialNumber,
  StockPosition,
  StockPositionAvailability,
} from '../apis/apiTypes'
import { PackingListCollos } from '../types/packingListTypes'
import { ColloPackingSelection } from '../views/PackingList/ColloPackingView'
import { Utils } from './Utils'

const colloUtils = {
  getIdentificationHash(collo: Collo, includePosition?: boolean, includeSerial?: boolean, includeMovementId?: boolean) {
    return Utils.hash({
      number: collo.number,
      articleId: collo.article.id,
      depositId: includePosition ? collo.deposit?.id : undefined,
      stockPositionId: includePosition ? collo.stockposition?.id : undefined,
      serialNumber: includeSerial ? collo.serialnumber?.id : undefined,
      movementId: includeMovementId ? collo.packinglistmovementId : undefined,
    })
  },
  compare(a: Collo, b: Collo) {
    if (a.id) return a.id === b.id
    return (
      !b.id &&
      a.number === b.number &&
      a.article.id === b.article.id &&
      a.deposit?.id === b.deposit?.id &&
      a.stockposition?.id === b.stockposition?.id &&
      a.serialnumber?.id === b.serialnumber?.id &&
      a.packinglistmovementId === b.packinglistmovementId
    )
  },
  getTitle(collo: Collo | undefined) {
    return collo?.number?.toString().trim() ?? ''
  },
  getDimension(collo: Collo | undefined) {
    const dimensions: number[] = []
    if (collo?.length) dimensions.push(collo.length)
    if (collo?.width) dimensions.push(collo.width)
    if (collo?.height) dimensions.push(collo.height)
    return dimensions.join('x')
  },
  sort(a: Collo, b: Collo): number {
    const sortKeyA = a?.number?.padStart(20, '*') || '0'
    const sortKeyB = b?.number?.padStart(20, '*') || '0'
    return sortKeyA > sortKeyB ? 1 : -1
  },
  sortForShipping(a: Collo, b: Collo) {
    let sortKeyA = a?.number?.padStart(20, '*') || '0'
    if (a.state === PackingListColloState.shipped) sortKeyA = `B-${sortKeyA}`
    else sortKeyA = `A-${sortKeyA}`

    let sortKeyB = b?.number?.padStart(20, '*') || '0'
    if (b.state === PackingListColloState.shipped) sortKeyB = `B-${sortKeyB}`
    else sortKeyB = `A-${sortKeyB}`

    return sortKeyA > sortKeyB ? 1 : -1
  },
  sortForCommissioning(a: Collo, b: Collo) {
    if (Utils.XOR(!!a?.id, !!b?.id)) {
      return a?.id ? -1 : 1
    }
    return 0
  },
  keepUniques(collos: Collo[] | undefined) {
    if (!collos) return []
    return Utils.keepUniques(collos, collo => collo.number)
  },
  create(
    packingListMovement: PackingListMovement,
    quantity: number,
    deposit?: Deposit,
    stockPosition?: StockPosition,
    serialNumber?: SerialNumber
  ): Collo {
    return {
      packinglistId: packingListMovement.packinglistId,
      packinglistmovementId: packingListMovement.id,
      packinglistmovementpartId: packingListMovement.packinglistmovementpartId,
      quantity: quantity,
      article: packingListMovement.article,
      deposit: deposit,
      stockposition: stockPosition,
      serialnumber: serialNumber,
    }
  },
  getQuantity(collos: Collo[] | undefined) {
    if (!collos) return 0
    return Utils.sum(collos, collo => collo.quantity)
  },
  getPackagingTypeTitle(packagingType: PackagingType | undefined) {
    if (!packagingType) return ''
    return `${packagingType.code} - ${packagingType.description}`
  },
  sortPackagingTypes(a: PackagingType, b: PackagingType) {
    return a.code > b.code ? 1 : -1
  },
  convertColloToStockPositionAvailability(collo: Collo) {
    return {
      article: collo.article,
      serialnumber: collo.serialnumber,
      quantity: collo.quantity,
      stockposition: collo.stockposition,
      deposit: collo.deposit,
    } as StockPositionAvailability
  },
  getColloWeight(colloNumber: string | undefined) {
    return new Promise<number>((resolve, reject) => {
      // read weight only if collonumber not 0 (Kollokommissionierung)
      if (colloNumber === undefined || colloNumber === '0') return resolve(0)
      api
        .getCollo({ number: colloNumber })
        .then(result => {
          if (!result || result.length === 0) return resolve(0)
          return resolve(result[0].weight ?? 0)
        })
        .catch(reject)
    })
  },
  isComplete(collo: Collo) {
    return !!collo && !!collo.packagingtypeId
  },
  isColloOpen(collo: Collo) {
    return !!collo?.id && !collo.packagingtypeId && collo.state === PackingListColloState.open
  },
  colloCanBeDeleted(collo: Collo) {
    return (
      !collo?.packagingtypeId &&
      (!collo.state ||
        collo.state === PackingListColloState.open ||
        collo.state === PackingListColloState.unloaded ||
        collo.state === PackingListColloState.packed)
    )
  },
  async getOpenCollosForPackingLists(packingLists: PackingList[]) {
    const requests = packingLists.map(packingList => {
      const hasDeliveryAddress = !!packingList.deliveryaddressId
      return api.getCollo({
        customerId: packingList.customerId,
        deliveryAddressId: hasDeliveryAddress ? packingList.deliveryaddressId : undefined,
        states: [PackingListColloState.open],
        onlyEmptyDeliveryAddress: hasDeliveryAddress ? undefined : true,
      })
    })

    const results = await Promise.all(requests)
    return results.map<PackingListCollos>((collos, index) => ({
      packingList: packingLists[index],
      collos: Utils.keepUniques(collos, c => c.number).sort(colloUtils.sort),
    }))
  },
  convertToColloPackingSelection(collos: Collo[]): ColloPackingSelection[] {
    return collos.map(
      collo =>
        ({
          collo,
          quantity: collo.quantity,
          height: collo.height,
          width: collo.width,
          length: collo.length,
          id: Utils.getUid(),
          packagingType: undefined,
          targetStockPositions: undefined,
          weight: collo.weight,
        }) satisfies ColloPackingSelection
    )
  },
}

export default colloUtils
