import { Button, IM, useItemSelector, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useState } from 'react'
import { FlatList } from 'react-native'

import api from '../../apis/apiCalls'
import { ProductionPartToMoveForRequest } from '../../apis/apiRequestTypes'
import { Item, Production, ProductionPartForTransfer, ProductionState } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ProductionCard from '../../cards/Production/ProductionCard'
import ProductionPartToMoveCard from '../../cards/Production/ProductionPartToMoveCard'
import ProductionPartTransferToMoveCard from '../../cards/Production/ProductionPartTransferToMoveCard'
import { ProductionPartToMove } from '../../classes/ProductionData'
import IMText from '../../components/IMText'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Separator from '../../components/Separator'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import { ScreenType } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import { Error_Utils } from '../../utils/ErrorUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import ProductionSelectionView from '../../views/Production/ProductionSelectionView'

export default function ProductionMoveArticleScreen() {
  const navigation = useNavigation()
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()

  const productionPartsToMove = useItemSelector<ProductionPartToMove>([])

  const [production, setProduction] = useState<Production>()
  const [productionPartSelected, setPartSelected] = useState<ProductionPartToMove>()
  const [itemSelected, setItemSelected] = useState<Item>()

  const [productionPartForTransfer, setProductionPartForTransfer] = useState<ProductionPartForTransfer[]>([])
  const [quantityToMoveSelected, setQuantityToMove] = useState<number>(0)

  function handleProductionSelection(item: Production) {
    if (item.quantityOpen === 0 || item.state !== ProductionState.Open) {
      alert.info(i18n.t('ProductionAlreadyComplete'))
    }
    setProduction(item)
    setItemSelected(undefined)
    setPartSelected(undefined)
    setQuantityToMove(0)
    productionPartsToMove.clear()
    setProductionPartForTransfer([])
  }

  function handleProductionToSelection(item: Production) {
    setProductionPartForTransfer([])
    if (!item) {
      return
    }

    loader.setLoading(true)
    api
      .getPartsForTransfer({
        productionId: item.id,
        articleId: itemSelected?.article.id,
      })
      .then(result => setProductionPartForTransfer(result))
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  function onDeleteProduction() {
    setProduction(undefined)
    setItemSelected(undefined)
    setPartSelected(undefined)
    setProductionPartForTransfer([])
    productionPartsToMove.clear()
  }

  function handleItemSelection(item: Item) {
    if (!item) return
    setItemSelected(item)
    setPartSelected(undefined)
    setProductionPartForTransfer([])
    loadParts(item)
  }

  function handlePressPart(item: ProductionPartToMove) {
    setPartSelected(item)
    setQuantityToMove(item.quantity)
  }

  function loadParts(item: Item) {
    if (!production || !item) {
      productionPartsToMove.clear()
      return
    }

    loader.setLoading(true)
    api
      .getProductionPartsBooked({
        productionId: production.id,
        articleId: item.article.id,
      })
      .then(result => {
        productionPartsToMove.clear()
        result.forEach(q => {
          productionPartsToMove.add({
            productionId: q.productionId,
            production: q.production,
            articleProduction: q.articleProduction,
            article: q.article,
            unitId: q.unitId,
            quantity: q.quantity,
            stockAccountings: q.stockAccountings,
          })
        })
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: itemSelected })
  }

  function onDeleteItem() {
    setItemSelected(undefined)
    setPartSelected(undefined)
    productionPartsToMove.clear()
    setProductionPartForTransfer([])
  }

  function handleCancel() {
    setPartSelected(undefined)
    setProductionPartForTransfer([])
  }

  function getUnitText() {
    const unit = articleUtils.getUnitById(itemSelected?.article, productionPartSelected?.unitId)
    return unit?.code
  }

  async function ProductionPartTransferToMoveCardPress(partToTransfer: ProductionPartForTransfer) {
    if (!partToTransfer || !productionPartSelected) return

    const result = await alert.yesNo(i18n.t('PERFORMASSIGNMENT'))

    if (!result) return

    const partsToMove: ProductionPartToMoveForRequest[] = []
    let quantity = quantityToMoveSelected
    for (const item of productionPartSelected.stockAccountings) {
      if (item.serialNumbersBooked && item.serialNumbersBooked.length > 0) {
        for (const itemSerialNumber of item.serialNumbersBooked) {
          const quantityToMove = quantity > itemSerialNumber.quantity ? itemSerialNumber.quantity : quantity
          const request = {
            stockaccountingId: itemSerialNumber.stockaccountingId,
            quantity: itemSerialNumber.quantity,
            productionIdTarget: partToTransfer.productionId,
            serialnumberId: itemSerialNumber.serialnumber.id,
          }
          partsToMove.push(request)
          quantity -= quantityToMove
          if (quantity <= 0) break
        }
      } else {
        const quantityToMove = quantity > item.quantity ? item.quantity : quantity
        const request = {
          stockaccountingId: item.id,
          quantity: quantityToMove,
          productionIdTarget: partToTransfer.productionId,
        }
        partsToMove.push(request)
        quantity -= quantityToMove
      }
      if (quantity <= 0) break
    }

    try {
      loader.setLoading(true)
      await api.patchProductionPartsMove({ partsToMove })
    } catch (error) {
      loader.setLoading(false)
      console.error(error)
      alert.error(i18n.t('FailedToReallocation'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
      handleCancel()
      if (itemSelected) {
        loadParts(itemSelected)
      } else {
        setProduction(undefined)
      }
    }
  }

  return (
    <MWS_Screen title={i18n.t('ProductionReallocation')}>
      <IM.View style={{ flex: 1 }}>
        {!production && <ProductionSelectionView onSelected={item => handleProductionSelection(item)} history filterResultByOrder />}
        {production && (
          <>
            <ProductionCard handler={production} onDelete={onDeleteProduction} endButton={true} />
            {!itemSelected && <ItemSelectionView onSelected={item => handleItemSelection(item)} showResultInModal />}
            {itemSelected && (
              <ArticleCard
                article={itemSelected?.article}
                serialNumber={itemSelected?.serialnumber}
                onDelete={onDeleteItem}
                onPress={showArticleInfoScreen}
              />
            )}
            <Separator />
            {!productionPartSelected && (
              <IM.View style={[{ flex: 1 }]}>
                <FlatList
                  style={{ flex: 1 }}
                  data={productionPartsToMove.items ?? []}
                  renderItem={({ item }) => (
                    <IM.View>
                      <ProductionPartToMoveCard
                        item={item}
                        showQuantities={true}
                        onPress={() => handlePressPart(item)}
                        style={[productionPartSelected && item === productionPartSelected && { marginHorizontal: 0 }]}
                      />
                    </IM.View>
                  )}
                />
              </IM.View>
            )}
            {productionPartSelected && (
              <IM.View style={[{ flex: 1 }]}>
                <IMText style={{ textAlign: 'center' }}>{i18n.t('PRODUCTIONSOURCE')}</IMText>
                <ProductionPartToMoveCard item={productionPartSelected} showQuantities={true} />
                <QuantityInput
                  value={productionPartSelected.quantity}
                  onValueChanged={setQuantityToMove}
                  icon={'cubes-stacked'}
                  unit={getUnitText()}
                />
                <IMText style={{ textAlign: 'center' }}>{i18n.t('ProductionTarget')}</IMText>
                {productionPartForTransfer?.length === 0 && <ProductionSelectionView onSelected={item => handleProductionToSelection(item)} />}
                {productionPartForTransfer !== undefined && productionPartForTransfer?.length > 0 && (
                  <IM.View style={[{ flex: 1 }]}>
                    <FlatList
                      style={{ flex: 1 }}
                      data={productionPartForTransfer}
                      renderItem={({ item }) => (
                        <ProductionPartTransferToMoveCard
                          item={item}
                          showQuantities={true}
                          onPress={() => {
                            ProductionPartTransferToMoveCardPress(item).catch(console.error)
                          }}
                        />
                      )}
                    />
                  </IM.View>
                )}
                <Button
                  style={{
                    margin: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
                  }}
                  title={i18n.t('CANCEL')}
                  onPress={handleCancel}
                />
              </IM.View>
            )}
          </>
        )}
      </IM.View>
    </MWS_Screen>
  )
}
