import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ArticleDepositQuantity, Deposit } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ArticleDepositAvailabilityCard from '../../cards/Article/ArticleDepositAvailabilityCard'
import ArticleDepositPurchaseIncomingQuantitiesCard from '../../cards/Article/ArticleDepositPurchaseIncomingQuantitiesCard'
import DepositCard from '../../cards/StockPosition/DepositCard'
import { Article } from '../../classes/Article'
import Separator from '../../components/Separator'

interface OrderProposalInfoViewProps {
  deposit: Deposit
  article: Article
  articleDepositQuantity: ArticleDepositQuantity
  selectedAmount?: number
  style?: StyleProp<ViewStyle>
}

export default function OrderProposalInfoView(props: OrderProposalInfoViewProps) {
  return (
    <>
      <DepositCard deposit={props.deposit} />
      <Separator />
      <ArticleCard article={props.article.info} unit={props.article.getUsedUnit()} maxLinesDescription={3} navigateToArticleInfoOnPress />
      <ArticleDepositAvailabilityCard article={props.article} depositQuantity={props.articleDepositQuantity} defaultOpen />
      <ArticleDepositPurchaseIncomingQuantitiesCard article={props.article} depositQuantity={props.articleDepositQuantity} />
    </>
  )
}
