/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useApi } from '@infominds/react-api'
import { useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleOrder, OrderState, SerialNumber } from '../../apis/apiTypes'
import ArticleOrderCard from '../../cards/Article/ArticleOrderCard'
import { Article } from '../../classes/Article'
import ElusiveButton from '../../components/ElusiveButton'
import IMLoadingIndicator from '../../components/IMLoadingIndicator'
import IMRefreshControl from '../../components/IMRefreshControl'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import NoEntriesTag from '../../components/NoEntriesTag'
import Text from '../../components/old/Text'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useAlert from '../../hooks/useAlert'
import useRouteParam from '../../hooks/useRouteParam'
import ArticleInfoView from '../../views/Article/ArticleInfoView'

export default function ArticleOrdersScreen(props: { navigation: any }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const article = useRouteParam<Article>('article')
  const serialNumber = useRouteParam<SerialNumber>('serialNumber')
  const [openOrders, loadOpenOrders, loadingOpenOrders, setOpenOrders] = useApi(api.getArticleOrders, [])
  const [otherOrders, loadOtherOrders, loadingOtherOrders, setOtherOrders] = useApi(api.getArticleOrders, [])
  const [openSerialNumberOrders, loadOpenSerialNumberOrders, loadingOpenSerialNumberOrders, setOpenSerialNumberOrders] = useApi(
    api.getSerialNumberOrders,
    []
  )
  const [otherSerialNumberOrders, loadOtherSerialNumberOrders, loadingOtherSerialNumberOrders, setOtherSerialNumberOrders] = useApi(
    api.getSerialNumberOrders,
    []
  )
  const [showAllOrders, setShowAllOrders] = useState(false)

  useEffect(() => {
    if (serialNumber) {
      loadOpenSerialNumberOrders({ serialNumberId: serialNumber.id, open: true, blocked: false, done: false, partiallyDone: false }, result =>
        result.sort(sortOrders)
      ).catch(failedToLoad)
    } else {
      loadOpenOrders({ articleId: article?.info.id, open: true, blocked: false, done: false, partiallyDone: false }, result =>
        result.sort(sortOrders)
      ).catch(failedToLoad)
    }
  }, [])

  function sortOrders(a: ArticleOrder, b: ArticleOrder) {
    if (a.state > b.state) return 1
    if (a.state < b.state) return -1
    if (a.document > b.document) return 1
    return -1
  }

  function handleLoadAllOrdersButtonPressed() {
    setOpenOrders([])
    setOpenSerialNumberOrders([])
    setOtherOrders([])
    setOtherSerialNumberOrders([])

    if (serialNumber) {
      if (showAllOrders) {
        loadOpenSerialNumberOrders({ serialNumberId: serialNumber.id, open: true, blocked: false, done: false, partiallyDone: false }, result =>
          result.sort(sortOrders)
        ).catch(failedToLoad)
      } else {
        loadOtherSerialNumberOrders({ serialNumberId: serialNumber.id, open: false, blocked: true, done: true, partiallyDone: true }, result =>
          result.sort(sortOrders)
        ).catch(failedToLoad)
      }
    } else {
      if (showAllOrders) {
        loadOpenOrders({ articleId: article?.info.id, open: true, blocked: false, done: false, partiallyDone: false }, result =>
          result.sort(sortOrders)
        ).catch(failedToLoad)
      } else {
        loadOtherOrders({ articleId: article?.info.id, open: false, blocked: true, done: true, partiallyDone: true }, result =>
          result.sort(sortOrders)
        ).catch(failedToLoad)
      }
    }
    setShowAllOrders(!showAllOrders)
  }

  function filterOrders(o: ArticleOrder) {
    return o.state === OrderState.Open || showAllOrders
  }

  function failedToLoad(reason?: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    alert.error(i18n.t('NoOrdersForArticleFound'), article?.info.code, reason?.Message)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    props.navigation.goBack()
  }

  return (
    <MWS_Screen title={i18n.t('Orders')}>
      {article && (
        <>
          <ArticleInfoView article={article} limitDescription serialNumber={article.serialNumber} />
          <View style={{ flex: 1 }}>
            <Title>{i18n.t('Orders')}</Title>
            <OrderList
              data={[
                ...(openOrders && openOrders?.length > 0 ? openOrders : openSerialNumberOrders ?? []),
                ...(otherOrders && otherOrders?.length > 0 ? otherOrders : otherSerialNumberOrders ?? []),
              ].filter(filterOrders)}
              loading={loadingOpenOrders || loadingOpenSerialNumberOrders}
              showAll={showAllOrders}
              showButton={!loadingOpenOrders && !loadingOtherOrders && !loadingOpenSerialNumberOrders && !loadingOtherSerialNumberOrders}
              toggleShowAll={handleLoadAllOrdersButtonPressed}
              reLoad={() => {}}
              loadingFooter={loadingOtherOrders || loadingOtherSerialNumberOrders}
            />
          </View>
        </>
      )}
    </MWS_Screen>
  )
}

function OrderList(props: {
  data: ArticleOrder[]
  loading: boolean
  loadingFooter: boolean
  showAll: boolean
  showButton: boolean
  toggleShowAll: () => void
  reLoad?: () => void
}) {
  const { i18n } = useLanguage()

  function getStateTag(index: number) {
    if (!props.data || props.data.length < 1) return undefined
    if (index === 0 || props.data[index].state !== props.data[index - 1].state) {
      switch (props.data[index].state) {
        case OrderState.Open:
          return i18n.t('Open')
        case OrderState.PartiallyDone:
          return i18n.t('PartiallyDone')
        case OrderState.Blocked:
          return i18n.t('Blocked')
        case OrderState.Done:
          return i18n.t('Done')
        default:
          return undefined
      }
    }
    return undefined
  }

  return (
    <FlatList
      refreshing={props.loading}
      refreshControl={
        <IMRefreshControl
          refreshing={props.loading}
          onRefresh={
            props.reLoad
              ? () => {
                  if (props.reLoad) props.reLoad()
                }
              : undefined
          }
        />
      }
      data={props.data}
      renderItem={({ item, index }) => <RenderItem order={item} stateTag={getStateTag(index)} />}
      ListFooterComponent={
        <View style={styles.listFooter}>
          {props.data.length === 0 && !props.loading && <NoEntriesTag />}
          <IMLoadingIndicator isVisible={props.loadingFooter} />
          <ElusiveButton
            hide={!props.showButton}
            title={props.showAll ? i18n.t('ShowOnlyOpenOrders') : i18n.t('SHOWOTHERORDERS')}
            onPress={() => {
              props.toggleShowAll()
            }}
          />
        </View>
      }
    />
  )
}

function RenderItem(props: { order: ArticleOrder; style?: StyleProp<ViewStyle>; stateTag?: string }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return (
    <View style={styles.renderItem}>
      {props.stateTag && (
        <View>
          <Text
            style={{
              margin: 0,
              marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
              marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
              textAlign: 'center',
              color: Colors.white,
              backgroundColor: theme.textDetail,
              borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
              paddingVertical: 5,
            }}>
            {props.stateTag}
          </Text>
        </View>
      )}
      <ArticleOrderCard order={props.order} />
    </View>
  )
}

const styles = StyleSheet.create({
  renderItem: {},
  listFooter: {
    marginBottom: 20,
  },
})
