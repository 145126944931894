import { CardProps, IM, IMLayout, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Collo, Freighter } from '../../apis/apiTypes'
import PackingListCard from '../../cards/Packinglist/PackingListCard'
import Picker from '../../components/MWS/Inputs/Picker'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import ListSpacer from '../../components/MWS/ListSpacer'
import Button from '../../components/old/Button'
import Separator from '../../components/Separator'
import SwitchWithText from '../../components/SwitchWithText'
import { StorageKeys, STYLE_CONSTANTS } from '../../constants/Constants'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import ColloCompletionModal from '../../modals/ColloCompletionModal'
import PackingListColloDetailModal from '../../modals/packingLists/PackingListColloDetailModal'
import colloUtils from '../../utils/colloUtils'
import { ColloPackingSelection } from './ColloPackingView'
import { PackingListToConclude, UpdatePackingList } from './PackingListCompletionListView'

export type PackingListCompletionViewProps = {
  packingList: PackingListToConclude
  freighters: Freighter[]
  loadingFreighters: boolean
  onChange: (value: UpdatePackingList) => void
  separator?: boolean
  reloadMovements: () => void
} & Pick<CardProps, 'onPress' | 'disabled'>

export default function PackingListCompletionView({
  packingList: dataIn,
  freighters,
  loadingFreighters,
  onChange,
  separator,
  reloadMovements,
  ...cardProps
}: PackingListCompletionViewProps) {
  const { theme } = useTheme()
  const alert = useAlert()
  const { colloMode, multiPackingListConsignment } = usePackingList()
  const { packingList, conclude, isOpen, freighter, isPrinting, isCreateDeliveryNote, collos, colloCount, weight } = dataIn
  const { i18n } = useLanguage()
  const colloDetailModal = useModalController<ColloPackingSelection[]>()
  const colloCompletionModal = useModalController<Collo[]>()

  const colloWeight = useMemo(() => {
    const w = (collos?.length ? Utils.sum(collos, c => c.weight ?? 0) : weight) || 0
    return Utils.roundToPrecision(w, 3)
  }, [collos, weight])
  const weightEditable = useMemo(() => !colloMode && !collos?.length, [collos])

  const collosToConclude = useMemo(
    () =>
      colloMode
        ? (collos
            ?.filter(c => !!c.collo && !colloUtils.isComplete(c.collo))
            .map(c => c.collo)
            .filter(Boolean) as Collo[])
        : undefined,
    [collos]
  )

  useEffect(() => {
    if (colloMode && !multiPackingListConsignment && collosToConclude?.length) {
      colloCompletionModal.show(collosToConclude)
    }
    if (colloMode) {
      loadColloWeight().catch(console.error)
    }
  }, [])

  async function loadColloWeight() {
    if (!collos?.length || !colloMode) return
    const result = await Promise.allSettled(collos?.map(c => api.getColloWeight({ number: c.collo?.number ?? '' })))
    onChange({ weight: Utils.sum(result, r => (r.status === 'fulfilled' ? (r.value ?? 0) : 0)) })
  }

  const { colloDetailButtonText, anyToConclude } = useMemo<{ colloDetailButtonText: string; anyToConclude?: boolean }>(() => {
    if (!colloMode || !collosToConclude?.length) return { colloDetailButtonText: i18n.t('COLLO_DETAILS') }
    if (collosToConclude.length === 1) return { colloDetailButtonText: i18n.t('CONCLUDE_COLLO'), anyToConclude: true }
    return { colloDetailButtonText: Utils.stringValueReplacer(i18n.t('CONCLUDE_N_COLLOS'), collosToConclude.length), anyToConclude: true }
  }, [collosToConclude, colloMode])

  function handleColloQuantityChanged(value = 0) {
    if (colloMode) return
    onChange({ colloCount: value, collos: [] })
  }

  function handleCollosSelected(changedCollos: ColloPackingSelection[]) {
    onChange({ collos: changedCollos, colloCount: undefined, weight: undefined })
  }

  function handleWeightChanged(value: number | undefined) {
    if (!weightEditable) return
    onChange(prev => ({ ...prev, weight: value }))
  }

  function handleConfirmPressed() {
    if (anyToConclude && colloMode) {
      alert.info(i18n.t('CONCLUDE_COLLOS_FIRST_ALERT'))
      return
    }
    onChange({ isOpen: false, conclude: true })
  }

  return (
    <IM.View>
      <PackingListCard
        packingList={packingList}
        isMarked={conclude}
        colloInfo={conclude && { colloCount: colloCount ?? collos?.length, weight: colloWeight }}
        {...cardProps}
      />
      {isOpen && (
        <IM.View spacing={'top'}>
          <QuantityInput
            text={i18n.t('ColloCount')}
            value={colloCount ?? collos?.length}
            editable={!colloMode}
            onEndEditing={handleColloQuantityChanged}
            style={[styles.spacingVertical]}
          />
          <QuantityInput
            text={i18n.t('Weight')}
            unit={i18n.t('WeightUnit')}
            value={colloWeight}
            onEndEditing={handleWeightChanged}
            placeHolder={''}
            editable={weightEditable}
            style={[styles.spacingVertical]}
          />
          {(!!collos?.length || !colloMode) && (
            <Button
              type={anyToConclude ? 'danger' : 'neutral'}
              title={colloDetailButtonText}
              style={[styles.spacingVertical, { borderWidth: 1, borderColor: theme.input.border, marginTop: 0 }]}
              onPress={() => {
                if (collosToConclude?.length) {
                  colloCompletionModal.show(collosToConclude)
                  return
                }
                colloDetailModal.show(collos ?? [])
              }}
            />
          )}
          <Picker
            text={i18n.t('Freighter')}
            defaultValue={freighter}
            values={freighters}
            onSubmit={() => onChange({ freighter: freighter })}
            placeHolder={i18n.t('SELECT_PLACEHOLDER')}
            style={styles.spacingVertical}
            captionExtractor={item => `${item.code} - ${item.description}`}
            disabled={!!packingList.freighter}
            loading={loadingFreighters}
          />
          <SwitchWithText
            text={i18n.t('Print_PackingList')}
            value={isPrinting === undefined ? false : isPrinting}
            setValue={value => onChange({ isPrinting: value })}
            style={[styles.spacingVerticalSwitch]}
          />
          {packingList.isPossibleCreateDeliveryNote && (
            <SwitchWithText
              text={i18n.t('CREATEDELIVERYNOTE_CONCLUDE_PACKINGLIST')}
              value={isCreateDeliveryNote === undefined ? false : isCreateDeliveryNote}
              setValue={value => onChange({ isCreateDeliveryNote: value })}
              style={[styles.spacingVerticalSwitch]}
              storageKey={StorageKeys.PACKINGLIST_CREATE_DELIVERY_NOTE_SWITCH}
            />
          )}
          {multiPackingListConsignment && (
            <IM.View style={[IMLayout.flex.row]}>
              {conclude && (
                <Button
                  style={[IMLayout.flex.f1, { borderWidth: 1, borderColor: theme.input.border }]}
                  type="neutral"
                  title={i18n.t('DONT_CONCLUDE')}
                  onPress={() => onChange({ isOpen: false, conclude: false })}
                />
              )}
              <Button
                style={[IMLayout.flex.f1, { borderWidth: 1, borderColor: theme.input.border }]}
                type="neutral"
                title={i18n.t('Confirm')}
                onPress={handleConfirmPressed}
              />
            </IM.View>
          )}
          {!!separator && <Separator style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }} />}
          <ListSpacer height={20} />
        </IM.View>
      )}
      <PackingListColloDetailModal
        controller={colloDetailModal}
        packingList={packingList}
        onCollosSelected={handleCollosSelected}
        proposedQuantity={collos?.length ? undefined : colloCount}
        proposedWeight={collos?.length ? undefined : weight}
      />
      <ColloCompletionModal
        controller={colloCompletionModal}
        onCompleted={reloadMovements}
        onAborted={reloadMovements}
        deposit={packingList.depositContra}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  spacingVertical: {
    marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  spacingVerticalSwitch: {
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
