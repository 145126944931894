import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import Title from '../../components/MWS/Title'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { MeasurementUnitNetWeightInputHandler } from '../../hooks/specific/useMeasurementUnitNetWeightInputHandler'

type TransferArticleWeightInputProps = {
  handler: MeasurementUnitNetWeightInputHandler
}

export default function TransferArticleWeightInput({ handler }: TransferArticleWeightInputProps) {
  const { i18n } = useLanguage()

  if (!handler.required) return <></>
  return (
    <View style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }}>
      <Title>{i18n.t('NetWeightInputRequired')}</Title>
      <QuantityInput value={0} onValueChanged={handler.setInput} text={`1 ${handler.unit?.code?.trim() ?? ''}`} unit={i18n.t('WeightUnit')} />
    </View>
  )
}
