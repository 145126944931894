import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { Item } from '../../apis/apiTypes'
import { ProductionPartCollection } from '../../classes/ProductionData'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { ProductionHandler, ProductionMode } from '../../hooks/specific/useProductionHandler'
import useAlert from '../../hooks/useAlert'
import productionUtils from '../../utils/ProductionUtils'
import ItemSelectionView from '../InputViews/ItemSelectionView'
import ProductionOutProcessMode from './ProductionOutProcessMode'

export default function ProductionOutMasterInputView(props: { handler: ProductionHandler }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const userSettings = useUserSettings()

  const { handler } = props

  function handleItemSelection(item: Item) {
    const foundPart = handler.containsItem(item, true)
    if (foundPart) {
      if (checkAlreadyDone(foundPart, item)) return
      //goto detail
      if (!foundPart.article.isSerialNumberActive || foundPart.article.isLottoSerialNumber || !foundPart.part.stockposition) {
        handler.setSelectedPart(foundPart, item.serialnumber)
      }
      //add serialNumber directly
      else {
        handler.addQuantity(foundPart.part, {
          quantity: 1,
          serialNumber: item.serialnumber,
          unit: foundPart.article.getUsedUnit(),
          deposit: handler.production?.production.depositOutgoing,
          stockPosition: foundPart.part.stockposition,
        })
      }
      return
    }
    //if part is not in the list
    if (handler.production?.employeeTimeTypeId || handler.containsItem(item)) {
      alert.info(i18n.t('ArticleNotInProductionListAlert'), item.article.code)
      return
    }
    //ask if user wants to add part to list and create/add it
    alert
      .yesNo(i18n.t('ArticleNotInProductionListAddAlert'), item.article.code)
      .then(result => {
        if (!result) return
        const dummyPart = productionUtils.createDummyPart(item)
        if (!dummyPart) return
        handler.setSelectedPart(productionUtils.createPartCollection(dummyPart, undefined, userSettings))
      })
      .catch(console.error)
  }

  function checkAlreadyDone(part: ProductionPartCollection, item: Item) {
    if (!part) return false
    if (handler.productionMode === ProductionMode.lastProductionStep && productionUtils.isPartFinished(part)) {
      alert.error(i18n.t('ProductionArticleAlreadyFinished'), part.article.info.code)
      return true
    }
    if (!item.serialnumber) return false

    if (part.selectedQuantities.find(q => q.serialNumber?.id === item.serialnumber?.id)) {
      alert.error(i18n.t('SelectedSerialAlreadyExists'), item.serialnumber.number)
      return true
    }
    return false
  }

  //in Process-Mode (scanned production with EmployeeTimeTypeId). User first inputs the produced amount and all non-SN articles will accept that amount*factor
  if (handler.productionMode === ProductionMode.processAmountSelection) {
    return (
      <ProductionOutProcessMode
        handler={handler}
        style={{
          marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS,
        }}
      />
    )
  }

  return <ItemSelectionView onSelected={handleItemSelection} fetchArticleAndSerial showResultInModal />
}
