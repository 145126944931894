import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDTO, Freighter, Item, StockPositionAvailabilityWithMainSupplier, StockTemplate } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import FreighterCard from '../../cards/FreighterCard'
import { IMIconButton } from '../../components/IMIconButton'
import IMRefreshControl from '../../components/IMRefreshControl'
import IMText from '../../components/IMText'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useTransfer } from '../../contexts/TransferContext'
import useArticle from '../../hooks/useArticle'
import SelectionModal from '../../modals/SelectionModal'
import { articleUtils } from '../../utils/articleUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import transferUtils from '../../utils/transferUtils'
import { Utils } from '../../utils/Utils'

export default function TransferArticleAvailabilityView(props: {
  show: boolean
  template: StockTemplate | undefined
  style?: StyleProp<ViewStyle>
  onItemSelected: (item: Item) => void
}) {
  const { i18n } = useLanguage()

  const isFocused = useIsFocused()
  const [showSelectorModal, setShowSelectorModal] = useState(false)
  const [availability, loadStockPositionAvailability, loading] = useApi(api.getStockPositionAvailabilitiesWithMainSuppliers)
  const { savedDeposit: savedDepositSource, savedStockPosition: savedStockPositionSource } = useTransfer()

  const [filter, setFilter] = useState<Freighter[]>([])

  const suppliers = useMemo(
    () =>
      Utils.keepUniques(
        availability?.filter(item => !!item.supplierId),
        item => item.supplierId
      ).map<Freighter>(q => ({ code: q.supplier, id: q.supplierId, description: '' })) ?? [],
    [availability]
  )

  const filteredAvailability = useMemo(
    () => reduceAvailability(availability?.filter(a => !filter.length || filter.find(f => f.id === a.supplierId))),
    [availability, filter]
  )

  function reduceAvailability(availabilityToReduce: StockPositionAvailabilityWithMainSupplier[] | undefined) {
    if (!availabilityToReduce) return []
    return availabilityToReduce.reduce<StockPositionAvailabilityWithMainSupplier[]>((total, current) => {
      const foundEntry = total.find(q => q.article.id === current.article.id)
      if (foundEntry) {
        foundEntry.quantity += current.quantity
        foundEntry.quantityCommissioned = (foundEntry.quantityCommissioned ?? 0) + (current.quantityCommissioned ?? 0)
        return total
      }
      total.push(current)
      return total
    }, [])
  }

  const title = useMemo(() => {
    let result = String(i18n.t('Availability'))
    if (props.template) {
      const deposit = transferUtils.getDeposit(props.template, savedDepositSource)
      result = `${result} - ${stockPositionUtils.getTitle(transferUtils.getStockPosition(props.template, savedStockPositionSource), deposit)}`
    }
    return result
  }, [props.template, savedDepositSource])

  const filterTitle = useMemo(() => filter.map(f => f.code).join(', '), [filter])

  useEffect(loadAvailability, [props.show, isFocused])

  function loadAvailability() {
    if (!props.show || !transferUtils.getStockPosition(props.template, savedStockPositionSource)?.id || !isFocused) return
    loadStockPositionAvailability({ stockPositionId: transferUtils.getStockPosition(props.template, savedStockPositionSource)?.id }, result =>
      result.filter(r => !articleUtils.isSerialNumberActive(r.article, undefined, props.template?.isVerifySerialNumber) || !!r.serialnumber)
    ).catch(console.error)
  }

  if (!props.show || !transferUtils.getStockPosition(props.template, savedStockPositionSource)?.id) return <></>

  return (
    <View style={[{ flex: 1 }, props.style]}>
      <View style={styles.titleAndButtons}>
        <View style={{ flex: 1 }}>
          <IMText>{title}</IMText>
          <IMText numberOfLines={2}>{filterTitle}</IMText>
        </View>
        {filter && (
          <View style={styles.buttons}>
            <IMIconButton
              style={[styles.filterButton, !!filter.length && styles.filterButtonWithClearButton, { backgroundColor: Colors.tint }]}
              iconColor={Colors.white}
              icon={'filter'}
              onPress={() => setShowSelectorModal(true)}
            />
            {!!filter.length && (
              <IMIconButton
                style={[styles.clearButton, { backgroundColor: Colors.tint }]}
                iconColor={Colors.white}
                icon={'times'}
                onPress={() => setFilter([])}
              />
            )}
          </View>
        )}
      </View>
      <FlatList
        data={filteredAvailability}
        renderItem={({ item }) => (
          <AvailabilityRenderItem
            article={item.article}
            quantity={item.quantity}
            stockTemplate={props.template}
            onPress={() => props.onItemSelected({ article: item.article })}
          />
        )}
        refreshControl={<IMRefreshControl refreshing={loading} onRefresh={loadAvailability} />}
        ListFooterComponent={loading || filteredAvailability.length ? <ListSpacer /> : <NoEntriesTag />}
      />
      <SelectionModal
        show={showSelectorModal}
        items={suppliers}
        preSelection={filter}
        multiSelection
        close={() => setShowSelectorModal(false)}
        onMultiSelection={setFilter}
        renderItem={(item, _index, selected) => <FreighterCard freighter={item} isMarked={selected} />}
      />
    </View>
  )
}

function AvailabilityRenderItem(props: { article: ArticleDTO; quantity: number; stockTemplate?: StockTemplate; onPress: () => void }) {
  const article = useArticle(
    props.article,
    { useUnitType: props.stockTemplate?.quantityInsertTyp, isVerifySerialNumber: props.stockTemplate?.isVerifySerialNumber },
    [props.article.id]
  )
  return <ArticleCard article={article?.info} quantity={article?.getQuantity(props.quantity)} unit={article?.getUsedUnit()} onPress={props.onPress} />
}

const styles = StyleSheet.create({
  titleAndButtons: {
    margin: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  filterButton: {
    padding: 10,
    justifyContent: 'center',
    borderRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
  },
  filterButtonWithClearButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  clearButton: {
    marginLeft: 1,
    padding: 10,
    borderTopRightRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    borderBottomRightRadius: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    justifyContent: 'center',
  },
  icon: {},
})
