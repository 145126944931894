import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Deposit, StockPosition } from '../../apis/apiTypes'
import DepositCard from './DepositCard'
import StockPositionCard from './StockPositionCard'

export interface PositionCardProps {
  deposit: Deposit | undefined
  stockPosition: StockPosition | undefined
  style?: StyleProp<ViewStyle>
  cardStyle?: StyleProp<ViewStyle>
  onPress?: false | (() => void)
  onDelete?: false | (() => void)
  onPin?: false | (() => void)
  quantity?: string
  isMarked?: boolean
  isPinned?: boolean
  liteMode?: boolean
}

export default function PositionCard(props: PositionCardProps) {
  if (props.stockPosition) {
    return (
      <StockPositionCard
        stockPosition={props.stockPosition}
        deposit={props.deposit}
        onDelete={props.onDelete}
        onPress={props.onPress}
        style={props.style}
        quantity={props.quantity}
        isMarked={props.isMarked}
        onPin={props.onPin}
        isPinned={props.isPinned}
        liteMode={props.liteMode}
      />
    )
  }
  if (props.deposit) {
    return (
      <DepositCard
        deposit={props.deposit}
        onDelete={props.onDelete}
        onPress={props.onPress}
        style={props.style}
        quantity={props.quantity}
        isMarked={props.isMarked}
        liteMode={props.liteMode}
      />
    )
  }
  return <></>
}
