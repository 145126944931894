import React, { useEffect, useState } from 'react'
import { FlatList, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../constants/Constants'
import IMRefreshControl from './IMRefreshControl'
import NoEntriesTag from './NoEntriesTag'
import SearchBox from './SearchBox'

export interface ItemSelectionListProps<T> {
  items?: T[]
  loadItemsAsync?: () => Promise<T[]>
  modifyLoadedItems?: (items: T[]) => T[]
  onSelected: (item: T) => void
  itemRenderer: (item: T, index: number) => JSX.Element
  style?: StyleProp<ViewStyle>
  searchBarFilter?: (item: T, filterString: string) => boolean
  loading?: boolean
}

export default function ItemSelectionList<T>(props: ItemSelectionListProps<T>) {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<T[]>([])
  const [filter, setFilter] = useState<string>('')

  const useItems = props.items ?? items

  useEffect(load, [])

  function selectItem(item: T) {
    props.onSelected(item)
  }

  function load() {
    if (!props.loadItemsAsync) return
    let aborted = false
    setLoading(true)
    props
      .loadItemsAsync()
      .then(loadedItems => {
        if (loadedItems && !aborted) {
          setItems(props.modifyLoadedItems ? props.modifyLoadedItems(loadedItems) : loadedItems)
        }
      })
      .catch(() => {})
      .finally(() => {
        if (!aborted) setLoading(false)
      })
    return () => {
      aborted = true
    }
  }

  function RenderItem(item: T, index: number) {
    if (props.itemRenderer) return props.itemRenderer(item, index)
    return <></>
  }

  function filterItems(itemsToFilter: T[]) {
    if (!props.searchBarFilter) return itemsToFilter
    return itemsToFilter.filter(item => props.searchBarFilter && props.searchBarFilter(item, filter))
  }

  return (
    <View style={[styles.main, props.style]}>
      {props.searchBarFilter && (
        <SearchBox
          style={{
            marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
            marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          }}
          value={filter}
          onChangeText={setFilter}
          searchOnChange
          searchOnChangeDelay={1000}
        />
      )}
      {useItems && (
        <FlatList
          data={filterItems(useItems)}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={styles.cards}
              onPress={() => {
                selectItem(item)
              }}>
              {RenderItem(item, index)}
            </TouchableOpacity>
          )}
          refreshing={loading}
          refreshControl={props.loadItemsAsync ? <IMRefreshControl refreshing={loading || !!props.loading} onRefresh={load} /> : undefined}
          ListFooterComponent={useItems.length === 0 && !loading ? <NoEntriesTag /> : undefined}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  main: { flex: 1 },
  cards: {},
})
