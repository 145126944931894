import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Deposit } from '../../apis/apiTypes'
import { ProductionPartCollection } from '../../classes/ProductionData'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { Colors } from '../../constants/Colors'
import { CONSTANTS, MWS_COLORS } from '../../constants/Constants'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import { ArticleAmountSelectionResult } from '../../views/Article/ArticleAmountSelectorV2'
import ArticleCard from '../Article/ArticleCard'

export default function ProductionPartCard(props: {
  part: ProductionPartCollection | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  showQuantities?: boolean
  isDefectiveMaterialProduction?: boolean
  selectedQuantities?: ArticleAmountSelectionResult[]
  showCompletionIndicator?: boolean
  requiredQuantity?: number
  deposit?: Deposit
}) {
  const { part, showQuantities, selectedQuantities, deposit, ...otherProps } = props
  const useSelectedQuantities = selectedQuantities ?? part?.selectedQuantities
  const roundingPrecision = part?.part?.decimalplaces ?? CONSTANTS.NUMBER_PRECISION
  const requiredQuantity =
    (props.requiredQuantity ? props.requiredQuantity * (part?.part.quantityFactor ?? 1) : undefined) ?? part?.part?.quantity ?? 0

  const quantityDone = Utils.roundToPrecision(
    Utils.sum(useSelectedQuantities, item => item.quantity),
    roundingPrecision
  )

  let quantityText = ''
  if (!props.isDefectiveMaterialProduction) quantityText = `${quantityDone}/${Utils.roundToPrecision(requiredQuantity, roundingPrecision)}`
  else if (quantityDone > 0) quantityText = `${quantityDone}`

  function getBackgroundColor() {
    if (props.isDefectiveMaterialProduction) {
      if (quantityDone > 0) return MWS_COLORS.PARTIALLY_OK
      return MWS_COLORS.NOT_STARTED
    }
    if (part && quantityDone >= requiredQuantity) return MWS_COLORS.OK
    if (quantityDone > 0) return MWS_COLORS.PARTIALLY_OK
    return MWS_COLORS.NOT_STARTED
  }

  const stockPosition = part?.part.stockposition ?? part?.part.stockpositionAvailable
  const quantityDepositAvailable = part?.part.quantityDepositAvailable

  if (!part) return <></>
  return (
    <ArticleCard
      style={{ backgroundColor: getBackgroundColor() }}
      article={part.article.info}
      quantityText={showQuantities ? quantityText : undefined}
      unit={part.article.getUsedUnit()}
      maxLinesDescription={2}
      navigateToArticleInfoOnPress
      textColor={Colors.light.text}
      {...otherProps}
      subComponent={
        <View style={{ flexDirection: 'row' }}>
          {(!!stockPosition || !!quantityDepositAvailable) && (
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1 }}>
                {!!stockPosition && (
                  <IMTextWithIcon
                    icon="shelf"
                    text={`${stockPositionUtils.getTitle(stockPosition)}`}
                    color={Colors.light.text}
                    iconColor={Colors.light.text}
                  />
                )}
              </View>
              {!!quantityDepositAvailable && (
                <IMTextWithIcon
                  icon="warehouse-full"
                  text={`${(deposit?.code ?? '').trim()} (${props.part?.article.getQuantity(quantityDepositAvailable) ?? ''})`}
                  color={Colors.light.text}
                  iconColor={Colors.light.text}
                />
              )}
            </View>
          )}
        </View>
      }
    />
  )
}
