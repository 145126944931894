import React from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Deposit, StockPosition } from '../../apis/apiTypes'
import { IMIconButton } from '../../components/IMIconButton'
import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { Colors } from '../../constants/Colors'
import { IMLayout } from '../../constants/Styles'
import stockPositionUtils from '../../utils/stockPositionUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function StockPositionCard(props: {
  stockPosition: StockPosition | undefined
  deposit?: Deposit
  style?: StyleProp<ViewStyle>
  onPress?: false | (() => void)
  onDelete?: false | (() => void)
  onPin?: false | (() => void)
  quantity?: string
  quantity2?: string
  addInfo?: string
  isMarked?: boolean | IMIconName
  isPinned?: boolean
  liteMode?: boolean
}) {
  const stockPosition = props.stockPosition
  if (props.deposit && stockPosition) stockPosition.deposit = props.deposit

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={'shelf'}
      isHiddenIcon={props.liteMode}
      cardStyle={props.style}
      style={[IMLayout.flex.row]}
      isMarked={props.isMarked}>
      <View style={IMLayout.flex.f1}>
        {!props.liteMode && (
          <IMText numberOfLines={1} primary style={IMLayout.flex.row}>
            {stockPositionUtils.getTitle(stockPosition)}
          </IMText>
        )}
        {!!props.liteMode && (
          <IMTextWithIcon icon="shelf" numberOfLines={1} primary style={IMLayout.flex.row} text={stockPositionUtils.getTitle(stockPosition)} />
        )}

        <IMText numberOfLines={1} secondary style={IMLayout.flex.row}>
          {stockPosition?.deposit?.description?.trim()}
        </IMText>
        {props.addInfo && (
          <IMText numberOfLines={1} secondary style={IMLayout.flex.row}>
            {props.addInfo}
          </IMText>
        )}
      </View>
      <View style={IMLayout.center}>
        {props.quantity && <IMText style={styles.text}>{props.quantity}</IMText>}
        {props.quantity2 && <IMText style={styles.text}>{props.quantity2}</IMText>}
      </View>
      {!!props.onPin && (
        <View style={IMLayout.center}>
          <IMIconButton icon="map-pin" iconSize={22} iconColor={props.isPinned ? Colors.info : undefined} onPress={props.onPin} />
        </View>
      )}
    </MWS_BaseCard>
  )
}

const styles = StyleSheet.create({
  text: {
    alignSelf: 'flex-end',
  },
})
