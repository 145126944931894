import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, StyleProp, View, ViewStyle } from 'react-native'

import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import { ProductionPartCollection } from '../../classes/ProductionData'
import Title from '../../components/MWS/Title'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { ProductionHandler } from '../../hooks/specific/useProductionHandler'
import { articleUtils } from '../../utils/articleUtils'
import { ArticleAmountSelectionResult } from '../Article/ArticleAmountSelectorV2'

export default function ProductionPartSelectedSerialListView(props: {
  part: ProductionPartCollection | undefined
  handler: ProductionHandler
  style?: StyleProp<ViewStyle>
}) {
  const { i18n } = useLanguage()

  const showTitle = !!props.part?.article.isSerialNumberActive && props.part.selectedQuantities.length > 0

  function handleDelete(deleteItem: ArticleAmountSelectionResult) {
    if (!props.part || !deleteItem.serialNumber) return
    props.handler.removeSerial(props.part.part, deleteItem.serialNumber)
  }

  return (
    <View style={[{ flex: 1, marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }, props.style]}>
      {showTitle && <Title>{i18n.t('SelectedSerialNumbers')}</Title>}
      <FlatList
        data={props.handler.selectedPart?.selectedQuantities}
        renderItem={({ item }) => {
          if (!item.serialNumber) return <></>
          return (
            <SerialnumberCard
              serialnumber={item.serialNumber}
              quantity={articleUtils.formatQuantity(item.quantity, item.unit)}
              onDelete={() => handleDelete(item)}
              stockPosition={item.stockPosition}
            />
          )
        }}
      />
    </View>
  )
}
