import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDepositData, Deposit, StockPosition, StockPositionAvailability, StockTemplate } from '../../apis/apiTypes'
import PositionCard from '../../cards/StockPosition/PositionCard'
import { Article } from '../../classes/Article'
import IMLoadingIndicator from '../../components/IMLoadingIndicator'
import Title from '../../components/MWS/Title'
import { useTransfer } from '../../contexts/TransferContext'
import { articleUtils } from '../../utils/articleUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import transferUtils from '../../utils/transferUtils'
import { Utils } from '../../utils/Utils'

export default function SuggestedPositionSelectionView(props: {
  active: boolean
  show: boolean
  article: Article | undefined
  onSelected: (deposit?: Deposit, stockPosition?: StockPosition) => void
  sourceDeposit?: Deposit
  sourceStockPosition?: StockPosition
  stockTemplate?: StockTemplate
}) {
  const { savedDeposit: savedDepositSource, savedDepositTarget } = useTransfer()
  const { i18n } = useLanguage()

  const [loading, setLoading] = useState(false)
  const [suggestedPositions, setSuggestedPositions] = useState<StockPositionAvailability[]>([])
  const [availablePositions, setAvailablePositions] = useState<StockPositionAvailability[]>([])
  const filterByDeposit =
    transferUtils.getDepositTarget(props.stockTemplate, savedDepositTarget) ??
    transferUtils.getDeposit(props.stockTemplate, savedDepositSource) ??
    props.sourceDeposit

  useEffect(() => load(), [props.active])

  function load() {
    if (!props.active || !props.article) return
    setLoading(true)
    api
      .getArticleDepositData({ articleId: props.article.info.id, depositId: filterByDeposit?.id })
      .then(result => {
        if (!result || result.length < 1) {
          setLoading(false)
          handleResult([]).catch(console.error)
          return
        }
        result = result.filter(filterSuggestions)
        if (result.length === 1) {
          props.onSelected(result[0].deposit, result[0].stockposition)
        }
        handleResult(result).catch(console.error)
      })
      .catch(() => setLoading(false))
  }

  function filterSuggestions(item: ArticleDepositData) {
    return (
      !transferUtils.getDepositTarget(props.stockTemplate, savedDepositTarget) ||
      !props.stockTemplate?.stockpositionTarget ||
      props.stockTemplate?.stockpositionTarget.id === item.stockposition?.id
    )
  }

  async function handleResult(depositData: ArticleDepositData[]) {
    try {
      const depositQuantities = articleUtils.convertDepositToStockPositionQuantity(
        (
          await api.getArticleAvailability({
            articleId: props.article?.info?.id,
            depositId: filterByDeposit?.id,
          })
        ).depositquantities ?? []
      )
      iterateData(depositData, depositQuantities)
    } catch (ex) {
      console.error(ex)
      return
    } finally {
      setLoading(false)
    }
  }

  function iterateData(depositData: ArticleDepositData[], stockAvailability: StockPositionAvailability[]) {
    const suggestions: StockPositionAvailability[] = []
    depositData.forEach(data => {
      const foundPosition = stockAvailability.find(item =>
        stockPositionUtils.comparePosition(item.deposit, data.deposit, item.stockposition, data.stockposition)
      )
      if (foundPosition) {
        suggestions.push(
          stockPositionUtils.createStockPositionAvailability(
            props.article?.info,
            foundPosition.deposit,
            foundPosition.stockposition,
            foundPosition.quantity
          )
        )
        return
      }
      suggestions.push(stockPositionUtils.createStockPositionAvailability(props.article?.info, data.deposit, data.stockposition, undefined))
    })

    const availability = Utils.keepUniques(
      stockAvailability.filter(
        item =>
          !suggestions.find(sugItem =>
            stockPositionUtils.comparePosition(sugItem.deposit, item.deposit, sugItem.stockposition, item.stockposition)
          ) &&
          !stockPositionUtils.comparePosition(item.deposit, props.sourceDeposit, item.stockposition, props.sourceStockPosition) &&
          item.quantity &&
          item.quantity > 0
      ) ?? [],

      stockPositionUtils.getPositionIdentifier
    )
    suggestions.sort(stockPositionUtils.sortAvailability)
    setSuggestedPositions(suggestions)
    setAvailablePositions(availability)
  }

  if (!props.active || !props.show) return <></>
  if (loading) return <IMLoadingIndicator isVisible={true} />
  if (!suggestedPositions?.length && !availablePositions?.length) return <></>
  return (
    <View>
      <Title>{i18n.t('SuggestedPositions')}</Title>
      {[...suggestedPositions, ...availablePositions].map((item, index) => (
        <PositionCard
          key={`SuggestedPositionElement${index}`}
          deposit={item.deposit}
          stockPosition={item.stockposition}
          isMarked={!!suggestedPositions.includes(item)}
          quantity={item.quantity ? props.article?.getQuantityWithUnitText(item.quantity, true) : undefined}
          onPress={() => props.onSelected(item.deposit, item.stockposition)}
        />
      ))}
    </View>
  )
}
