import { IM, IMLayout, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useCallback, useMemo } from 'react'
import { FlatList, Pressable } from 'react-native'

import { WarehouseFilterConfigRequest } from '../apis/apiRequestTypes'
import { PackingList, PackingListAdditionalField, PackingListAdditionalFieldType } from '../apis/apiTypes'
import PackingListCard from '../cards/Packinglist/PackingListCard'
import ElusiveButton from '../components/ElusiveButton'
import FullScreenModal from '../components/FullScreenModal'
import ListSpacer from '../components/MWS/ListSpacer'
import NoEntriesTag from '../components/NoEntriesTag'
import LoadingIndicator from '../components/old/LoadingIndicator'
import Separator from '../components/Separator'
import {
  PackingListFilterConfig,
  PackingListPreConsignmentFilterConfig,
  PackingListProductionFilterConfig,
  PackingListSubTypes,
} from '../constants/Filters'
import { FilterProvider } from '../contexts/FilterContext'
import useFilter from '../hooks/filter/useFilter'
import useFilterConfig, { UseFilterConfigProps } from '../hooks/filter/useFilterConfig'
import useItemSelector from '../hooks/useItemSelector'
import { ModalController } from '../hooks/useModalController'
import ActiveFilterView from '../views/filter/ActiveFilterView'
import FilterModal from './filter/FilterModal'

type PackingListFilterModalProps = {
  controller: ModalController<PackingList[]>
  onSelected: (selection: PackingList[]) => void
  multiSelection?: boolean
  lastUsed?: PackingList[]
  type: WarehouseFilterConfigRequest['type']
  selectButtonText?: string
}

const defaultConfig = {
  groupConfig: PackingListFilterConfig.group,
  orderConfig: PackingListFilterConfig.order,
  filterConfig: PackingListFilterConfig.filter,
  valueProvider,
} satisfies UseFilterConfigProps<PackingList, PackingListSubTypes>

const preConsignmentConfig = {
  groupConfig: PackingListPreConsignmentFilterConfig.group,
  orderConfig: PackingListPreConsignmentFilterConfig.order,
  filterConfig: PackingListPreConsignmentFilterConfig.filter,
  valueProvider,
} satisfies UseFilterConfigProps<PackingList, PackingListSubTypes>

const productionConfig = {
  groupConfig: PackingListProductionFilterConfig.group,
  orderConfig: PackingListProductionFilterConfig.order,
  filterConfig: PackingListProductionFilterConfig.filter,
  valueProvider,
} satisfies UseFilterConfigProps<PackingList, PackingListSubTypes>

function additionalFieldValueProvider(additionalField: PackingListAdditionalField | undefined) {
  if (!additionalField) return ''

  switch (additionalField.definitionFieldType) {
    case PackingListAdditionalFieldType.Boolean:
      return `${additionalField.definitionDescription}: ${additionalField.value.toString()}`
    case PackingListAdditionalFieldType.Text:
      return additionalField.value?.toString().substring(0, 20)
    default:
      return additionalField.value
  }
}

function valueProvider(id: string, value: PackingListSubTypes | PackingListSubTypes[]) {
  if (!value) return ''
  if (Array.isArray(value)) {
    return additionalFieldValueProvider((value as PackingListAdditionalField[]).find(v => v.definitionId === id)) ?? ''
  } else if (typeof value === 'object') {
    return (value as PackingListAdditionalField).definitionId === id && (additionalFieldValueProvider(value as PackingListAdditionalField) ?? '')
  }
  return value
}

export default function PackingListSelectionFilterModal(props: PackingListFilterModalProps) {
  const { i18n } = useLanguage()

  const config = useMemo(() => {
    switch (props.type) {
      case 'PreConsignment':
        return preConsignmentConfig
      case 'ProductionConsignment':
        return productionConfig
      default:
        return defaultConfig
    }
  }, [props.type])
  const { groupConfig, filterConfig, orderConfig, loadingConfig } = useFilterConfig(props.type, config)

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
      }}
      title={i18n.t('SelectPackingList')}>
      {loadingConfig && <LoadingIndicator isVisible />}

      {!loadingConfig && (
        <FilterProvider
          storageKeyUniqueId={`PackingListFilter-${props.type}`}
          filterConfig={filterConfig}
          groupConfig={groupConfig}
          orderConfig={orderConfig}>
          <PackingListSelectionFilterModalContent {...props} />
        </FilterProvider>
      )}
    </FullScreenModal>
  )
}

function PackingListSelectionFilterModalContent(props: PackingListFilterModalProps) {
  const itemSelector = useItemSelector<PackingList>([], (a, b) => a.id === b.id)
  const filterModal = useModalController()
  const { i18n } = useLanguage()

  const { filteredItems: packingLists, filterResult: packingListsToRender } = useFilter<PackingList>(props.controller.data)

  const allShownItemsSelected = useMemo(() => !packingLists.some(p => !itemSelector.includes(p)), [itemSelector, packingLists])

  const lastUsed = useMemo(
    () =>
      props.lastUsed?.reduce<PackingList[]>((result, lu) => {
        const foundPackingList = packingLists.find(item => item.id === lu.id)
        if (!foundPackingList || typeof foundPackingList !== 'object') return result
        result.push(foundPackingList)
        return result
      }, []),
    [packingLists, props.lastUsed]
  )

  function handlePackingListSelected(packingList: PackingList) {
    if (!props.multiSelection) {
      onDone([packingList])
      return
    }
    itemSelector.toggle(packingList)
  }

  function onDone(selectedItems: PackingList[]) {
    props.controller.close()
    props.onSelected(selectedItems)
  }

  const packingListRenderItem = useCallback(
    (item: PackingList | string, keyPrefix?: string) =>
      typeof item === 'string' ? (
        <IM.View key={`${keyPrefix ?? ''}#${item}`} spacing={['horizontal', 'top']} style={{ alignItems: 'center' }}>
          <IM.Text numberOfLines={1}>{item}</IM.Text>
        </IM.View>
      ) : (
        <PackingListCard
          key={`${keyPrefix ?? ''}#${item.id}`}
          packingList={item}
          showDate
          showDepositContra
          onPress={() => handlePackingListSelected(item)}
          isMarked={itemSelector.includes(item)}
        />
      ),
    [itemSelector]
  )

  return (
    <IM.View style={{ flex: 1 }}>
      <ActiveFilterView showFilter={() => filterModal.show()} />

      <FlatList
        data={packingListsToRender}
        keyExtractor={(item, index) => (typeof item === 'string' ? item : index.toString())}
        ListHeaderComponent={
          <>
            <ListSpacer />
            {!!lastUsed?.length && (
              <IM.View>
                {packingListRenderItem(i18n.t('LAST_USED'), 'lastUsed')}
                {lastUsed.map(item => packingListRenderItem(item, 'lastUsed'))}
                <Separator />
              </IM.View>
            )}
            {!!packingListsToRender?.length && (
              <IM.View spacing={['horizontal']} style={[IMLayout.flex.row, { justifyContent: 'space-between', alignItems: 'center' }]}>
                <IM.PressableIcon style={{ opacity: 0 }} disabled icon={['fal', 'check']} size={20} />
                <IM.Text>{i18n.t('PACKING_LISTS')}</IM.Text>
                <Pressable
                  hitSlop={{ top: 20, left: 30, bottom: 5, right: 20 }}
                  onPress={() => {
                    if (allShownItemsSelected) {
                      itemSelector.clear()
                    } else {
                      itemSelector.add(...packingLists)
                    }
                  }}>
                  <IM.Icon icon={allShownItemsSelected ? ['fal', 'times'] : ['fal', 'check']} size={18} />
                </Pressable>
              </IM.View>
            )}
          </>
        }
        renderItem={({ item }) => packingListRenderItem(item)}
        ListFooterComponent={
          <>
            <NoEntriesTag hide={!!packingListsToRender?.length || !!lastUsed?.length} />
            <ListSpacer />
          </>
        }
      />
      <ElusiveButton disabled={!itemSelector.any} title={props.selectButtonText ?? i18n.t('SELECT')} onPress={() => onDone(itemSelector.items)} />
      <FilterModal controller={filterModal} />
    </IM.View>
  )
}
