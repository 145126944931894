import React from 'react'

import { MenuCollection } from '../../types'
import menuUtils from '../../utils/MenuUtils'
import NavigationCard from './NavigationCard'

export default function MenuItemCard(props: { menu: MenuCollection }) {
  const { menu, subMenus } = props.menu
  const hasSubMenus = !!subMenus && subMenus.length > 1
  const navData = hasSubMenus ? { menu: props.menu } : { menuCode: menu.parameterCode, actionType: menu.actionType }

  return (
    <NavigationCard
      title={(hasSubMenus ? menu.group : menu.title) ?? ''}
      navigationTarget={menuUtils.getNavigationTarget(props.menu)}
      data={{ ...navData, ...menuUtils.getPresetData(menu.actionType) }}
      icon={menuUtils.getIcon(menu.actionType)}
    />
  )
}
