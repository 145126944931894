import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import ArticleCard from '../../cards/Article/ArticleCard'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import ListSpacer from '../../components/MWS/ListSpacer'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import NoEntriesTag from '../../components/NoEntriesTag'
import { IMLayout } from '../../constants/Styles'
import useArticle from '../../hooks/useArticle'
import useRouteParam from '../../hooks/useRouteParam'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import { StockPositionInfoCollection } from './StockPositionInfoScreen'

export default function StockPositionSerialScreen() {
  const { i18n } = useLanguage()

  const items = useRouteParam<StockPositionInfoCollection>('collection')
  const article = useArticle(items?.collection[0].article, { useSalesUnit: true })

  const itemsWithSerial = useMemo(() => items?.collection.filter(c => !!c.serialnumber).sort(stockPositionUtils.sortAvailabilityBySerial), [items])

  return (
    <MWS_Screen>
      {items && article && (
        <>
          <ArticleCard article={article.info} quantityText={article.getQuantityWithUnitText(Utils.sum(items.collection, c => c.quantity ?? 0))} />
          <Title>{i18n.t('SerialNumbers')}</Title>
          <View style={styles.content}>
            {items.collection.length > 0 ? (
              <FlatList
                style={IMLayout.flex.f1}
                data={itemsWithSerial}
                renderItem={({ item }) => (
                  <SerialnumberCard serialnumber={item.serialnumber} quantity={article.getQuantityWithUnitText(item.quantity)} />
                )}
                ListFooterComponent={<ListSpacer />}
              />
            ) : (
              <NoEntriesTag />
            )}
          </View>
        </>
      )}
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  content: { flex: 1 },
})
