import { useLanguage } from '@infominds/react-native-components'
import lodash from 'lodash'
import { useContext, useEffect, useMemo } from 'react'

import FilterContext, { FilterContextProps } from '../../contexts/FilterContext'
import { SearchKey } from '../../types'
import { filterUtils } from '../../utils/FilterUtils'

export type FilterOptions<T> = {
  search?: string
  searchConfig?: SearchKey<T>[]
}

export default function useFilter<T extends object>(items?: T[], options?: FilterOptions<T>) {
  const context = useContext(FilterContext) as FilterContextProps<T> | undefined
  const { language, i18n } = useLanguage()

  useEffect(() => {
    if (!items || !context?.initOk) return
    context.initFilters(items ?? [])
  }, [items, context?.initOk])

  const filterResult = useMemo(() => {
    if (!items || !context || !context.filtersInitialized) return undefined
    let filtered = filterUtils.applyFilter(lodash.cloneDeep(items ?? []), context.filters)
    if (options?.searchConfig) {
      filtered = filterUtils.filterItemsBySearch(filtered, options.search ?? '', options.searchConfig)
    }
    const sortedResult = filterUtils.applySort(filtered, context.orders)
    const activeGroup = context.groups.find(g => !!g.active)
   
    const inverseGroupSorting =
      !!activeGroup && context.orders.some(o => o.active && o.fieldId === activeGroup?.fieldId && o.options?.modifier === 'inverse')
    const groupedResult = filterUtils.groupBy(sortedResult, activeGroup, context.filters, language, i18n, inverseGroupSorting)

    return filterUtils.groupedDataToSectionList(groupedResult, i18n.t(activeGroup?.options?.noGroupTextKey ?? 'WITHOUT_GROUP'), !!activeGroup)
  }, [items, context?.filters, context?.orders, context?.groups, options?.search, options?.searchConfig])

  const filteredItems = useMemo(() => (filterResult?.filter(q => !!q && typeof q !== 'string') ?? []) as T[], [filterResult])

  if (context === undefined) throw new Error('useFilter() must be called inside FilterProvider')
  return { ...context, filterResult, filteredItems }
}
