import { useApi } from '@infominds/react-api'
import { useMemo, useState } from 'react'

import { PackingList } from '../../apis/apiTypes'
import { PackingListArticle } from '../../classes/PackingListCollection'
import { ActiveColloNumbers } from '../../types/packingListTypes'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'

export default function useColloSelector(isPreConsignment: boolean, colloMode: boolean) {
  const [availableCollos, loadAvailableCollos, _loadingAvailableCollos, setAvailableCollos] = useApi(colloUtils.getOpenCollosForPackingLists, [])

  const [activeCollos, setActiveCollos] = useState<ActiveColloNumbers>({})
  const anyActiveCollos = useMemo(() => {
    if (!activeCollos) return false
    return !!Object.entries(activeCollos).filter(Boolean).length
  }, [activeCollos])

  async function updateAvailableCollos(updatedPackingLists: PackingList[], updatedMovements: PackingListArticle[]) {
    if (!colloMode) return
    // load available open collos from packingList and associated packingLists (same customer & deliveryAddress)
    const defaultCollos = packingListUtils.convertCollosToPackingListCollos(
      packingListUtils.getCollosFromPackingListArticles(updatedMovements, true, true).sort(colloUtils.sort),
      updatedPackingLists ?? []
    )
    if (isPreConsignment) {
      setAvailableCollos(defaultCollos)
      return defaultCollos
    }

    try {
      const result = await loadAvailableCollos(updatedPackingLists)
      if (!result.length) setAvailableCollos(defaultCollos)
      return result
    } catch (error) {
      console.error(error)
      setAvailableCollos(defaultCollos)
      return defaultCollos
    }
  }

  async function updateCollosFromMovements(updatedPackingLists: PackingList[], updatedMovements: PackingListArticle[]) {
    const newAvailableCollos = await updateAvailableCollos(updatedPackingLists, updatedMovements)
    const updatedActiveCollos = { ...activeCollos }
    // if pre-consignment, assign any open collo to all packingLists
    if (isPreConsignment) {
      const foundOpenCollo = newAvailableCollos
        ?.find(avc => avc.collos.some(c => c.number))
        ?.collos.filter(c => c.number)
        ?.at(-1)

      for (const packingList of updatedPackingLists) {
        if (foundOpenCollo?.number) {
          updatedActiveCollos[packingList.id] = foundOpenCollo?.number
        } else {
          delete updatedActiveCollos[packingList.id]
        }
      }

      setActiveCollos(updatedActiveCollos)
      return
    }

    for (const packingList of updatedPackingLists) {
      const foundOpenCollo = newAvailableCollos?.find(avc => avc.packingList.id === packingList.id)?.collos.at(-1)

      const activeColloNumber = updatedActiveCollos[packingList.id]
      if (foundOpenCollo?.number && !activeColloNumber) {
        updatedActiveCollos[packingList.id] = foundOpenCollo?.number
      }
      //if stored colloNumber is not in movementList delete it
      else if (
        activeColloNumber &&
        (!packingListUtils.packingListMovementsContainColloNumber(updatedMovements, activeColloNumber) ||
          !packingListUtils.isColloNrOpen(activeColloNumber, updatedMovements))
      ) {
        delete updatedActiveCollos[packingList.id]
      }
    }
    setActiveCollos(updatedActiveCollos)
  }

  function setActiveColloNumber(number: string | undefined, packingListId: string) {
    setActiveCollos(prev => {
      if (!number) {
        delete prev[packingListId]
        return { ...prev }
      }
      prev[packingListId] = number
      return { ...prev }
    })
  }

  function getActiveColloNumber(packingListId: string): string | undefined {
    let value
    // little workaround to make sure value is always up to date even before re-render is triggered
    setActiveCollos(prev => {
      value = activeCollos[packingListId]
      return prev
    })
    return value
  }

  return {
    availableCollos,
    setActiveColloNumber,
    getActiveColloNumber,
    activeCollos,
    anyActiveCollos,
    updateCollosFromMovements,
    updateAvailableCollos,
    setActiveCollos,
  }
}

export type ColloSelector = ReturnType<typeof useColloSelector>
