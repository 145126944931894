import { useLanguage, Utils } from '@infominds/react-native-components'
import { useMemo } from 'react'

import { Collo, Deposit, PackingList, PackingListMovement, StockPosition } from '../../apis/apiTypes'
import { PackingListArticleMovement } from '../../classes/PackingListCollection'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { articleUtils } from '../../utils/articleUtils'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'

export type PackingListMovementCollo = {
  packingList: PackingList | undefined
  movement: PackingListMovement | undefined
  collo: Collo
}

export type PackingListColloGroup = {
  stockPosition: StockPosition | undefined
  deposit: Deposit | undefined
  items: PackingListMovementCollo[]
  completed: boolean
}

export default function useGroupedCollos(collos: Collo[] | undefined, movements: PackingListArticleMovement[] | undefined) {
  const { i18n } = useLanguage()
  const userSettings = useUserSettings()

  const items = useMemo(() => {
    const anyMovement = movements?.at(0)?.movement
    const isSerialNumberArticle = articleUtils.isSerialNumberActive(anyMovement?.article, userSettings?.isHistoricalSerialnumberActive)
    const quantityToCommission = Utils.sum(movements, m => m.movement.quantity)
    const sortedCollos = (collos ?? []).sort(colloUtils.sortForCommissioning)
    const groupedCollos: PackingListColloGroup[] = []

    for (const collo of sortedCollos) {
      const foundGroup =
        !isSerialNumberArticle &&
        groupedCollos.find(
          g => g.completed === !!collo.id && stockPositionUtils.comparePosition(collo.deposit, g.deposit, collo.stockposition, g.stockPosition)
        )
      const movement = movements?.find(pl => pl.movement.id === collo.packinglistmovementId)
      const itemToAdd: PackingListMovementCollo = { collo: { ...collo }, movement: movement?.movement, packingList: movement?.packingList }

      if (foundGroup) {
        const foundMovement = foundGroup.items.find(
          c => colloUtils.getIdentificationHash(c.collo, true, true, true) === colloUtils.getIdentificationHash(itemToAdd.collo, true, true, true)
        )
        if (foundMovement) {
          foundMovement.collo.quantity += itemToAdd.collo.quantity
        } else {
          foundGroup.items.push(itemToAdd)
        }
      } else {
        groupedCollos.push({ items: [itemToAdd], deposit: collo.deposit, stockPosition: collo.stockposition, completed: !!collo.id })
      }
    }

    groupedCollos.forEach(g => {
      g.items.sort((a, b) => packingListUtils.sortPackingListsById(a.packingList, b.packingList))
    })

    const consignedGroups = groupedCollos.filter(g => g.completed)
    const openGroups = groupedCollos.filter(g => !g.completed)

    const result: (PackingListColloGroup | string)[] = []

    if (consignedGroups.length) {
      result.push(
        `${i18n.t('Consigned')} (${articleUtils.formatPartQuantity(
          Utils.sum(
            sortedCollos.filter(q => !!q.id),
            q => q.quantity
          ),
          quantityToCommission,
          anyMovement?.unitCode
        )})`
      )
      result.push(...consignedGroups)
    }
    if (openGroups.length) {
      result.push(
        `${i18n.t('ToBeConfirmed')} (${articleUtils.formatQuantity(
          Utils.sum(
            sortedCollos.filter(q => !q.id),
            q => q.quantity
          ),
          anyMovement?.unitCode
        )})`
      )
      result.push(...openGroups)
    }

    return result
  }, [collos, movements])

  return items
}
