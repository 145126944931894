/* eslint-disable @typescript-eslint/no-unsafe-call */

import React from 'react'

import { Customer } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import CustomerSelectionView from '../../views/InputViews/CustomerSelectionView'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ShoppingCartCustomerSelectionScreen(props: { navigation: any }) {
  function onCustomerSelected(customer: Customer) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    props.navigation.navigate(ScreenType.ShoppingCart, { customer })
  }

  return (
    <MWS_Screen>
      <CustomerSelectionView onSelected={onCustomerSelected} />
    </MWS_Screen>
  )
}
