import api from '../apis/apiCalls'
import { ColloPostRequest, ColloSerialNumberPost } from '../apis/apiRequestTypes'
import {
  ArticleDTO,
  Collo,
  Deposit,
  PackingList,
  PackingListMovement,
  PackingListOrder,
  SerialNumber,
  StockPosition,
  UserSettings,
} from '../apis/apiTypes'
import { PackingListArticle, PackingListArticleMovement } from '../classes/PackingListCollection'
import { PackingListMovementColloPostResult } from '../types'
import { ActiveColloNumbers, PackingListCollos } from '../types/packingListTypes'
import colloUtils from './colloUtils'
import { serialnumberUtils } from './serialnumberUtils'
import stockPositionUtils from './stockPositionUtils'
import { Utils } from './Utils'

const packingListUtils = {
  getPackingListsTitle(lists: PackingList[]) {
    if (!lists) return ''
    if (lists.length === 1) return lists[0].number + ' - ' + lists[0].customer
    return lists.map(item => item.number).join(', ')
  },
  getMovementsFromPackingListArticles(articleMovements: PackingListArticle[]) {
    if (!articleMovements) return []
    return articleMovements
      .map(m => m.movements)
      .flat()
      .map(m => m.movement)
  },
  getMovementProgress(movement: PackingListArticle): 0 | 1 | 2 {
    if (!movement) return 0
    const quantityCommissioned = Utils.sum(movement.movements, m => m.movement?.quantityPicked ?? 0)
    if (quantityCommissioned <= 0) return 0
    const quantityTotal = Utils.sum(movement.movements, m => m.movement?.quantity ?? 0)
    return quantityCommissioned < quantityTotal ? 1 : 2
  },
  sortPackingLists(a: PackingList | undefined, b: PackingList | undefined) {
    if (!a || !b) return 0
    if (!a.deliverydate) return 1
    if (!b.deliverydate) return -1
    return a.deliverydate > b.deliverydate ? 1 : -1
  },
  sortMovementBySortKey(a: PackingListArticle, b: PackingListArticle) {
    return getSortKey(a) > getSortKey(b) ? 1 : -1
  },
  sortPackingListOrders(a: PackingListOrder, b: PackingListOrder) {
    return a > b ? 1 : -1
  },
  sortPackingListsByFreighter(a: PackingList, b: PackingList) {
    return Utils.compareStringsForSort(a.freighter?.description, b.freighter?.description)
  },
  sortPackingListsByCostumer(a: PackingList, b: PackingList) {
    return Utils.compareStringsForSort(a.customer, b.customer)
  },
  sortPackingListsById(a: PackingList | undefined, b: PackingList | undefined) {
    return Utils.compareStringsForSort(a?.id, b?.id)
  },
  sortPackingListsByDocumentNumber(a: PackingList, b: PackingList) {
    return Utils.compareStringsForSort(a.number, b.number)
  },
  checkMovementOutOfStock(plArticle: PackingListArticle | undefined) {
    if (!plArticle || !plArticle.article.isStockmovement) return false
    const stockPosition = Utils.getValueIfAllAreEqual(plArticle.movements, m => m.movement.stockposition?.id)
    if (!stockPosition) return false
    const quantityTotal = Utils.sum(plArticle?.movements, item => item?.movement.quantity)
    return stockPosition.movement.quantityStoringposition <= quantityTotal
  },
  getCollosFromPackingListMovement(movement: PackingListMovement | undefined) {
    if (!movement || !movement.collonumbers) return []
    const conversion = this.getColloToUsedUnitConversionFactor(movement)
    return movement.collonumbers.map<Collo>(collo => ({ ...collo, quantity: collo.quantity * conversion }))
  },
  getCollosFromPackingListMovements(movements: PackingListMovement[] | undefined, filterDuplicate?: boolean) {
    if (!movements) return []
    const collos = Utils.to1DList(movements, m => this.getCollosFromPackingListMovement(m))
    if (filterDuplicate) return Utils.keepUniques(collos, collo => collo.number)
    return collos
  },
  getCollosFromPackingListArticles(
    articleMovements: PackingListArticle[] | undefined,
    filterDuplicate?: boolean,
    onlyIncomplete?: boolean,
    filterByPackingListId?: string
  ) {
    if (!articleMovements) return []

    const movements = this.getMovementsFromPackingListArticles(articleMovements).filter(
      m => !filterByPackingListId || m.packinglistId === filterByPackingListId
    )

    const collos = this.getCollosFromPackingListMovements(movements).filter(c => !onlyIncomplete || colloUtils.isColloOpen(c))
    if (filterDuplicate) return Utils.keepUniques(collos, collo => collo.number)
    return collos
  },
  convertCollosToPackingListCollos(collos: Collo[], packingLists: PackingList[]) {
    const result: PackingListCollos[] = []
    for (const packingList of packingLists) {
      const foundCollos = collos.filter(c => c.packinglistId === packingList.id)
      if (!foundCollos.length) continue
      result.push({ packingList, collos: foundCollos })
    }
    return result
  },
  getMovementSerialnumber(packingListArticleMovements: PackingListArticleMovement[] | undefined) {
    if (!packingListArticleMovements) return ''
    let serialnumber = ''
    for (const packingListArticleMovement of packingListArticleMovements) {
      for (const orderSerialnumberquantity of packingListArticleMovement.movement.orderSerialnumberquantities) {
        if (serialnumber !== '') serialnumber = serialnumber + ';'
        if (orderSerialnumberquantity.quantity > 1) {
          serialnumber = `${serialnumber} ${orderSerialnumberquantity.serialnumber?.number?.trim()} (${orderSerialnumberquantity.quantity})`
        } else {
          serialnumber = `${serialnumber} ${orderSerialnumberquantity.serialnumber?.number?.trim()}`
        }
      }
    }
    return serialnumber
  },
  joinSameCollos(collos: Collo[] | undefined) {
    if (!collos) return []
    return Utils.keepUniques(
      collos,
      collo => colloUtils.getIdentificationHash(collo, true, true),
      collo => ({
        ...collo,
        quantity: Utils.sum(
          collos.filter(c => colloUtils.getIdentificationHash(c, true, true) === colloUtils.getIdentificationHash(collo, true, true)),
          c => c.quantity
        ),
      })
    )
  },
  async postPackingListMovements(
    packingListArticle: PackingListArticle | undefined,
    collosToPost: Collo[],
    isSerialNumberActive: boolean,
    userSettings: UserSettings | undefined,
    activeColloNumbersIn: ActiveColloNumbers,
    isPreConsignment?: boolean,
    isProductionConsignment?: boolean
  ): Promise<PackingListMovementColloPostResult[] | null> {
    if (!packingListArticle) return null
    const collos = [...collosToPost].filter(c => !c.id)
    const requests: ColloPostRequest[] = []

    for (const movement of packingListArticle.movements) {
      const movementCollos = collos.filter(c => c.packinglistmovementId === movement.movement.id)
      if (!movementCollos.length) continue
      requests.push(...createPostRequest(movement.movement, movementCollos, isSerialNumberActive, userSettings))
    }

    if (requests.length === 0) return null
    const results: PackingListMovementColloPostResult[] = []
    const activeColloNumbers = { ...activeColloNumbersIn }
    for (const request of requests) {
      const packingList = packingListArticle.movements.find(m => m.movement.id === request.packingListMovementId)?.packingList
      if (!packingList) continue
      let activeColloNumber = activeColloNumbers[packingList.id]

      // if no collo number is set, check if any packinglist are linked
      if (!activeColloNumber) {
        let linkedPackingList: string | undefined
        // if pre-consignment, any packingList is allowed
        if (isPreConsignment) {
          linkedPackingList = packingListArticle.movements.find(m => activeColloNumbers[m.packingList.id])?.packingList.id
        }
        // if not pre-consignment, check if any packinglist with the same customer and delivery address has a collo number exists
        else {
          linkedPackingList = packingListArticle.movements.find(
            m =>
              m.packingList.customerId === packingList.customerId &&
              m.packingList.deliveryaddressId === packingList.deliveryaddressId &&
              activeColloNumbers[m.packingList.id] &&
              !activeColloNumbersIn[m.packingList.id]
          )?.packingList.id
        }
        if (linkedPackingList) {
          activeColloNumber = activeColloNumbers[linkedPackingList]
        }
      }
      if (!!activeColloNumber && activeColloNumber !== '0') {
        request.number = activeColloNumber
      }
      if (!request.number && !!isPreConsignment) {
        request.generateNumber = true
      }

      if (isProductionConsignment) {
        request.generateNumber = false
      }
      const assignedColloNumber = await api.postCollo(request)
      activeColloNumbers[packingList.id] = assignedColloNumber
      results.push({ assignedColloNumber: assignedColloNumber, colloPostRequest: request, packingListId: packingList.id })
    }
    return results
  },
  isLinkedPackingList(main: PackingList, listToCheck: PackingList) {
    if (!main || !listToCheck || main.id === listToCheck.id || main.invoicetemplateId !== listToCheck.invoicetemplateId) return false
    for (const order of main.orders) {
      if (!!order?.id && listToCheck.orders.find(o => !!o?.id && o.id === order.id)) {
        return true
      }
    }
    return false
  },
  movementsAreJoinable(a: PackingListMovement, b: PackingListMovement) {
    if (!a || !b) return false
    if (a.article.id !== b.article.id || a.unitId !== b.unitId) return false
    return stockPositionUtils.comparePosition(a.deposit, b.deposit, a.stockposition, b.stockposition)
  },
  packingListMovementsContainColloNumber(movements: PackingListArticle[] | undefined, colloNumber: string | undefined) {
    if (!movements || !colloNumber) return false
    return !!this.getCollosFromPackingListArticles(movements, true).find(c => c.number === colloNumber)
  },
  getOpenCollos(movements: PackingListArticle[]) {
    return this.getCollosFromPackingListArticles(movements).filter(c => colloUtils.isColloOpen(c)) ?? []
  },
  getOpenColloNumber(collos: Collo[] | undefined) {
    if (!collos) return undefined
    return collos.find(c => colloUtils.isColloOpen(c))
  },
  isColloNrOpen(colloNr: string, movements: PackingListArticle[]) {
    const collos = this.getOpenCollos(movements)
    return !!collos.find(c => c.number === colloNr)
  },
  getIncompleteMovements(movements: PackingListArticle[] | undefined) {
    if (!movements) return []
    return movements.filter(
      plm => !!plm.movements.find(m => Utils.sum(this.getCollosFromPackingListMovement(m.movement), c => c.quantity) < m.movement.quantity)
    )
  },
  getColloToUsedUnitConversionFactor(packingListMovement: PackingListMovement | undefined) {
    return 1 / (packingListMovement?.conversionColloUnit || 1)
  },
  getUsedToColloUnitConversionFactor(packingListMovement: PackingListMovement | undefined) {
    return packingListMovement?.conversionColloUnit || 1
  },
  getMasterToUsedUnitConversionFactor(packingListMovement: PackingListMovement | undefined) {
    return 1 / (packingListMovement?.conversionMasterUnit || 1)
  },
  async getHistoricalSerialNumberAvailability(
    serialNumber: string | SerialNumber,
    article: ArticleDTO | undefined,
    deposit: Deposit | undefined,
    stockPosition: StockPosition | undefined
  ) {
    const articleAvailability = await api.getItemAvailability({
      articleId: article?.id,
      depositId: deposit?.id,
      stockPositionId: stockPosition?.id,
    })
    if (articleAvailability > 0) {
      return [serialnumberUtils.createAvailability(serialNumber, articleAvailability, 0, 0, article, deposit, stockPosition)]
    }
    const completeAvailability = await api.getArticleAvailability({ articleId: article?.id, depositId: deposit?.id, considerPickedQuantity: true })
    const depositQuantity = completeAvailability.depositquantities.find(q => q.deposit.id === deposit?.id)
    return (
      depositQuantity?.stockpositionquantities
        .filter(q => q.quantity && q.quantity > 0)
        .map(q => serialnumberUtils.createAvailability(serialNumber, q.quantity, 0, 0, article, deposit, stockPosition)) ?? []
    )
  },
  getOrderFromPackingListMovement(movement: PackingListMovement) {
    return { id: movement.orderId, number: movement.order } as PackingListOrder
  },
  getSortKey(packingListArticle: PackingListArticle) {
    return getSortKey(packingListArticle)
  },
}

export default packingListUtils

function createPostRequest(
  packingListMovement: PackingListMovement | undefined,
  collos: Collo[],
  isSerialNumberActive: boolean,
  userSettings: UserSettings | undefined
) {
  const uniqueCollos = Utils.keepUniques(
    collos.filter(collo => !collo?.id),
    collo => colloUtils.getIdentificationHash(collo, !isSerialNumberActive)
  )

  return uniqueCollos.map<ColloPostRequest>(collo => mapColloToPostRequest(packingListMovement, collo, collos, userSettings, isSerialNumberActive))
}

function mapColloToPostRequest(
  packingListMovement: PackingListMovement | undefined,
  collo: Collo,
  allCollos: Collo[],
  userSettings: UserSettings | undefined,
  isSerialNumberActive: boolean
): ColloPostRequest {
  const sameCollos = allCollos.filter(
    c => colloUtils.getIdentificationHash(c, !isSerialNumberActive) === colloUtils.getIdentificationHash(collo, !isSerialNumberActive)
  )
  const conversion = packingListUtils.getUsedToColloUnitConversionFactor(packingListMovement)
  const result: ColloPostRequest = {
    number: '',
    packingListMovementId: packingListMovement?.id ?? '',
    packingListMovementPartId: packingListMovement?.packinglistmovementpartId ?? '',
    quantity: Utils.sum(sameCollos, c => c.quantity) * conversion,
    articleId: collo.article?.id,
    depositId: collo.deposit?.id,
    storingPositionId: collo.stockposition?.id ?? '',
    collaboratorId: userSettings?.employeeId ?? '',
  }
  if (!collo.serialnumber) return result
  result.depositId = undefined
  result.storingPositionId = undefined
  result.serialNumbers = sameCollos.map<ColloSerialNumberPost>(c => ({
    quantity: c.quantity * conversion,
    serialNumberId: c.serialnumber?.id,
    serialNumber: !c.serialnumber?.id ? c.serialnumber?.number : undefined,
    depositId: c.deposit?.id,
    storingPositionId: c.stockposition?.id,
  }))
  return result
}

function getSortKey(packingListArticle: PackingListArticle) {
  return `${packingListUtils.getMovementProgress(packingListArticle)}#${
    packingListArticle.movements?.find(m => !!m.movement.sortkey)?.movement.sortkey ?? ''
  }#${packingListArticle.article.code?.trim()}`
}
