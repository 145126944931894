import React, { useEffect, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { ArticleMeasurementUnit, Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import { Article } from '../../classes/Article'
import TransferQuantitySelector from '../../components/MWS/Transfer/TransferQuantitySelector'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import MultiSerialSelectionModal from '../../modals/MultiSerialSelectionModal'
import { depositUtils } from '../../utils/depositUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import SerialnumberQuantitySelectionView from '../InputViews/SerialnumberQuantitySelectionView'

export interface ArticleAmountSelectionResult {
  quantity: number
  quantityOrdered?: number
  quantityCommissioned?: number
  unit: ArticleMeasurementUnit | undefined
  serialNumber: SerialNumber | undefined
  stockPosition?: StockPosition
  deposit?: Deposit
}

interface ArticleAmountSelectorProps {
  article: Article | undefined
  deposit?: Deposit
  stockPosition?: StockPosition
  onSelected: (result: ArticleAmountSelectionResult) => void
  proposedQuantity?: number
  style?: StyleProp<ViewStyle>
  title?: string
  noPreloadedData?: boolean
  allowUnitSelection?: boolean
  selectSerialsWithPosition?: boolean
  checkAvailability?: boolean
  selectedItemsToFilter?: ArticleAmountSelectionResult[]
  amountSelectionActive?: (value: boolean) => void
  preSelectedSerial?: SerialNumber
  allowNewSerialCreation?: boolean
  ignoreAvailability?: boolean
  hideAvailability?: boolean
  hidePositionFromSelectedSerial?: boolean
  ignoreSerialNumber?: boolean
  errorSerialNotAllowedText?: string
  allowedSerialNumbers?: SerialNumber[]
  availabilityCheckFailedWorkaroundMessage?: string
  availabilityTolerance?: number
  availability?: number
}

export default function ArticleAmountSelectorV2(props: ArticleAmountSelectorProps) {
  const article = props.article
  const alert = useAlert()
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<StockPositionAvailability | undefined>()
  const showAmountSelector = !article?.isSerialNumberActive || !!selectedSerialNumber || props.ignoreSerialNumber
  const preSerialSelectionModal = useModalController<StockPositionAvailability[]>()
  const selectorModal = useModalController<StockPositionAvailability[]>()

  useEffect(() => {
    if (props.amountSelectionActive) props.amountSelectionActive(!!showAmountSelector)
  }, [selectedSerialNumber, article])

  useEffect(() => {
    if (!article) return

    if (props.preSelectedSerial) {
      if (props.noPreloadedData) {
        handleSerialSelection(
          serialnumberUtils.createAvailability(props.preSelectedSerial, 1, 0, 0, props.article?.info, props.deposit, props.stockPosition)
        )
        return
      }
      const serials = article.serialNumberQuantities?.filter(q => q.serialnumber?.id === props.preSelectedSerial?.id)
      if (!serials?.length) {
        if (!props.ignoreAvailability) return
        handleSerialSelection(serialnumberUtils.createAvailability(props.preSelectedSerial, 1, 0, 0, props.article?.info))
        return
      }
      if (serials?.length === 1) {
        const serial = serials[0]
        if (!serial?.serialnumber || !serial.quantity || serial.quantity < 0) return
        handleSerialSelection(
          serialnumberUtils.createAvailability(
            serial.serialnumber,
            serial.quantity,
            serial.quantityOrdered,
            serial.quantityCommissioned,
            props.article?.info,
            serial.deposit,
            serial.stockposition
          )
        )
        return
      }
      preSerialSelectionModal.show(serials)
      return
    }
    if (props.allowedSerialNumbers?.length === 1) {
      handleSerialSelection(serialnumberUtils.createAvailability(props.allowedSerialNumbers[0]))
    }
  }, [article?.info?.id])

  function handleSerialSelection(stockPositionAvailability: StockPositionAvailability) {
    if (
      !!stockPositionAvailability.serialnumber &&
      !!props.allowedSerialNumbers?.length &&
      !serialnumberUtils.serialNumberInList(stockPositionAvailability.serialnumber, props.allowedSerialNumbers)
    ) {
      if (props.errorSerialNotAllowedText) alert.info(props.errorSerialNotAllowedText)

      return
    }
    if (article?.isLottoSerialNumber) setSelectedSerialNumber(stockPositionAvailability)
    else handleCompletion(1, stockPositionAvailability)
  }

  function handleMultiSerialSelection(stockPositionAvailability: StockPositionAvailability[]) {
    const firstArt = stockPositionAvailability?.length ? stockPositionAvailability[0] : undefined
    if (!firstArt) return
    if (article?.isLottoSerialNumber) setSelectedSerialNumber(firstArt)
    else handleCompletion(1, firstArt)
  }

  function handleCompletion(quantity: number, stockPositionAvailability: StockPositionAvailability | undefined) {
    setSelectedSerialNumber(undefined)
    props.onSelected({
      serialNumber: props.ignoreSerialNumber ? undefined : stockPositionAvailability?.serialnumber,
      quantity: quantity,
      quantityOrdered: stockPositionAvailability?.quantityOrdered,
      quantityCommissioned: stockPositionAvailability?.quantityCommissioned,
      unit: article?.getUsedUnit(),
      stockPosition: stockPositionAvailability?.stockposition,
      deposit: stockPositionAvailability?.deposit,
    })
  }

  return (
    <View style={props.style}>
      {selectedSerialNumber && (
        <SerialnumberCard
          serialnumber={selectedSerialNumber.serialnumber}
          quantity={props.checkAvailability && !props.hideAvailability ? article?.getQuantityWithUnitText(selectedSerialNumber.quantity) : undefined}
          onDelete={() => setSelectedSerialNumber(undefined)}
          infoText={
            selectedSerialNumber.deposit && !props.hidePositionFromSelectedSerial
              ? depositUtils.getTitleWithStockPosition(selectedSerialNumber.deposit, selectedSerialNumber.stockposition)
              : undefined
          }
          hideManufacturer
        />
      )}
      {showAmountSelector && (
        <TransferQuantitySelector
          article={article}
          onQuantitySelected={value => handleCompletion(value, selectedSerialNumber)}
          availability={props.checkAvailability ? props.availability ?? selectedSerialNumber?.quantity ?? props.article?.getQuantity() : undefined}
          proposedQuantity={props.proposedQuantity ?? selectedSerialNumber?.quantity}
          availabilityCheckFailedWorkaroundMessage={props.availabilityCheckFailedWorkaroundMessage}
          availabilityTolerance={props.availabilityTolerance}
          ignoreAvailability={props.ignoreAvailability}
        />
      )}
      <View>
        {article?.isSerialNumberActive && !selectedSerialNumber && !props.ignoreSerialNumber && (
          <SerialnumberQuantitySelectionView
            title={props.title}
            onSelected={handleSerialSelection}
            article={props.article?.info}
            handleShowListButtonPressed={props.noPreloadedData ? undefined : selectorModal.show}
            showResultInModal
            reduceToNumber={!props.selectSerialsWithPosition}
            allowNewSerialCreation={props.allowNewSerialCreation}
            ignoreAvailability={props.ignoreAvailability}
            stockPosition={props.stockPosition}
            deposit={props.deposit}
          />
        )}
        <MultiSerialSelectionModal
          show={preSerialSelectionModal.isShown}
          article={props.article}
          close={preSerialSelectionModal.close}
          onSelected={items => {
            preSerialSelectionModal.close()
            handleMultiSerialSelection(items)
          }}
        />
        <MultiSerialSelectionModal
          show={selectorModal.isShown}
          close={selectorModal.close}
          onSelected={items => {
            selectorModal.close()
            handleMultiSerialSelection(items)
          }}
          deposit={props.deposit}
          article={props.article}
          noDepositSelectionMode={!article?.isSerialNumberActive}
          selectSerialsWithoutPosition={!props.selectSerialsWithPosition}
          filterItem={item => !props.selectedItemsToFilter?.find(filterItem => serialnumberUtils.compareAny(filterItem, item))}
        />
      </View>
    </View>
  )
}
