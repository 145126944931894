import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { IMIconName } from '../../components/IMIconNames'
import IMText from '../../components/IMText'
import MWS_BaseCard from '../MWS_BaseCard'

export default function MacAddressCard(props: {
  macAddress: string | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: false | (() => void)
  selected?: boolean
  hideIcon?: boolean
  isMarked?: boolean | IMIconName
}) {
  const { i18n } = useLanguage()

  const macAddressString = useMemo(() => {
    return `${i18n.t('MacAddress')}: ${props.macAddress ?? ''}`
  }, [props.macAddress])

  return (
    <MWS_BaseCard
      onPress={props.onPress}
      onDelete={props.onDelete}
      icon={!props.hideIcon ? 'laptop-binary' : undefined}
      isSelected={props.selected}
      cardStyle={props.style}
      isMarked={props.isMarked}
      style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }}>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <IMText primary>{macAddressString}</IMText>
      </View>
    </MWS_BaseCard>
  )
}
