import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useRef } from 'react'

import api from '../../apis/apiCalls'
import { Parcel } from '../../apis/apiTypes'
import ParcelCard from '../../cards/ParcelCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { SelectionViewProps } from '../../types'

export default function ParcelSelectionView(props: SelectionViewProps<Parcel>) {
  const { i18n } = useLanguage()

  const { onSelected, ...otherProps } = props
  const info = useRef<string | undefined>()

  function load(inputResult: ScannerInputResult) {
    const input = inputResult.scannerInput ?? inputResult.textInput ?? ''
    return api.getParcel({ trackingnumber: input })
  }

  const RenderItem = useCallback((item: Parcel) => <ParcelCard handler={item} freighter={undefined} />, [])

  return (
    <MWS_Input
      type={'Parcel'}
      {...otherProps}
      onSelected={item => {
        onSelected(item, info.current)
        info.current = undefined
      }}
      //history={true}
      title={props.title ?? i18n.t('SelectParcel')}
      load={load}
      itemRenderer={RenderItem}
      failedToLoadText={i18n.t('NoParcelWithTrackingnumer')}
      modalTitle={i18n.t('SelectParcel')}
      historyNameExtractor={item => item.id}
      loadFromHistory={item => api.getParcel({ trackingnumber: item.trackingnumber })}
    />
  )
}
