import { useLanguage } from '@infominds/react-native-components'

import { SerialNumber } from '../apis/apiTypes'
import { serialnumberUtils } from '../utils/serialnumberUtils'
import useAlert from './useAlert'

export default function useRxMWSAlerts() {
  const alert = useAlert()
  const { i18n } = useLanguage()

  function serialNotAvailable(serialNumber: SerialNumber) {
    alert.error(i18n.t('SelectedSerialNotAvailable'), serialnumberUtils.getSerialNumberString(serialNumber, true))
  }

  function serialAlreadySelected() {
    alert.info(i18n.t('SerialnumberAlreadySelected'))
  }

  return {
    info: {
      // info alerts
      serialAlreadySelected,
    },
    error: {
      // error alerts
      serialNotAvailable,
    },
  }
}
