import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { InventoryEntry } from '../../apis/apiTypes'
import InventoryCard from '../../cards/Inventory/InventoryCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'
import Button from '../../components/old/Button'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useUserSettings } from '../../contexts/UserSettingsContext'

export default function InventorySelectionView(props: { onInventorySelected: (inventory: InventoryEntry) => void }) {
  const [items, loadItems, loading] = useApi(api.getInventoryCode, [])
  const { i18n } = useLanguage()

  const [showAll, setShowAll] = useState(false)
  const userSettings = useUserSettings()
  const filteredItems = useMemo(() => {
    if (showAll) return items
    const filteredByUserDepotItems = items?.filter(i => i.deposit.id === userSettings?.depositId) ?? []
    if (filteredByUserDepotItems.length) return filteredByUserDepotItems
    else return items
  }, [items, userSettings, showAll])

  useEffect(() => {
    load()
  }, [])

  function load() {
    loadItems().catch(console.error)
  }

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={filteredItems}
        renderItem={({ item }) => <InventoryCard inventory={item} onPress={() => props.onInventorySelected(item)} />}
        refreshControl={<IMRefreshControl refreshing={loading} onRefresh={load} />}
        ListFooterComponent={
          (filteredItems && filteredItems?.length > 0) || loading ? (
            <View>
              {!!filteredItems?.length && !!items?.length && items.length !== filteredItems.length && !showAll && (
                <Button
                  style={{
                    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
                  }}
                  title={i18n.t('SHOW_ALL')}
                  onPress={() => setShowAll(true)}
                />
              )}
              <ListSpacer />
            </View>
          ) : (
            <NoEntriesTag />
          )
        }
      />
    </View>
  )
}
