import { IM, IMLayout, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useCallback, useState } from 'react'
import { FlatList, StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import { WarehouseFilterConfigRequest } from '../apis/apiRequestTypes'
import { Production } from '../apis/apiTypes'
import ProductionCard from '../cards/Production/ProductionCard'
import BaseTextInput from '../components/BaseTextInput'
import ElusiveButton from '../components/ElusiveButton'
import FullScreenModal from '../components/FullScreenModal'
import ListSpacer from '../components/MWS/ListSpacer'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { ProductionFilterConfig } from '../constants/Filters'
import { FilterProvider } from '../contexts/FilterContext'
import useFilter from '../hooks/filter/useFilter'
import useItemSelector from '../hooks/useItemSelector'
import { ModalController } from '../hooks/useModalController'
import ActiveFilterView from '../views/filter/ActiveFilterView'
import FilterModal from './filter/FilterModal'

type ProductionListFilterModalProps = {
  controller: ModalController<Production[]>
  onSelected: (selection: Production[]) => void
  type: WarehouseFilterConfigRequest['type']
}

export default function ProductionListSelectionFilterModal(props: ProductionListFilterModalProps) {
  const { i18n } = useLanguage()

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
      }}
      title={i18n.t('SelectProduction')}>
      <FilterProvider storageKeyUniqueId={`ProductionListFilter-${props.type}`} orderConfig={ProductionFilterConfig.order}>
        <ProductionListSelectionFilterModalContent {...props} />
      </FilterProvider>
    </FullScreenModal>
  )
}

function ProductionListSelectionFilterModalContent(props: {
  controller: ModalController<Production[]>
  onSelected: (selection: Production[]) => void
  multiSelection?: boolean
  lastUsed?: Production[]
}) {
  const itemSelector = useItemSelector<Production>([], (a, b) => a.id === b.id)
  const filterModal = useModalController()
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')

  const { filterResult: productionsToRender } = useFilter<Production>(props.controller.data, {
    search,
    searchConfig: ProductionFilterConfig.searchKeys,
  })

  function handleProductionListSelected(production: Production) {
    if (!props.multiSelection) {
      onDone([production])
      return
    }
    itemSelector.toggle(production)
  }

  function onDone(selectedItems: Production[]) {
    props.controller.close()
    props.onSelected(selectedItems)
  }

  const productionRenderItem = useCallback(
    (item: Production | string, keyPrefix?: string) =>
      typeof item === 'string' ? (
        <IM.View key={`${keyPrefix ?? ''}#${item}`} spacing={['horizontal', 'top']} style={{ alignItems: 'center' }}>
          <IM.Text numberOfLines={1}>{item}</IM.Text>
        </IM.View>
      ) : (
        <ProductionCard key={`${keyPrefix ?? ''}#${item.id}`} onPress={() => handleProductionListSelected(item)} handler={item} />
      ),
    [itemSelector]
  )

  return (
    <IM.View style={{ flex: 1 }}>
      <BaseTextInput
        viewStyle={[styles.input]}
        placeholder={i18n.t('SEARCH')}
        value={search}
        onChangeText={setSearch}
        onClearPressed={() => {
          setSearch('')
        }}
        editable
        selectTextOnFocus
      />
      <ActiveFilterView showFilter={() => filterModal.show()} filterButtonText={i18n.t('SORT')} noFilterText="" />

      <FlatList
        data={productionsToRender}
        keyExtractor={(item, index) => (typeof item === 'string' ? item : index.toString())}
        renderItem={({ item }) => productionRenderItem(item)}
        ListFooterComponent={
          <>
            <ListSpacer />
          </>
        }
      />
      <ElusiveButton hide={!itemSelector.any} title={i18n.t('SELECT')} onPress={() => onDone(itemSelector.items)} />
      <FilterModal controller={filterModal} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginBottom: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
  },
})
