import { IM, IMLayout, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, ScrollView } from 'react-native'
import { useToast } from 'react-native-toast-notifications'

import api from '../apis/apiCalls'
import { Collo, Deposit } from '../apis/apiTypes'
import ColloCard from '../cards/Collo/ColloCard'
import { PackingListArticleMovement } from '../classes/PackingListCollection'
import FullScreenModal from '../components/FullScreenModal'
import Title from '../components/MWS/Title'
import SubmitButton from '../components/SubmitButton'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../contexts/UserSettingsContext'
import useAlert from '../hooks/useAlert'
import colloUtils from '../utils/colloUtils'
import { Error_Utils } from '../utils/ErrorUtils'
import { Utils } from '../utils/Utils'
import ColloPackingView, { ColloPackingSelection } from '../views/PackingList/ColloPackingView'

type ColloCompletionModalProps = {
  controller: ModalController<Collo[]>
  onCompleted: (concludedCollos: Collo[], concludedColloPackings: ColloPackingSelection[]) => void
  onAborted?: () => void
  deposit?: Deposit
  selectCollosBeforeConclusion?: boolean
  packingListArticleMovements?: PackingListArticleMovement[]
}

export default function ColloCompletionModal(props: ColloCompletionModalProps) {
  const { i18n } = useLanguage()

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
        if (props.onAborted) props.onAborted()
      }}
      title={i18n.t('ConcludeCollo')}>
      <Content {...props} />
    </FullScreenModal>
  )
}

function Content({ controller, onCompleted, deposit, selectCollosBeforeConclusion, packingListArticleMovements }: ColloCompletionModalProps) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const toast = useToast()
  const userSettings = useUserSettings()
  const [colloSelectionActive, setColloSelectionActive] = useState(
    !!selectCollosBeforeConclusion && !!controller.data?.length && controller.data.length > 1
  )
  const [collosToConclude, setCollosToConclude] = useState(() => [...(controller.data ?? [])])
  const [concludedColloPackings, setConcludedColloPackings] = useState<ColloPackingSelection[]>([])

  const [colloIndex, setColloIndex] = useState(0)
  const collo = collosToConclude.length > 0 && colloIndex < collosToConclude.length ? collosToConclude[colloIndex] : undefined

  const packingLists = useMemo(
    () => packingListArticleMovements?.filter(pl => pl.movement.collonumbers?.some(c => c.number === collo?.number)),
    [packingListArticleMovements, collo]
  )
  const packingListText = useMemo(() => packingLists?.map(pl => pl.packingList.number).join(', '), [packingLists])

  const [colloPacking, setColloPacking] = useState<ColloPackingSelection>({})

  useEffect(() => {
    const colloHasDimensions = collo?.length || collo?.weight || collo?.height
    if (colloHasDimensions) {
      setColloPacking(prev => ({ ...prev, length: collo?.length, width: collo?.width, height: collo?.height }))
    }
  }, [])

  useEffect(() => {
    if (!collo) {
      controller.close()
      onCompleted(collosToConclude, concludedColloPackings)
      return
    }

    setColloPacking(prev => ({ ...prev, collo: collo }))

    api
      .getColloWeight({ number: collo?.number ?? '' })
      .then(value => {
        setColloPacking(prev => ({ ...prev, weight: Math.max(Utils.roundToPrecision(value, 4), 0) }))
      })
      .catch(console.error)
  }, [colloIndex])

  async function concludeCollo() {
    try {
      loader.setLoading(true)
      await api.concludeCollo({
        number: collo?.number ?? '',
        packagingTypeId: colloPacking.packagingType?.id ?? '',
        weight: colloPacking.weight,
        height: colloPacking.height,
        width: colloPacking.width,
        length: colloPacking.length,
        collaboratorId: userSettings?.employeeId,
        stockpositionId: colloPacking.targetStockPositions?.id,
      })
      setConcludedColloPackings(prev => [...prev, colloPacking])
      loader.setLoading(false)
      setNextColloActive()
      toast.show(i18n.t('ConcludeColloSuccessful'))
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FailedToConcludeCollo'), collo?.number, Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
    }
  }

  function setNextColloActive() {
    setColloIndex(prev => prev + 1)
  }

  if (colloSelectionActive) {
    return (
      <IM.View style={[IMLayout.flex.f1]}>
        <ScrollView>
          <Title>{i18n.t('SELECT_COLLOS_TO_CONCLUDE')}</Title>
          <FlatList
            data={controller.data}
            renderItem={({ item }) => (
              <ColloCard
                collo={item}
                endIcon={collosToConclude.some(c => c.id === item.id) ? 'check' : undefined}
                onPress={() =>
                  setCollosToConclude(prev => {
                    const found = prev.find(c => c.id === item.id)
                    if (found) return prev.filter(c => c.id !== item.id)
                    return [...prev, item].sort(colloUtils.sort)
                  })
                }
              />
            )}
          />
        </ScrollView>
        <SubmitButton
          hideFromKeyBoard
          disabled={!collosToConclude.length}
          title={i18n.t('Continue')}
          onPress={() => Promise.resolve(setColloSelectionActive(false))}
        />
      </IM.View>
    )
  }

  return (
    <IM.View style={[IMLayout.flex.f1]}>
      <ColloCard
        collo={collo}
        style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }}
        packingList={packingListText}
      />

      <ColloPackingView
        style={{ flex: 1 }}
        colloPacking={colloPacking}
        setColloPacking={setColloPacking}
        deposit={deposit}
        stockPositionSelectionActive={userSettings?.isTransferPackinglistcollonumberActive}
      />

      <SubmitButton
        hideFromKeyBoard
        disabled={!colloPacking?.packagingType || !!loader.loading}
        title={i18n.t('ConcludeCollo')}
        onPress={concludeCollo}
      />
    </IM.View>
  )
}
