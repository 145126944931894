import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import colloUtils from '../../utils/colloUtils'
import { ColloPackingSelection } from '../../views/PackingList/ColloPackingView'
import MWS_BaseCard from '../MWS_BaseCard'

export type SplitColloCardProps = {
  collo: ColloPackingSelection | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
  index?: number
}

export default function SplitColloCard({ collo, style, onPress, onDelete, index }: SplitColloCardProps) {
  const { i18n } = useLanguage()

  const dimensions = colloUtils.getDimension({ ...collo } as Collo)

  const title = useMemo(() => {
    let result = collo?.collo?.number?.toString() ?? ''
    const packingTitle = collo?.packagingType?.code?.trim()
    if (packingTitle) {
      if (result) result += ' - '
      result += packingTitle
    }
    if (!result) {
      if (index !== undefined) return index.toString()
      return i18n.t('NEW_COLLO')
    }
    return result
  }, [collo?.packagingType, collo?.collo?.number, index])

  return (
    <MWS_BaseCard onPress={onPress} onDelete={onDelete} icon={'box-taped'} cardStyle={style}>
      <View style={{ flex: 1 }}>
        <IMText numberOfLines={1} primary style={{}}>
          {title}
        </IMText>
        {!!collo?.packagingType?.description && (
          <IMText numberOfLines={1} secondary style={{}}>
            {collo.packagingType.description?.trim()}
          </IMText>
        )}
        {!!dimensions && (
          <IMText numberOfLines={1} secondary style={{ flex: 1 }}>
            {dimensions}
          </IMText>
        )}
      </View>
      <View style={{ justifyContent: 'center' }}>
        {!!collo?.weight && (
          <IMText numberOfLines={1} primary style={{ marginLeft: 5 }}>
            {`${collo?.weight} ${i18n.t('WeightUnit')}`}
          </IMText>
        )}
      </View>
    </MWS_BaseCard>
  )
}
