/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import { ArticleDepositQuantity } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import { Article } from '../../classes/Article'
import IMRefreshControl from '../../components/IMRefreshControl'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'
import { SelectorViewProps } from './DepositSelectorView'

export default function DepositAvailabilitySelectorView(props: { article: Article | undefined } & SelectorViewProps<ArticleDepositQuantity>) {
  const [items, setItems] = useState<ArticleDepositQuantity[]>(props.itemList ?? [])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.preLoad || !props.item) {
      loadItems()
    }
  }, [])

  useEffect(() => {
    if (props.itemList) setItems(props.itemList)
  }, [props.itemList])

  async function loadItems() {
    if (!props.getItemList) return
    setLoading(true)
    try {
      const result = await props.getItemList()
      if (result) setItems(result)
    } catch (ex) {
      console.error(ex)
    }
    setLoading(false)
  }

  const isLoading = loading || !!props.loading

  return (
    <View>
      {props.item && (
        <DepositCard
          deposit={props.item.deposit}
          onDelete={
            items?.length > 1
              ? () => {
                  props.setItem(undefined)
                  loadItems()
                }
              : undefined
          }
          quantity={props.article ? props.article.getQuantityWithUnitText(props.item.quantity) : undefined}
        />
      )}
      {!props.item && (
        <FlatList
          data={items}
          renderItem={({ item }) => (
            <DepositCard
              deposit={item.deposit}
              onPress={() => props.setItem(item)}
              quantity={props.article ? props.article.getQuantityWithUnitText(item.quantity) : undefined}
            />
          )}
          refreshControl={<IMRefreshControl refreshing={isLoading} onRefresh={loadItems} />}
          ListFooterComponent={!isLoading && items.length === 0 ? <NoEntriesTag /> : <ListSpacer />}
        />
      )}
    </View>
  )
}
