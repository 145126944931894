import { ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'

import FullScreenModal from '../components/FullScreenModal'
import { useUserSettings } from '../contexts/UserSettingsContext'
import ColloPackingView, { ColloPackingSelection } from '../views/PackingList/ColloPackingView'

type ColloPackingModalProps = {
  controller: ModalController<ColloPackingSelection>
  onConfirm: (packing: ColloPackingSelection) => void
  onAborted?: () => void
  modalTitle?: string
}

export default function ColloPackingModal(props: ColloPackingModalProps) {
  const { i18n } = useLanguage()
  const userSettings = useUserSettings()

  const [colloPacking, setColloPacking] = useState<ColloPackingSelection>({})

  useEffect(() => {
    if (!props.controller.isShown) {
      setColloPacking({})
      return
    }
    setColloPacking(props.controller.data ?? {})
  }, [props.controller.isShown])

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
        if (props.onAborted) props.onAborted()
      }}
      title={props.modalTitle || props.controller.data?.id ? i18n.t('EDIT_COLLO') : i18n.t('AddCollo')}
      onClose={() => {
        props.controller.close()
        props.onConfirm(colloPacking)
      }}
      closeIcon={'check'}>
      <ColloPackingView
        style={{ flex: 1 }}
        colloPacking={colloPacking}
        setColloPacking={setColloPacking}
        showQuantityInput={!props.controller.data?.id}
        focusWeightInput
        stockPositionSelectionActive={userSettings?.isTransferPackinglistcollonumberActive}
      />
    </FullScreenModal>
  )
}
