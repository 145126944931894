import { useLanguage, Utils } from '@infominds/react-native-components'
import React, { useCallback, useState } from 'react'

import api from '../../apis/apiCalls'
import { AdditionalActions } from '../../apis/apiRequestTypes'
import { Item, ItemStockPositionQuantity } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { SelectionViewProps } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import StockPositionItemQuantitiesSelectionView from './StockPositionItemQuantitiesSelectionView'

export default function ItemSelectionView(
  props: {
    fetchArticleAndSerial?: boolean
    loadOngoingInventoryItems?: boolean
    ongoingInventoryDate?: string
    showNegativeQuantities?: boolean
    ignoreSerialNumberDuringOngoingInventory?: boolean
    ignoreHistoricalSN?: boolean
    depositId?: string
    stockPositionId?: string
    additionalActions?: AdditionalActions[]
    onItemStockPositionQuantitiesSelected?: (items: ItemStockPositionQuantity[]) => void
  } & SelectionViewProps<Item>
) {
  const { i18n } = useLanguage()

  const [showSelection, setShowSelection] = useState<boolean>(false)

  //reduces items depending on input. Used to cause history selection to always return 1 item
  function resultReducer(items: Item[], input: Item | string | undefined) {
    if (props.ignoreHistoricalSN && items) {
      items.forEach(item => {
        if (item.serialnumber && item.article.isSerialnumberOnlyForHistory) {
          item.serialnumber = undefined
        }
      })
      items = Utils.keepUniques(items, item => `id#${item.article.id};SN#${item.serialnumber?.id ?? ''}`)
    }
    if (!items || !input || typeof input !== 'object') return items
    const filteredItems = items.filter(i => i.article?.code === input.article?.code && i.serialnumber?.number === input.serialnumber?.number)
    if (filteredItems.length > 0) return filteredItems
    return items
  }

  function handleSelection(item: Item) {
    props.onSelected(item)
  }

  function handleSelectionArticleSerialNumber() {
    setShowSelection(true)
  }

  function handleSelectionArticleSerialNumberQuantities(items: ItemStockPositionQuantity[]) {
    if (props.onItemStockPositionQuantitiesSelected) props.onItemStockPositionQuantitiesSelected(items)
    setShowSelection(false)
  }

  const RenderItem = useCallback((item: Item) => <ArticleCard article={item.article} serialNumber={item.serialnumber} maxLinesDescription={1} />, [])

  return (
    <>
      <MWS_Input
        type={'Item'}
        allowEmptyInput={props.allowEmptyInput}
        load={input =>
          api.getItem({
            searchText: input.textInput ?? input.scannerInput,
            fetchArticleAndSN: props.fetchArticleAndSerial,
            additionalActions: props.additionalActions,
          })
        }
        onSelected={handleSelection}
        itemRenderer={RenderItem}
        title={props.title ?? i18n.t('SelectItem')}
        history={props.history}
        hideHistory={props.hideHistory}
        historyNameExtractor={item => articleUtils.getArticleTitle(item.article) + (item.serialnumber?.id ?? '')}
        handleShowListButtonPressed={props.allowEmptyInput && props.stockPositionId ? handleSelectionArticleSerialNumber : undefined}
        loadFromHistory={item =>
          api.getItem({
            searchText: item.serialnumber?.number ?? item.article.code,
            fetchArticleAndSN: props.fetchArticleAndSerial,
          })
        }
        modifyResult={(items, input) => resultReducer(items, input)}
        showResultInModal={props.showResultInModal}
        overwriteLoadingSpinner={props.overwriteLoadingSpinner}
        dontStopLoadingSpinner={props.donStopLoadingSpinner}
        style={props.style}
        denyTextInput={props.denyTextInput}
      />

      <StockPositionItemQuantitiesSelectionView
        show={showSelection}
        depositId={props.depositId}
        stockPositionId={props.stockPositionId}
        showNegativeQuantities={props.showNegativeQuantities}
        ignoreSerialNumberDuringOngoingInventory={props.ignoreSerialNumberDuringOngoingInventory}
        clearQuantity
        loadOngoingInventoryItems={props.loadOngoingInventoryItems}
        ongoingInventoryDate={props.ongoingInventoryDate}
        style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        onItemsSelected={items => handleSelectionArticleSerialNumberQuantities(items)}
      />
    </>
  )
}
