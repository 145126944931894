import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/core'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { SerialNumber } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import MacAddressCard from '../../cards/Serialnumber/MacAddressCard'
import ScannerInput from '../../components/MWS/Inputs/ScannerInput'
import SubmitButton from '../../components/SubmitButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import { ScreenType } from '../../types'

export default function SerialnumberInputMacAddressView(props: { serialnumber: SerialNumber }) {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const loader = useLoadingIndicator()
  const navigation = useNavigation()
  const article = useArticle(props.serialnumber.article)
  const [selectedMacAddress, setSelectedMacAddress] = useState<string>('')

  const macAddressAlreadyAssigned = !!props.serialnumber.alternativeIdMonitoringMapping

  useEffect(() => {
    if (macAddressAlreadyAssigned) {
      alert.info(i18n.t('MacAddressAlreadyAssigned'), props.serialnumber.alternativeIdMonitoringMapping)
    }
  }, [])

  async function checkAndSetMacAddress() {
    try {
      const macAddress = selectedMacAddress
      loader.setLoading(true)
      const result = await api.checkSerialnumberChecks({ alternativeIdMonitoringMapping: macAddress })
      if (!result.isOk) {
        alert.error(result.warning)
        return
      }
      if (result.warning !== '') {
        const resultQuestion = await alert.yesNo(result.warning)
        if (!resultQuestion) return
      }
      await api.patchSerialnumber({ id: props.serialnumber.id, alternativeIdMonitoringMapping: macAddress })
      alert.info(i18n.t('MacAddressAssigned'))
      navigation.navigate(ScreenType.SerialnumberSelection, { actionType: 'isOpenMacAddress' })
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('ERROR_TITLE'), i18n.t('MacAddressCouldNotBeAssigned'))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <View>
      <View>{article && <ArticleCard article={article.info} serialNumber={props.serialnumber} macAddressLabel={i18n.t('MacAddress')} />}</View>
      {!macAddressAlreadyAssigned && (
        <View>
          {!selectedMacAddress && (
            <ScannerInput
              style={styles.input}
              placeHolder={i18n.t('SelectMacAddress')}
              useInput={input => setSelectedMacAddress(input.scannerInput ?? input.textInput ?? '')}
            />
          )}
          {!!selectedMacAddress && (
            <View style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}>
              <MacAddressCard macAddress={selectedMacAddress} onDelete={() => setSelectedMacAddress('')} />
              <SubmitButton onPress={() => checkAndSetMacAddress()} title={i18n.t('AssignMacAddress')} />
            </View>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  infoView: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  input: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
