import { useItemSelector, useLanguage } from '@infominds/react-native-components'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React from 'react'
import { FlatList } from 'react-native'

import { PackingList } from '../../apis/apiTypes'
import PackingListCard from '../../cards/Packinglist/PackingListCard'
import ElusiveButton from '../../components/ElusiveButton'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import { RootStackParamList } from '../../navigation/types'
import packingListUtils from '../../utils/packingListUtils'
import PackingListSelectionView from '../../views/InputViews/PackingListSelectionView'

export default function PackingListSelectionScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()

  const route = useRoute<RouteProp<RootStackParamList, 'PackingListSelection'>>()
  const listSelector = useItemSelector<PackingList>([], (a, b) => a.id === b.id)

  function handlePackingListSelection(packingLists: PackingList[]) {
    listSelector.add(...packingLists)
  }

  function beginConsignment(packingLists: PackingList[]) {
    navigation.navigate('PackingListStack', {
      screen: 'PackingList',
      params: {
        packingLists: packingLists.sort(packingListUtils.sortPackingListsById),
        isPreConsignment: route.params?.isPreConsignment,
        isProductionConsignment: false,
      },
    })
    listSelector.clear()
  }

  return (
    <MWS_Screen>
      <PackingListSelectionView
        onSelected={packingList => handlePackingListSelection([packingList])}
        onMultiSelect={beginConsignment}
        allowEmptyInput
        filterResultByOrder
        loadList={route.params?.loadList}
        preConsignment={route.params?.isPreConsignment}
        showResultInModal
      />
      {listSelector.any && <Title>{i18n.t('SelectedPackingLists')}</Title>}
      <FlatList
        data={listSelector.items}
        renderItem={({ item }) => <PackingListCard packingList={item} onDelete={() => listSelector.remove(item)} />}
      />
      <ElusiveButton
        hideFromKeyBoard
        hide={!listSelector.any}
        title={route.params?.isPreConsignment ? i18n.t('BeginPreConsignment') : i18n.t('BeginConsignment')}
        onPress={() => beginConsignment(listSelector.items)}
      />
    </MWS_Screen>
  )
}
