import { Layout } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { Colors } from '../../constants/Colors'
import { HeaderProps } from './Header'
import Text from './Text'

export type TitleProps = HeaderProps

export default function Title(props: TitleProps) {
  const { style, ...otherProps } = props

  return <Text style={[Layout.defaultComponentsStyles, styles.text, style]} {...otherProps} />
}

const styles = StyleSheet.create({
  text: {
    fontSize: 28,
    color: Colors.tint,
    marginBottom: 10,
    marginTop: 10,
  },
})
