import { IM, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'

import { Collo } from '../../../apis/apiTypes'
import ColloCard from '../../../cards/Collo/ColloCard'
import PackingListCard from '../../../cards/Packinglist/PackingListCard'
import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { usePackingList } from '../../../contexts/packingLists/PackingListContext'
import { usePackingListMovement } from '../../../contexts/packingLists/PackingListMovementContext'
import useAlert from '../../../hooks/useAlert'
import useModalController from '../../../hooks/useModalController'
import SelectionModalFullScreen from '../../../modals/SelectionModalFullScreen'
import { PackingListCollos } from '../../../types/packingListTypes'
import IMIcon from '../../IMIcon'
import { IMTextWithIcon } from '../../IMTextWithIcon'
import CardBasic from '../../old/CardBasic'
import ListSpacer from '../ListSpacer'

export default function PackingListColloSelector(props: { style?: StyleProp<ViewStyle> }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const alert = useAlert()
  const { i18n } = useLanguage()
  const {
    activeColloSelector: { activeCollos },
  } = usePackingList()

  const { anyMovement, availableCollos } = usePackingListMovement()

  const assignText = useMemo(() => {
    if (availableCollos.length === 1) {
      const activeCollo = !!anyMovement?.packingList.id && activeCollos[anyMovement?.packingList.id]
      if (activeCollo) return activeCollo
      return i18n.t('NEW_COLLO')
    }
    return i18n.t('ASSIGNMENT')
  }, [availableCollos, activeCollos, anyMovement])

  const selectorModal = useModalController()

  function handleOnPress() {
    if (!availableCollos?.some(ac => !!ac.collos.length)) return showNoCollosInfo()
    selectorModal.show()
  }

  function showNoCollosInfo() {
    alert.info(i18n.t('PackingListContainsNoCollos'))
  }

  return (
    <TouchableOpacity style={[{ flexDirection: 'row' }, props.style]} onPress={handleOnPress}>
      <CardBasic
        style={{
          flex: 1,
          flexDirection: 'row',
          margin: 0,
          marginHorizontal: 0,
          paddingHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <IMTextWithIcon viewStyle={{ flex: 1 }} style={{ textAlign: 'left', flex: 1 }} numberOfLines={1} icon={'box-taped'} text={assignText} />
        <IMIcon color={theme.textDetail} style={{ justifyContent: 'center' }} icon={'angle-down'} size={15} />
      </CardBasic>
      <SelectionModalFullScreen
        disabled
        show={selectorModal.isShown}
        title={i18n.t('ASSIGN_COLLO')}
        items={availableCollos}
        close={() => selectorModal.close()}
        renderItem={item => <PackingListCollosView item={item} onSelected={selectorModal.close} />}
      />
    </TouchableOpacity>
  )
}

type PackingListCollosProps = { item: PackingListCollos; onSelected: () => void }
function PackingListCollosView({ item, onSelected }: PackingListCollosProps) {
  const {
    activeColloSelector: { setActiveColloNumber, activeCollos },
  } = usePackingList()
  const { i18n } = useLanguage()
  const activeColloNumber = !!item.packingList.id && activeCollos[item.packingList.id]
  const [open, setOpen] = useState(true)

  function handleSelection(packingListId: string, collo: Collo | undefined) {
    setActiveColloNumber(collo?.number, packingListId)
    onSelected()
  }

  return (
    <IM.View>
      <PackingListCard packingList={item.packingList} onPress={() => setOpen(p => !p)} endButtonIcon={open ? 'chevronUp' : 'chevronDown'} />
      {open && (
        <IM.View spacing={'left'}>
          {item.collos.map((collo, index) => (
            <ColloCard
              key={`ColloCard-${index}`}
              collo={collo}
              onPress={() => handleSelection(item.packingList.id, collo)}
              info={activeColloNumber === collo.number ? i18n.t('SELECTED_COLLO') : undefined}
            />
          ))}
          <ColloCard
            collo={{ id: '', number: i18n.t('NEW_COLLO') } as Collo}
            onPress={() => handleSelection(item.packingList.id, undefined)}
            info={!activeColloNumber ? i18n.t('SELECTED_COLLO') : undefined}
          />
        </IM.View>
      )}
      <ListSpacer height={STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS} />
    </IM.View>
  )
}
