import { useLanguage } from '@infominds/react-native-components'
import lodash from 'lodash'
import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react'

import useDataFilterState from '../hooks/filter/useDataFilterState'
import useDataGroupState from '../hooks/filter/useDataGroupState'
import useDataOrderState from '../hooks/filter/useDataOrderState'
import {
  DataFilterConfigType,
  DataFilterValue,
  FilterConfig,
  FilterDataSorter,
  GroupConfig,
  GroupDataSorter,
  OrderConfig,
  OrderDataSorter,
} from '../types'
import { filterUtils } from '../utils/FilterUtils'

export interface FilterContextProps<T, TSub = void> {
  filters: FilterDataSorter<T, TSub>[]
  groups: GroupDataSorter<T, TSub>[]
  orders: OrderDataSorter<T, TSub>[]
  isAnyFilterActive: boolean
  initFilters: (elements: T[]) => void
  changeFilterStatus: (type: DataFilterConfigType, id: string, setValue?: boolean, filterValue?: string) => void
  clear: () => void
  initOk: boolean
  filtersInitialized: boolean
}

const FilterContext = createContext<FilterContextProps<object> | undefined>(undefined)

type ProviderProps<T, TSub = void> = {
  storageKeyUniqueId: string
  groupConfig?: GroupConfig<T, TSub>
  orderConfig?: OrderConfig<T, TSub>
  filterConfig?: FilterConfig<T, TSub>
}

export function FilterProvider<T extends object, TSub extends object | void = void>({
  storageKeyUniqueId,
  children,
  groupConfig,
  orderConfig,
  filterConfig,
}: PropsWithChildren<ProviderProps<T, TSub>>) {
  const { i18n } = useLanguage()
  const [groups, setGroups, groupInitOK] = useDataGroupState<T, TSub>(storageKeyUniqueId, groupConfig)
  const [orders, setOrders, orderInitOk] = useDataOrderState<T, TSub>(storageKeyUniqueId, orderConfig)
  const [filters, setFilters, filterInitOK] = useDataFilterState<T, TSub>(storageKeyUniqueId, filterConfig)
  const [filtersInitialized, setFiltersInitialized] = useState(false)
  const initOk = groupInitOK && orderInitOk && filterInitOK

  const isAnyFilterActive = useMemo(() => {
    if (groups.find(g => g.active && !g.options?.isDefault)) return true
    if (orders.find(o => o.active && !o.options?.isDefault)) return true
    if (filters.find(f => f.values.find(v => v.active))) return true
    return false
  }, [groups, orders, filters])

  function initFilters(elements: T[] | undefined) {
    if (!elements) return

    setFilters(prev => {
      const clone = lodash.cloneDeep(prev)

      return clone.map(filterEntry => {
        const mergedValues: DataFilterValue[] = []
        const valuesFromData = filterUtils.prepareFilterValues(elements, filterEntry.dataKey, filterEntry.id, filterEntry.options, i18n)
        const valuesFromStorage = filterEntry.values

        for (const valueFromData of valuesFromData) {
          const storageValueFound = valuesFromStorage.find(el => el.id === valueFromData.id)

          if (storageValueFound !== undefined) {
            mergedValues.push({ ...valueFromData, active: storageValueFound.active })
          } else {
            mergedValues.push(valueFromData)
          }
        }
        return { ...filterEntry, values: mergedValues }
      })
    })
    setFiltersInitialized(true)
  }

  const changeFilterStatus = useCallback((type: DataFilterConfigType, id: string, setValue?: boolean) => {
    switch (type) {
      case DataFilterConfigType.Filter: {
        setFilters(prev => {
          prev.forEach(entry => {
            entry.values.forEach(value => {
              if (value.id === id) value.active = setValue ?? !value.active
            })
          })
          return [...prev]
        })
        break
      }
      case DataFilterConfigType.Group: {
        setGroups(prev => updateGroups(prev, id, setValue))
        break
      }
      case DataFilterConfigType.Order: {
        setOrders(prev => {
          prev.forEach(entry => {
            if (entry.id === id) {
              entry.active = setValue ?? !entry.active
              entry.order = entry.active ? Date.now() : undefined
            }
          })

          return [...prev]
        })
        break
      }
    }
  }, [])

  function updateGroups(prevState: GroupDataSorter<T, TSub>[], id: string, setValue?: boolean) {
    let anyTrue = false
    prevState.forEach(entry => {
      if (entry.id !== id) {
        entry.active = false
        return
      }

      entry.active = setValue ?? !entry.active
      if (entry.active) anyTrue = true
    })
    if (!anyTrue) {
      const hasDefault = prevState.find(q => q.options?.isDefault)
      if (hasDefault) {
        hasDefault.active = true
      }
    }

    return [...prevState]
  }

  const clear = useCallback(() => {
    setFilters(prev => {
      prev.forEach(entry => {
        entry.values.forEach(value => {
          value.active = false
        })
      })
      return [...prev]
    })

    setGroups(prev => {
      prev.forEach(entry => {
        entry.active = false
      })
      return [...prev]
    })

    setOrders(prev => {
      prev.forEach(entry => {
        entry.active = false
      })
      return [...prev]
    })
  }, [])

  return (
    <FilterContext.Provider
      value={
        {
          initOk,
          filters,
          groups,
          orders,
          isAnyFilterActive,
          changeFilterStatus,
          initFilters,
          clear,
          filtersInitialized,
        } as FilterContextProps<object>
      }>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
