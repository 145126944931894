import { useNavigation } from '@react-navigation/core'
import React from 'react'

import { StockPosition } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import StockPositionSelectionView from '../../views/InputViews/StockPositionSelectionView'

export default function StockPositionSelectionScreen() {
  const navigation = useNavigation()

  function navigateToInfoScreen(stockPosition: StockPosition) {
    navigation.navigate(ScreenType.StockPositionInfo, { stockPosition })
  }

  return (
    <MWS_Screen>
      <StockPositionSelectionView onSelected={navigateToInfoScreen} style={{ flex: 1 }} history />
    </MWS_Screen>
  )
}
