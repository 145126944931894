import { useRef, useState } from 'react'

export interface ModalController<T = void> {
  /**
   * State of Modal visibility
   */
  isShown: boolean
  /**
   * Shows the modal and forwards the given data to it
   */
  show: (setData?: T) => void
  /**
   * Closes the modal
   */
  close: () => void
  data: T | undefined
}

/**
 * Provides a ModalController, which contains the basic functionality used by modals
 * @returns Object \{isShown, show(), close(), data\}
 */
export default function useModalController<T = void>(): ModalController<T> {
  const [show, setShow] = useState(false)
  const data = useRef<T | undefined>(undefined)

  function doShow(setData?: T) {
    data.current = setData
    setShow(true)
  }

  return {
    isShown: show,
    show: doShow,
    close: () => setShow(false),
    data: data.current,
  }
}
