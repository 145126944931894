import { IM, useTheme } from '@infominds/react-native-components'
import * as React from 'react'
import { TouchableOpacity } from 'react-native'

import { Colors } from '../../constants/Colors'

export default function ModalCloseButton(props: { onPress: () => void }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return (
    <TouchableOpacity onPress={() => props.onPress()}>
      <IM.Icon icon={['fal', 'times']} size={28} color={theme.text} style={{ alignSelf: 'flex-end' }} />
    </TouchableOpacity>
  )
}
