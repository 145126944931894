import { ClientProvider } from '@infominds/react-api'

import {
  InventoryAccountingRequest,
  InventoryCodeRequest,
  InventoryPostRequest,
  OngoingInventoryPatchRequest,
  OngoingInventoryPostRequest,
  OngoingInventoryRequest,
  OngoingInventoryToConfirmRequest,
} from './apiRequestTypes'
import { InventoryAccounting, InventoryEntry, InventoryPostResult, OngoingInventory, OngoingInventoryToConfirm } from './apiTypes'

export const inventoryCode = ClientProvider<InventoryEntry[], InventoryCodeRequest>('inventory/code')
export const inventoryAccountings = ClientProvider<InventoryAccounting[], InventoryAccountingRequest>('inventory/accountings')
export const inventoryAccounting = ClientProvider<
  unknown,
  unknown,
  InventoryPostResult,
  InventoryPostRequest,
  unknown,
  unknown,
  boolean,
  unknown,
  { id: string }
>('inventory/accounting')
export const inventoryOngoings = ClientProvider<OngoingInventory[], OngoingInventoryRequest>('inventory/ongoingInventories')
export const inventoryOngoingsToConfirm = ClientProvider<OngoingInventoryToConfirm[], OngoingInventoryToConfirmRequest>(
  'inventory/ongoingInventoriesToConfirm'
)
export const inventoryOngoing = ClientProvider<unknown, unknown, boolean, OngoingInventoryPostRequest, boolean, OngoingInventoryPatchRequest>(
  'inventory/ongoingInventory'
)
