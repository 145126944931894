import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useEffect } from 'react'

import api from '../../apis/apiCalls'
import { ArticleDepositQuantity, Deposit, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import PositionCard from '../../cards/StockPosition/PositionCard'
import { Article } from '../../classes/Article'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useModalController from '../../hooks/useModalController'
import MultiLevelSelectionModal from '../../modals/MultiLevelSelectionModal'
import { SelectionViewProps } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import { depositUtils } from '../../utils/depositUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import StockPositionSelectionView from './StockPositionSelectionView'

export default function StockPositionWithAvailabilitySelectionView(
  props: {
    article: Article | undefined
    deposit?: Deposit
    unitConversionFactor?: number
    modalHeader?: JSX.Element
    noListButton?: boolean
    filterNotProposedInPackingLists?: boolean //filter StockPositions that are flagged "NotProposedInPackinglists" from list (scanning is still possible)
    filterOtherDeposits?: boolean
    filterStockPositions?: StockPosition[]
    allowDepositSelection?: boolean
    considerPickedQuantity?: boolean
    productionStockPositions?: boolean
  } & Omit<SelectionViewProps<StockPositionAvailability>, 'handleShowListButtonPressed' | 'title'>
) {
  const { i18n } = useLanguage()

  const {
    article,
    deposit,
    unitConversionFactor,
    modalHeader,
    noListButton,
    onSelected,
    allowDepositSelection,
    considerPickedQuantity,
    productionStockPositions,
    filterStockPositions,
    ...selectionViewProps
  } = props

  const modalController = useModalController<ArticleDepositQuantity>()
  const loader = useLoadingIndicator()

  useEffect(() => {
    if (!props.loadOnMount) return
    loader.setLoading(true)
    loadStockPositionAvailability(depositUtils.createArticleDepositQuantity(deposit))
      .then(result => {
        if (!result?.length) return
        if (result.length === 1) {
          props.onSelected(result[0])
          return
        }
        modalController.show(depositUtils.createArticleDepositQuantity(deposit, result))
      })
      .catch(console.error)
      .finally(() => loader.setLoading(false))
  }, [])

  async function loadArticleAvailability() {
    const result = await api.getArticleAvailability({
      articleId: props.article?.info.id,
      // depositId: props.filterOtherDeposits ? props.deposit?.id : undefined,
      considerPickedQuantity: props.considerPickedQuantity,
      productionStockPositions: props.productionStockPositions,
    })
    if (filterStockPositions) {
      return stockPositionUtils.filterDepositQuantities(result.depositquantities, filterStockPositions)
    }
    return result.depositquantities
  }

  async function loadStockPositionAvailability(depositQuantity?: ArticleDepositQuantity): Promise<StockPositionAvailability[]> {
    const result = await api.getArticleAvailability({
      articleId: props.article?.info.id,
      depositId: depositQuantity?.deposit.id,
      considerPickedQuantity: props.considerPickedQuantity,
      productionStockPositions: props.productionStockPositions,
    })
    result.depositquantities = stockPositionUtils.filterDepositQuantities(result.depositquantities, filterStockPositions)
    return (
      stockPositionUtils
        .convertDepositAvailabilityToStockPosition(result, !!depositQuantity?.deposit, true)
        ?.filter(
          q =>
            (!!q.stockposition || !q.deposit?.isStockpositionActive) &&
            (!props.filterNotProposedInPackingLists || !q.stockposition?.notProposedInPackinglists)
        ) ?? []
    )
  }

  async function handleSelection(selectedStockPosition: StockPosition | undefined, selectedDeposit: Deposit | undefined, availability?: number) {
    if (!availability) {
      loader.setLoading(true)
      availability = await api.getItemAvailability({
        articleId: article?.info.id,
        depositId: selectedDeposit?.id,
        stockPositionId: selectedStockPosition?.id,
      })
      loader.setLoading(false)
    }
    onSelected(stockPositionUtils.createStockPositionAvailability(article?.info, selectedDeposit, selectedStockPosition, availability))
  }

  const firstLevelRenderItem = useCallback(
    (item: ArticleDepositQuantity, isSelected: boolean) => (
      <DepositCard
        deposit={item.deposit}
        isMarked={isSelected ? 'times' : undefined}
        quantity={item.quantity ? articleUtils.formatQuantity(item.quantity * (unitConversionFactor || 1), article?.getUsedUnit()) : undefined}
      />
    ),
    [unitConversionFactor, article]
  )

  const secondLevelRenderItem = useCallback(
    (item: StockPositionAvailability) => (
      <PositionCard
        deposit={item.deposit}
        stockPosition={item.stockposition}
        quantity={articleUtils.formatQuantity((item.quantity ?? 0) * (unitConversionFactor || 1), article?.getUsedUnit())}
      />
    ),
    [unitConversionFactor, article]
  )

  return (
    <>
      <StockPositionSelectionView
        depositId={deposit?.id}
        onSelected={selectedStockPosition => {
          handleSelection(selectedStockPosition, deposit).catch(console.error)
        }}
        showResultInModal
        handleShowListButtonPressed={
          noListButton
            ? undefined
            : () => {
                modalController.show(depositUtils.createArticleDepositQuantity(deposit))
              }
        }
        style={selectionViewProps.style}
        inputStyle={selectionViewProps.inputStyle}
        allowEmptyInput={selectionViewProps.allowEmptyInput}
      />
      <MultiLevelSelectionModal
        controller={modalController}
        header={modalHeader}
        preselectedFirstLevelItem={depositUtils.createArticleDepositQuantity(deposit)}
        loadFirstLevelItems={loadArticleAvailability}
        noItemsTextT2={i18n.t('NoAvailabilityOnDeposit')}
        firstLevelRenderItem={firstLevelRenderItem}
        filterFirstLevelItems={item => !!item.quantity && (!!item.deposit.isStockpositionActive || !!allowDepositSelection)}
        loadSecondLevelItems={loadStockPositionAvailability}
        filterSecondLevelItems={item => !!item.quantity && item.quantity > 0}
        secondLevelRenderItem={secondLevelRenderItem}
        onSelected={(selectedDeposit, selectedStockPosition) => {
          handleSelection(selectedStockPosition?.stockposition, selectedDeposit.deposit, selectedStockPosition?.quantity ?? selectedDeposit.quantity)
            .catch(console.error)
            .finally(() => modalController.close())
        }}
        itemHasSecondLevel={item => !!item.deposit.isStockpositionActive}
        title={i18n.t('SelectStockPosition')}
      />
    </>
  )
}
