import { ModalController, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { PackingListArticle } from '../../classes/PackingListCollection'
import FullScreenModal from '../../components/FullScreenModal'
import PackingLisIncompleteMovementsView from '../../views/PackingList/PackingLisIncompleteMovementsView'

export type PackingListIncompleteMovementsModalProps = { controller: ModalController<PackingListArticle[]>; onConfirm: () => void }

export default function PackingListIncompleteMovementsModal({ controller, onConfirm }: PackingListIncompleteMovementsModalProps) {
  const { i18n } = useLanguage()

  return (
    <FullScreenModal
      isVisible={controller.isShown}
      close={() => {
        controller.close()
      }}
      title={i18n.t('ATTENTION')}>
      <PackingLisIncompleteMovementsView
        incompletePackingListMovements={controller.data ?? []}
        onContinue={() => {
          controller.close()
          onConfirm()
        }}
      />
    </FullScreenModal>
  )
}
