import moment from 'moment'
import hash from 'object-hash'
import { Platform, StatusBar } from 'react-native'
import uuid from 'react-native-uuid'

import { CONSTANTS } from '../constants/Constants'

export const StyleUtils = {
  getTitleBarHeaderHight: () => {
    return Platform.OS === 'android' ? StatusBar.currentHeight : 0
  },
}

export const Utils = {
  isNumberString(numberStr: string) {
    return numberStr && numberStr.match(/^\d*$/) !== null
  },
  formatNumberString(value: string) {
    if (!value) return ''
    return value.replace(/^0-9+-,./g, '')
  },
  convertNumberToString(number: number | string | undefined, useDotAsComma?: boolean) {
    if (number === undefined) return ''
    if (useDotAsComma) return this.formatNumberString(number.toString().replace(',', '.'))
    return this.formatNumberString(number.toString().replace('.', ','))
  },
  parseFloatFromText(text: string) {
    return Number.parseFloat((text ?? '').replace(',', '.').replace(/[^0-9+-.]/g, ''))
  },
  parseIntegerFromText(text: string) {
    return Number.parseInt((text ?? '').replace(',', '.').replace(/[^0-9+-.]/g, ''), 10)
  },
  modifyDisplayString(displayString: string) {
    if (displayString) {
      //remove starting spaces
      while (displayString.startsWith(' ')) {
        displayString = displayString.substring(1)
      }
    }
    return displayString
  },
  //compares two strings. trims and lowerCases them before comparison
  compareStrings(a: string | undefined, b: string | undefined) {
    if (a === undefined || b === undefined) return false
    return a.toLowerCase().trim() === b.toLowerCase().trim()
  },
  compareStringsForSort(a: string | undefined, b: string | undefined) {
    if (!a) return -1
    if (!b) return 1
    const sA = a.trim().toLowerCase()
    const sB = b.trim().toLowerCase()
    if (sA === sB) return 0
    return sA > sB ? 1 : -1
  },
  parseDate(date: string | Date | undefined) {
    try {
      if (!date) return undefined
      const parsedDate = new Date(date)
      if (parsedDate.getFullYear() > 1) return parsedDate
    } catch (error) {
      console.error(error)
    }
    return undefined
  },
  IsDateValid(date: Date | undefined) {
    if (!date) return false
    return date
  },
  FormatDate(date: Date | string | undefined, format?: string, langId?: string) {
    if (date) {
      return moment(date)
        .locale([langId || 'en'])
        .format(format)
    } else {
      return undefined
    }
  },
  DateToTimestamp(date?: Date, unit?: 'ms' | 's') {
    if (date) {
      const time = moment(date)
      let timeStamp = time.unix() //returns unix timestamp[s]
      if (unit === 'ms') {
        timeStamp *= 1000
      }
      return timeStamp
    }
    return undefined
  },
  XOR(a: boolean, b: boolean) {
    return (a && !b) || (!a && b)
  },
  AddDaysToDate(date: Date, days: number) {
    if (days < 0) {
      return moment(date).startOf('day').add(days, 'd').toDate()
    } else {
      return moment(date).endOf('day').add(days, 'd').toDate()
    }
  },
  getToday() {
    return moment(new Date()).startOf('day').toDate()
  },
  comparePhoneNumbers(number1: string, number2: string) {
    return number1 && number2 && number1.toString().replace(/ /g, '') === number2.toString().replace(/ /g, '')
  },
  roundToPrecision(number: number, precision: number) {
    const p = Math.pow(10, Math.max(0, precision))
    return Math.round(number * p) / p
  },
  roundToDefaultPrecision(numberToRound: number | string) {
    let number = typeof numberToRound === 'number' ? numberToRound : 0
    if (typeof numberToRound === 'string') {
      number = Number.parseFloat(numberToRound)
      if (!Number.isFinite(number)) {
        return number
      }
    }
    return this.roundToPrecision(number, CONSTANTS.NUMBER_PRECISION)
  },
  /**
   * Replaces ${value1}, ${value2},... in text with given values.
   * Unused keys will be replaced with an empty string
   */
  stringValueReplacer(text: string | undefined, ...values: unknown[]) {
    if (!text) return ''
    if (!values) return text
    const token = '${value{n}}'
    const valueModifier = (value: unknown) => value?.toString?.()?.trim() ?? ''
    for (let i = 0; i < values.length; i++) {
      if (i === 0) {
        text = text.replace(token.replace('{n}', ''), valueModifier(values[0])) //may also be ${value} (without number)
      }
      text = text.replace(token.replace('{n}', (i + 1).toString()), valueModifier(values[i]))
    }

    return text.replace(/\$\{value.*\}/g, '')
  },
  /**
   *
   * @param items list of items to filter uniques from
   * @param keyExtractor function used to extract value for comparison (id, code, name, etc...)
   * @returns list of unique items
   */
  keepUniques<T>(items: T[] | undefined, keyExtractor: (item: T) => unknown, modifier?: (item: T) => T) {
    if (!items) return []
    return items
      .filter((filterItem, index) => index === items.findIndex(findItem => keyExtractor(findItem) === keyExtractor(filterItem)))
      .map(item => (modifier ? modifier(item) : item))
  },
  join<T>(items: T[] | undefined, nameExtractor: (item: T) => string, separator?: string) {
    if (!items || items.length === 0) return ''
    return items.map(item => nameExtractor(item)).join(separator ?? ', ')
  },
  getUid() {
    return uuid.v4().toString()
  },
  sum<T>(items: T[] | undefined, quantityExtractor?: (item: T) => number) {
    if (!items) return 0
    return items.reduce((total, item) => total + (quantityExtractor ? quantityExtractor(item) : Number(item)), 0)
  },
  getValueIfAllAreEqual<T>(items: T[] | undefined, valueExtractor: (item: T) => unknown): T | undefined {
    if (!items || items.length === 0 || !valueExtractor) return undefined
    const firstValue = valueExtractor(items[0])
    if (items.find(item => valueExtractor(item) !== firstValue)) return undefined
    return items[0]
  },
  hash(input: unknown) {
    return hash(input ?? {})
  },
  to1DList<TIn, TOut>(list: TIn[] | undefined, extractor?: (listItem: TIn) => TOut[]): TOut[] {
    if (!list) return []
    const extractArray = (item: TIn) => (extractor ? extractor(item) : (item as TOut[]))
    return list.reduce<TOut[]>((newList, current) => [...newList, ...extractArray(current)], [])
  },
}

export function cancellablePromise<T>(promise: Promise<T | void>, _id: string) {
  const isCancelled = { value: false }
  const wrappedPromise = new Promise((res, rej) => {
    promise
      .then(d => {
        return isCancelled.value ? rej('cancelled') : res(d)
      })
      .catch(e => {
        rej(isCancelled.value ? 'cancelled' : e)
      })
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true
    },
  }
}

export function timeoutPromise<T>(promise: Promise<T>, timeout: number) {
  const promiseWithTimeout = new Promise<T>((_, reject) => {
    setTimeout(() => {
      reject('timeout')
    }, timeout)
  })
  return Promise.race([promise, promiseWithTimeout])
}
