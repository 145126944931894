import AsyncStorage from '@react-native-async-storage/async-storage'

export default function useAsyncStorage(key: string) {
  return {
    save: async <T = object,>(value: T) => {
      try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(key, jsonValue)
      } catch (e) {
        console.error(e)
      }
    },
    // @ts-ignore todo
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    load: async function <T = any>() {
      try {
        const jsonValue = await AsyncStorage.getItem(key)
        if (jsonValue != null) return JSON.parse(jsonValue) as T
        else return null
      } catch (e) {
        console.error(e)
      }
    },
    remove: async () => {
      try {
        await AsyncStorage.removeItem(key)
      } catch (e) {
        console.error(e)
      }
    },
  }
}
