import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { OngoingInventoryToConfirm } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import OngoingInventoryToConfirmListView from '../../views/Inventory/OngoingInventoryToConfirmListView'

export default function OngoingInventoryRecountingListScreen() {
  const navigation = useNavigation()
  function setOngoingInventoryToConfirm(selectedOngoingInventoryToConfirm: OngoingInventoryToConfirm) {
    if (selectedOngoingInventoryToConfirm) {
      navigation.navigate(ScreenType.OngoingInventoryCreation, { ongoingInventoryToConfirm: selectedOngoingInventoryToConfirm })
    }
  }

  return (
    <MWS_Screen>
      <OngoingInventoryToConfirmListView onOngoingInventoryToConfirmSelected={setOngoingInventoryToConfirm} />
    </MWS_Screen>
  )
}
