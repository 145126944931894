import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef } from 'react'
import { ScrollView, StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Collo, Deposit, PackagingType, StockPosition } from '../../apis/apiTypes'
import Picker from '../../components/MWS/Inputs/Picker'
import QuantityInput from '../../components/MWS/Inputs/QuantityInput'
import StockPositionSelector from '../../components/MWS/StockPosition/StockPositionSelector'
import Separator from '../../components/Separator'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import colloUtils from '../../utils/colloUtils'

export type ColloPackingSelection = {
  id?: string
  collo?: Collo
  quantity?: number
  weight?: number
  length?: number
  width?: number
  height?: number
  packagingType?: PackagingType
  targetStockPositions?: StockPosition
}

export type ColloCompletionViewProps = {
  colloPacking: ColloPackingSelection
  setColloPacking: React.Dispatch<React.SetStateAction<ColloPackingSelection>>
  showQuantityInput?: boolean
  stockPositionSelectionActive?: boolean
  deposit?: Deposit
  style?: StyleProp<ViewStyle>
  focusWeightInput?: boolean
}

export default function ColloPackingView({
  colloPacking,
  setColloPacking,
  showQuantityInput,
  stockPositionSelectionActive,
  deposit,
  style,
  focusWeightInput,
}: ColloCompletionViewProps) {
  const { i18n } = useLanguage()

  const [types, loadTypes, loadingTypes] = useApi(api.getPackagingType, [])
  const packagingType = colloPacking.packagingType
  const quantity = colloPacking.quantity ?? 0
  const weight = colloPacking.weight ?? 0
  const length = colloPacking.length ?? 0
  const width = colloPacking.width ?? 0
  const height = colloPacking.height ?? 0
  const targetStockPositions = colloPacking?.targetStockPositions

  const weightInputRef = useRef<TextInput | null>(null)

  useEffect(() => {
    loadTypes({}, result => result.sort(colloUtils.sortPackagingTypes))
      .then(result => {
        if (!result.length || !colloPacking || colloPacking.packagingType) return
        handlePackagingTypeSelection(result[0])
      })
      .catch(console.error)
  }, [])

  function handlePackagingTypeSelection(selectedType: PackagingType) {
    setColloPacking(prev => ({
      ...prev,
      packagingType: selectedType,
      width: selectedType.width ?? prev.width ?? 0,
      length: selectedType.length ?? prev.length ?? 0,
      height: selectedType.height ?? prev.height ?? 0,
    }))
  }

  function handelPickerModalHide() {
    if (!colloPacking?.packagingType || !focusWeightInput) return
    if (weightInputRef.current) {
      setTimeout(() => {
        weightInputRef.current?.focus()
      }, 100)
    }
  }

  return (
    <View style={style}>
      <ScrollView>
        <Picker<PackagingType>
          text={i18n.t('Type')}
          modalTitle={i18n.t('SelectPackagingType')}
          defaultValue={packagingType}
          values={types ?? []}
          captionExtractor={colloUtils.getPackagingTypeTitle}
          onSubmit={handlePackagingTypeSelection}
          style={[styles.input, { marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }]}
          loading={loadingTypes}
          onModalHide={handelPickerModalHide}
        />
        <QuantityInput
          inputRef={weightInputRef}
          text={i18n.t('Weight')}
          // unit={i18n.t('WeightUnit')}
          value={weight}
          onValueChanged={changedWeight => setColloPacking(prev => ({ ...prev, weight: changedWeight }))}
          placeHolder={''}
          style={styles.input}
        />
        <QuantityInput
          text={i18n.t('Length')}
          // unit={i18n.t('LengthUnitM')}
          value={length}
          onValueChanged={changedLength => setColloPacking(prev => ({ ...prev, length: changedLength }))}
          placeHolder={''}
          style={styles.input}
        />
        <QuantityInput
          text={i18n.t('Width')}
          // unit={i18n.t('LengthUnitM')}
          value={width}
          onValueChanged={changedWidth => setColloPacking(prev => ({ ...prev, width: changedWidth }))}
          placeHolder={''}
          style={styles.input}
        />
        <QuantityInput
          text={i18n.t('Height')}
          // unit={i18n.t('LengthUnitM')}
          value={height}
          onValueChanged={changedHeight => setColloPacking(prev => ({ ...prev, height: changedHeight }))}
          placeHolder={''}
          style={styles.input}
        />
        {showQuantityInput && (
          <>
            <Separator />
            <QuantityInput
              text={i18n.t('Amount')}
              // unit={i18n.t('PiecesShort')}
              value={quantity}
              onValueChanged={changedQuantity => setColloPacking(prev => ({ ...prev, quantity: changedQuantity }))}
              placeHolder={''}
              style={styles.input}
            />
          </>
        )}
        {!!stockPositionSelectionActive && (
          <StockPositionSelector
            title={i18n.t('TransferTo')}
            deposit={deposit}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            stockPosition={targetStockPositions}
            onSelected={selectedTargetStockPositions => setColloPacking(prev => ({ ...prev, targetStockPositions: selectedTargetStockPositions }))}
            showListLoader
          />
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  input: { marginBottom: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS },
  content: {},
})
