import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Customer, Deposit, SerialNumber, StockPositionAvailability } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import CustomerCard from '../../cards/ShoppingCart/CustomerCard'
import DepositCard from '../../cards/StockPosition/DepositCard'
import { Article } from '../../classes/Article'
import Separator from '../../components/Separator'

interface ShoppingCartInfoViewProps {
  customer: Customer
  article: Article
  serialNumber: SerialNumber | undefined
  deposit: Deposit | undefined
  stockPosition?: StockPositionAvailability
  selectedAmount?: number
  style?: StyleProp<ViewStyle>
}

export default function ShoppingCartInfoView(props: ShoppingCartInfoViewProps) {
  const totalQuantity = props.stockPosition?.quantity ?? props.article.getNetQuantity() ?? undefined

  return (
    <>
      <CustomerCard customer={props.customer} />
      <Separator />
      <ArticleCard
        article={props.article.info}
        quantity={totalQuantity}
        serialNumber={props.serialNumber}
        unit={props.article.getUsedUnit()}
        price={props.article.getPriceText(false)}
        navigateToArticleInfoOnPress
        priceScale={true}
        customerId={props.customer?.id}
      />
      <DepositCard deposit={props.deposit} />
    </>
  )
}
