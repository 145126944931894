import React from 'react'

import { SerialNumber } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import useRouteParam from '../../hooks/useRouteParam'
import SerialnumberInputMacAddressView from '../../views/SerialNumber/SerialnumberInputMacAddressView'

export default function SerialnumberMacAddressScreen() {
  const serialnumber = useRouteParam<SerialNumber>('serialnumber')

  return <MWS_Screen>{serialnumber && <SerialnumberInputMacAddressView serialnumber={serialnumber} />}</MWS_Screen>
}
