import { Layout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { View as DefaultView, StyleSheet } from 'react-native'

import { Colors } from '../../constants/Colors'

export type CardBasicProps = DefaultView['props']

export default function CardBasic(props: CardBasicProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { style, ...otherProps } = props

  return (
    <DefaultView
      style={[
        Layout.defaultComponentsStyles,
        styles.input,
        {
          backgroundColor: theme.card,
          borderColor: theme.inputBorder,
        },
        style,
      ]}
      {...otherProps}
    />
  )
}

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 10,
    padding: 12,
    shadowColor: '#999999',
    shadowOpacity: 0.2,
  },
})
