import { useAuthentication } from '@infominds/react-native-license'
import { useEffect, useMemo, useState } from 'react'

import { CONSTANTS } from '../constants/Constants'
import useAsyncStorage from './useAsyncStorage'

export type HistoryEntryKey = ''

export interface HistoryEntry<T> {
  key: string
  item: T
}

export default function useInputHistory<T>(historyKey: string, limitHistoryEntryCount?: number) {
  const { company, name } = useAuthentication()
  const [history, setHistory] = useState<HistoryEntry<T>[]>([])
  const storageKey = `MWSInputHistory[${historyKey}-${company ?? ''}-${(name ?? '').replaceAll(' ', '')}]`
  const storage = useAsyncStorage(storageKey)
  const maxHistoryEntries = useMemo(() => limitHistoryEntryCount ?? CONSTANTS.HISTORY_ENTRIES, [limitHistoryEntryCount])

  useEffect(() => {
    load().catch(console.error)
  }, [historyKey])

  async function load() {
    const value = (await storage.load()) as unknown
    if (value && typeof value === 'object' && Array.isArray(value)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const hist = value.filter(h => !!h.key && !!h.item)
      setHistory(hist)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return [...hist].reverse() as HistoryEntry<T>[]
    } else {
      setHistory([])
      return [] as HistoryEntry<T>[]
    }
  }

  function add(key: string, item: T) {
    if (!key || !item) return
    try {
      setHistory(prevHistory => {
        const newHist = [{ item: item, key: key }, ...prevHistory.filter(h => h.key !== key)].slice(0, maxHistoryEntries)

        storage.save(newHist).catch(console.error)
        return newHist
      })
    } catch (e) {
      console.error(e)
    }
  }

  function addMultiple(items: HistoryEntry<T>[]) {
    if (!items.length) return
    try {
      setHistory(prevHistory => {
        let newHist = [...prevHistory]
        items.forEach(({ key, item }) => {
          newHist = newHist.filter(h => h.key !== key)
          newHist.unshift({ item: item, key: key })
        })
        newHist = newHist.slice(0, maxHistoryEntries)
        storage.save(newHist).catch(console.error)
        return newHist
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    add,
    addMultiple,
    load: load,
    get: () => {
      return [...history]
    },
    history,
  }
}
