import { useLanguage, Utils } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'

import api from '../../apis/apiCalls'
import { Collo, PackingList, PackingListMovement, UserSettings } from '../../apis/apiTypes'
import { PackingListArticle } from '../../classes/PackingListCollection'
import useColloSelector, { ColloSelector } from '../../hooks/packingLists/useColloSelector'
import useAlert from '../../hooks/useAlert'
import { Error_Utils } from '../../utils/ErrorUtils'
import packingListUtils from '../../utils/packingListUtils'
import { useLoadingIndicator } from '../LoadingIndicatorContext'
import { useUserSettings } from '../UserSettingsContext'

export type PackingListContextType = {
  packingLists: PackingList[] | undefined
  movements: PackingListArticle[] | undefined
  isPreConsignment: boolean
  isProductionConsignment: boolean
  loadPackingListMovements: (
    packingLists: PackingList[],
    isPreConsignment: boolean,
    isProductionConsignment: boolean,
    abortController?: AbortController
  ) => Promise<PackingListArticle[]>
  reloadPackingListMovements: (invisibleLoading?: boolean, abortController?: AbortController) => Promise<PackingListArticle[]>
  userSettings?: UserSettings
  forceScan: boolean
  colloMode: boolean
  activeColloSelector: ColloSelector
  removeCollos: (collosToDelete: Collo[] | undefined) => void
  updateCollosOf: (updatePackingListArticle: PackingListArticle | undefined) => Promise<boolean>
  ignoreHistoricalSN: boolean
  multiPackingListConsignment: boolean
}

export const PackingListContext = createContext<PackingListContextType | null>(null)

export type PackingListContextProviderProps = PropsWithChildren

export function PackingListContextProvider({ children }: PackingListContextProviderProps) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const loader = useLoadingIndicator()
  const userSettings = useUserSettings()
  const forceScan = !!userSettings?.isScanningArticleAndStockpositionMandatoryOnPicking
  const [packingLists, setPackingLists] = useState<PackingList[]>([])

  const [isPreConsignment, setIsPreConsignment] = useState(false)
  const [isProductionConsignment, setIsProductionConsignment] = useState(false)
  const colloMode = (!!userSettings?.isPalletPickingActive && !isProductionConsignment) || isPreConsignment
  const [movements, setMovements] = useState<PackingListArticle[]>([])

  const activeColloSelector = useColloSelector(isPreConsignment, colloMode)

  useEffect(() => {
    if (!colloMode) return
    activeColloSelector.updateCollosFromMovements(packingLists, movements).catch(console.error)
  }, [movements])

  const ignoreHistoricalSN = useMemo(() => !!packingLists?.find(pl => !!pl.internalPackinglist), [packingLists])
  const multiPackingListConsignment = packingLists.length > 1

  const loadPackingListMovements: PackingListContextType['loadPackingListMovements'] = async (
    packingListsIn,
    isPreConsignmentIn,
    isProductionConsignmentIn,
    abortController
  ) => {
    if (!packingListsIn) throw new Error('No packing list selected')
    loader.setLoading(true)
    setIsPreConsignment(isPreConsignmentIn)
    setIsProductionConsignment(isProductionConsignmentIn)
    setPackingLists(packingListsIn)
    return load(packingListsIn, abortController)
  }

  async function load(packingListsToLoad: PackingList[], abortController?: AbortController) {
    try {
      const result = await api.getPackingListMovements({ packingListIds: packingListsToLoad.map(pl => pl.id) }, abortController)

      if (!result || abortController?.signal.aborted) return []

      const newMovements = (groupMovementsByArticle(result, packingListsToLoad) ?? []).sort(packingListUtils.sortMovementBySortKey)
      setMovements(newMovements)
      return newMovements
    } catch (error) {
      if (abortController?.signal.aborted) return []
      console.error(error)

      alert.error(
        i18n.t('FailedToLoadPackingListMovements'),
        packingListUtils.getPackingListsTitle(packingListsToLoad),
        Error_Utils.extractErrorMessageFromException(error)
      )
      throw error
    } finally {
      loader.setLoading(false)
    }
  }

  const reloadPackingListMovements: PackingListContextType['reloadPackingListMovements'] = async (invisibleLoading, abortController) => {
    if (!packingLists.length) throw new Error('No packing list selected')
    if (!invisibleLoading) loader.setLoading(true)

    return load(packingLists, abortController)
  }

  async function updateCollosOf(updatePackingListArticle: PackingListArticle | undefined) {
    if (!updatePackingListArticle) return false
    try {
      // reload collos for every sub movement
      const updatedCollos = await Promise.all(
        updatePackingListArticle.movements.map(m =>
          api.getCollo({
            packingListIds: [m.movement.packinglistId ?? ''],
            packingListMovementId: m.movement.id ?? '',
            packingListMovementPartId: m.movement.packinglistmovementpartId,
          })
        )
      )
      updatedCollos.forEach((collos, index) => {
        setMovements(prevMovements => {
          const result = prevMovements.map(prevMovement => {
            if (prevMovement.id !== updatePackingListArticle.id) return prevMovement

            prevMovement.movements = prevMovement.movements.map(element => {
              if (element.movement.id !== updatePackingListArticle.movements[index].movement.id) return element

              element.movement.collonumbers = collos.filter(
                c =>
                  c.packinglistmovementpartId === element?.movement.packinglistmovementpartId ||
                  (!c.packinglistmovementpartId && !element?.movement.packinglistmovementpartId)
              )

              element.movement.quantityPicked = Utils.sum(collos, c => c.quantity)

              return { ...element }
            })

            return { ...prevMovement }
          })

          return [...result]
        })
      })
      setMovements(prev => prev.sort(packingListUtils.sortMovementBySortKey))
      return true
    } catch (err) {
      console.error(err)
    }
    return false
  }

  function removeCollos(collosToDelete: Collo[] | undefined) {
    if (!collosToDelete) return
    setMovements(prev => {
      prev.forEach(article => {
        article.movements = article.movements.map(movement => {
          movement.movement.collonumbers = (movement.movement.collonumbers ?? []).filter(c => !collosToDelete.find(ctd => ctd.id === c.id))
          movement.movement.quantityPicked = Utils.sum(movement.movement.collonumbers, c => c.quantity)
          return { ...movement }
        })
      })
      return [...prev].sort(packingListUtils.sortMovementBySortKey)
    })
  }

  return (
    <PackingListContext.Provider
      value={{
        packingLists,
        movements,
        isPreConsignment,
        isProductionConsignment,
        loadPackingListMovements: loadPackingListMovements,
        reloadPackingListMovements: reloadPackingListMovements,
        userSettings,
        forceScan,
        colloMode,
        removeCollos,
        updateCollosOf,
        ignoreHistoricalSN,
        multiPackingListConsignment,
        activeColloSelector,
      }}>
      {children}
    </PackingListContext.Provider>
  )
}

function groupMovementsByArticle(movementsToGroup: PackingListMovement[] | undefined, packingLists: PackingList[] | undefined, dontGroup?: boolean) {
  if (!movementsToGroup || !packingLists) return undefined
  const movements: PackingListArticle[] = []
  for (const movementToAdd of movementsToGroup) {
    const foundPackingList = packingLists.find(pl => pl.id === movementToAdd.packinglistId)
    if (!foundPackingList) {
      console.error('No packingList found for packingListMovement', movementToAdd)
      continue
    }

    if (!dontGroup) {
      const foundArticle = movements.find(m => packingListUtils.movementsAreJoinable(m.movements[0].movement, movementToAdd))
      if (foundArticle) {
        foundArticle.movements.push({ movement: movementToAdd, packingList: foundPackingList })
        continue
      }
    }
    movements.push({
      id: Utils.getUid(),
      article: movementToAdd.article,
      movements: [{ movement: movementToAdd, packingList: foundPackingList }],
    })
  }
  return movements
}

export function usePackingList() {
  const context = useContext(PackingListContext)
  if (!context) {
    throw new Error('usePackingList must be used within a PackingListContextProvider')
  }
  return context
}
