import { BarCode, ScannerModal, useBarcodeScanner } from '@infominds/react-native-barcode-scanner'
import { useLanguage } from '@infominds/react-native-components'
import { Locale } from '@infominds/react-native-license'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { Keyboard, Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import BaseTextInput from '../../BaseTextInput'
import IMIcon from '../../IMIcon'
import CardBasic from '../../old/CardBasic'
import LoadingIndicator from '../../old/LoadingIndicator'

interface ScannerInputProps {
  useInput: (input: ScannerInputResult) => void
  showList?: () => void
  style?: StyleProp<ViewStyle>
  hideButton?: boolean
  onClearPressed?: () => void
  clearOnReFocus?: boolean
  placeHolder?: string
  denyTextInput?: boolean
  showListLoader?: boolean
}

export interface ScannerInputResult {
  textInput?: string
  scannerInput?: string
}

export default function ScannerInput(props: ScannerInputProps) {
  const { i18n, language } = useLanguage()
  const isFocused = useIsFocused()
  const [value, setValue] = useState<string>('')
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false)
  const [zebraScannerResult, setZebraScannerResult] = useState<BarCode | undefined>() //used to sync result. since useBarCodeScanner is async
  const scanPossible = useMemo(() => Platform.OS === 'android' || Platform.OS === 'ios', [])
  // This is the PDA zebra scanner
  const barcodeScanner = useBarcodeScanner(setZebraScannerResult)

  useEffect(() => {
    if (!zebraScannerResult) return
    onResult(zebraScannerResult)
  }, [zebraScannerResult])

  useEffect(() => {
    if (props.clearOnReFocus && isFocused) {
      setValue('')
      if (props.onClearPressed) props.onClearPressed()
    }
  }, [isFocused])

  function onResult(result: BarCode) {
    setPopUpVisible(false)
    if (result?.barCode) {
      props.useInput({ scannerInput: result?.barCode?.trim() ?? '' })
    }
  }

  function accept() {
    if (value) {
      Keyboard.dismiss()
      props.useInput({ textInput: value.trim() })
      setValue('')
    }
  }

  function handleScannerButtonPressed() {
    if (barcodeScanner?.isScannerAvailable()) {
      barcodeScanner?.toggleBarcodeScanner()
    } else {
      setPopUpVisible(true)
    }
  }

  return (
    <View style={[styles.main, props.style]}>
      {/* This is the camera scanner */}
      <ScannerModal
        isVisible={popUpVisible}
        onClose={() => setPopUpVisible(false)}
        onScanned={barCodes => onResult({ rawValue: barCodes[0], barCode: barCodes[0] })}
        languageCode={language as Locale}
      />
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={{ flexDirection: 'row' }} disabled={!scanPossible} onPress={handleScannerButtonPressed}>
          <CardBasic style={[styles.sideButtons, styles.noBorderRight]}>
            <IMIcon style={styles.iconLeft} size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE} icon="barcode-read" color={Colors.white} />
          </CardBasic>
        </TouchableOpacity>
        <BaseTextInput
          viewStyle={[{ flex: 1 }, styles.noBorderLeft, styles.noBorderRight]}
          placeholder={props.placeHolder ?? i18n.t('SEARCH')}
          value={value}
          onChangeText={setValue}
          onClearPressed={() => {
            setValue('')
            if (props.onClearPressed) props.onClearPressed()
          }}
          onSubmitEditing={accept}
          editable={!props.denyTextInput}
          selectTextOnFocus
        />
        <TouchableOpacity
          style={{ flexDirection: 'row' }}
          onPress={() => (!value && props.showList ? props.showList() : accept())}
          disabled={!props.showList && !value}>
          <CardBasic style={[styles.sideButtons, styles.noBorderLeft]}>
            {!!props.showListLoader && (
              <View style={[styles.listLoader]}>
                <LoadingIndicator isVisible color={'white'} size={20} />
              </View>
            )}
            {!props.showListLoader && (
              <IMIcon style={[styles.iconRight]} size={20} icon={props.showList && !value ? 'list-ul' : 'search'} color={Colors.white} />
            )}
          </CardBasic>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  sideButtons: {
    margin: 0,
    backgroundColor: Colors.tint,
    justifyContent: 'center',
  },
  iconLeft: {
    padding: 2,
  },
  iconRight: {
    padding: 4,
  },
  listLoader: {
    padding: 4,
  },
})
