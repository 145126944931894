import { useLanguage } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { StyleProp, StyleSheet, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from 'react-native'

import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import screenUtils from '../../utils/ScreenUtils'
import IMIcon from '../IMIcon'
import { IMIconName } from '../IMIconNames'
import Text from '../old/Text'
import SettingsModal from '../SettingsModal'

export interface MWSHeaderProps {
  title?: string
  style?: StyleProp<ViewStyle>
  preNavigationCallback?: (type: 'goBack' | 'popToTop') => boolean | Promise<boolean | undefined> //return true to indicate navigation was already handled
  noNavigation?: boolean
  hideGoBackButton?: boolean
  hideCloseButton?: boolean
  hideSettingsButton?: boolean
  onSettingsChanged?: () => void
  onSettingsClosed?: () => void
  onGoBack?: () => void
  onClose?: () => void
  closeIcon?: IMIconName
}

export default function MWS_Header(props: MWSHeaderProps) {
  const { i18n } = useLanguage()

  const navigation = useNavigation()
  const route = useRoute()
  const canGoBack = useMemo(() => navigation.canGoBack(), []) || !!props.onGoBack
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const textColor = Colors.white

  const showNavigationButtons = canGoBack && (!props.noNavigation || !!props.onGoBack)
  const showLeftNavigationButtons = canGoBack && !props.noNavigation && !props.hideGoBackButton

  function preNavigationCallback(type: 'goBack' | 'popToTop') {
    return new Promise<boolean>(resolve => {
      if (props.preNavigationCallback) {
        Promise.resolve(props.preNavigationCallback(type))
          .then(result => {
            resolve(!!result)
          })
          .catch(console.error)
      } else {
        resolve(false)
      }
    })
  }

  return (
    <View style={[styles.main, props.style]}>
      <SettingsModal
        onSettingsChange={props.onSettingsChanged}
        isVisible={showSettingsModal}
        close={() => {
          setShowSettingsModal(false)
          if (props.onSettingsClosed) props.onSettingsClosed()
        }}
      />
      <IconButton
        icon={'arrow-left'}
        disabled={!showLeftNavigationButtons}
        iconColor={showLeftNavigationButtons ? textColor : Colors.tint} //hack to always center title
        onPress={() => {
          preNavigationCallback('goBack')
            .then(result => {
              if (!result) navigation.goBack()
            })
            .catch(console.error)
        }}
        style={styles.goBackButtonView}
      />

      <View style={styles.titleContainer}>
        <Text numberOfLines={1} style={{ flex: 1, fontSize: 20, margin: 0, padding: 0, textAlign: 'center', color: textColor }}>
          {/* @ts-ignore: ok */}
          {props.title ?? (i18n.t(`${route.name}`) || i18n.t(`${route.name.toUpperCase()}`) || route.name)}
        </Text>
      </View>
      {screenUtils.showSettings(screenUtils.getScreenType(route.name)) && !props.hideSettingsButton ? (
        <IconButton icon={'cog'} iconColor={textColor} onPress={() => setShowSettingsModal(true)} style={styles.rightButtonView} />
      ) : (
        <>
          {((showNavigationButtons && !props.hideCloseButton) || props.onClose) && (
            <IconButton
              icon={props.closeIcon ?? 'times'}
              iconColor={textColor}
              onPress={() => {
                if (props.onClose) {
                  props.onClose()
                  return
                }
                preNavigationCallback('popToTop')
                  .then(result => {
                    if (!result) navigation.navigate('Root')
                  })
                  .catch(console.error)
              }}
              style={styles.rightButtonView}
            />
          )}
        </>
      )}
    </View>
  )
}

function IconButton(props: { icon: IMIconName; iconColor?: string } & TouchableOpacityProps) {
  const { icon, iconColor, ...touchableProps } = props

  return (
    <TouchableOpacity {...touchableProps}>
      <IMIcon size={STYLE_CONSTANTS.DEFAULT_HEADER_ICON_SIZE} icon={icon} color={iconColor} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  main: {
    justifyContent: 'space-between',
    alignContent: 'center',
    flexDirection: 'row',
    zIndex: 10,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  goBackButtonView: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    paddingVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  rightButtonView: {
    justifyContent: 'center',

    paddingHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    paddingVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
})
