import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, StyleProp, View, ViewStyle } from 'react-native'

import { ArticleInfoPrice, SerialNumber } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ArticlePriceCard from '../../cards/Article/ArticlePriceCard'
import { Article } from '../../classes/Article'
import TextRow from '../../components/TextRow'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import useModalController from '../../hooks/useModalController'
import ImageModal from '../../modals/ImageModal'

interface ArticleInfoProps {
  article: Article
  prices?: ArticleInfoPrice[]
  showUnit?: boolean
  style?: StyleProp<ViewStyle>
  limitDescription?: boolean
  serialNumber?: SerialNumber
  imageUri?: string
  configurableDescription?: string
}

export default function ArticleInfoView(props: ArticleInfoProps) {
  const { i18n } = useLanguage()

  const unitText = props.showUnit && props.article.getUsedUnitText()
  const imgModal = useModalController<string>()

  return (
    <>
      <ArticleCard
        article={props.article.info}
        addInfo={unitText ? `${i18n.t('Unit')}: ${unitText}` : undefined}
        style={[{ marginBottom: 0 }, props.style]}
        maxLinesDescription={props.limitDescription ? 5 : 0}
        serialNumber={props.serialNumber}
        endButton={props.imageUri ? { icon: 'image', onPress: () => imgModal.show(props.imageUri) } : undefined}
        configurableDescription={props.configurableDescription}
      />
      {props.prices && props.prices.length > 0 && (
        <>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
              marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN + 21,
            }}>
            <TextRow
              textStyle={{ margin: 0 }}
              texts={[
                { text: i18n.t('PRICEKEY'), style: { textAlign: 'left', fontWeight: 'bold' } },
                { text: i18n.t('VATINCLUDED'), style: { textAlign: 'center', fontWeight: 'bold' } },
                { text: i18n.t('PRICE'), style: { textAlign: 'right', fontWeight: 'bold' } },
              ]}
            />
          </View>
          <FlatList data={props.prices} renderItem={({ item }) => <ArticlePriceCard price={item} />} />
        </>
      )}
      <ImageModal controller={imgModal} title={props.article.getTitle()} />
    </>
  )
}
