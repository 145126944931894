import React from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'

import Text, { TextProps } from './old/Text'
import Separator from './Separator'

export interface TextRowEntry {
  text: string
  flexShare?: number
  style?: StyleProp<TextStyle>
  textProps?: TextProps
}

export default function TextRow(props: {
  texts: TextRowEntry[]
  style?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  separator?: boolean
  separatorStyle?: StyleProp<ViewStyle>
  textProps?: TextProps
}) {
  return (
    <View style={[props.style]}>
      <View style={{ flexDirection: 'row' }}>
        {props.texts.map((t, i) => (
          <Text
            key={'TextRow' + i.toString()}
            {...props.textProps}
            {...t.textProps}
            style={[{ flex: t.flexShare || 1, textAlign: 'left' }, t.style, props.textStyle]}>
            {t.text}
          </Text>
        ))}
      </View>
      {props.separator && <Separator style={props.separatorStyle} />}
    </View>
  )
}
