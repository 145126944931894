import { useApi } from '@infominds/react-api'
import { useItemSelector, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FlatList } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import DepositCard from '../../cards/StockPosition/DepositCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import { Article } from '../../classes/Article'
import FullScreenModal from '../../components/FullScreenModal'
import MultiLevelSelector from '../../components/MultiLevelSelector'
import NoEntriesTag from '../../components/NoEntriesTag'
import Button from '../../components/old/Button'
import { ModalController } from '../../hooks/useModalController'
import { depositUtils } from '../../utils/depositUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'

export type SerialNumberAvailabilitySelectionModalProps = {
  controller: ModalController
  article: Article
  serialNumber?: SerialNumber
  preSelectedDeposit?: Deposit
  preSelectedStocksPosition?: StockPosition
  considerPickedQuantity?: boolean

  filterSerialNumber?: (serialNumberAvailability: StockPositionAvailability) => boolean
  unitConversionFactor?: number
} & (
  | { onSelected: (serialNumberAvailability: StockPositionAvailability) => void; multiSelection?: false }
  | { onSelected: (serialNumberAvailability: StockPositionAvailability[]) => void; multiSelection: true }
)

type CachedResult = {
  cacheLevel: 'stockPosition' | 'deposit' | 'all'
  availability: StockPositionAvailability[] | undefined
}

export default function SerialNumberAvailabilitySelectionModal({
  controller,
  article,
  serialNumber,
  preSelectedDeposit,
  preSelectedStocksPosition,
  considerPickedQuantity,
  unitConversionFactor,
  multiSelection,
  onSelected,
  filterSerialNumber,
}: SerialNumberAvailabilitySelectionModalProps) {
  const { i18n } = useLanguage()

  const [selectedDeposit, setSelectedDeposit] = useState<Deposit | null>(preSelectedDeposit ?? null)
  const [selectedStockPosition, setSelectedStockPosition] = useState<StockPosition | null>(preSelectedStocksPosition ?? null)
  const [serialNumberAvailability, loadSerialNumberAvailability, loadingSerialNumberAvailability, setSerialNumberAvailability] = useApi(
    api.getSerialnumberQuantities,
    []
  )
  const cachedResult = useRef<CachedResult | null>(null)

  const showSerials = !loadingSerialNumberAvailability && !!selectedDeposit && (!selectedDeposit.isStockpositionActive || !!selectedStockPosition)

  const preSelectedDepositAvailability = useMemo(
    () =>
      preSelectedDeposit ? stockPositionUtils.createStockPositionAvailability(article.info, preSelectedDeposit, undefined, undefined) : undefined,
    [preSelectedDeposit]
  )
  const preSelectedStockPositionAvailability = useMemo(
    () =>
      preSelectedStocksPosition
        ? stockPositionUtils.createStockPositionAvailability(article.info, preSelectedDeposit, preSelectedStocksPosition, undefined)
        : undefined,
    [preSelectedDeposit]
  )

  const serialSelector = useItemSelector<StockPositionAvailability>([], stockPositionUtils.compareAvailability)

  useEffect(() => {
    if (!controller.isShown) return
    serialSelector.clear()
  }, [controller.isShown])

  async function loader(deposit: Deposit | undefined, stockPosition: StockPosition | undefined) {
    if (
      cachedResult.current?.availability &&
      (cachedResult.current.cacheLevel === 'all' ||
        (cachedResult.current.cacheLevel === 'deposit' && !!deposit) ||
        (cachedResult.current.cacheLevel === 'stockPosition' && !!stockPosition))
    ) {
      const filterResult = filterAvailability(
        cachedResult.current.availability.filter(
          a => (!deposit || deposit.id === a.deposit?.id) && (!stockPosition || stockPosition.id === a.stockposition?.id)
        )
      )
      setSerialNumberAvailability(filterResult)
      return filterResult
    }

    const availability = await loadSerialNumberAvailability(
      {
        articleId: article.info.id,
        serialnumberId: serialNumber?.id,
        depositId: deposit?.id,
        stockPositionId: stockPosition?.id,
        considerPickedQuantity: considerPickedQuantity,
        readQuantitiesForCalculationAvailability: true,
      },
      filterAvailability
    )
    cachedResult.current = { availability, cacheLevel: !deposit ? 'all' : !stockPosition ? 'deposit' : 'stockPosition' }
    return availability
  }

  async function loadAllAvailability() {
    const availability = await loader(undefined, undefined)

    return depositUtils.reduceStockPositionAvailability(availability).sort((a, b) => depositUtils.sort(a.deposit, b.deposit))
  }

  async function loadDepositAvailability(deposit: StockPositionAvailability | undefined, stockPosition?: StockPositionAvailability) {
    const availability = await loader(deposit?.deposit, stockPosition?.stockposition)
    return stockPositionUtils.reduceStockPositionAvailability(availability).sort((a, b) => stockPositionUtils.sort(a.stockposition, b.stockposition))
  }

  function filterAvailability(stockPositionAvailability: StockPositionAvailability[]) {
    stockPositionAvailability = stockPositionAvailability.filter(q => q.quantity && q.quantity > 0)
    if (!filterSerialNumber) return stockPositionAvailability
    return stockPositionAvailability.filter(filterSerialNumber)
  }

  function handlePositionChanged(depositChanged?: StockPositionAvailability, stockPositionChanged?: StockPositionAvailability) {
    setSelectedDeposit(depositChanged?.deposit ?? null)
    setSelectedStockPosition(stockPositionChanged?.stockposition ?? null)
    if (stockPositionChanged?.stockposition) loadDepositAvailability(depositChanged, stockPositionChanged).catch(console.error)
  }

  function convertQuantityToText(quantity: number | undefined) {
    if (!quantity) return undefined
    if (unitConversionFactor) {
      return article.getQuantityWithUnitText(quantity * unitConversionFactor, true)
    }
    return article.getQuantityWithUnitText(quantity)
  }

  const DepositRenderItem = useCallback(
    (depositToRender: StockPositionAvailability, isSelected: boolean) => (
      <DepositCard
        deposit={depositToRender.deposit}
        isMarked={isSelected ? 'times' : undefined}
        quantity={convertQuantityToText(depositToRender.quantity)}
      />
    ),
    [unitConversionFactor, article]
  )
  const StockPositionRenderItem = useCallback(
    (stockPosition: StockPositionAvailability, _deposit: StockPositionAvailability, isSelected: boolean) => (
      <StockPositionCard
        stockPosition={stockPosition.stockposition}
        isMarked={isSelected ? 'times' : undefined}
        quantity={convertQuantityToText(stockPosition.quantity)}
      />
    ),
    [unitConversionFactor, article]
  )

  const SerialNumberRenderItem = useCallback(
    ({ item }: { item: StockPositionAvailability }) => (
      <SerialnumberCard
        serialnumber={item.serialnumber}
        quantity={convertQuantityToText(item.quantityOrdered)}
        stockPosition={item.stockposition}
        isMarked={!!multiSelection && serialSelector.includes(item) && 'check'}
        onPress={() => {
          if (!multiSelection) {
            controller.close()
            onSelected(item)
          } else {
            serialSelector.add(item)
          }
        }}
      />
    ),
    [serialSelector.items, article]
  )

  return (
    <FullScreenModal isVisible={controller.isShown} close={controller.close} title={i18n.t('SelectSerialnumber')}>
      <MultiLevelSelector
        preselectedFirstLevelItem={preSelectedDepositAvailability}
        preSelectedSecondLevelItem={preSelectedStockPositionAvailability}
        loadFirstLevelItems={loadAllAvailability}
        firstLevelRenderItem={DepositRenderItem}
        loadSecondLevelItems={loadDepositAvailability}
        secondLevelRenderItem={StockPositionRenderItem}
        itemHasSecondLevel={deposit => !!deposit.deposit?.isStockpositionActive}
        onChanged={handlePositionChanged}
        loadingChildren={loadingSerialNumberAvailability}>
        {showSerials && (
          <FlatList
            data={serialNumberAvailability}
            renderItem={SerialNumberRenderItem}
            keyExtractor={serialnumberUtils.getKeyFromSerialNumberAvailability}
            ListFooterComponent={<NoEntriesTag text={i18n.t('SERIALNUMBER_NOT_AVAILABLE')} hide={!!serialNumberAvailability?.length} />}
          />
        )}
        {multiSelection && serialSelector.any && (
          <Button
            title={Utils.stringValueReplacer(i18n.t('SelectNSerialNumbers'), serialSelector.count)}
            onPress={() => {
              controller.close()
              onSelected(serialSelector.items)
            }}
          />
        )}
      </MultiLevelSelector>
    </FullScreenModal>
  )
}
