import { useMemo, useState } from 'react'

export default function useModal<T>(modal: (isShown: boolean, hide: () => void, item?: T) => JSX.Element) {
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState<T | undefined>()

  function show(useItem?: T) {
    setShowModal(true)
    setItem(useItem)
  }
  const hide = () => setShowModal(false)

  const Component = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (_props: {}) => modal(showModal, hide, item)
  }, [modal])

  return {
    Component,
    show,
    hide,
    isShown: showModal,
  }
}
