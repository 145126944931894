import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { FlatList } from 'react-native'

import { ArticleDepositQuantity, Deposit, StockPositionAvailability } from '../apis/apiTypes'
import StockPositionCard from '../cards/StockPosition/StockPositionCard'
import { Article } from '../classes/Article'
import FullScreenModal from '../components/FullScreenModal'
import IMRefreshControl from '../components/IMRefreshControl'
import ListSpacer from '../components/MWS/ListSpacer'
import Title from '../components/MWS/Title'
import NoEntriesTag from '../components/NoEntriesTag'
import DepositAvailabilitySelectorView from '../views/Deposit/DepositAvailabilitySelectorView'

export default function TransferPositionSelectionModal(props: {
  show: boolean
  article: Article | undefined
  articleAvailability: ArticleDepositQuantity[] | undefined
  close: () => void
  onSelected: (deposit: Deposit | undefined, stockPosition?: StockPositionAvailability, quantity?: number) => void
  loading?: boolean
  showQuantity?: boolean
  selectDepositCaption?: string
  selectStockPositionCaption?: string
}) {
  const { i18n } = useLanguage()

  const [deposit, setDeposit] = useState<ArticleDepositQuantity | undefined>()
  const depositList = useMemo(() => (props.articleAvailability ?? []).filter(item => !props.showQuantity || item.quantity > 0), [props.show])
  const serialNumbersOnDeposit = deposit?.stockpositionquantities ?? []

  function handleDepositSelected(selectedDeposit: ArticleDepositQuantity | undefined) {
    if (selectedDeposit) {
      if (!selectedDeposit.deposit.isStockpositionActive) {
        props.onSelected(selectedDeposit.deposit, undefined, selectedDeposit.quantity)
        return
      }
      if (selectedDeposit.stockpositionquantities.length === 1) {
        props.onSelected(selectedDeposit.deposit, selectedDeposit.stockpositionquantities[0], selectedDeposit.quantity)
        return
      }
    }
    setDeposit(selectedDeposit)
  }

  return (
    <FullScreenModal
      isVisible={props.show}
      close={props.close}
      title={deposit ? props.selectStockPositionCaption || i18n.t('SelectStockPosition') : props.selectDepositCaption || i18n.t('SelectDeposit')}>
      <DepositAvailabilitySelectorView item={deposit} setItem={handleDepositSelected} getItemList={() => depositList} article={props.article} />
      {deposit && (
        <>
          <Title>{props.selectStockPositionCaption || i18n.t('SelectStockPosition')}</Title>
          <FlatList
            data={serialNumbersOnDeposit}
            renderItem={({ item }) => (
              <StockPositionCard
                stockPosition={item.stockposition}
                deposit={deposit.deposit}
                quantity={props.showQuantity ? props.article?.getQuantityWithUnitText(item.quantity) : undefined}
                onPress={() => props.onSelected(deposit.deposit, item, item.quantity)}
              />
            )}
            ListFooterComponent={!serialNumbersOnDeposit || serialNumbersOnDeposit.length === 0 ? <NoEntriesTag /> : <ListSpacer />}
            refreshControl={<IMRefreshControl refreshing={!!props.loading} />}
          />
        </>
      )}
    </FullScreenModal>
  )
}
