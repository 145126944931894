import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import PositionCard from '../../cards/StockPosition/PositionCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import SerialNumberAvailabilitySelector from '../../components/MWS/Inputs/SerialNumberAvailabilitySelector'
import Title from '../../components/MWS/Title'
import TransferQuantitySelector from '../../components/MWS/Transfer/TransferQuantitySelector'
import { KanbanBox } from '../../types'
import stockPositionUtils from '../../utils/stockPositionUtils'
import StockPositionWithAvailabilitySelectionView from '../InputViews/StockPositionWithAvailabilitySelectionView'

export type KanbanInputViewProps = {
  box: KanbanBox | undefined
  setBox: (box: KanbanBox) => void
  kanbanStockPosition: StockPosition | undefined
  onSubmit: (value: number) => Promise<void>
  style?: StyleProp<ViewStyle>
}

export default function KanbanInputView({ box, setBox, kanbanStockPosition, style, onSubmit }: KanbanInputViewProps) {
  const { i18n } = useLanguage()

  const article = box?.article
  const filterStockPositions = useMemo(() => {
    const result: StockPosition[] = []
    if (kanbanStockPosition) result.push(kanbanStockPosition)
    if (box?.stockPositionProduction) result.push(box?.stockPositionProduction)
    return result
  }, [box?.stockPositionProduction, kanbanStockPosition])
  const allowEdit = !box?.transferResult
  const showStockPositionSelector = allowEdit && !box?.stockPositionAvailability
  const showSerialNumberSelector =
    allowEdit && !showStockPositionSelector && article?.isSerialNumberActive && !article.isHistoricalSerialNumber && !box.serialNumberAvailability
  const showQuantityInput = allowEdit && !showStockPositionSelector && !showSerialNumberSelector

  const stockPositionQuantityText = useMemo(() => {
    if (box?.quantity && !box.serialNumberAvailability) return article?.getQuantityWithUnitText(box?.quantity)
    if (box?.stockPositionAvailability?.quantity) return article?.getQuantityWithUnitText(box?.stockPositionAvailability?.quantity)
    return undefined
  }, [box?.stockPositionAvailability?.quantity, article])
  const serialNumberQuantityText = useMemo(() => {
    if (box?.quantity) return article?.getQuantityWithUnitText(box?.quantity)
    if (box?.serialNumberAvailability?.quantity) return article?.getQuantityWithUnitText(box?.serialNumberAvailability?.quantity)
    return undefined
  }, [box?.serialNumberAvailability?.quantity, article])
  const proposedQuantity = useMemo(() => {
    if (box?.minQuantityStockPosition) {
      const diff = Math.max(box?.minQuantityStockPosition - (box.article.quantity ?? 0), 0)
      if (box.serialNumberAvailability) return Math.max(Math.min(diff, box.serialNumberAvailability.quantity ?? 0), 0)
      if (box.stockPositionAvailability) return Math.max(Math.min(diff, box.stockPositionAvailability.quantity ?? 0), 0)
      return diff
    }

    return undefined
  }, [box])

  function updateStockPosition(selection: StockPositionAvailability | undefined) {
    if (!box) return
    if (!selection) {
      setBox({ ...box, stockPositionAvailability: undefined, serialNumberAvailability: undefined })
      return
    }

    setBox({ ...box, stockPositionAvailability: selection, quantity: undefined })
  }

  function updateSerialNumber(serialNumber: StockPositionAvailability | undefined) {
    if (!box) return
    setBox({ ...box, serialNumberAvailability: serialNumber, stockPositionAvailability: serialNumber ? serialNumber : box.stockPositionAvailability })
  }

  useEffect(() => {
    if (box?.stockPositionQuantities?.filter(q => q.quantity > 0 && q.stockposition).length === 1) {
      const stockPositionQuantity = box?.stockPositionQuantities?.find(q => q.quantity > 0 && q.stockposition)
      if (stockPositionQuantity) {
        api
          .getStockPositionAvailability({ stockPositionId: stockPositionQuantity.stockposition?.id })
          .then(result => {
            if (!result?.length) return
            const stockPositionAvailability = result.find(q => q.article.id === stockPositionQuantity.articleId)
            if (stockPositionAvailability) {
              updateStockPosition(
                stockPositionUtils.createStockPositionAvailability(
                  stockPositionAvailability.article,
                  stockPositionAvailability.deposit,
                  stockPositionAvailability.stockposition,
                  stockPositionAvailability.quantity,
                  undefined
                )
              )
            }
          })
          .catch(console.error)
      }
    }
  }, [])

  return (
    <View style={style}>
      <Title>{i18n.t('SELECTION')}</Title>
      {!!box?.stockPositionAvailability && (
        <PositionCard
          stockPosition={box.stockPositionAvailability.stockposition}
          deposit={box.stockPositionAvailability.deposit}
          onDelete={allowEdit && (() => updateStockPosition(undefined))}
          quantity={stockPositionQuantityText}
        />
      )}
      {!!box?.serialNumberAvailability && !!box.stockPositionAvailability && (
        <SerialnumberCard
          serialnumber={box.serialNumberAvailability.serialnumber}
          onDelete={allowEdit && (() => updateSerialNumber(undefined))}
          quantity={serialNumberQuantityText}
        />
      )}

      {showStockPositionSelector && (
        <View>
          {box?.stockPositionQuantityForKanbanTransfer?.stockposition && (
            <>
              <Title>{i18n.t('SUGGESTION')}</Title>
              <StockPositionCard stockPosition={box?.stockPositionQuantityForKanbanTransfer?.stockposition} />
            </>
          )}
          <StockPositionWithAvailabilitySelectionView
            article={article}
            showResultInModal
            deposit={kanbanStockPosition?.deposit}
            onSelected={updateStockPosition}
            filterStockPositions={filterStockPositions}
          />
        </View>
      )}
      {showSerialNumberSelector && (
        <View>
          <SerialNumberAvailabilitySelector
            article={article}
            onSelected={updateSerialNumber}
            deposit={box.stockPositionAvailability?.deposit}
            stockPosition={box.stockPositionAvailability?.stockposition}
            showResultInModal
            allowEmptyInput
            considerPickedQuantity={false}
            filterSerialNumber={sna => !sna.stockposition || !filterStockPositions.find(fs => fs.id === sna.stockposition?.id)}
          />
        </View>
      )}
      {showQuantityInput && (
        <TransferQuantitySelector
          article={article}
          onQuantitySubmitted={onSubmit}
          proposedQuantity={proposedQuantity}
          ignoreAvailability
          autoFocus={!proposedQuantity}
        />
      )}
    </View>
  )
}
