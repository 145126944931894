import { useApi } from '@infominds/react-api'
import { Language as IMLanguage, useLanguage, useTheme } from '@infominds/react-native-components'
import { Locale, useAuthentication } from '@infominds/react-native-license'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DevSettings, Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import DeviceInfo from 'react-native-device-info'

import packageJson from '../../package.json'
import api from '../apis/apiCalls'
import Button from '../components/old/Button'
import Input from '../components/old/Input'
import PickerModal from '../components/old/PicketModal'
import SwitchDarkMode from '../components/old/SwitchDarkMode'
import Text from '../components/old/Text'
import Separator from '../components/Separator'
import { Colors } from '../constants/Colors'
import { IMLayout } from '../constants/Styles'
import devEnvironments from '../devEnvironments'
import usePrinter from '../hooks/usePrinter'
import { Company, ScreenType } from '../types'

type Language = { label: string; value: IMLanguage }

export default function SettingsScreen(props: { onSettingsChange?: () => void; isSettingsOpen: boolean; closeSettings: () => void }) {
  const { companies: licenseCompanies, company: selectedCompany, license, url, name, updateToken } = useAuthentication()
  const { i18n, setLanguageAsync } = useLanguage()
  const { colorScheme, updateColorScheme } = useTheme()
  const navigation = useNavigation()

  const isDarkMode = colorScheme === 'dark'

  const [isEnabled, setIsEnabled] = useState(isDarkMode)
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.t('ID'))
  const [loadedCompanies, setLoadedCompanies] = useState<Company[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>(undefined)

  const [_, loadCompanies, loadingCompanies] = useApi(api.getCompanies, [])
  const [showPrinterSettings, setShowPrinterSettings] = useState(false)
  const printer = usePrinter()

  const originalCompanyId = useRef<string | undefined>(undefined)

  useEffect(() => {
    if (!props.isSettingsOpen) return
    printer
      .isEnabled()
      .then(setShowPrinterSettings)
      .catch(error => {
        console.error(error)
        setShowPrinterSettings(false)
      })
    setIsEnabled(isDarkMode)

    loadCompanies(undefined)
      .then((gotCompanies: Company[]) => {
        const filteredArray = gotCompanies.filter(value => licenseCompanies?.includes(value.id))
        setLoadedCompanies(filteredArray)

        if (filteredArray.length > 0 && filteredArray.find(el => el.id === selectedCompany)) {
          originalCompanyId.current = selectedCompany
          setSelectedCompanyId(selectedCompany)
        } else {
          console.error('Empty company array loaded from db')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [props.isSettingsOpen])

  function toggleSwitch() {
    setIsEnabled(previousState => !previousState)

    if (colorScheme === 'light') {
      updateColorScheme('dark')
    } else {
      updateColorScheme('light')
    }
  }

  const PickerLanguage = useCallback(() => {
    const languages: Language[] = [
      { label: 'Deutsch', value: 'de' },
      { label: 'Italiano', value: 'it' },
      { label: 'English', value: 'en' },
    ]

    return (
      <View style={IMLayout.flex.f1}>
        {__DEV__ ? ( //language is taken from radix user language
          <PickerModal
            value={() => languages.find(item => item.value === selectedLanguage)}
            onSelect={(language: Language) => {
              if (language) {
                setLanguageAsync(language.value)
                  .then(() => {
                    setSelectedLanguage(language.value)
                    updateToken(undefined, language.value as Locale)
                      .catch(console.error)
                      .finally(props.onSettingsChange)
                  })
                  .catch(console.error)
              }
            }}
            renderSelected={(item: { label: string }) => item?.label}
            renderItemContent={({ item }: { item: { label: string } }) => <Text>{item.label}</Text>}
            data={languages}
          />
        ) : (
          <Text style={{ textAlign: 'right' }}>{languages.find(item => item.value === selectedLanguage)?.label}</Text>
        )}
      </View>
    )
  }, [selectedLanguage])

  const PickerCompanies = useCallback(
    () => (
      <View style={{ flex: 1 }}>
        {loadingCompanies && (
          <View style={{ alignSelf: 'flex-end' }}>
            <Text>{i18n.t('LOADING')}</Text>
          </View>
        )}
        {!loadingCompanies && (
          <PickerModal
            value={loadedCompanies.find(el => el.id === selectedCompanyId)}
            onSelect={(value: Company) => {
              if (value) {
                setSelectedCompanyId(value.id)
                updateToken(value.id, undefined).catch(console.error).finally(props.onSettingsChange)
              }
            }}
            renderSelected={(item: Company) => item?.description}
            renderItemContent={({ item }: { item: Company }) => <Text>{item.description}</Text>}
            data={loadedCompanies}
          />
        )}
      </View>
    ),
    [loadingCompanies, loadedCompanies, selectedCompanyId]
  )

  return (
    <View style={{ margin: 0 }}>
      <TouchableOpacity>
        <TouchableWithoutFeedback>
          <View style={{ padding: 20 }}>
            {devEnvironments.enableIntroSlider && (
              <>
                <Button
                  title={'Show tutorial'}
                  style={{ backgroundColor: Colors.grey, margin: 0, marginBottom: 5, padding: 3 }}
                  onPress={() => {
                    AsyncStorage.removeItem('intro')
                      .then(() => {
                        if (Platform.OS === 'web') window.location.reload()
                        else DevSettings.reload()
                      })
                      .catch(console.error)
                  }}
                />
                <Separator style={{ marginVertical: 5 }} />
              </>
            )}

            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
              <Text>{i18n.t('SETTINGS_DARKMODE')}</Text>
              <SwitchDarkMode
                trackColor={{ true: Colors.tint }}
                style={{ alignSelf: 'flex-end' }}
                onValueChange={() => toggleSwitch()}
                value={isEnabled}
              />
            </View>

            <Separator style={{ marginVertical: 5 }} />

            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text>{i18n.t('SETTINGS_LANGUAGE_RADIX')}</Text>
              <PickerLanguage />
            </View>

            <Separator style={{ marginVertical: 5 }} />

            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text>{i18n.t('SETTINGS_MANDANT')}</Text>
              <PickerCompanies />
            </View>

            <Separator style={{ marginVertical: 5 }} />

            {showPrinterSettings && (
              <>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text>{i18n.t('PRINTER_NR')}</Text>
                  <Input
                    style={{ height: 40, textAlign: 'right' }}
                    value={printer.printerNumber ?? ''}
                    onChangeText={text => printer.setPrinterNumber(text)}
                    keyboardType="numeric"
                    selectTextOnFocus
                  />
                </View>
                <Separator style={{ marginVertical: 5 }} />
              </>
            )}

            {Platform.OS === 'android' && (
              <>
                <View style={{ justifyContent: 'flex-end' }}>
                  <Button
                    type="neutral"
                    title={i18n.t('ConfigureDoubleClick')}
                    style={{ paddingVertical: 5 }}
                    onPress={() => {
                      props.closeSettings()
                      navigation.navigate(ScreenType.DoubleClickConfig)
                    }}
                  />
                </View>
                <Separator style={{ marginVertical: 5 }} />
              </>
            )}

            <Text>Info</Text>

            {Platform.OS !== 'web' ? (
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.info}>{i18n.t('SETTINGS_VERSION')}: </Text>
                <Text style={styles.info}>
                  {packageJson.name} v{DeviceInfo.getVersion()}
                </Text>
              </View>
            ) : (
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.info}>{i18n.t('SETTINGS_VERSION')}: </Text>
                <Text style={styles.info}>{process.env.VERSION}</Text>
              </View>
            )}

            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={styles.info}>{i18n.t('SETTINGS_LICENSE')}: </Text>
              <Text style={styles.info}>{license}</Text>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={styles.info}>{i18n.t('SETTINGS_SERVER')}: </Text>
              <Text style={styles.infoDetail}>{url}</Text>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Text style={styles.info}>{i18n.t('USERNAME')}: </Text>
              <Text style={styles.info}>{name}</Text>
            </View>

            <View style={{ height: 40 }} />
          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  info: {
    color: Colors.icon,
  },
  infoDetail: {
    color: Colors.icon,
    flex: 1,
    flexWrap: 'wrap',
    textAlign: 'right',
  },
})
