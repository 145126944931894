import { useItemSelector, useLanguage } from '@infominds/react-native-components'
import { forEach } from 'lodash'
import React, { useState } from 'react'
import { FlatList, View } from 'react-native'

import api from '../../apis/apiCalls'
import { Item, Production, ProductionPartForExtraction } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ProductionCard from '../../cards/Production/ProductionCard'
import ProductionPartExtractionCard from '../../cards/Production/ProductionPartExtractionCard'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import SubmitButton from '../../components/SubmitButton'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import { Error_Utils } from '../../utils/ErrorUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import ProductionSelectionView from '../../views/Production/ProductionSelectionView'

export interface ProductionPartToExtract {
  productionId: string
  articleId: string
  serialnumberId?: string
  quantityBooked: number
  quantity: number
}

export default function ProductionExtractionArticleScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()

  const [productionSelected, setProductionFrom] = useState<Production>()
  const [itemSelected, setItemSelected] = useState<Item>()
  const article = useArticle(itemSelected?.article, { serialNumber: itemSelected?.serialnumber }, [itemSelected])

  const [productionPartsForExtraction, setProductionPartsForExtraction] = useState<ProductionPartForExtraction[]>()
  const productionPartsToExtract = useItemSelector<ProductionPartToExtract>([])

  function handleProductionSelection(production: Production) {
    setProductionFrom(production)
    setItemSelected(undefined)
    setProductionPartsForExtraction(undefined)
    productionPartsToExtract.clear()
  }

  function onDeleteProductionFrom() {
    setProductionFrom(undefined)
    setItemSelected(undefined)
    setProductionPartsForExtraction(undefined)
    productionPartsToExtract.clear()
  }

  function handleItemSelection(item: Item) {
    setItemSelected(item)
    setProductionPartsForExtraction(undefined)
    productionPartsToExtract.clear()
    loadParts(item)
  }

  function loadParts(item: Item) {
    if (!productionSelected || !item) {
      setProductionPartsForExtraction(undefined)
      productionPartsToExtract.clear()
      return
    }

    loader.setLoading(true)
    api
      .getPartsForExtraction({
        productionId: productionSelected.id,
        articleId: item.article.id,
      })
      .then(result => {
        setProductionPartsForExtraction(result)
      })
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        alert.error(reason?.Message)
        console.error(reason)
      })
      .finally(() => loader.setLoading(false))
  }

  async function handleConfirm() {
    if (!productionPartsToExtract) return
    if (productionPartsToExtract.items.length === 0) return

    // Kontrolle ob Übermengen
    productionPartsToExtract.items.forEach(item => {
      if (item.quantityBooked < item.quantity) {
        alert.error(i18n.t('EXCESSQUANTITIESNOTPERMITTED'))
        return
      }
    })

    try {
      loader.setLoading(true)
      await Promise.all(
        productionPartsToExtract.items.map(async item => {
          await api.patchProductionPartExtraction({
            productionId: item.productionId,
            articleId: item.articleId,
            serialnumberId: item.serialnumberId,
            quantity: item.quantity,
          })
        })
      )
      alert.info(i18n.t('EXTRACTIONOK'))
    } catch (error) {
      loader.setLoading(false)
      alert.error(i18n.t('FAILEDTOEXTRACT'), Error_Utils.extractErrorMessageFromException(error))
    } finally {
      loader.setLoading(false)
      setProductionFrom(undefined)
    }
  }

  function onSetQuantityToExtract(item: ProductionPartForExtraction, quantity: number) {
    // Zeile suchen, anlegen bzw. editiere
    let productionPartToExtract = productionPartsToExtract.items.find(
      q => q.productionId === item.productionId && q.articleId === item.article.id && q.serialnumberId === item.serialNumber?.id
    )
    if (!productionPartToExtract) {
      productionPartToExtract = {
        productionId: item.productionId,
        articleId: item.article.id,
        serialnumberId: item.serialNumber?.id,
        quantity: 0,
        quantityBooked: item.quantity,
      }
      productionPartsToExtract.add(productionPartToExtract)
    }
    productionPartToExtract.quantity = quantity
  }

  return (
    <MWS_Screen title={i18n.t('PRODUCTIONEXTRACTION')}>
      <View style={{ flex: 1 }}>
        {!productionSelected && <ProductionSelectionView onSelected={item => handleProductionSelection(item)} history filterResultByOrder />}
        {productionSelected && (
          <View style={[{ flex: 1 }]}>
            <ProductionCard handler={productionSelected} onDelete={onDeleteProductionFrom} endButton={true} />
            {!article && productionSelected && <ItemSelectionView onSelected={item => handleItemSelection(item)} showResultInModal />}
            {article && <ArticleCard article={itemSelected?.article} serialNumber={itemSelected?.serialnumber} />}
            <FlatList
              style={{ flex: 1 }}
              data={productionPartsForExtraction ?? []}
              renderItem={({ item }) => <ProductionPartExtractionCard item={item} onSetQuantityToExtract={onSetQuantityToExtract} />}
            />
            {productionPartsForExtraction && <SubmitButton title={i18n.t('EXTRACTION')} onPress={handleConfirm} hideFromKeyBoard />}
          </View>
        )}
      </View>
    </MWS_Screen>
  )
}
