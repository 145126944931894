import { IM, useLanguage, useModalController, ViewProps } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'

import { PackingListArticle } from '../../classes/PackingListCollection'
import ElusiveButton from '../../components/ElusiveButton'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import usePackingListConclusion from '../../hooks/packingLists/usePackingListConclusion'
import useAlert from '../../hooks/useAlert'
import PackingListIncompleteMovementsModal from '../../modals/packingLists/PackingListIncompleteMovementsModal'
import PreConsignmentColloUnloadModal from '../../modals/packingLists/PreConsignmentColloUnloadModal'
import { PackingListStackParamList } from '../../navigation/types'
import { ScreenType } from '../../types'
import AppUtils from '../../utils/AppUtils'
import packingListUtils from '../../utils/packingListUtils'

export type PackingListConcludeButtonsViewProps = ViewProps

export default function PackingListConcludeButtonsView({ ...viewProps }: PackingListConcludeButtonsViewProps) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const navigation = useNavigation<NavigationProp<PackingListStackParamList, 'PackingList'>>()
  const incompleteMovementsModal = useModalController<PackingListArticle[]>()
  const unloadCollosModal = useModalController()
  const { isPreConsignment, isProductionConsignment, activeColloSelector, packingLists, reloadPackingListMovements } = usePackingList()

  const { concludePackingLists } = usePackingListConclusion()

  async function handleSubmit() {
    try {
      loader.setLoading(true)
      // delay loading for a moment to improve UX
      await AppUtils.delay(100)
      // first reload movements to make sure everything is up to date
      const movements = await reloadPackingListMovements()

      const incompleteMovements = packingListUtils.getIncompleteMovements(movements)

      // if any incomplete movements are found then show info
      if (incompleteMovements.length) {
        if (isProductionConsignment) {
          const openMovements = movements?.filter(plm => packingListUtils.getIncompleteMovements([plm]).length > 0)
          if (openMovements != null && openMovements.length > 0) {
            const answer = await alert.yesNo(i18n.t('PackingListIncompleteMovementsQuestion'))
            if (!answer) return
          }
        } else {
          incompleteMovementsModal.show(incompleteMovements)
          return
        }
      }

      await conclude()
    } finally {
      loader.setLoading(false)
    }
  }

  async function conclude() {
    // if pre-consignment, then conclude all packingLists directly
    if (isPreConsignment) {
      await concludePackingLists(packingLists?.map(pl => ({ packingList: pl })) ?? [], { closeOnSuccess: true })
    }

    // otherwise go to completion screen
    navigation.navigate(ScreenType.PackingListCompletion)
  }

  function onColloUnloaded() {
    reloadPackingListMovements().catch(console.error)
  }

  return (
    <IM.View {...viewProps}>
      <IM.View style={[{ flexDirection: 'row' }]}>
        <ElusiveButton
          style={{ flex: 1 }}
          title={i18n.t('Unload')}
          hide={!isPreConsignment || !activeColloSelector.anyActiveCollos || isProductionConsignment}
          hideFromKeyBoard
          onPress={() => unloadCollosModal.show()}
        />
        <ElusiveButton
          style={{ flex: 1 }}
          title={i18n.t('Conclude')}
          hide={isPreConsignment && !!activeColloSelector.anyActiveCollos}
          hideFromKeyBoard
          onPress={() => {
            handleSubmit().catch(console.error)
          }}
        />
      </IM.View>
      <PackingListIncompleteMovementsModal
        controller={incompleteMovementsModal}
        onConfirm={() => {
          conclude().catch(console.error)
        }}
      />
      <PreConsignmentColloUnloadModal controller={unloadCollosModal} onDone={() => onColloUnloaded()} />
    </IM.View>
  )
}
