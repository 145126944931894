import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useState } from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDTO, Deposit, StockPosition } from '../../apis/apiTypes'
import DepositCard from '../../cards/StockPosition/DepositCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import useAlert from '../../hooks/useAlert'
import PositionSelectionModal from '../../modals/PositionSelectionModal'
import StockPositionSelectionView from './StockPositionSelectionView'

interface PositionSelectionViewProps {
  article: ArticleDTO | undefined
  deposit?: Deposit
  stockPosition?: StockPosition
  setDeposit: (deposit: Deposit | undefined) => void
  setStockPosition: (stockPosition: StockPosition | undefined) => void
  setPosition: (deposit: Deposit | undefined, stockPosition: StockPosition | undefined) => void
  onListButtonPressed?: () => void
  selectDepositCaption?: string
  selectStockPositionCaption?: string
}

export default function PositionSelectionView(props: PositionSelectionViewProps) {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const [deposits, setDeposits] = useState<Deposit[]>()
  const [showModal, setShowModal] = useState(false)

  function handleDepositSelection(result: Deposit[] | undefined, input?: string | Deposit) {
    setDeposits(result as Deposit[])
    if (result && result.length > 0) {
      if (result.length === 1) {
        if (!result[0].isStockpositionActive) props.setPosition(result[0], undefined)
        else props.setDeposit(result[0])
        return
      }
      setShowModal(true)
    } else {
      alert.info(i18n.t('NoDepositPosWithCode'), (typeof input === 'string' ? input : input?.code) ?? '')
    }
  }

  const RenderItem = useCallback((item: Deposit) => <DepositCard deposit={item} />, [])

  return (
    <View>
      {!props.deposit && (
        <MWS_Input<Deposit>
          type="Deposit"
          title={props.selectDepositCaption || i18n.t('SelectDeposit')}
          load={input => api.getDeposit({ code: input.scannerInput ?? input.textInput })}
          itemRenderer={RenderItem}
          failedToLoadText={i18n.t('NoSerialnumberWithCode')}
          modalTitle={props.selectDepositCaption ?? i18n.t('SelectDeposit')}
          allowEmptyInput={!props.onListButtonPressed}
          handleShowListButtonPressed={props.onListButtonPressed}
          showResultInModal
          onSelected={() => null}
          handleResult={(result, input) => handleDepositSelection(result as Deposit[], input)}
        />
      )}
      {props.deposit && props.deposit.isStockpositionActive && !props.stockPosition && (
        <StockPositionSelectionView
          onSelected={selectedStockPosition => props.setPosition(props.deposit, selectedStockPosition)}
          depositId={props.deposit.id}
          showResultInModal
          allowEmptyInput
          title={props.selectStockPositionCaption}
          withAvailableArticleId={props.article?.id}
        />
      )}

      <PositionSelectionModal
        deposits={deposits}
        show={showModal}
        close={() => setShowModal(false)}
        onSelected={(deposit: Deposit, stockPosition?: StockPosition | undefined) => {
          setShowModal(false)
          props.setPosition(deposit, stockPosition)
        }}
        selectDepositCaption={props.selectDepositCaption}
        selectStockPositionCaption={props.selectStockPositionCaption}
      />
    </View>
  )
}
