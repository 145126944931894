import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../../apis/apiCalls'
import { Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../../apis/apiTypes'
import SerialnumberCard from '../../../cards/Serialnumber/SerialnumberCard'
import { Article } from '../../../classes/Article'
import useAlert from '../../../hooks/useAlert'
import useModalController from '../../../hooks/useModalController'
import SerialNumberAvailabilitySelectionModal from '../../../modals/serialNumber/SerialNumberAvailabilitySelectionModal'
import SerialNumberSelectionModal from '../../../modals/serialNumber/SerialNumberSelectionModal'
import { SelectionViewProps } from '../../../types'
import packingListUtils from '../../../utils/packingListUtils'
import { serialnumberUtils } from '../../../utils/serialnumberUtils'
import stockPositionUtils from '../../../utils/stockPositionUtils'
import MWS_Input from './MWS_Input'

export type SerialNumberSelectorProps = {
  article: Article
  deposit?: Deposit
  stockPosition?: StockPosition
  allowOtherPositionsOnScan?: boolean
  ignoreAvailability?: boolean
  filterSerialNumber?: (serialNumberAvailability: StockPositionAvailability) => boolean
  filterNotProposedInPackingLists?: boolean //filter SN that are on StockPositions that are flagged as "NotProposedInPackinglists"
  unitConversionFactor?: number
  considerPickedQuantity?: boolean
} & (
  | { onSelected: (serialNumberAvailability: StockPositionAvailability) => void; multiSelection?: false }
  | { onSelected: (serialNumberAvailability: StockPositionAvailability[]) => void; multiSelection: true }
)

export default function SerialNumberAvailabilitySelector({
  article,
  title,
  deposit,
  stockPosition,
  ignoreAvailability,
  allowOtherPositionsOnScan,
  filterSerialNumber,
  allowEmptyInput,
  onSelected,
  filterNotProposedInPackingLists,
  unitConversionFactor,
  considerPickedQuantity,
  multiSelection,
  ...selectionViewProps
}: SerialNumberSelectorProps & Omit<SelectionViewProps<SerialNumber>, 'onSelected' | 'handleShowListButtonPressed'>) {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const modalController = useModalController()
  const serialScanResultSelectionModal = useModalController<StockPositionAvailability[]>()

  async function handleSerialNumberSelected(serialNumber: SerialNumber) {
    try {
      //if historical serialnumber
      if (article.isHistoricalSerialNumber) {
        const result = await packingListUtils.getHistoricalSerialNumberAvailability(serialNumber, article.info, deposit, stockPosition)
        multiSelection ? onSelected([result[0]]) : onSelected(result[0])
        return
      }

      if (ignoreAvailability) {
        return [stockPositionUtils.createStockPositionAvailability(article.info, deposit, stockPosition, 1, serialNumber)]
      }
      try {
        const result = await serialnumberUtils.getSerialnumberQuantities(
          article.info,
          serialNumber,
          deposit,
          stockPosition,
          filterNotProposedInPackingLists,
          true
        )
        if (result.length) {
          multiSelection ? onSelected([result[0]]) : onSelected(result[0])
          return
        }
      } catch (_exception) {}

      if (!allowOtherPositionsOnScan) {
        showNotAvailableError()
        return
      }

      const result = await serialnumberUtils.getSerialnumberQuantities(
        article.info,
        serialNumber,
        deposit,
        undefined,
        filterNotProposedInPackingLists,
        true
      )
      if (result.length === 1) {
        multiSelection ? onSelected([result[0]]) : onSelected(result[0])
        return
      } else if (result.length > 1) {
        serialScanResultSelectionModal.show(result)
      } else {
        showNotAvailableError()
      }
    } catch (exception) {
      console.error('SerialNumberAvailabilitySelector() Error: ', exception)
      showNotAvailableError()
    }

    return
  }

  function showNotAvailableError() {
    alert.error(i18n.t('SERIALNUMBER_NOT_AVAILABLE'))
  }

  function handleShowListButtonPressed() {
    if (!modalController.isShown) modalController.show()
  }

  function handleSelection(result: StockPositionAvailability | StockPositionAvailability[]) {
    if (!result) return
    if (Array.isArray(result)) {
      if (!result.length) return
      if (unitConversionFactor) {
        result.forEach(sn => {
          if (sn.quantity) sn.quantity *= unitConversionFactor
        })
      }
      multiSelection ? onSelected(result) : onSelected(result[0])
    } else {
      if (unitConversionFactor && result.quantity) result.quantity *= unitConversionFactor
      multiSelection ? onSelected([result]) : onSelected(result)
    }
  }

  const RenderItem = useCallback((item: SerialNumber) => <SerialnumberCard serialnumber={item} />, [])

  return (
    <>
      <MWS_Input<SerialNumber>
        type="Serialnumber"
        title={title ?? i18n.t('SelectSerialnumber')}
        load={input => api.getSerialnumber({ articleId: article.info.id, number: input.scannerInput ?? input.textInput })}
        allowEmptyInput={allowEmptyInput}
        onSelected={selection => {
          handleSerialNumberSelected(selection).catch(console.error)
        }}
        itemRenderer={RenderItem}
        failedToLoadText={i18n.t('NoSerialnumberWithCode')}
        modalTitle={i18n.t('SelectSerialnumber')}
        handleShowListButtonPressed={allowEmptyInput ? handleShowListButtonPressed : undefined}
        {...selectionViewProps}
      />

      {multiSelection ? (
        <SerialNumberAvailabilitySelectionModal
          controller={modalController}
          article={article}
          preSelectedDeposit={deposit}
          preSelectedStocksPosition={stockPosition}
          considerPickedQuantity={considerPickedQuantity}
          onSelected={handleSelection}
          filterSerialNumber={filterSerialNumber}
          unitConversionFactor={unitConversionFactor}
          multiSelection
        />
      ) : (
        <SerialNumberAvailabilitySelectionModal
          controller={modalController}
          article={article}
          preSelectedDeposit={deposit}
          preSelectedStocksPosition={stockPosition}
          considerPickedQuantity={considerPickedQuantity}
          onSelected={handleSelection}
          filterSerialNumber={filterSerialNumber}
          unitConversionFactor={unitConversionFactor}
        />
      )}
      <SerialNumberSelectionModal
        article={article}
        controller={serialScanResultSelectionModal}
        onSelected={handleSelection}
        unitConversionFactor={unitConversionFactor}
      />
    </>
  )
}
