import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, Item, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import PositionCard from '../../cards/StockPosition/PositionCard'
import PackingListColloSelector from '../../components/MWS/PackingList/PackingListColloSelector'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import useAlert from '../../hooks/useAlert'
import packingListUtils from '../../utils/packingListUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import ItemSelectionView from '../InputViews/ItemSelectionView'
import StockPositionSelectionView from '../InputViews/StockPositionSelectionView'

export default function PackingListMovementMultiScanView(props: { style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()
  const {
    deposit: movementDeposit,
    stockPosition: movementStockPosition,
    packingListArticle,
    anyMovement,
    article,
    colloSelector,
    addCollos,
    addCollosWithSerialNumbers,
  } = usePackingListMovement()
  const { forceScan, colloMode } = usePackingList()
  const serialFilter = useRef<{ serialNumber: string | undefined; stockPosition: StockPosition | undefined } | undefined>(undefined)
  const loadedDepositSerialNumberAvailability = useRef(false)
  const [deposit, setDeposit] = useState<Deposit | undefined>()
  const [stockPosition, setStockposition] = useState<StockPosition | undefined>()
  const showStockPositionSelector = !!deposit && !!deposit.isStockpositionActive && !stockPosition
  const showItemSelector = (!!deposit && !deposit.isStockpositionActive) || (deposit?.isStockpositionActive && stockPosition)

  useEffect(() => {
    if (!packingListArticle) return
    if (movementDeposit) setDeposit(movementDeposit)
    if (movementStockPosition) setStockposition(movementStockPosition)
    if (forceScan) return
  }, [])

  function handleError(error: unknown) {
    loader.setLoading(false)
    console.error(error)
  }

  function addColloFromPosition() {
    if (!anyMovement?.movement || !article || article.isSerialNumberActive) return

    let quantityTotal = 1
    const colloFound = colloSelector.items.find(
      c => !c.id && c.article?.id === article?.info.id && stockPositionUtils.comparePosition(c.deposit, deposit, c.stockposition, stockPosition)
    )
    if (colloFound) {
      quantityTotal += colloFound.quantity
    }

    loader.setLoading(true)
    api
      .getItemAvailability({
        articleId: article.info.id,
        depositId: deposit?.id,
        stockPositionId: stockPosition?.id,
      })
      .then(result => {
        if (!result || result <= 0) {
          alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
          return
        }
        result *= packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)
        if (result < quantityTotal) {
          alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
          return
        }
        if (anyMovement?.movement) {
          if (colloFound) {
            colloFound.quantity += 1
          } else {
            addCollos(1, deposit, stockPosition)
          }
        }
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function filterSerials(serial: StockPositionAvailability) {
    if (!serial) return false
    if (serial.stockposition?.notProposedInPackinglists) return false
    if (!anyMovement?.movement?.orderSerialnumberquantities?.length) return true
    return !!anyMovement?.movement.orderSerialnumberquantities.find(orderSN => serialnumberUtils.compare(serial.serialnumber, orderSN.serialnumber))
  }

  function loadSerialAvailability(scannedSerialNumber: SerialNumber | undefined) {
    if (!anyMovement?.movement || !article || !scannedSerialNumber) return

    let quantityTotal = 1
    let colloFound = colloSelector.items.find(
      c =>
        serialnumberUtils.compareByNumber(c.serialnumber, scannedSerialNumber) &&
        stockPositionUtils.comparePosition(c.deposit, deposit, c.stockposition, stockPosition)
    )
    if (!colloFound && !article.isLottoSerialNumber) {
      colloFound = colloSelector.items.find(c => serialnumberUtils.compareByNumber(c.serialnumber, scannedSerialNumber))
      if (colloFound) {
        alert.error(i18n.t('SerialnumberAlreadySelected'))
        return
      }
    }
    if (colloFound) {
      quantityTotal += colloFound.quantity
    }

    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    article
      ?.loadSerialNumberQuantities(deposit, stockPosition, scannedSerialNumber, filterSerials, undefined, true, true)
      .then(() => {
        const serials = article?.getConvertedSerialNumberQuantities() ?? []
        if (serials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), scannedSerialNumber.number)
        if (serials.length >= 1) {
          const serial = serials[0]
          if (serial !== null && serial.quantity) {
            if (serial.quantity < quantityTotal) {
              alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
              return
            }
            return addColloWithSerialFromPosition(serial)
          }
        }
      })
      .catch(() => {
        handleError
        alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(stockPosition, deposit))
      })
      .finally(() => loader.setLoading(false))
  }

  function handleHistoricalSerialScanned(scannedSerialNumber: SerialNumber | undefined) {
    if (!anyMovement?.movement || !article || !scannedSerialNumber) return
    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    packingListUtils
      .getHistoricalSerialNumberAvailability(scannedSerialNumber, article.info, deposit, stockPosition)
      .then(availability => {
        serialFilter.current = { serialNumber: undefined, stockPosition: undefined }
        if (article) article.serialNumberQuantities = availability ?? []
        if (!availability) return
        const matchingSerials = availability.filter(s => serialnumberUtils.compareByNumber(s.serialnumber, scannedSerialNumber))
        if (matchingSerials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), scannedSerialNumber.number)
        return addColloWithSerialFromPosition(matchingSerials[0])
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function addColloWithSerialFromPosition(serialNumberAvailability: StockPositionAvailability) {
    if (!anyMovement?.movement || !article || !serialNumberAvailability) return

    if (checkSerialAlreadySelectedAndIncreaseQuantity(serialNumberAvailability)) return
    addCollosWithSerialNumbers([serialNumberAvailability])
  }

  function checkSerialAlreadySelectedAndIncreaseQuantity(serial: StockPositionAvailability) {
    if (!serial) return false

    // Bei EinzelSN kontrollieren ob bereits vorhanden
    if (!article?.isLottoSerialNumber && !article?.isHistoricalSerialNumber) {
      if (colloSelector.items.filter(i => !i.id).find(i => i.serialnumber?.id === serial.serialnumber?.id)) {
        alert.info(i18n.t('SerialnumberAlreadySelected'))
        return true
      }
    }

    // Kontrolle ob SN bereits vorhanden, dann einfache Menge erhöhen
    const colloFound = colloSelector.items.find(
      c =>
        !c.id &&
        serialnumberUtils.compareByNumber(c.serialnumber, serial.serialnumber) &&
        stockPositionUtils.comparePosition(c.deposit, deposit, c.stockposition, stockPosition)
    )
    if (colloFound) {
      colloFound.quantity += 1
      return true
    }

    // Normal hinzufügen
    return false
  }

  function handlePositionDelete() {
    if (stockPosition) {
      setStockposition(undefined)
      return
    }
  }

  function handleItemSelection(selectedItem: Item) {
    if (!selectedItem) return
    if (article?.info.id === selectedItem.article.id) {
      if (article?.isHistoricalSerialNumber && !!selectedItem.serialnumber) {
        handleHistoricalSerialScanned(selectedItem?.serialnumber)
      } else if (article?.isSerialNumberActive && !!selectedItem.serialnumber) {
        loadSerialAvailability(selectedItem?.serialnumber)
      } else if (!article?.isSerialNumberActive) {
        addColloFromPosition()
      } else {
        alert.info(i18n.t('SerialNumberMustBeSpecified'))
      }
    } else {
      alert.info(i18n.t('InvalidArticle'))
    }
  }

  return (
    <View style={props.style}>
      {(!!deposit || !stockPosition) && <PositionCard deposit={deposit} stockPosition={stockPosition} onDelete={handlePositionDelete} />}
      {showStockPositionSelector && <StockPositionSelectionView depositId={deposit.id} onSelected={setStockposition} showResultInModal />}
      {showItemSelector && (
        <View style={{ flexDirection: 'row', gap: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
          {colloMode && <PackingListColloSelector style={{ flex: 1 }} />}
          <ItemSelectionView style={{ flex: 2 }} fetchArticleAndSerial onSelected={handleItemSelection} showResultInModal />
        </View>
      )}
    </View>
  )
}
