import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { Platform, StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { PackingListArticle } from '../../classes/PackingListCollection'
import HtmlView from '../../components/HtmlView'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import OutOfStockIndicator from '../../components/MWS/OutOfStockIndicator'
import { Colors } from '../../constants/Colors'
import { MWS_COLORS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { ScreenType } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import packingListUtils from '../../utils/packingListUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard, { MWSBaseCardEndIconButtonProps } from '../MWS_BaseCard'

export interface PackingListArticleCardProps {
  item: PackingListArticle | undefined
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  showAvailability?: boolean
  showOrderSerialnumber?: boolean
  quantity?: number
  navigateToArticleInfoOnPress?: boolean
  showPackingLists?: boolean
  endButton?: MWSBaseCardEndIconButtonProps
}

export default function PackingListArticleCard(props: PackingListArticleCardProps) {
  const navigation = useNavigation()
  const movementCount = props.item?.movements?.length ?? 0
  const anyMovement = movementCount > 0 ? props.item?.movements[0] : undefined
  const description = anyMovement?.movement.description ?? ''
  const descriptionHTML = anyMovement?.movement.descriptionHTML ?? ''
  const state = anyMovement?.movement.state ?? 0
  const loader = useLoadingIndicator()
  const quantityTotal = useMemo(() => Utils.sum(props.item?.movements, item => item?.movement.quantity), [props.item?.movements])

  const quantityCommissioned = useMemo(
    () =>
      props.quantity ??
      Utils.sum(
        props.item?.movements,
        item => Utils.sum(item.movement.collonumbers, c => c.quantity) * packingListUtils.getColloToUsedUnitConversionFactor(item.movement)
      ) ??
      0,
    [props.quantity, props.item?.movements]
  )

  const quantityText = `${articleUtils.formatQuantity(quantityCommissioned ?? 0) || '0'}/${articleUtils.formatQuantity(quantityTotal ?? 0)} ${
    anyMovement?.movement.unitCode ?? ''
  }`
  const stockPosition = useMemo(() => Utils.getValueIfAllAreEqual(props.item?.movements, m => m.movement.stockposition?.id), [props.item?.movements])
  const deposit = useMemo(() => Utils.getValueIfAllAreEqual(props.item?.movements, m => m.movement.deposit?.id), [props.item?.movements])
  const outOfStockWarning = useMemo(() => packingListUtils.checkMovementOutOfStock(props.item), [props.item])

  const stockPositionText = useMemo(() => {
    if (!stockPosition?.movement.stockposition?.code) return ''
    return stockPosition.movement.stockposition.code.trim()
  }, [stockPosition])

  const stockPositionQuantityText = useMemo(() => {
    if (!stockPosition?.movement.stockposition?.code) return ''
    if (stockPosition.movement.quantityStoringposition > 0) {
      const quantityFormatted = articleUtils.formatQuantity(
        stockPosition.movement.quantityStoringposition,
        anyMovement?.movement.unitCode,
        packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)
      )
      return `(${quantityFormatted})`
    }
    return ''
  }, [stockPosition])

  const packingListText =
    props.showPackingLists && !!props.item?.movements.length
      ? packingListUtils.getPackingListsTitle(
          Utils.keepUniques(
            props.item.movements.map(m => m.packingList),
            q => q
          )
        )
      : ''
  const movementSerialNumber = useMemo(() => packingListUtils.getMovementSerialnumber(props.item?.movements), [props.item?.movements])
  const backgroundColor = useMemo(() => {
    if (props.item && state === 4) return MWS_COLORS.NOT_AVAILABLE
    if (props.item && Utils.roundToDefaultPrecision(quantityCommissioned) >= Utils.roundToDefaultPrecision(quantityTotal)) return MWS_COLORS.OK
    if (props.item && quantityCommissioned > 0) return MWS_COLORS.PARTIALLY_OK
    return MWS_COLORS.NOT_STARTED
  }, [props.item, state, quantityCommissioned, quantityTotal])

  function getQuantityTextColor() {
    if (quantityCommissioned > quantityTotal) return Colors.red
    return Colors.light.text
  }

  function navigateToArticleInfo() {
    if (!props.item?.article) return
    loader.setLoading(true)
    api
      .getArticle({ id: props.item.article.id })
      .then(article => {
        if (article?.length) navigation.navigate(ScreenType.ArticleAvailability, { item: { article: article[0] } })
      })
      .catch(error => {
        console.error(error)
        if (props.item?.article) navigation.navigate(ScreenType.ArticleAvailability, { item: { article: props.item?.article } })
      })
      .finally(() => loader.setLoading(false))
  }

  return (
    <MWS_BaseCard
      onPress={props.navigateToArticleInfoOnPress && !props.onPress ? navigateToArticleInfo : props.onPress}
      icon={'box-taped'}
      cardStyle={props.style}
      style={{ justifyContent: 'flex-start', flexDirection: 'column', backgroundColor }}
      endButton={props.endButton}>
      {props.showAvailability && (
        <View>
          {!!stockPositionText && (
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <IMTextWithIcon icon={'shelf'} iconColor={Colors.light.text} text={stockPositionText} primary style={{ color: Colors.light.text }} />
                <IMText secondary style={{ flex: 1, marginRight: 10, justifyContent: 'flex-start', color: Colors.light.text }}>
                  {stockPositionQuantityText}
                </IMText>
                <IMText primary style={{ color: getQuantityTextColor() }}>
                  {quantityText}
                </IMText>
              </View>
            </View>
          )}
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <IMText
          numberOfLines={1}
          primary
          style={{ flex: 1, marginRight: 10, justifyContent: 'flex-start', color: Colors.light.text }}>{`${props.item?.article.code.trim() ?? ''} - ${
          props.item?.article.searchtext ?? ''
        }`}</IMText>
        {!stockPositionText && (
          <IMText primary style={{ color: getQuantityTextColor() }}>
            {quantityText}
          </IMText>
        )}
      </View>
      {!!description && (!descriptionHTML || Platform.OS === 'web') && (
        <View>
          <IMText secondary style={{ color: Colors.light.textDetail }} numberOfLines={3}>
            {description}
          </IMText>
        </View>
      )}
      <HtmlView html={descriptionHTML} />
      {!!packingListText && (
        <View>
          <IMText numberOfLines={2} style={{ color: Colors.light.textDetail }}>
            {packingListText}
          </IMText>
        </View>
      )}
      {props.showOrderSerialnumber && !!movementSerialNumber && (
        <IMTextWithIcon icon={'barcode'} iconColor={Colors.light.text} text={movementSerialNumber} style={{ color: Colors.light.text }} />
      )}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          {!!deposit?.movement.deposit && !!props.item?.article.isStockmovement && (
            <IMTextWithIcon
              icon="warehouse-full"
              secondary
              iconColor={Colors.light.text}
              style={{ color: Colors.light.text }}
              text={`${deposit.movement.deposit.code?.trim()} (${articleUtils.formatQuantity(
                deposit.movement.quantityDeposit,
                anyMovement?.movement.unitCode,
                packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)
              )})`}
            />
          )}
        </View>
        {outOfStockWarning && <OutOfStockIndicator style={{ marginLeft: 5 }} />}
      </View>
    </MWS_BaseCard>
  )
}
