import { useLanguage } from '@infominds/react-native-components'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import api from '../../apis/apiCalls'
import { Item } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useOnScreenFocus from '../../hooks/useOnScreenFocus'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'

export default function ProductionCreatePreProductionScreen() {
  const navigation = useNavigation()
  const alert = useAlert()
  const { i18n } = useLanguage()
  const isFocused = useIsFocused()
  const loader = useLoadingIndicator()

  const [item, setItem] = useState<Item>()
  const article = useArticle(item?.article, { serialNumber: item?.serialnumber }, [item])

  useOnScreenFocus(() => {
    setItem(undefined)
  })

  useEffect(() => {
    if (!article || !isFocused) return
  }, [article])

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: item })
  }

  async function submitQuantity(quantity: number) {
    if (!article) return
    try {
      const request = { articleId: article?.info.id, quantity: quantity }
      loader.setLoading(true)
      await api.postCreatePreProduction(request)
      navigation.goBack()
    } catch (reason) {
      alert.error(i18n.t('ProductionCreatePreProductionFailure'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  return (
    <MWS_Screen title={i18n.t('CreatePreProduction')}>
      {!article && (
        <>
          <ItemSelectionView onSelected={setItem} showResultInModal />
        </>
      )}
      {article && (
        <View style={{ flex: 1 }}>
          <ArticleCard
            article={article.info}
            serialNumber={article.serialNumber}
            onDelete={() => {
              setItem(undefined)
            }}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            onPress={showArticleInfoScreen}
          />
          <VKUnitAmountSelector
            setValue={value => {
              submitQuantity(value).catch(console.error)
            }}
            autoFocus
            usedUnit={article?.getUsedUnit()}
            onUnitSelected={unit => article?.setUsedUnit(unit)}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          />
        </View>
      )}
    </MWS_Screen>
  )
}
