import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { Item } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { ScreenType } from '../../types'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'

export default function ArticleSelectionScreen() {
  const navigation = useNavigation()

  function navigateTo(item: Item) {
    navigation.navigate(ScreenType.ArticleAvailability, { item: item })
  }

  return (
    <MWS_Screen>
      <ItemSelectionView onSelected={navigateTo} fetchArticleAndSerial ignoreHistoricalSN history />
    </MWS_Screen>
  )
}
