import React, { useEffect } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import useAsyncStorage from '../hooks/useAsyncStorage'
import IMText from './IMText'
import Switch from './Switch'

type SwitchWithTextProps = {
  text: string
  value: boolean
  setValue: (value: boolean) => void
  style?: StyleProp<ViewStyle>
  storageKey?: string
}

export default function SwitchWithText({ text, value, setValue, style, storageKey }: SwitchWithTextProps) {
  const { load, save } = useAsyncStorage(storageKey ?? '')

  useEffect(() => {
    if (!storageKey) return
    load<{ value: boolean }>()
      .then(result => {
        if (!result) return
        setValue(result.value)
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    if (!storageKey) return
    save({ value: !!value }).catch(console.error)
  }, [value])

  return (
    <View style={[styles.main, style]}>
      <IMText style={styles.text}>{text}</IMText>
      <Switch onColor={Colors.tint} value={value} onValueChange={setValue} />
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  text: {
    flex: 1,
    textAlign: 'left',
  },
})
