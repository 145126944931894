import { useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { QualityCharacteristic, QualityCharacteristicType } from '../../../apis/apiTypes'
import { Colors } from '../../../constants/Colors'
import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { QualityValidationResult, QualityValidationResultType } from '../../../types'
import Dot from '../../Dot'
import IMText from '../../IMText'

export default function CharacteristicTitle({
  characteristic,
  style,
  validationResult,
}: {
  characteristic: QualityCharacteristic
  style?: StyleProp<ViewStyle>
  validationResult: QualityValidationResult | undefined
}) {
  const title = useMemo(() => characteristic.description?.trim(), [characteristic])
  const checkInfo = useMemo(() => {
    const getInfo = () => {
      if (!characteristic.check?.targetCompare) return null
      switch (characteristic.fieldTyp) {
        case QualityCharacteristicType.Text:
          return characteristic.check.targetText
        case QualityCharacteristicType.Selection:
          return characteristic.check.targetText
        case QualityCharacteristicType.Boolean:
          return characteristic.check.targetBoolean
        case QualityCharacteristicType.Numeric:
          return `${characteristic.check.targetNumericFrom?.toString() ?? ''} -  ${characteristic.check.targetNumericUntil?.toString() ?? ''}`
        default:
          return null
      }
    }
    const info = getInfo()
    if (info !== null && info !== undefined) return `(${info?.toString()})`
    return null
  }, [characteristic])
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const dotColor = useMemo(() => {
    switch (validationResult?.check) {
      case QualityValidationResultType.notRequired:
        return theme.background
      case QualityValidationResultType.noValue:
        return theme.inputBackground
      case QualityValidationResultType.notOk:
        return Colors.modern.red
      case QualityValidationResultType.ok:
        return Colors.tint
      default:
        return theme.background
    }
  }, [validationResult])
  const textColor = useMemo(() => {
    switch (validationResult?.required) {
      case QualityValidationResultType.notOk:
        return Colors.modern.red
      default:
        return theme.text
    }
  }, [validationResult])

  return (
    <View style={[styles.main, style]}>
      {!!validationResult?.check && validationResult.check !== QualityValidationResultType.notRequired && (
        <View style={styles.dotView}>
          <Dot color={dotColor} />
        </View>
      )}
      <IMText style={[styles.title, { color: textColor }]}>{title}</IMText>
      {!!validationResult?.required && validationResult?.required !== QualityValidationResultType.notRequired && (
        <IMText style={[styles.titleRequiredIndicator, { color: textColor }]}>*</IMText>
      )}
      {!!checkInfo && (
        <IMText secondary style={[styles.titleCheckInfo]}>
          {checkInfo}
        </IMText>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    flexDirection: 'row',
    marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS,
  },
  title: {
    marginLeft: STYLE_CONSTANTS.DEFAULT_BORDER_RADIUS_COMPONENT,
    marginBottom: 2,
    justifyContent: 'center',
    textAlignVertical: 'center',
  },
  titleRequiredIndicator: {
    marginLeft: 2,
    marginBottom: 2,
    justifyContent: 'center',
    textAlignVertical: 'center',
  },
  titleCheckInfo: { marginLeft: 2, marginBottom: 2, flex: 1, justifyContent: 'center', textAlignVertical: 'center', textAlign: 'right' },
  dotView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
