import { IM, IMLayout, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'

import { PackingList } from '../../../apis/apiTypes'
import MWS_BaseCard, { MWSBaseCardProps } from '../../../cards/MWS_BaseCard'
import ColloPackingListSelectionModal from '../../../modals/packingLists/ColloPackingListSelectionModal'
import Text from '../../old/Text'

export type PackingListSelectorProps = {
  selectedPackingListId?: string
  packingLists: PackingList[]
  onSelected: (packingList: PackingList) => void
} & Omit<MWSBaseCardProps, 'onPress'>

export default function PackingListSelector({ selectedPackingListId, packingLists, onSelected, ...cardProps }: PackingListSelectorProps) {
  const { i18n } = useLanguage()
  const packingListSelectionModal = useModalController()

  const selected = useMemo(
    () => !!selectedPackingListId && packingLists?.find(pl => pl.id === selectedPackingListId),
    [selectedPackingListId, packingLists]
  )

  return (
    <MWS_BaseCard icon={'clipboard-check-list'} style={[{ alignItems: 'center' }]} onPress={packingListSelectionModal.show} {...cardProps}>
      <IM.View style={[IMLayout.flex.f1]}>
        {!!selected && <Text>{selected.number}</Text>}
        {!selected && <Text secondary>{i18n.t('SELECT_PLACEHOLDER')}</Text>}
      </IM.View>
      <IM.Icon icon={['fal', 'chevron-down']} />
      <ColloPackingListSelectionModal
        controller={packingListSelectionModal}
        packingLists={packingLists}
        onSelected={onSelected}
        selectedId={selected ? selected.id : undefined}
      />
    </MWS_BaseCard>
  )
}
