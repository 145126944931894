import { EventArg, useNavigation } from '@react-navigation/native'
import { useEffect } from 'react'

type InterceptorEvent = (
  e: EventArg<
    'beforeRemove',
    true,
    {
      action: Readonly<{
        type: string
        payload?: object | undefined
        source?: string | undefined
        target?: string | undefined
      }>
    }
  >
) => void

/**
 * Navigation-Interceptor. Will call interceptor before screen is unmounted by navigation
 * @param interceptor interceptorFunction. e.preventDefault() can be used to cancel navigation
 */
export default function useNavigationInterceptor(interceptor: InterceptorEvent) {
  const navigation = useNavigation()

  useEffect(() => {
    navigation.addListener('beforeRemove', interceptor)
    return () => {
      navigation.removeListener('beforeRemove', interceptor)
    }
  }, [])
}
