import { IM, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { ScrollView } from 'react-native'

import api from '../../apis/apiCalls'
import { TipType } from '../../apis/apiRequestTypes'
import { Collo, PackingListMovement } from '../../apis/apiTypes'
import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import ListSpacer from '../../components/MWS/ListSpacer'
import StockPositionCheckSwitch from '../../components/StockPositionCheckSwitch'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { IMLayout } from '../../constants/Styles'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import PackingListColloEditorModal from '../../modals/PackingListColloEditorModal'
import PackingListReplaceMovementModal from '../../modals/PackingListReplaceMovementModal'
import { PackingListStackParamList } from '../../navigation/types'
import colloUtils from '../../utils/colloUtils'
import { Error_Utils } from '../../utils/ErrorUtils'
import PackingListMovementCollosView from './PackingListMovementCollosView'
import PackingListMovementInputView from './PackingListMovementInputView'
import PackingListMovementMultiScanView from './PackingListMovementMultiScanView'
import PackingListSubmitButtonsView from './PackingListSubmitButtonsView'

export default function PackingListMovementView() {
  const { i18n } = useLanguage()
  const navigation = useNavigation<NavigationProp<PackingListStackParamList, 'PackingListMovement'>>()
  const alert = useAlert()
  const scrollViewRef = useRef<ScrollView>(null)
  const userSettings = useUserSettings()
  const loader = useLoadingIndicator()
  const {
    isPreConsignment,
    isProductionConsignment,
    packingLists,
    removeCollos,
    multiPackingListConsignment: multiListConsignment,
  } = usePackingList()
  const {
    colloSelector,
    mandatoryScanningIndividualQuantities,
    movementCollos,
    article,
    anyMovement,
    stockPosition,
    packingListArticle,
    multiPackingListConsignment,
  } = usePackingListMovement()
  const editorModal = useModalController<Collo>()
  const changeArticleModal = useModalController()
  const showStockPositionCheckSwitch =
    !!stockPosition && userSettings?.additionalFieldDefId_CheckStockposition !== null && userSettings?.additionalFieldDefId_CheckStockposition !== ''
  const [isTipLoaded, setIsTipLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (isTipLoaded) return
    loadTip()
  }, [])

  const memCount = useRef(colloSelector.count)
  useEffect(() => {
    if (colloSelector.count > memCount.current) {
      scrollViewRef.current?.scrollToEnd()
    }
    memCount.current = colloSelector.count
  }, [colloSelector.count])

  const articleCanBeChanged =
    !isPreConsignment &&
    !isProductionConsignment &&
    !multiPackingListConsignment &&
    !!userSettings?.isPackingReplaceItemActive &&
    colloSelector.items.filter(item => !!item.id).length === 0

  const showMultiScanView =
    !isPreConsignment &&
    !isProductionConsignment &&
    packingLists &&
    packingLists?.length > 0 &&
    !packingLists[0].internalPackinglist &&
    mandatoryScanningIndividualQuantities

  function getQuantityFromCollos() {
    return colloUtils.getQuantity(colloSelector.items)
  }

  function handleDeleteCollos(collosToDelete: Collo[]) {
    const newCollos = collosToDelete.filter(collo => !collo?.id)
    newCollos.forEach(item => colloSelector.remove(item))

    const existingCollosToDelete = collosToDelete.filter(collo => !!collo?.id)
    if (!existingCollosToDelete.length) return

    loader.setLoading(true)
    const collosToRemove: Collo[] = []
    existingCollosToDelete.forEach(item => {
      collosToRemove.push(
        ...movementCollos.filter(
          collo => colloUtils.getIdentificationHash(collo, true, true, true) === colloUtils.getIdentificationHash(item, true, true, true)
        )
      )
    })
    const requests = collosToRemove.map(collo => api.deleteCollo({ id: collo?.id ?? '' }))
    Promise.all(requests)
      .then(() => {
        collosToRemove.forEach(item => colloSelector.remove(item))
        removeCollos(collosToRemove)
      })

      .catch(reason => alert.error(Error_Utils.extractErrorMessageFromException(reason) ?? i18n.t('FailedToDeleteCollo')))
      .finally(() => loader.setLoading(false))
  }

  function handleColloEdited(quantity: number, packingListMovement: PackingListMovement | undefined, collo: Collo) {
    if (!collo) return
    colloSelector.set(prev =>
      prev.map(c =>
        colloUtils.compare(c, collo)
          ? {
              ...c,
              quantity,
              packinglistmovementId: packingListMovement?.id ?? c.packinglistmovementId,
              packinglistmovementpartId: packingListMovement?.packinglistmovementpartId ?? c.packinglistmovementpartId,
            }
          : c
      )
    )
  }

  function loadTip() {
    if (!article?.info) return

    api
      .getTip({ type: TipType.Article, masterId: article?.info.id })
      .then(result => {
        if (result) alert.info(result)
        setIsTipLoaded(true)
      })
      .catch(console.error)
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      <ScrollView ref={scrollViewRef} style={IMLayout.flex.f1} stickyHeaderIndices={[showStockPositionCheckSwitch ? 4 : 3]}>
        <PackingListColloEditorModal
          article={article}
          packingListMovements={packingListArticle?.movements}
          controller={editorModal}
          onSubmit={handleColloEdited}
          canChangePackingList={article?.isSerialNumberActive}
        />
        <ListSpacer />
        <PackingListArticleCard
          item={packingListArticle}
          navigateToArticleInfoOnPress
          showAvailability
          showOrderSerialnumber
          quantity={getQuantityFromCollos()}
          showPackingLists={multiListConsignment}
          endButton={
            articleCanBeChanged
              ? {
                  icon: 'pen',
                  onPress: () => {
                    changeArticleModal.show()
                  },
                }
              : undefined
          }
          style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        />
        {showStockPositionCheckSwitch && <StockPositionCheckSwitch stockpositionId={stockPosition.id} />}
        <IM.View style={{ paddingTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}>
          {showMultiScanView && <PackingListMovementMultiScanView />}
          {!showMultiScanView && <PackingListMovementInputView />}
        </IM.View>

        <PackingListMovementCollosView
          show={colloSelector.any}
          packingListArticle={packingListArticle}
          onDelete={handleDeleteCollos}
          onPress={editorModal.show}
        />
      </ScrollView>
      <PackingListSubmitButtonsView />

      <PackingListReplaceMovementModal
        controller={changeArticleModal}
        onDone={() => {
          changeArticleModal.close()
          navigation.navigate('PackingList', { openMovementWithId: anyMovement?.movement?.id })
        }}
        onNotDeliverable={() => {
          changeArticleModal.close()
          navigation.goBack()
        }}
      />
    </IM.View>
  )
}
