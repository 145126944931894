import { useApi } from '@infominds/react-api'
import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { FlatList } from 'react-native'

import api from '../../apis/apiCalls'
import { StockPositionAvailabilityRequest } from '../../apis/apiRequestTypes'
import { ArticleDTO, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import { MWSBaseCardEndIconButtonProps } from '../../cards/MWS_BaseCard'
import StockPositionCard from '../../cards/StockPosition/StockPositionCard'
import IMRefreshControl from '../../components/IMRefreshControl'
import ListSpacer from '../../components/MWS/ListSpacer'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import Title from '../../components/MWS/Title'
import NoEntriesTag from '../../components/NoEntriesTag'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useArticle from '../../hooks/useArticle'
import useRouteParam from '../../hooks/useRouteParam'
import { ScreenType } from '../../types'
import { Utils } from '../../utils/Utils'

export interface StockPositionInfoCollection {
  collection: StockPositionAvailability[]
}

export default function StockPositionInfoScreen() {
  const { i18n } = useLanguage()

  const loader = useLoadingIndicator()
  const stockPosition = useRouteParam<StockPosition>('stockPosition')
  const [items, loadStockPositions, loading] = useApi(getStockPositionAvailabilityInfo, [])
  const navigation = useNavigation()

  function getStockPositionAvailabilityInfo(request: StockPositionAvailabilityRequest) {
    return new Promise<StockPositionInfoCollection[]>((resolve, reject) => {
      api
        .getStockPositionAvailability(request)
        .then(result => {
          if (result) resolve(groupItems(result))
          reject()
        })
        .catch(reject)
    })
  }

  useEffect(() => {
    load()
  }, [])

  function load() {
    if (!stockPosition) return

    loadStockPositions({ stockPositionId: stockPosition.id }).catch(console.error)
  }

  function groupItems(itemsToGroup: StockPositionAvailability[]) {
    const collection: StockPositionInfoCollection[] = []
    const ids: string[] = []
    itemsToGroup.forEach(i => {
      if (ids.indexOf(i.article.id) === -1) {
        ids.push(i.article.id)
      }
    })
    ids.forEach(id => {
      const filteredItems = itemsToGroup.filter(i => i.article.id === id)
      if (filteredItems.length > 0) {
        collection.push({ collection: filteredItems })
      }
    })
    return collection
  }

  function showSelectedItem(stockPositionCollection: StockPositionInfoCollection) {
    if (stockPositionCollection.collection.length > 0) {
      loadItemAndNavigate(stockPositionCollection.collection[0])
    }
  }

  function loadItemAndNavigate(stockPosAvailability: StockPositionAvailability) {
    loader.setLoading(true)
    api
      .getItem(
        stockPosAvailability.serialnumber?.number
          ? { serialNumber: stockPosAvailability.serialnumber.number }
          : { articleCode: stockPosAvailability.article?.code }
      )
      .then(result => {
        if (result && result.length > 0) {
          navigation.navigate(ScreenType.ArticleAvailability, { item: { article: result[0].article } })
        }
      })
      .finally(() => loader.setLoading(false))
      .catch(console.error)
  }

  return (
    <MWS_Screen>
      <StockPositionCard stockPosition={stockPosition} />
      <Title style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_BIG_COMPONENTS }}>{i18n.t('Availability')}</Title>
      <FlatList
        style={{ flex: 1 }}
        data={items}
        renderItem={({ item }) => (
          <StockPositionArticle
            articleDTO={item.collection[0].article}
            onPress={() => showSelectedItem(item)}
            quantity={Utils.sum(item.collection, q => q.quantity ?? 0)}
            endButton={{
              icon: 'barcode',
              disabled: !item.collection.find(c => !!c.serialnumber),
              onPress: () => navigation.navigate(ScreenType.StockPositionSerial, { collection: item }),
            }}
          />
        )}
        refreshControl={<IMRefreshControl refreshing={loading} onRefresh={load} />}
        ListFooterComponent={(items && items.length > 0) || loading ? <ListSpacer /> : <NoEntriesTag />}
      />
    </MWS_Screen>
  )
}

function StockPositionArticle({
  articleDTO,
  quantity,
  onPress,
  endButton,
}: {
  articleDTO: ArticleDTO
  quantity: number
  onPress: () => void
  endButton: MWSBaseCardEndIconButtonProps
}) {
  const userSettings = useUserSettings()
  const article = useArticle(articleDTO, { useSalesUnit: userSettings?.isShowingVpk1Active })
  if (article?.isSerialNumberActive && userSettings?.isShowingVpk1Active && article.salesUnit) article?.setUsedUnit(article.salesUnit)

  return (
    <ArticleCard
      article={article?.info}
      quantity={article?.convertQuantity(quantity)}
      unit={article?.getUsedUnit()}
      onPress={onPress}
      endButton={endButton}
    />
  )
}
