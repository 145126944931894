import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import MWS_Screen from '../../../components/MWS/MWS_Screen'
import { usePackingList } from '../../../contexts/packingLists/PackingListContext'
import PackingListCompletionListView from '../../../views/PackingList/PackingListCompletionListView'

export default function PackingListCompletionScreen() {
  const { i18n } = useLanguage()
  const { isProductionConsignment } = usePackingList()

  return (
    <MWS_Screen noTopPadding title={i18n.t('PackingListConclude')} hideGoBackButton={isProductionConsignment}>
      <PackingListCompletionListView />
    </MWS_Screen>
  )
}
