import { ClientProvider } from '@infominds/react-api'

import {
  ColloConcludePostRequest,
  ColloDeleteRequest,
  ColloNumberForFinalPickingRequest,
  ColloNumberShippingDataRequest,
  ColloPackingRequest,
  ColloPostRequest,
  ColloRequest,
  ColloTransferPostRequest,
  ColloUnloadPackingListRequest,
  ColloUnloadPostRequest,
  ColloWeightRequest,
} from './apiRequestTypes'
import { Collo, ColloUnloadPackingList } from './apiTypes'

export const collo = ClientProvider<Collo[], ColloRequest, string, ColloPostRequest, unknown, unknown, boolean, unknown, ColloDeleteRequest>(
  'colloNumbers'
)
export const colloConclude = ClientProvider<unknown, unknown, string, ColloConcludePostRequest, unknown, unknown, boolean, ColloDeleteRequest>(
  'colloNumbers/conclude'
)
export const colloPackaging = ClientProvider<unknown, unknown, boolean, ColloPackingRequest, unknown, unknown, boolean, ColloDeleteRequest>(
  'colloNumbers/packing'
)
export const colloNumberForFinalPickingPackaging = ClientProvider<Collo[], ColloNumberForFinalPickingRequest, boolean, ColloPackingRequest>(
  'colloNumbers/forFinalPicking'
)
export const colloNumberShippingData = ClientProvider<unknown, unknown, boolean, ColloNumberShippingDataRequest>('colloNumbers/shippingData')
export const colloTransfer = ClientProvider<unknown, unknown, boolean, ColloTransferPostRequest>('colloNumbers/transfer')
export const colloUnloadedPackagingList = ClientProvider<ColloUnloadPackingList[], ColloUnloadPackingListRequest>(
  'colloNumbers/unloadingPackingLists'
)
export const colloUnload = ClientProvider<unknown, unknown, boolean, ColloUnloadPostRequest>('colloNumbers/unloading')
export const colloWeight = ClientProvider<number, ColloWeightRequest>('colloNumbers/weight')
