import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { OngoingInventoryToConfirm } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function OngoingInventoryToConfirmListCard(props: {
  ongoingInventory: OngoingInventoryToConfirm
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
}) {
  return (
    <MWS_BaseCard onPress={props.onPress} onDelete={props.onDelete} icon={'boxes-stacked'} style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, justifyContent: 'center', marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}>
        <IMTextWithIcon primary icon={'shelf'} text={stockPositionUtils.getTitle(props.ongoingInventory.stockposition)} />
        {props.ongoingInventory.date && (
          <View style={{ justifyContent: 'center' }}>
            <IMText secondary>{`${Utils.FormatDate(new Date(props.ongoingInventory.date), 'DD/MM/yyyy') ?? ''}`}</IMText>
          </View>
        )}
      </View>
    </MWS_BaseCard>
  )
}
