import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'

import api from '../../apis/apiCalls'
import { Deposit } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { ScreenType } from '../../types'
import DepositSelectionView from '../../views/InputViews/DepositSelectionView'

export default function OrderProposalDepositSelectionScreen() {
  const { i18n } = useLanguage()

  const navigation = useNavigation()
  const userSettings = useUserSettings()
  const loader = useLoadingIndicator()
  const [deposit, setDeposit] = useState<Deposit | undefined>()

  function onDepositSelected(depositSelected: Deposit) {
    navigation.navigate(ScreenType.OrderProposal, { deposit: depositSelected })
  }

  useEffect(() => {
    load()
      .catch(console.error)
      .finally(() => loader.setLoading(false))
  }, [])

  async function load() {
    if (!deposit) {
      loader.setLoading(true)
      if (!deposit && userSettings?.depositId) {
        const results = await api.getDeposit({ id: userSettings?.depositId })
        if (results && results.length > 0) {
          setDeposit(results[0])
          navigation.navigate(ScreenType.OrderProposal, { deposit: results[0] })
        }
      }
    }
  }

  return (
    <MWS_Screen title={i18n.t('CreateOrderProposal')}>
      <DepositSelectionView onSelected={onDepositSelected} title={`${i18n.t('Deposit')} ${i18n.t('OrderProposal')}`} />
    </MWS_Screen>
  )
}
