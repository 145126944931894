import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'

import api from '../apis/apiCalls'
import { Item, StockPosition } from '../apis/apiTypes'
import ArticleCard from '../cards/Article/ArticleCard'
import { Article } from '../classes/Article'
import FullScreenModal from '../components/FullScreenModal'
import VKUnitAmountSelector from '../components/MWS/Inputs/VKUnitAmountSelector'
import StockPositionSelector from '../components/MWS/StockPosition/StockPositionSelector'
import { useLoadingIndicator } from '../contexts/LoadingIndicatorContext'
import { usePackingList } from '../contexts/packingLists/PackingListContext'
import useAlert from '../hooks/useAlert'
import { ModalController } from '../hooks/useModalController'
import { articleUtils } from '../utils/articleUtils'
import stockPositionUtils from '../utils/stockPositionUtils'

export default function PackingListAddItemModal(props: { controller: ModalController<Item>; onDone: (openMovementWithId?: string) => void }) {
  const { i18n } = useLanguage()

  const alert = useAlert()
  const loader = useLoadingIndicator()
  const [selectedStockPosition, setSelectedStockPosition] = useState<StockPosition | undefined>()
  const [article, setArticle] = useState<Article | null>(null)
  const { packingLists, userSettings } = usePackingList()
  const anyPackingList = packingLists?.length ? packingLists[0] : undefined
  const stockPositionRequired = !!anyPackingList?.deposit?.isStockpositionActive

  useEffect(() => {
    if (!props.controller.isShown) return
    setSelectedStockPosition(undefined)
    if (!props.controller.data?.article) {
      props.controller.close()
      return
    }
    loader.setLoading(true)
    const tmpArt = new Article(props.controller.data?.article, { useSalesUnit: !!userSettings?.isVpk1PickingActive })
    tmpArt
      .loadAvailability(false, anyPackingList?.deposit?.id)
      .catch(console.error)
      .finally(() => {
        setArticle(tmpArt)
        setSelectedStockPosition(
          stockPositionUtils.checkIfOnlyOneStockPositionIsAvailable(tmpArt.getStockPositions(true, true), anyPackingList?.deposit?.id)?.stockposition
        )
        loader.setLoading(false)
      })
  }, [props.controller.isShown])

  function handleCreate(quantity: number) {
    if (!articleUtils.quantityValidator(quantity) || !anyPackingList || !props.controller.data?.article) {
      return
    }
    loader.setLoading(true)
    api
      .addPackingListMovement({
        packinglistId: anyPackingList.id,
        articleId: props.controller.data?.article.id,
        quantity: quantity,
        stockpositionId: selectedStockPosition?.id,
      })
      .then(movementId => props.onDone(movementId))
      .catch(reason => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert.error(i18n.t('FailedToCreatePackingListMovement'), reason?.Message)
      })
      .finally(() => loader.setLoading(false))
  }

  return (
    <FullScreenModal
      isVisible={props.controller.isShown}
      close={() => {
        props.controller.close()
      }}
      title={i18n.t('PackingListAddMovement')}>
      <ArticleCard article={props.controller.data?.article} />
      {!!stockPositionRequired && (
        <StockPositionSelector
          stockPosition={selectedStockPosition}
          deposit={anyPackingList?.deposit}
          onSelected={setSelectedStockPosition}
          availability={article?.getStockPositions(false, true)}
        />
      )}
      {(!!selectedStockPosition || !stockPositionRequired) && (
        <VKUnitAmountSelector setValue={handleCreate} autoFocus usedUnit={article?.getUsedUnit()} />
      )}
    </FullScreenModal>
  )
}
