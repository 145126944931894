import { DateUtils, Language, Utils } from '@infominds/react-native-components'
import { i18n } from 'i18next'

import { REGEX } from '../constants/Constants'
import {
  DataFilterValue,
  FilterConfig,
  FilterConfigOptions,
  FilterDataSorter,
  GroupConfig,
  GroupDataSorter,
  GroupedData,
  OrderConfig,
  OrderDataSorter,
  SearchKey,
} from '../types'

type DataSorter<T, TSub = void> = OrderDataSorter<T, TSub> | GroupDataSorter<T, TSub>

export const filterUtils = {
  initDataGroup<T extends object, TSub = void>(config: GroupConfig<T, TSub> | undefined): GroupDataSorter<T, TSub>[] {
    if (!config) return []

    return config.config.map<GroupDataSorter<T, TSub>>(([dataKey, textKey, options]) => ({
      id: `${dataKey.toString()}${options?.modifier ?? ''}${options?.idSuffix ?? ''}`,
      fieldId: `${dataKey.toString()}${options?.idSuffix ?? ''}`,
      type: config.type,
      dataKey,
      textKey,
      active: !!options?.isDefault,
      options,
    }))
  },
  initDataOrder<T extends object, TSub = void>(config: OrderConfig<T, TSub> | undefined): OrderDataSorter<T, TSub>[] {
    if (!config) return []

    return config.config.map<OrderDataSorter<T, TSub>>(([dataKey, textKey, options]) => ({
      id: `${dataKey.toString()}${options?.modifier ?? ''}${options?.idSuffix ?? ''}`,
      fieldId: `${dataKey.toString()}${options?.idSuffix ?? ''}`,
      type: config.type,
      dataKey,
      textKey,
      active: !!options?.isDefault,
      options,
    }))
  },
  initDataFilter<T extends object, TSub = void>(config: FilterConfig<T, TSub> | undefined): FilterDataSorter<T, TSub>[] {
    return (
      config?.config.map<FilterDataSorter<T, TSub>>(([dataKey, textKey, options]) => ({
        id: `${dataKey.toString()}${options?.idSuffix ?? ''}`,
        fieldId: `${dataKey.toString()}${options?.idSuffix ?? ''}`,
        type: config?.type,
        dataKey,
        textKey,
        values: [],
        options,
      })) ?? []
    )
  },
  prepareFilterValues: <T, TSub = void>(
    data: T[],
    dataKey: keyof T,
    filterId: string,
    options: FilterConfigOptions<TSub> | undefined,
    translator: i18n
  ) => {
    let toRet: DataFilterValue[] = []

    switch (options?.filterType) {
      case 'array': {
        const foundValues: TSub[] = []
        const subArrays = data.map(el => el[dataKey]).filter(Boolean)

        for (const subArray of subArrays) {
          if (Array.isArray(subArray)) {
            subArray.forEach(el => {
              foundValues.push(el as TSub)
            })
          }
        }

        foundValues.forEach(val => {
          const value = options.valueExtractor(val)
          if (!value.value) return
          const count = foundValues.filter(el => {
            if (!el || !val) return false
            const eValue = options.valueExtractor(el)
            return eValue.id === value.id && !!eValue.value
          }).length

          toRet.push({
            id: value.id,
            value: value.value,
            count,
          })
        })

        toRet = Utils.keepUniques(toRet, e => (e ? e.id : undefined))
        break
      }
      case 'boolean': {
        let foundValues: string[] = []
        foundValues = data.map(element => element[dataKey]?.toString() ?? '')

        const trueCount = foundValues.filter(el => el !== '').length
        toRet.push({
          id: `${filterId}#true`,
          value: translator.t(options.trueText),
          booleanTypeTrueTag: true,
          count: trueCount,
        })

        const falseCount = foundValues.filter(el => el === '').length
        toRet.push({
          id: `${filterId}#false`,
          value: translator.t(options.falseText),
          booleanTypeTrueTag: false,
          count: falseCount,
        })

        toRet = toRet.filter((value, index, self) => index === self.findIndex(t => t.id === value.id))
        break
      }
      case 'object': {
        const values = data.map(element => element[dataKey] && options.valueExtractor(element[dataKey] as TSub)).filter(q => !!q?.id)
        const foundValues = Utils.keepUniques(values, q => q.id)

        for (const filterValue of foundValues) {
          // add new values
          if (!toRet.find(q => q.id === filterValue.id)) {
            toRet.push({
              id: filterValue.id,
              value: filterValue.value,
            })
          }
        }

        // update the count for each value
        toRet.forEach(value => {
          value.count = data.filter(element => element[dataKey] && options.valueExtractor(element[dataKey] as TSub).id === value.id)?.length
        })

        // filter old values, that no longer exist
        toRet = toRet.filter(filterEntryValue => !!foundValues.find(fv => fv.id === filterEntryValue.id))

        break
      }
      default: {
        let foundValues: string[] = []
        const values = data.map(element => element[dataKey]?.toString() ?? '').filter(q => !!q)
        foundValues = [...new Set(values)]

        for (const filterValue of foundValues) {
          // add new values
          if (!toRet.find(q => q.value === filterValue)) {
            toRet.push({
              id: `${filterId}-${filterValue}`,
              value: filterValue,
            })
          }
        }

        // update the count for each value
        toRet.forEach(value => {
          const count = data.filter(element => element[dataKey]?.toString() === value.value)?.length
          value.count = count
        })

        // filter old values, that no longer exist
        toRet = toRet.filter(filterEntryValue => !!foundValues.find(fv => fv === filterEntryValue.value))
      }
    }

    toRet.sort((a, b) => Utils.compareStringsForSort(a.value, b.value))
    return toRet
  },
  getActiveFilters<T extends object, TSub = void>(filters: FilterDataSorter<T, TSub>[]) {
    const result: FilterDataSorter<T, TSub>[] = []

    for (const filter of filters) {
      const activeValues = filter.values.filter(v => v.active && v.value)
      if (!activeValues.length) continue
      result.push({ ...filter, values: [...activeValues] })
    }
    return result
  },
  getActiveSorter<T extends object>(sorter: DataSorter<T>[]) {
    const result: DataSorter<T>[] = []

    for (const entry of sorter) {
      if (!entry.active) continue
      result.push({ ...entry })
    }

    return result
  },
  sortOrders<T>(a: DataSorter<T>, b: DataSorter<T>) {
    if (a.active && !b.active) return -1
    if (!a.active && b.active) return 1
    return (a.order ?? 0) - (b.order ?? 0)
  },
  applySort<T extends object>(items: T[], orders: DataSorter<T>[]) {
    const activeOrders = orders.filter(o => o.active).sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    // if no active orders are set, use default order instead
    if (!activeOrders.length) {
      const defaultOrder = orders.find(o => o.options?.isDefault)
      if (defaultOrder) activeOrders.push(defaultOrder)
    }
    if (!activeOrders?.length) return [...items]
    const sortedItems = [...items]
    sortedItems.sort((a, b) => {
      for (const activeOrder of activeOrders) {
        const sortDir = activeOrder.options?.modifier === 'inverse' ? -1 : 1
        const valueA = getSorterValues(a, activeOrder)?.at(0) as (string | number)[]
        const valueB = getSorterValues(b, activeOrder)?.at(0) as (string | number)[]
        if (valueA && !valueB) return -1
        if (!valueA && valueB) return 1
        if (!valueA && !valueB) continue
        if (valueA > valueB) return sortDir
        else if (valueA < valueB) return -sortDir
      }
      return 0
    })

    return sortedItems
  },
  applyFilter<T extends object, TSub = void>(items: T[], filters: FilterDataSorter<T, TSub>[]) {
    const activeFilters = this.getActiveFilters(filters)
    if (!activeFilters.length) return items

    const filtered = items.filter(item => {
      for (const activeFilter of activeFilters) {
        switch (activeFilter.options?.filterType) {
          case 'array': {
            const dataValue = item[activeFilter.dataKey]

            const valueExtractor = activeFilter.options.valueExtractor

            if (!dataValue || !Array.isArray(dataValue)) return false

            if (!activeFilter.values.find(activeFilterValue => dataValue.some(el => valueExtractor(el as TSub).id === activeFilterValue.id))) {
              return false
            }
            break
          }
          case 'boolean': {
            const dataValue = item[activeFilter.dataKey]?.toString()
            for (const activeFilterValue of activeFilter.values) {
              if (
                !(
                  (activeFilterValue.active && activeFilterValue.booleanTypeTrueTag === true && dataValue !== undefined) ||
                  (activeFilterValue.booleanTypeTrueTag === false && dataValue === undefined)
                )
              ) {
                return false
              }
            }
            break
          }
          case 'object': {
            const dataValue = item[activeFilter.dataKey] && activeFilter.options.valueExtractor(item[activeFilter.dataKey] as TSub)
            if (!dataValue) return false

            const results = []
            for (const activeFilterValue of activeFilter.values) {
              results.push(activeFilterValue.active && activeFilterValue.id === dataValue.id)
            }

            if (!results.includes(true, 0)) return false

            break
          }
          default: {
            const dataValue = item[activeFilter.dataKey]?.toString()
            if (!dataValue) return false

            const results = []
            for (const activeFilterValue of activeFilter.values) {
              results.push(activeFilterValue.active && activeFilterValue.value === dataValue)
            }

            if (!results.includes(true, 0)) return false

            break
          }
        }
      }

      return true
    })

    return filtered
  },
  groupBy<T extends object, TSub = void>(
    items: T[],
    group: DataSorter<T> | undefined,
    filter: FilterDataSorter<T, TSub>[],
    language: Language,
    i18nValue: i18n,
    sortInverse?: boolean
  ): GroupedData<T>[] {
    if (!items || !group?.active) return [{ key: null, title: '', rawTitle: '', data: items }]

    // find all unique group keys
    const keys = Utils.keepUniques(
      items.reduce<unknown[]>((result, q) => {
        const v = getSorterValues(q, group)
        if (!v?.length) return [...result, '']
        result.push(...v)
        return result
      }, []),
      q => q
    )
    const sortValue = sortInverse ? -1 : 1

    // create the grouped data and fill it with the corresponding items
    return keys
      .map<GroupedData<T>>(keyValue => {
        const data = items.filter(item => {
          const sv = getSorterValues(item, group)
          if (!keyValue && !sv?.length) return true
          return sv?.find(v => v === keyValue)
        })
        if (!data.length) return { key: '', title: '', rawTitle: '', data: [] }
        let title = ''
        if (group.options?.textKey) {
          if (typeof group.options.textKey === 'string') {
            title = data[0][group.options.textKey]?.toString() ?? ''
          } else if (typeof group.options.textKey === 'object') {
            const selectedLanguageValue =
              data[0][group.options.textKey[language]]?.toString() ||
              data[0][group.options.textKey.de]?.toString() ||
              data[0][group.options.textKey.it]?.toString() ||
              data[0][group.options.textKey.en]?.toString()
            if (selectedLanguageValue) {
              title = selectedLanguageValue
            }
          }
        } else if (group.options?.textProvider) {
          title = i18nValue.t(group.options.textProvider(keyValue))
        }
        if (!title) title = keyValue?.toString() ?? ''
        const rawTitle = title
        if (title.match(REGEX.DATE)) {
          try {
            const formattedDate = DateUtils.formatDate(DateUtils.dateify(title), 'P', language)
            if (formattedDate) title = formattedDate
          } catch (_e) {
            _e
          }
        }
        return { key: keyValue?.toString() ?? null, title, data, rawTitle }
      })
      .filter(q => {
        if (!q.data.length) return false
        // check if group is also filtered. if true then filter if current element is not active
        const activeFilterForGroup = filter.find(f => f.values.some(v => v.active) && f.id === group.id)
        if (!activeFilterForGroup) return true
        return activeFilterForGroup.values.find(v => v.value === q.rawTitle && v.active)
      })
      .sort((a, b) =>
        group.options?.titleSortFunction
          ? group.options?.titleSortFunction(a.rawTitle, b.rawTitle) * sortValue
          : !a.rawTitle && b.rawTitle
            ? 1
            : a.rawTitle && !b.rawTitle
              ? -1
              : Utils.compareStringsForSort(a.rawTitle, b.rawTitle) * sortValue
      )
  },
  groupedDataToSectionList<T extends object>(data: GroupedData<T>[], notAssignedText?: string, hasGrouping?: boolean) {
    const result: (string | T)[] = []
    for (const entry of data) {
      if (!entry.data.length) continue
      if (entry.key || (notAssignedText && data.length > (hasGrouping ? 0 : 1))) result.push(entry.title || entry.key || notAssignedText || '')
      result.push(...entry.data)
    }
    return result
  },
  searchOnItem<T extends object | string>(item: T | undefined, search: string, keys?: SearchKey<T>[]) {
    if (!item) return false
    if (!search) return true
    const searchTerms = search
      .trim()
      .split(' ')
      .map(q => q.toLowerCase().trim())
      .filter(q => !!q)
    if (typeof item === 'string') {
      const lowerCaseItem = item.toLowerCase()
      for (const searchTerm of searchTerms) {
        if (!lowerCaseItem.includes(searchTerm)) return false
      }
      return true
    }
    if (!keys) return true
    for (const searchTerm of searchTerms) {
      let found = false
      for (const key of keys) {
        let itemValue: unknown = null
        // if searchKey is of type string then add the value directly from the object
        if (typeof key === 'string') {
          itemValue = item[key]
          // if searchKey is a combination-key then join the values using the separator ([0])
        } else if (Array.isArray(key)) {
          const [t1, t2, ...tr] = key
          if (typeof t2 === 'function') {
            const value = item[t1 as keyof T]
            if (!value) continue
            itemValue = t2(value)
          } else {
            const separator = t1 as string
            const combinationKeys = [t2, ...tr]

            if (combinationKeys?.length) {
              itemValue = combinationKeys
                .map(comKey => item[comKey]?.toString())
                .filter(q => !!q)
                .join(separator)
            }
          }
        }
        if (itemValue === undefined || itemValue === null) continue
        if (typeof itemValue === 'string' && itemValue.toLowerCase().includes(searchTerm)) {
          found = true
          break
        }
        if (typeof itemValue === 'number' && itemValue.toString() === searchTerm) {
          found = true
          break
        }
      }
      if (!found) return false
    }
    return true
  },
  filterItemsBySearch<T extends object | string>(items: T[] | undefined, search: string, keys?: SearchKey<T>[]) {
    return items?.filter(item => this.searchOnItem(item, search, keys)) ?? []
  },
}

function getSorterValues<T extends object, TSub>(obj: T, sorter: DataSorter<T, TSub>): unknown[] | null {
  const value = obj[sorter.dataKey]
  if (value === undefined || value === null) return null
  if (typeof value !== 'object') return [value]

  if (sorter.options?.subObjectValueProvider) {
    const providedValue = sorter.options.subObjectValueProvider(value as TSub, obj)
    if (!providedValue) return null
    return Array.isArray(providedValue) ? providedValue : [providedValue]
  }
  if (sorter.options?.subObjectKey) {
    const subObjectKey = sorter.options?.subObjectKey
    if (Array.isArray(value)) {
      const foundValues = (value as TSub[]).filter(v => !!v[subObjectKey])
      if (!foundValues) return null
      return foundValues.map(sv => sv[subObjectKey]).filter(Boolean)
    }
    const subValue = value[subObjectKey as keyof typeof value]
    if (!subValue) return null
    return [subValue]
  }
  console.warn('DataSorter value was an object, but no subObjectKey or valueProvider was set in the configuration', sorter.dataKey, sorter.textKey)
  return null
}
