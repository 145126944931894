import React from 'react'
import { View } from 'react-native'

import LoadingIndicator, { LoadingIndicatorProps } from './old/LoadingIndicator'

export default function IMLoadingIndicator(props: LoadingIndicatorProps) {
  if (!props.isVisible) return <></>

  return (
    <View style={{ height: 70, alignContent: 'center' }}>
      <LoadingIndicator {...props} />
    </View>
  )
}
