import React, { useCallback } from 'react'

import { StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import { Article } from '../../classes/Article'
import { ModalController } from '../../hooks/useModalController'
import SelectionModalFullScreen from '../SelectionModalFullScreen'

export type SerialNumberSelectionModalProps = {
  article: Article
  controller: ModalController<StockPositionAvailability[]>
  onSelected: (serial: StockPositionAvailability) => void
  unitConversionFactor?: number
}

export default function SerialNumberSelectionModal({ article, controller, onSelected, unitConversionFactor }: SerialNumberSelectionModalProps) {
  function convertQuantityToText(quantity: number | undefined) {
    if (!quantity) return undefined
    if (unitConversionFactor) {
      return article.getQuantityWithUnitText(quantity * unitConversionFactor, true)
    }
    return article.getQuantityWithUnitText(quantity)
  }

  const RenderItem = useCallback(
    (serial: StockPositionAvailability) => (
      <SerialnumberCard
        serialnumber={serial.serialnumber}
        stockPosition={serial.stockposition}
        quantity={convertQuantityToText(serial.quantity)}
        onPress={() => {
          controller.close()
          onSelected(serial)
        }}
      />
    ),
    [article, onSelected, unitConversionFactor]
  )

  return <SelectionModalFullScreen show={controller.isShown} items={controller.data ?? []} close={controller.close} renderItem={RenderItem} />
}
