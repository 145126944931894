import { IM, IMLayout, ModalController, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { FlatList } from 'react-native'

import { PackingList, PackingListMovement } from '../../apis/apiTypes'
import PackingListMovementCard from '../../cards/Packinglist/PackingListMovementCard'
import ElusiveButton from '../../components/ElusiveButton'
import FullScreenModal from '../../components/FullScreenModal'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import packingListUtils from '../../utils/packingListUtils'

export type ProductionConsignmentCompletionModalProps = {
  controller: ModalController<PackingList>
  onConclude: (packingList: PackingList) => void
  onAbort?: () => void
}

export default function ProductionConsignmentCompletionModal({ controller, onConclude, onAbort }: ProductionConsignmentCompletionModalProps) {
  const { i18n } = useLanguage()

  return (
    <FullScreenModal
      noTopPadding
      isVisible={controller.isShown}
      close={() => {
        controller.close()
        onAbort?.()
      }}
      title={i18n.t('PackingListConclude')}>
      <Content controller={controller} onConclude={onConclude} />
    </FullScreenModal>
  )
}

function Content({ controller, onConclude }: ProductionConsignmentCompletionModalProps) {
  const { i18n } = useLanguage()
  const { movements } = usePackingList()
  const packingListMovementsToShow = useMemo<PackingListMovement[]>(() => {
    return packingListUtils.getMovementsFromPackingListArticles(movements ?? []).filter(m => m.packinglistId === controller.data?.id)
  }, [controller.data, movements])

  return (
    <IM.View style={[IMLayout.flex.f1]}>
      <IMText
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
          marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
        }}>
        {controller.data?.number}
      </IMText>
      <IMTextWithIcon
        viewStyle={{ justifyContent: 'center', marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        iconColor={Colors.info}
        icon={'exclamationTriangle'}
        text={i18n.t('PackingListIncompleteMovements')}
      />
      <FlatList data={packingListMovementsToShow} renderItem={({ item }) => <PackingListMovementCard item={item} />} />

      <ElusiveButton
        title={i18n.t('ConcludePackingList')}
        onPress={() => {
          controller.close()
          if (controller.data) onConclude(controller.data)
        }}
      />
    </IM.View>
  )
}
