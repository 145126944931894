import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { View } from 'react-native'

import { ArticleDepositQuantity } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import TextRow from '../../components/TextRow'
import MWS_BaseCard from '../MWS_BaseCard'

export default function ArticleDepositPurchaseIncomingQuantitiesCard(props: { article: Article; depositQuantity: ArticleDepositQuantity }) {
  const { i18n } = useLanguage()

  function getQuantityWithUnit(quantity: number | undefined) {
    return props.article.getQuantityWithUnitText(quantity)
  }

  return (
    <MWS_BaseCard icon="warehouse-full">
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <TextRow
          textStyle={{ margin: 0 }}
          texts={[
            { text: i18n.t('FreePurchaseIncoming'), style: { textAlign: 'left' } },
            { text: getQuantityWithUnit(props.depositQuantity.quantityFreeInputs), style: { textAlign: 'right' } },
          ]}
        />
      </View>
    </MWS_BaseCard>
  )
}
