import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import IMModal from '../components/IMModal'
import VKUnitAmountSelector, { VKUnitAmountSelectorProps } from '../components/MWS/Inputs/VKUnitAmountSelector'
import { STYLE_CONSTANTS } from '../constants/Constants'

export type AmountEditorModalProps = {
  show: boolean
  close: () => void
  headerRenderItem?: JSX.Element
  title?: string
  onSubmit: VKUnitAmountSelectorProps['setValue']
} & Omit<VKUnitAmountSelectorProps, 'setValue'>

export default function AmountEditorModal({ close, show, title, headerRenderItem, style, onSubmit, ...props }: AmountEditorModalProps) {
  const { i18n } = useLanguage()

  return (
    <IMModal title={title ?? i18n.t('EditAmount')} isVisible={show} keyboardAvoiding close={close}>
      {headerRenderItem}
      <VKUnitAmountSelector
        style={[{ marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }, style]}
        setValue={onSubmit}
        {...props}
      />
    </IMModal>
  )
}
