import { useNavigation } from '@react-navigation/native'
import React from 'react'

import api from '../../apis/apiCalls'
import { Worksheet } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { ScreenType } from '../../types'
import WorksheetSelectionView from '../../views/Worksheet/WorksheetSelectionView'

export default function WorksheetSelectionScreen() {
  const navigation = useNavigation()
  const loader = useLoadingIndicator()

  function handleWorksheetSelected(worksheet: Worksheet) {
    loader.setLoading(true)
    api
      .getArticle({ id: worksheet?.articleId })
      .then(result => {
        if (result && result.length > 0) {
          navigation.navigate(ScreenType.Worksheet, { worksheet: worksheet, article: new Article(result[0]) })
        }
      })
      .finally(() => loader.setLoading(false))
      .catch(console.error)
  }

  return <MWS_Screen>{<WorksheetSelectionView onSelected={handleWorksheetSelected} />}</MWS_Screen>
}
