import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, FlatListProps, View } from 'react-native'

import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import Button from './old/Button'

interface DeletableListProps<T> {
  anySelected: boolean
  onDeletePressed: () => void
}

export default function DeletableList<T>(props: DeletableListProps<T> & FlatListProps<T>) {
  const { i18n } = useLanguage()

  const { anySelected, onDeletePressed, ...flatListProps } = props

  return (
    <View style={{ flex: 1 }}>
      <FlatList {...flatListProps} />
      {anySelected && (
        <Button
          style={{ backgroundColor: Colors.error, marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
          title={i18n.t('DeleteSelected')}
          onPress={onDeletePressed}
        />
      )}
    </View>
  )
}
