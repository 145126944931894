import { useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Article } from '../../../classes/Article'
import useAlert from '../../../hooks/useAlert'
import { articleUtils } from '../../../utils/articleUtils'
import VKUnitAmountSelector from '../Inputs/VKUnitAmountSelector'

export interface TransferQuantitySelectorProps {
  article: Article | undefined
  proposedQuantity?: number
  onQuantitySelected?: (value: number) => void
  onQuantitySubmitted?: (value: number) => Promise<void>
  availabilityCheckFailedWorkaroundMessage?: string
  style?: StyleProp<ViewStyle>
  availability?: number
  availabilityTolerance?: number
  ignoreAvailability?: boolean
  autoFocus?: boolean
  allowUnityChange?: boolean
}

export default function TransferQuantitySelector(props: TransferQuantitySelectorProps) {
  const { i18n } = useLanguage()

  const [busy, setBusy] = useState(false)
  const alert = useAlert()

  function handleSelection(quantity: number) {
    if (!articleUtils.quantityValidator(quantity, props.availability, props.availabilityTolerance)) {
      if (props.availabilityCheckFailedWorkaroundMessage) {
        alert
          .yesNo(props.availabilityCheckFailedWorkaroundMessage)
          .then(result => {
            if (result) {
              submitQuantity(quantity)
            }
          })
          .catch(console.error)
        return
      } else if (!props.ignoreAvailability) {
        alert.info(i18n.t('SelectedAmountInvalidAlert'), props.availability)
        return
      }
    }
    submitQuantity(quantity)
  }

  function submitQuantity(quantity: number) {
    if (props.onQuantitySelected) props.onQuantitySelected(quantity)
    if (props.onQuantitySubmitted) {
      setBusy(true)
      props
        .onQuantitySubmitted(quantity)
        .catch(console.error)
        .finally(() => setBusy(false))
    }
  }

  return (
    <VKUnitAmountSelector
      value={1}
      article={props.article}
      proposedQuantity={props.proposedQuantity}
      setValue={handleSelection}
      autoFocus={!!props.autoFocus || props.autoFocus === undefined}
      usedUnit={props.article?.getUsedUnit()}
      units={props.allowUnityChange ? props.article?.info.measurementUnits : undefined}
      disabled={busy}
      onUnitSelected={unit => props.article?.setUsedUnit(unit)}
      style={props.style}
    />
  )
}
