import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import SerialNumberAvailabilitySelector from '../../components/MWS/Inputs/SerialNumberAvailabilitySelector'
import PackingListColloSelector from '../../components/MWS/PackingList/PackingListColloSelector'
import Button from '../../components/old/Button'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import useAlert from '../../hooks/useAlert'
import MultiSerialSelectionModal from '../../modals/MultiSerialSelectionModal'
import packingListUtils from '../../utils/packingListUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import StockPositionWithAvailabilitySelectionView from '../InputViews/StockPositionWithAvailabilitySelectionView'

export default function PackingListMovementInputView(props: { style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  const loader = useLoadingIndicator()
  const {
    packingListArticle,
    colloSelector,
    article,
    deposit,
    stockPosition,
    missingQuantity,
    scannedSerialNumber,
    anyMovement,
    selectedQuantity,
    addCollos,
    addCollosWithSerialNumbers,
  } = usePackingListMovement()
  const { forceScan, colloMode, isPreConsignment, isProductionConsignment } = usePackingList()
  const [showSerialSelectionModal, setShowSerialSelectionModal] = useState<boolean>(false)
  const serialFilter = useRef<{ serialNumber: string | undefined; stockPosition: StockPosition | undefined } | undefined>(undefined)
  const loadedDepositSerialNumberAvailability = useRef(false)

  useEffect(() => {
    if (!packingListArticle) return

    if (article?.isSerialNumberActive) {
      const serialsToAdd: SerialNumber[] = []
      if (scannedSerialNumber) {
        serialsToAdd.push(scannedSerialNumber)
      }
      packingListArticle.movements.forEach(movement => {
        if (!movement.movement.orderSerialnumberquantities.length) return

        serialsToAdd.push(
          ...movement.movement.orderSerialnumberquantities
            .map(sn => sn.serialnumber)
            .filter(q => !colloSelector.items.some(c => c.serialnumber?.id === q?.id) && (!scannedSerialNumber || q.id !== scannedSerialNumber.id))
        )
      })

      if (serialsToAdd) {
        addSerialNumbers(serialsToAdd)
        return
      }

      if (article.isHistoricalSerialNumber) handleHistoricalSerialScanned(deposit, stockPosition)
      return
    }
    if (forceScan) return
    if (missingQuantity > 0 && deposit) addColloFromPosition(deposit, stockPosition, true)
  }, [])

  function handleError(error: unknown) {
    loader.setLoading(false)
    console.error(error)
  }

  function addColloFromPosition(fromDeposit: Deposit | undefined, fromStockPosition?: StockPosition, ignoreError?: boolean) {
    if (!anyMovement?.movement || !article || article.isSerialNumberActive) return
    if (!article.hasStock) {
      addCollos(missingQuantity, fromDeposit, fromStockPosition)
      return
    }
    loader.setLoading(true)
    api
      .getItemAvailability({
        articleId: article.info.id,
        depositId: fromDeposit?.id,
        stockPositionId: fromStockPosition?.id,
      })
      .then(result => {
        if (!result || result <= 0) {
          if (!ignoreError) {
            alert.info(i18n.t('StockPositionNotAvailable'), stockPositionUtils.getTitle(fromStockPosition, fromDeposit))
          }
          return
        }
        result *= packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)
        addCollos(Math.min(result, missingQuantity), fromDeposit, fromStockPosition)
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function addSerialNumbers(serialNumbers: SerialNumber[]) {
    loader.setLoading(true)
    Promise.allSettled(
      serialNumbers.map(sn =>
        api.getSerialnumberQuantities({ articleId: article?.info.id, serialnumberId: sn.id, depositId: anyMovement?.movement?.deposit?.id })
      )
    )
      .then(availabilities => {
        let failedToAddSerials = serialNumbers.map(sn => sn.id)
        const serialsToAdd: StockPositionAvailability[] = []
        if (availabilities?.length) {
          availabilities.forEach(availability => {
            if (availability.status === 'rejected' || !availability.value.length || !availability.value[0].quantity) {
              return
            }
            serialsToAdd.push(availability.value[0])

            failedToAddSerials = failedToAddSerials.filter(sn => sn !== availability.value[0].serialnumber?.id)
          })
        }
        addCollosWithSerialNumbers(serialsToAdd)
        if (failedToAddSerials.length) {
          alert.info(i18n.t('NoSerialAvailability'), failedToAddSerials.join(', '))
        }
        return
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function handleHistoricalSerialScanned(fromDeposit?: Deposit, fromStockPosition?: StockPosition) {
    if (!anyMovement?.movement || !article || !scannedSerialNumber) return
    loader.setLoading(true)
    loadedDepositSerialNumberAvailability.current = false
    packingListUtils
      .getHistoricalSerialNumberAvailability(scannedSerialNumber, article.info, fromDeposit, fromStockPosition)
      .then(availability => {
        serialFilter.current = { serialNumber: undefined, stockPosition: undefined }
        if (article) article.serialNumberQuantities = availability ?? []
        if (!availability) return
        if (scannedSerialNumber) {
          const matchingSerials = availability.filter(s => serialnumberUtils.compareByNumber(s.serialnumber, scannedSerialNumber))
          if (matchingSerials.length <= 0) return alert.info(i18n.t('NoSerialAvailability'), scannedSerialNumber.number)
          if (matchingSerials.length === 1) return addCollosWithSerialNumbers(matchingSerials)
          const matchingSerialsOnPosition = matchingSerials.filter(s =>
            stockPositionUtils.comparePosition(s.deposit, fromDeposit, s.stockposition, fromStockPosition)
          )
          if (matchingSerialsOnPosition.length === 1) return addCollosWithSerialNumbers(matchingSerialsOnPosition)
          serialFilter.current.serialNumber = scannedSerialNumber.number
          setShowSerialSelectionModal(true)
          return
        }

        if (availability.length <= 0 || missingQuantity <= 0 || forceScan) return
        const stockPosSerials = (article?.serialNumberQuantities ?? []).filter(s =>
          stockPositionUtils.comparePosition(s.deposit, fromDeposit, s.stockposition, fromStockPosition)
        )
        if (stockPosSerials.length <= 0) return
        if (stockPosSerials.length === 1) {
          addCollosWithSerialNumbers(stockPosSerials)
        } else if (!colloSelector.any) {
          serialFilter.current.stockPosition = anyMovement?.movement?.stockposition
          setShowSerialSelectionModal(true)
          return
        }
      })
      .catch(handleError)
      .finally(() => loader.setLoading(false))
  }

  function filterSerialInSelection(serial: StockPositionAvailability) {
    if (
      !!article?.isSerialNumberActive &&
      colloSelector.items
        .filter(i => !i.id)
        .find(
          c =>
            serialnumberUtils.compareByNumber(c.serialnumber, serial.serialnumber) &&
            stockPositionUtils.comparePosition(c.deposit, serial.deposit, c.stockposition, serial.stockposition)
        )
    ) {
      return false
    }
    if (!serialFilter.current) return true
    if (!!serialFilter.current.stockPosition && !stockPositionUtils.compare(serial.stockposition, serialFilter.current.stockPosition)) return false
    if (
      !!serialFilter.current.serialNumber &&
      !serialnumberUtils.compareByNumber({ number: serialFilter.current.serialNumber } as SerialNumber, serial.serialnumber)
    ) {
      return false
    }
    return true
  }

  function handleSerialSelection(serialNumberAvailability: StockPositionAvailability[] | StockPositionAvailability) {
    if (Array.isArray(serialNumberAvailability)) {
      addCollosWithSerialNumbers(serialNumberAvailability)
    } else {
      addCollosWithSerialNumbers([serialNumberAvailability])
    }
  }

  return (
    <View style={props.style}>
      <MultiSerialSelectionModal
        allowMultiSelection
        header={
          <PackingListArticleCard
            item={packingListArticle}
            navigateToArticleInfoOnPress
            showAvailability
            quantity={selectedQuantity}
            showPackingLists={isPreConsignment || isProductionConsignment}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
          />
        }
        title={`${i18n.t('SelectSerialnumber')} (${missingQuantity} ${anyMovement?.movement?.unitCode ?? ''})`}
        show={showSerialSelectionModal}
        article={article}
        close={() => {
          serialFilter.current = undefined
          setShowSerialSelectionModal(false)
        }}
        filterItem={filterSerialInSelection}
        onSelected={serials => {
          serialFilter.current = undefined
          setShowSerialSelectionModal(false)
          addCollosWithSerialNumbers(serials)
        }}
        showWarrantyDate
      />
      <View style={{ flexDirection: 'row', marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
        {colloMode && !isPreConsignment && !isProductionConsignment && (
          <PackingListColloSelector style={{ flex: 1, marginRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_SPACE_BETWEEN_COMPONENTS }} />
        )}

        {article?.isSerialNumberActive ? (
          <SerialNumberAvailabilitySelector //Serialnumber selection
            article={article}
            style={styles.inputView}
            inputStyle={styles.input}
            onSelected={handleSerialSelection}
            deposit={deposit}
            stockPosition={stockPosition}
            allowEmptyInput={!article.isHistoricalSerialNumber && !forceScan}
            unitConversionFactor={packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)}
            showResultInModal
            considerPickedQuantity={true}
            multiSelection={!article.isLottoSerialNumber}
            allowOtherPositionsOnScan
            filterSerialNumber={sn =>
              !colloSelector.items.find(
                c =>
                  c.serialnumber?.id === sn.serialnumber?.id &&
                  stockPositionUtils.comparePosition(c.deposit, sn.deposit, c.stockposition, sn.stockposition)
              )
            }
          />
        ) : (
          <>
            {!!article?.hasStock && (
              <StockPositionWithAvailabilitySelectionView //StockPosition selection
                style={styles.inputView}
                inputStyle={styles.input}
                onSelected={selection => addColloFromPosition(selection.deposit, selection.stockposition)}
                showResultInModal
                deposit={deposit}
                article={article}
                noListButton={forceScan}
                unitConversionFactor={packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)}
                filterNotProposedInPackingLists
                considerPickedQuantity={true}
                modalHeader={
                  <PackingListArticleCard
                    item={packingListArticle}
                    navigateToArticleInfoOnPress
                    showAvailability
                    quantity={selectedQuantity}
                    showPackingLists={isPreConsignment || isProductionConsignment}
                    style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
                  />
                }
              />
            )}
            {!article?.hasStock && <Button style={{ flex: 1 }} title={i18n.t('Add')} onPress={() => addColloFromPosition(deposit, stockPosition)} />}
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  inputView: { flex: 2 },
  input: { marginHorizontal: 0 },
})
