import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useRef } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { InventoryAccounting, InventoryEntry, StockPosition } from '../../apis/apiTypes'
import InventoryAccountingCard from '../../cards/Inventory/InventoryAccountingCard'
import GrowingList, { LoaderRef } from '../../components/GrowingList'
import Title from '../../components/MWS/Title'
import Button from '../../components/old/Button'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import useItemSelector from '../../hooks/useItemSelector'
import { ScreenType } from '../../types'
import inventoryUtils from '../../utils/inventoryUtils'
import { Utils } from '../../utils/Utils'

interface InventoryListProps {
  inventory: InventoryEntry
  stockPosition: StockPosition | undefined
  style?: StyleProp<ViewStyle>
}

export interface InventoryListGroup {
  items: InventoryAccounting[]
}

export default function InventoryList(props: InventoryListProps) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const selector = useItemSelector<InventoryListGroup>()

  const navigation = useNavigation()
  const loader = useLoadingIndicator()
  const loadRef = useRef<LoaderRef>()

  function load(skip?: number, take?: number) {
    return new Promise<InventoryListGroup[]>(resolve => {
      api
        .getInventoryAccounting({ inventoryCodeId: props.inventory.id, stockPositionId: props.stockPosition?.id, skip: skip, take: take })
        .then(result => {
          if (!result || result.length === 0) {
            resolve([])
            return
          }
          const articles = Utils.keepUniques(
            result.map(r => r.article),
            art => art.id
          )
          resolve(
            articles.map(art => {
              return { items: result.filter(r => r.article.id === art.id) ?? [] } as InventoryListGroup
            })
          )
        })
        .catch(() => resolve([]))
    })
  }

  function deleteSelected() {
    if (!selector?.items || selector.items.length === 0) return
    loader.setLoading(true)

    Promise.all(selector.items.map(item => inventoryUtils.deleteInventoryEntries(item.items)))
      .then(() => {
        selector.clear()
        loadRef.current?.load().catch(console.error)
      })
      .catch(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        alert.error(i18n.t('FailedToDeleteInventory'), err?.Message)
        console.error(err)
      })
      .finally(() => loader.setLoading(false))
  }

  return (
    <View style={[{ flex: 1 }, props.style]}>
      <Title>{i18n.t('Inventory')}</Title>
      <GrowingList
        loadItems={load}
        take={100000}
        reloadOnFocus
        loadRef={loadRef}
        onLoaded={skip => {
          if (!skip) selector.clear()
        }}
        renderItem={({ item }) => (
          <InventoryAccountingCard
            accounting={item.items}
            isSelected={selector.includes(item)}
            onPress={() => {
              selector.toggle(item)
            }}
            onSerialPress={() => {
              navigation.navigate(ScreenType.InventorySerial, {
                inventory: props.inventory,
                stockPosition: props.stockPosition,
                accounting: item.items,
              })
            }}
            useSalesUnit={false}
          />
        )}
      />
      {selector.any && (
        <Button
          style={{ backgroundColor: Colors.error, marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}
          title={i18n.t('DeleteSelected')}
          onPress={deleteSelected}
        />
      )}
    </View>
  )
}
