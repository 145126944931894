import { QualityCharacteristicValue } from '../types'
import { PackingListColloState, PrintingType, ProductionState, StockPositionState } from './apiTypes'

export interface StockTemplateRequest {
  id?: string
  code?: string
}

export enum AdditionalActions {
  None = 'None',
  CreateSerialNumberAfterKochBarcodeDecoding = 'CreateSerialNumberAfterKochBarcodeDecoding',
  OngoingInventory = 'OngoingInventory',
}

export interface ItemRequest {
  searchText?: string
  articleCode?: string
  serialNumber?: string
  depositId?: string
  fetchArticleAndSN?: boolean
  additionalActions?: AdditionalActions[]
}

export interface ItemAvailabilityRequest {
  depositId?: string
  articleId?: string
  serialNumberId?: string
  stockPositionId?: string
}
export interface ItemStockPositionQuantitiesRequest {
  depositId?: string
  articleId?: string
  stockPositionId?: string
  showNegativeQuantities?: boolean
  ignoreSerialNumberDuringOngoingInventory?: boolean
}
export interface ArticleRequest {
  companyId?: string
  id?: string
  code?: string
  searchText?: string
}

export interface StockPositionRequest {
  id?: string
  code?: string
  depositId?: string
  state?: StockPositionState
  NotProposedInPackinglists?: boolean
  production?: boolean
}

export interface DepositRequest {
  id?: string
  code?: string
}

export interface StockPositionAvailabilityRequest {
  stockPositionId?: string
  articleId?: string
}

export interface ArticleAvailabilityRequest {
  depositId?: string
  articleId?: string
  stockPositionId?: string
  useUserDepositFilter?: boolean
  considerPickedQuantity?: boolean
  productionStockPositions?: boolean
}

export interface ArticleGraphicRequest {
  id: string
}

export interface ArticleDescriptionRequest {
  articleId?: string
  textTyp?: TextNumberType
}

export interface GetArticleConfigurableDescriptionRequest {
  id: string
}

export enum TextNumberType {
  Article = 'Article',
  Offer = 'Offer',
  Order = 'Order',
  DeliveryNote = 'Deliverynote',
  Invoice = 'Invoice',
}

export interface ArticleOrdersRequest {
  articleId?: string
  open?: boolean
  partiallyDone?: boolean
  blocked?: boolean
  done?: boolean
}

export interface SerialNumberOrderRequest {
  serialNumberId?: string
  open?: boolean
  partiallyDone?: boolean
  blocked?: boolean
  done?: boolean
}

export interface ArticlePriceRequest {
  articleId?: string
  customerId?: string
  deliveryAddressId?: string
  quantity?: number
}

export interface ArticlePricesRequest {
  articleId?: string
}

export interface ArticleSalesPriceRequest {
  articleId?: string
  customerId?: string
  deliveryAddressId?: string
  measurementUnitId?: string
  quantity?: number
}

export interface ArticleSalesPriceScaleRequest {
  articleId?: string
  customerId?: string
  deliveryAddressId?: string
  measurementUnitId?: string
}

export interface ArticleMeasurementUnitPatchRequest {
  articleId: string
  unitId: string
  netWeight?: number
}

export interface ArticleBarcodePostRequest {
  articleId: string
  barcode: string
  position?: number
}

export interface ArticleDepositDataRequest {
  id?: string
  articleId?: string
  depositId?: string
}

export interface ArticleDepositDataPostRequest {
  articleId?: string
  depositId?: string
  storingPositionId?: string
  minQuantityDeposit?: number
  maxQuantityDeposit?: number
  minQuantityStoringposition?: number
  maxQuantityStoringposition?: number
  packagingUnit?: number
}

export interface ArticleDepositDataPatchRequest {
  id: string
  depositId?: string
  storingPositionId?: string
  minQuantityDeposit?: number
  maxQuantityDeposit?: number
  minQuantityStoringposition?: number
  maxQuantityStoringposition?: number
  packagingUnit?: number
}

export interface CustomersRequest {
  companyId?: string
  id?: string
  ids?: string[]
  number?: number
  numbers?: number[]
  searchText?: string
  mapLat?: number
  mapLong?: number
  userCode?: string
  skip?: number
  take?: number
  createDateFrom?: Date
  updateDateFrom?: Date
  active?: boolean
  modifyDateFrom?: Date
}

export interface ShoppingCartRequest {
  customerId?: string
  listName?: string
  readSalesPrice?: boolean
}

export interface ShoppingCartPostRequest {
  customerId: string
  articleId: string
  quantity: number
  listName: string
  description?: string
  note?: string
  unitId: string
  storingPositionId: string
  option1?: string
}
export interface ShoppingCartDeleteRequest {
  id: string
}

export interface SerialnumberRequest {
  id?: string
  number?: string
  articleId?: string
}

export interface SerialnumberPostRequest {
  number: string
  articleId?: string
  numberManufactor?: string
  customerId?: string
  customerShippingAddressId?: string
  note?: string
}

export interface SerialnumberQuantityRequest {
  depositId?: string
  articleId?: string
  stockPositionId?: string
  serialnumberId?: string
  considerPickedQuantity?: boolean
  readQuantitiesForCalculationAvailability?: boolean
}

export interface SerialNumberManufactorNumberCheckRequest {
  id: string
  numberManufactor: string
}

export interface SerialnumberChecksRequest {
  serialnumberManufactor?: string
  alternativeIdMonitoringMapping?: string
}

export interface SerialnumberPatchCommand {
  id?: string
  numberManufactor?: string
  alternativeIdMonitoringMapping?: string
  customerId?: string
  customerShippingAddressId?: string
  note?: string
}

export interface InventoryCodeRequest {
  code?: string
}
export interface InventoryAccountingRequest {
  inventoryCodeId?: string
  articleId?: string
  stockPositionId?: string
  skip?: number
  take?: number
}

export interface InventoryPostRequest {
  inventoryCodeId: string
  inventoryDate: Date
  articleId: string
  articleCode: string
  serialnumberId: string
  serialnumber: string
  unitId: string
  quantity: number
  stockpositionId: string
  stockpositionText: string
}

export interface OngoingInventoryPostRequest {
  stockpositionId: string
  articleId: string
  serialnumberId: string
  quantity: number
}

export interface OngoingInventoryPatchRequest {
  stockpositionId: string
  articleId: string
  serialnumberId: string
  date: string
  quantityRecounting: number
}

export interface ColloPostRequest {
  number: string
  packingListMovementId: string
  packingListMovementPartId: string
  quantity: number
  articleId: string
  depositId?: string
  storingPositionId?: string
  collaboratorId?: string
  generateNumber?: boolean
  serialNumbers?: ColloSerialNumberPost[]
}

export interface ColloDeleteRequest {
  id: string
}

export interface ColloSerialNumberPost {
  quantity: number
  serialNumberId: string | undefined
  serialNumber: string | undefined
  depositId: string | undefined
  storingPositionId: string | undefined
}

export interface ColloRequest {
  id?: string
  number?: string
  packingListIds?: string[]
  packingListMovementId?: string
  packingListMovementPartId?: string
  customerId?: string
  deliveryAddressId?: string
  states?: PackingListColloState[]
  ignorePackingListState?: boolean
  ignoreUserSettingFilter?: boolean
  onlyEmptyDeliveryAddress?: boolean
}

export interface ColloTransferPostRequest {
  colloNumber: string
  stockpositionId: string
  collaboratorId: string
}

export interface ColloConcludePostRequest {
  number: string
  packagingTypeId: string
  weight?: number
  length?: number
  width?: number
  height?: number
  stockpositionId?: string
  collaboratorId?: string
}

export interface ColloPackingRequest {
  packinglistId: string
  collaboratorId?: string
  printLabel?: boolean
  colli: ColloPacking[]
}

export interface ColloPacking {
  weight?: number
  length?: number
  width?: number
  height?: number
  packagingtypeId?: string
}

export interface ColloUnloadPackingListRequest {
  colloNumber: string
}

export interface ColloWeightRequest {
  number: string
  CollaboratorId?: string
}
export interface SerialNumberAvailabilityRequest {
  depositId?: string
  serialNumberId?: string
  useUserDepositFilter?: boolean
}
export interface PackagingTypeRequest {
  id?: string
  code?: string
}

export interface PackingListMovementsRequest {
  packingListIds: string[]
  OnlyNotConsigned?: boolean
}
export interface PackingListRequest {
  id?: string
  colloNumber?: string
  preConsignment?: boolean
  finalConsignment?: boolean
  productionConsignment?: boolean
}
export interface PackingListPatchRequest {
  packingListIds: string[]
  EmployeeId_CurrentPicker?: string
}
export interface ProductionRequest {
  id: string
}
export interface ProductionListRequest {
  searchText?: string
  state?: ProductionState
}
export interface ProductionPartsRequest {
  productionId: string
  employeeTimeTypeId?: string
  loadCompletedParts?: boolean
}
export interface ProductionPartsBookedRequest {
  productionId: string
  articleId: string
}
export interface PartsOfMasterProductionRequest {
  productionId: string
}
export interface PartsForExtractionRequest {
  productionId: string
  articleId: string
}
export interface PartsForTransferRequest {
  productionId: string
  articleId?: string
}
export interface ProductionsEmployeeTimeTypesRequest {
  productionId: string
}
export interface ProductionIncomingPostRequest {
  productionId: string
  completeProductionOrder: boolean
  quantities: ProductionQuantity[]
}
export interface ProductionQuantity {
  quantity: number
  serialNumber?: string
}

export interface ProductionOutgoingPostRequest {
  productionId: string
  isDefectiveMaterial?: boolean
  isTransfer?: boolean
  quantities: ProductionPostQuantity[]
}

export interface ProductionPreProductionPostRequest {
  articleId: string
  quantity: number
}

export interface ProductionPartToMoveForRequest {
  productionIdTarget?: string
  stockaccountingId: string
  stockId?: string
  serialnumberId?: string
  quantity: number
}

export interface ProductionPartsMovePatchRequest {
  partsToMove: ProductionPartToMoveForRequest[]
}

export interface ProductionPartExtractionPatchRequest {
  productionId: string
  articleId: string
  serialnumberId?: string
  quantity: number
}

export interface PartToPreproductionPatchRequest {
  preProductionId?: string
  productionPartId: string
}

export interface ProductionPostQuantity {
  productionPartId?: string
  storingpositionId?: string
  articleId?: string
  serialnumberId?: string
  quantity: number
}
export interface ProductionPartPostRequest {
  productionId: string
  stockpositionId?: string
  employeeTimeTypeId?: string
  articleId: string
  quantity: number
  isCreateOutgoing: boolean
  isCheckAvailability: boolean
}
export interface ProductionPrintPostRequest {
  productionId: string
}
export interface ColloNumberShippingDataRequest {
  colloNumber?: string
}
export interface PackingListShippingRequest {
  customerId?: string
  deliveryAddressId?: string
  createDeliveryNote?: boolean
}

export interface PackingListAddMovementRequest {
  packinglistId: string
  articleId: string
  stockpositionId?: string
  description?: string
  quantity: number
}

export interface PackingListPatchMovementRequest {
  packinglistmovementId: string
  articleId: string
  stockpositionId?: string
  state?: number
}

export interface PackingListConcludePostRequest {
  packingListId?: string
  conclusionTyp?: PackingListConclusionType
  packages?: number
  weight?: number
  freighterId?: string
  collaboratorId?: string
  printing?: PrintingType
  createDeliveryNote?: boolean
}

export enum PackingListConclusionType {
  conclude = 'conclude',
  concludeAndCreateTransfer = 'concludeAndCreateTransfer',
  preCommissioning = 'precommissioning',
}

export interface StockAccountingPostRequest {
  stockId?: string
  articleId: string
  articleId_Matrix?: string
  unitId: string
  serialnumberId?: string
  quantity: number
  depositId: string
  stockpositionId: string
  depositIdTarget?: string
  stockpositionIdTarget?: string
  stockTempleteId: string
  documentDate: Date
  collaboratorId: string
}

export interface StockPositionProposalRequest {
  articleId: string
  depositId?: string
  palletType?: number
  additionalFilter?: string
}

export interface StockPositionQuantityForKanbanTransferRequest {
  articleId: string
  depositId?: string
  stockpositionIdToIgnore?: string
}

export interface OngoingInventoryRequest {
  stockpositionId?: string
  articleId?: string
  serialNumberId?: string
  date?: string
  toConfirm?: boolean
}

export interface OngoingInventoryToConfirmRequest {
  toConfirm?: boolean
}

export interface CustomerAndDeliveriesForFinalPickingRequest {
  searchText?: string
}

export interface ColloNumberForFinalPickingRequest {
  CustomerId?: string
  DeliveryAddressId?: string
}

export interface FreighterRequest {
  id?: string
  code?: string
}

export interface ColloUnloadPostRequest {
  collonumberIds: string[]
  stockpositionId?: string
  collaboratorId?: string
}

export interface WorksheetRequest {
  id: string
  number?: number
}

export interface WorksheetSerialNumbersRequest {
  worksheetId: string
  quantity: number
}

export interface WorksheetConfirmPostRequest {
  worksheetId: string
  stockPositionId: string
  quantity: number
  serialNumberQuantities: WorksheetConfirmSerialNumber[]
}

export interface WorksheetConfirmSerialNumber {
  serialNumberId?: string
  serialNumber: string
  quantity: number
}

export interface AdditionalFieldPostRequest {
  fkId: string
  value: string
  definitionId?: string
}

export interface AdditionalFieldRequest {
  type?: number
  defId?: string
  fkIds: string[]
}

export interface PurchaseOrderProposalPostRequest {
  articleId: string
  depositId: string
  unitId: string
  quantity: number
  desiredDate?: Date
  note?: string
}

export enum TipType {
  None = 'None',
  Customer = 'Customer',
  CustomerShippingAddress = 'CustomerShippingAddress',
  Document = 'Document',
  Article = 'Article',
}

export interface TipRequest {
  type?: TipType
  masterId?: string
}

export interface QualityControlCharacteristicsRequest {
  articleId: string
}

export interface QualityControlRequest {
  serialNumberId?: string
  productionId?: string
}

export interface QualityControlPostRequest {
  articleId: string
  serialnumberId?: string
  productionId?: string
  employeeId?: string
  registerId?: string
  denomination?: string
  date?: string
  values: QualityControlPostValue[]
}
export interface QualityControlPostValue {
  qualityCharacteristicId: string
  value: string
  note?: string
  order?: number
}

export interface QualityControlPatchRequest {
  id: string
  values: QualityControlPatchValue[]
}

export interface QualityControlPatchValue {
  qualityCharacteristicId: string
  value: QualityCharacteristicValue
  note?: string
  order?: number
  targetBoolean?: boolean
  targetText?: string
  targetNumericFrom?: number
  targetNumericUntil?: number
  targetCompare?: boolean
}

export interface ArticleStockPositionDataRequest {
  id?: string
  articleId?: string
  stockPositionId?: string
}

export interface StockPositionQuantitiesRequest {
  articleId: string
  depositId: string
  stockPositionId: string
  forKanban?: boolean
}

export interface PatchStockAccountingRequest {
  stockId: string
  stockaccountingId?: string
  stockpositionIdOld: string
  stockpositionIdNew: string
}

export interface DeleteStockAccountingRequest {
  stockaccountingId: string
}

export interface JobExecParameterRequest {
  call: string
  customerId?: string
  state: number
  code1?: string
  value1?: string
  code2?: string
  value2?: string
  code3?: string
  value3?: string
  code4?: string
  value4?: string
  code5?: string
  value5?: string
  reportÍd?: string
}

export interface ParcelRequest {
  trackingnumber: string
}

export interface ParcelPatchRequest {
  id: string
  loadingDateTime?: Date
  protocol: string
}

export type WarehouseFilterConfigRequest = {
  type: 'Consignment' | 'PreConsignment' | 'ProductionConsignment' | 'Production'
}
