import { StockPosition } from '../apis/apiTypes'
import { Article } from '../classes/Article'
import { KanbanBox } from '../types'
import { articleUtils } from './articleUtils'
import stockPositionUtils from './stockPositionUtils'
import { Utils } from './Utils'

export const KanbanUtils = {
  getCode(box: KanbanBox) {
    return `${box.article.info.code.trim()}-${box.stockPositionProduction.code.trim()}`
  },
  createBox(article: Article, stockPosition: StockPosition): KanbanBox {
    return {
      id: Utils.getUid(),
      article,
      stockPositionProduction: stockPosition,
    }
  },
  compare(box1: KanbanBox, box2: KanbanBox) {
    return (
      box1.article.info.id === box2.article.info.id &&
      box1.stockPositionProduction.id === box2.stockPositionProduction.id &&
      box1.stockPositionQuantityForKanbanTransfer?.stockposition?.code === box2.stockPositionQuantityForKanbanTransfer?.stockposition?.code
    )
  },
  sortBySuggestion(a: KanbanBox, b: KanbanBox) {
    const sortResult = stockPositionUtils.sort(
      a.stockPositionQuantityForKanbanTransfer?.stockposition,
      b.stockPositionQuantityForKanbanTransfer?.stockposition
    )
    if (sortResult !== 0) return sortResult
    return articleUtils.sortByCode(a.article.info, b.article.info)
  },
}
