import { useLanguage, useTheme } from '@infominds/react-native-components'
import { useAsyncStorage } from '@react-native-async-storage/async-storage'
import React, { PropsWithChildren, useEffect, useState } from 'react'

const OLD_THEME_KEY = 'theme'
const OLD_LANGUAGE_KEY = 'lang'

export default function ContextMigration({ children }: PropsWithChildren) {
  const [ready, setReady] = useState(false)
  const { updateColorScheme } = useTheme()
  const { setLanguageAsync } = useLanguage()

  const { getItem: getTheme, removeItem: removeTheme } = useAsyncStorage(OLD_THEME_KEY)
  const { getItem: loadLanguage, removeItem: removeLanguage } = useAsyncStorage(OLD_LANGUAGE_KEY)

  useEffect(() => {
    migration()
      .catch(err => console.error('🔴 Local migration failed', err))
      .finally(() => setReady(true))
  }, [])

  const migration = async () => {
    const loadedTheme = await getTheme()
    const loadedLanguage = await loadLanguage()

    loadedTheme !== null && updateColorScheme(loadedTheme === 'light' ? 'light' : 'dark')
    loadedLanguage !== null && (await setLanguageAsync(loadedLanguage === 'it' ? 'it' : loadedLanguage === 'de' ? 'de' : 'en'))

    await removeTheme()
    await removeLanguage()
  }

  if (ready === false) return <></>

  return <>{children}</>
}
