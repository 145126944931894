import React, { useEffect, useState } from 'react'
import { View } from 'react-native'

import { Deposit, StockPosition } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import VKUnitAmountSelector from '../../components/MWS/Inputs/VKUnitAmountSelector'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { TransferPositionHandler } from '../../hooks/specific/useTransferPositionHandler'
import TransferPositionSelectionModal from '../../modals/TransferPositionSelectionModal'
import { articleUtils } from '../../utils/articleUtils'
import PositionSelectionView from '../InputViews/PositionSelectionView'

interface PositionSelectionViewProps {
  show: boolean
  article: Article | undefined
  handler: TransferPositionHandler
  checkAvailability?: boolean
  selectDepositCaption?: string
  selectStockPositionCaption?: string
}

export default function PositionAvailabilitySelectionView(props: PositionSelectionViewProps) {
  const loader = useLoadingIndicator()
  const handler = props.handler
  const [showSelectionModal, setShowSelectionModal] = useState(false)
  const [quantityProposal, setQuantityProposal] = useState<number | undefined>(undefined)

  const selectDeposit = handler.depositRequired && !handler.deposit
  const selectStockPosition = handler.stockPositionRequired && !handler.stockPosition && !!handler.deposit?.isStockpositionActive
  const selectQuantity = !selectDeposit && !selectStockPosition && handler.quantityRequired && handler.quantity <= 0

  useEffect(() => {
    if (!(selectDeposit || selectStockPosition || selectQuantity) || !props.article || !props.article.depositQuantities || !props.checkAvailability) {
      return
    }

    const filteredResult = articleUtils.filterAvailability(props.article?.depositQuantities)
    if (filteredResult?.length === 1 && filteredResult[0].stockpositionquantities.length === 1) {
      handlePositionSelection(
        filteredResult[0].deposit,
        filteredResult[0].stockpositionquantities[0].stockposition,
        filteredResult[0].stockpositionquantities[0].quantity
      )
    }
  }, [props.article])

  function handlePositionSelection(deposit: Deposit | undefined, stockPosition?: StockPosition, maxQuantity?: number) {
    setShowSelectionModal(false)

    if (!handler.quantityRequired || !props.checkAvailability) {
      handler.setDeposit(deposit)
      handler.setStockPosition(stockPosition)
      return
    }

    if (maxQuantity) {
      handler.setDeposit(deposit)
      handler.setStockPosition(stockPosition)
      setQuantityProposal(maxQuantity)
      return
    }
    if (props.article?.info && deposit) {
      loader.setLoading(true)
      articleUtils
        .getArticleAvailability(props.article?.info, deposit, stockPosition)
        .then(result => {
          if (result) setQuantityProposal(result.quantity)
        })
        .catch(console.error)
        .finally(() => {
          handler.setDeposit(deposit)
          handler.setStockPosition(stockPosition)
          loader.setLoading(false)
        })
    }
  }

  if (!props.show) return <></>
  return (
    <View>
      {selectQuantity && (
        <VKUnitAmountSelector
          value={1}
          article={props.article}
          proposedQuantity={quantityProposal}
          setValue={handler.setQuantity}
          autoFocus
          usedUnit={props.article?.getUsedUnit()}
          units={props.article?.info.measurementUnits}
          onUnitSelected={unit => props.article?.setUsedUnit(unit)}
          checkAvailability={
            props.checkAvailability
              ? {
                  articleId: props.article?.info.id ?? '',
                  depositId: handler.deposit?.id,
                  stockPositionId: handler.stockPosition?.id,
                }
              : undefined
          }
        />
      )}
      {(selectDeposit || selectStockPosition) && (
        <>
          <PositionSelectionView
            article={props.article?.info}
            deposit={handler.deposit}
            setDeposit={handler.setDeposit}
            setStockPosition={selection => handlePositionSelection(handler.deposit, selection)}
            onListButtonPressed={props.checkAvailability ? () => setShowSelectionModal(true) : undefined}
            selectDepositCaption={props.selectDepositCaption}
            selectStockPositionCaption={props.selectStockPositionCaption}
            setPosition={handlePositionSelection}
          />
          {/* Shows modal to select Deposit and StockPosition */}
          <TransferPositionSelectionModal
            show={showSelectionModal}
            articleAvailability={props.article?.depositQuantities}
            close={() => setShowSelectionModal(false)}
            onSelected={(selectedDeposit, selectedStockPosition, quantity) =>
              handlePositionSelection(selectedDeposit, selectedStockPosition?.stockposition, quantity)
            }
            showQuantity={handler.quantityRequired}
            article={props.article}
            selectDepositCaption={props.selectDepositCaption}
            selectStockPositionCaption={props.selectStockPositionCaption}
          />
        </>
      )}
    </View>
  )
}
