import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IconProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { DataFilterConfigType, GroupDataSorter, OrderDataSorter } from '../../types'
import Tag from './Tag'

interface Props<T> extends Partial<IconProps> {
  sorter: OrderDataSorter<T> | GroupDataSorter<T>
  isHeader?: boolean
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: (id: string) => void
}

export default function SorterTag<T>({ sorter, icon: propsIcon, style, ...iconProps }: Props<T>) {
  const { i18n } = useLanguage()

  const icon = useMemo<IconProp | undefined>(() => {
    if (sorter.type === DataFilterConfigType.Order) {
      if (sorter.options?.modifier === 'inverse') return ['fal', 'arrow-down-wide-short']
      return ['fal', 'arrow-down-short-wide']
    }

    return undefined
  }, [sorter])

  const text = useMemo(() => (typeof sorter.textKey === 'string' ? i18n.t(sorter.textKey) : sorter.textKey.text), [sorter.textKey])

  return <Tag id={sorter.id} name={text} active={sorter.active} icon={propsIcon ?? icon} style={[styles.tag, style]} {...iconProps} />
}

const styles = StyleSheet.create({
  tag: { marginVertical: 3 },
})
