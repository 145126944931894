import { useLanguage } from '@infominds/react-native-components'
import { forEach } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { FlatList, View } from 'react-native'

import { Deposit, StockPositionAvailability } from '../apis/apiTypes'
import SerialnumberCard from '../cards/Serialnumber/SerialnumberCard'
import { Article } from '../classes/Article'
import ElusiveButton from '../components/ElusiveButton'
import FullScreenModal from '../components/FullScreenModal'
import IMRefreshControl from '../components/IMRefreshControl'
import ListSpacer from '../components/MWS/ListSpacer'
import Title from '../components/MWS/Title'
import NoEntriesTag from '../components/NoEntriesTag'
import Separator from '../components/Separator'
import { IMLayout } from '../constants/Styles'
import useItemSelector from '../hooks/useItemSelector'
import { articleUtils } from '../utils/articleUtils'
import { serialnumberUtils } from '../utils/serialnumberUtils'
import stockPositionUtils from '../utils/stockPositionUtils'
import { Utils } from '../utils/Utils'
import DepositSelectorView from '../views/Deposit/DepositSelectorView'

export default function MultiSerialSelectionModal(props: {
  show: boolean
  allowMultiSelection?: boolean
  deposit?: Deposit
  article: Article | undefined
  close: () => void
  onSelected: (serialNumbers: StockPositionAvailability[]) => void
  loading?: boolean
  noDepositSelectionMode?: boolean
  selectSerialsWithoutPosition?: boolean
  filterItem?: (item: StockPositionAvailability) => boolean
  showWarrantyDate?: boolean
  header?: JSX.Element
  title?: string
}) {
  const { i18n } = useLanguage()

  const article = props.article
  const [deposit, setDeposit] = useState<Deposit | undefined>(() => props.deposit)

  const serialNumberQuantities = useMemo(() => article?.getConvertedSerialNumberQuantities() ?? [], [article?.serialNumberQuantities])
  const filteredSerialNumberAvailability = serialNumberQuantities.filter(serialAvailabilityFilter)
  const filteredSerialNumbers = (props.article?.serialNumbers ?? []).filter(item =>
    serialAvailabilityFilter(serialnumberUtils.createAvailability(item))
  )

  const serialSelector = useItemSelector<StockPositionAvailability>([])

  function serialAvailabilityFilter(serial: StockPositionAvailability) {
    if (!props.filterItem) return true
    return props.filterItem(serial)
  }

  const depositList = props.selectSerialsWithoutPosition ? [] : Utils.keepUniques(filteredSerialNumberAvailability, item => item.deposit?.id) ?? []
  const serialNumbersOnDeposit = getSerialNumbersOnDeposit(deposit)
  const serialNumbers = props.selectSerialsWithoutPosition ? filteredSerialNumbers ?? [] : []

  function getSerialNumbersOnDeposit(useDeposit: Deposit | undefined) {
    if (props.selectSerialsWithoutPosition) {
      return []
    }
    const sortAndFilter = (items: StockPositionAvailability[] | undefined) =>
      items
        ?.filter(item => item.quantity && item.quantity > 0)
        .sort(props.showWarrantyDate ? stockPositionUtils.sortAvailabilityBySerialWarranty : stockPositionUtils.sortAvailabilityBySerial) ?? []
    if (!depositList || depositList.length < 1) return []
    return sortAndFilter(filteredSerialNumberAvailability?.filter(item => !useDeposit || item.deposit?.id === useDeposit.id))
  }

  useEffect(() => {
    if (!props.show) return
    if (depositList.length === 1) handleDepositSelection(depositList[0].deposit, true)
  }, [props.show, depositList])

  function handleDepositSelection(selectedDeposit: Deposit | undefined, autoSelected?: boolean) {
    if (!selectedDeposit) {
      setDeposit(undefined)
      return
    }

    if (!autoSelected) {
      const serials = getSerialNumbersOnDeposit(selectedDeposit)
      if (serials.length === 1) {
        handleSubmit(serials)
        return
      }
    }
    setDeposit(selectedDeposit)
  }

  function handleSelection(serialNumber: StockPositionAvailability) {
    if (!serialNumber) return
    if (!props.allowMultiSelection || serialNumbersOnDeposit.length === 1) {
      if (serialNumbersOnDeposit.length === 1) setDeposit(undefined)
      return handleSubmit([serialNumber])
    }
    serialSelector.toggle(serialNumber)
  }

  function handleSubmit(serials?: StockPositionAvailability[]) {
    props.onSelected(serials ?? serialSelector.items ?? [])
    serialSelector.clear()
  }

  function getQuantityByDeposit(byDeposit: Deposit) {
    return articleUtils.formatQuantity(
      Utils.sum(
        filteredSerialNumberAvailability.filter(serial => serial.deposit?.id === byDeposit?.id),
        serial => serial.quantity ?? 0
      ),
      article?.getUsedUnit()
    )
  }

  function getNetQuantityByDeposit(byDeposit: Deposit) {
    const items = filteredSerialNumberAvailability.filter(serial => serial.deposit?.id === byDeposit?.id)
    if (!items || items.length === 0) return ''

    const groupedItems = serialnumberUtils.groupSerialNumberAvailability(items)

    const quantity = Utils.sum(groupedItems, serial => serial.quantity ?? 0)
    const ordered = Utils.sum(groupedItems, serial => serial.quantityOrdered ?? 0)
    const commissioned = Utils.sum(groupedItems, serial => serial.quantityCommissioned ?? 0)
    const netQuantity = quantity - ordered - commissioned

    if (netQuantity === quantity) return ''

    return i18n.t('Availability') + ': ' + articleUtils.formatQuantity(netQuantity, article?.getUsedUnit())
  }

  function getModalTitle(): string {
    if (props.selectSerialsWithoutPosition || props.deposit || props.noDepositSelectionMode || deposit) {
      return props.title ?? i18n.t('SelectSerialnumber')
    }
    return i18n.t('SelectDeposit')
  }

  return (
    <FullScreenModal isVisible={props.show} close={props.close} title={getModalTitle()}>
      <View style={IMLayout.flex.f1}>
        {!!props.header && props.header}
        {!props.noDepositSelectionMode && !props.selectSerialsWithoutPosition && (
          <DepositSelectorView
            item={deposit}
            setItem={handleDepositSelection}
            loading={!!props.loading}
            //@ts-ignore .filter() already makes sure deposit is not undefined
            itemList={depositList?.filter(item => !!item.deposit).map(item => item.deposit) ?? []}
            quantityExtractor={getQuantityByDeposit}
            quantityAvailabilityExtractor={getNetQuantityByDeposit}
          />
        )}
        {(deposit || !!props.noDepositSelectionMode) && !props.selectSerialsWithoutPosition && (
          <>
            <Separator />
            <FlatList
              data={serialNumbersOnDeposit ?? []}
              renderItem={({ item }) => (
                <SerialnumberCard
                  serialnumber={item.serialnumber}
                  quantity={articleUtils.formatQuantity(item.quantity, article?.getUsedUnit())}
                  reservedQuantity={
                    stockPositionUtils.getReservedQuantity(item)
                      ? articleUtils.formatQuantity(stockPositionUtils.getReservedQuantity(item), article?.getUsedUnit())
                      : undefined
                  }
                  onPress={() => handleSelection(item)}
                  hideManufacturer
                  isMarked={serialSelector.includes(item)}
                  stockPosition={item.stockposition}
                  showWarrantyDate={props.showWarrantyDate}
                />
              )}
              ListFooterComponent={!serialNumbersOnDeposit || serialNumbersOnDeposit.length === 0 ? <NoEntriesTag /> : <ListSpacer />}
              refreshControl={<IMRefreshControl refreshing={!!props.loading} />}
            />
          </>
        )}
        {!!props.selectSerialsWithoutPosition && (
          <>
            <Title>{i18n.t('SelectSerialnumber')}</Title>
            <FlatList
              data={serialNumbers}
              renderItem={({ item }) => (
                <SerialnumberCard
                  serialnumber={item}
                  onPress={() => handleSelection({ serialnumber: item, article: props.article?.info, quantity: 1 } as StockPositionAvailability)}
                  hideManufacturer
                />
              )}
              ListFooterComponent={!filteredSerialNumbers || filteredSerialNumbers.length === 0 ? <NoEntriesTag /> : <ListSpacer />}
              refreshControl={<IMRefreshControl enabled={false} refreshing={!!props.loading} />}
            />
          </>
        )}
      </View>
      <ElusiveButton
        hideFromKeyBoard
        hide={!serialSelector.any}
        title={Utils.stringValueReplacer(i18n.t('SelectNSerialNumbers'), serialSelector.count)}
        onPress={() => handleSubmit()}
      />
    </FullScreenModal>
  )
}
