import api from '../apis/apiCalls'
import {
  ArticleAvailability,
  ArticleDepositQuantity,
  ArticleDTO,
  Deposit,
  SerialNumber,
  StockPosition,
  StockPositionAvailability,
} from '../apis/apiTypes'
import { articleUtils } from './articleUtils'
import { depositUtils } from './depositUtils'
import { serialnumberUtils } from './serialnumberUtils'
import { Utils } from './Utils'

const stockPositionUtils = {
  compare(a: StockPosition | undefined, b: StockPosition | undefined) {
    return !!a && !!b && a.id === b.id
  },
  comparePosition(
    depositA: Deposit | undefined,
    depositB: Deposit | undefined,
    stockPositionA: StockPosition | undefined,
    stockPositionB: StockPosition | undefined
  ) {
    return depositUtils.compare(depositA, depositB) && ((!stockPositionA && !stockPositionB) || this.compare(stockPositionA, stockPositionB))
  },
  compareAvailability(availabilityA: StockPositionAvailability, availabilityB: StockPositionAvailability) {
    return (
      !!availabilityA &&
      !!availabilityB &&
      articleUtils.compare(availabilityA.article, availabilityB.article) &&
      serialnumberUtils.compare(availabilityA.serialnumber, availabilityB.serialnumber, true) &&
      stockPositionUtils.comparePosition(availabilityA.deposit, availabilityB.deposit, availabilityA.stockposition, availabilityB.stockposition)
    )
  },
  getTitle(stockPosition?: StockPosition, deposit?: Deposit) {
    if (!stockPosition?.code && !deposit?.code) return ''
    if (!stockPosition?.code) return deposit?.code?.trim() ?? ''
    let title = stockPosition?.code?.trim() ?? ''
    const useDeposit = deposit ?? stockPosition?.deposit
    if (useDeposit?.code) title += ` (${useDeposit.code.trim()})`
    return title.trim()
  },
  sort(a: StockPosition | undefined, b: StockPosition | undefined) {
    const depositSortResult = depositUtils.sort(a?.deposit, b?.deposit)
    if (depositSortResult !== 0) return depositSortResult
    return Utils.compareStringsForSort(a?.code, b?.code)
  },
  sortAvailability(a: StockPositionAvailability | undefined, b: StockPositionAvailability | undefined) {
    const depositSortResult = depositUtils.sort(a?.deposit, b?.deposit)
    if (depositSortResult !== 0) return depositSortResult
    return Utils.compareStringsForSort(a?.stockposition?.code, b?.stockposition?.code)
  },
  sortAvailabilityBySerial(a: StockPositionAvailability, b: StockPositionAvailability) {
    return Number.parseInt(a?.serialnumber?.number ?? '0', 10) - Number.parseInt(b?.serialnumber?.number ?? '0', 10)
  },
  sortAvailabilityBySerialWarranty(a: StockPositionAvailability, b: StockPositionAvailability) {
    const warrantyA = Utils.parseDate(a.serialnumber?.warrantySupplier)
    const warrantyB = Utils.parseDate(b.serialnumber?.warrantySupplier)
    if (!warrantyA && !warrantyB) return Number.parseInt(a?.serialnumber?.number ?? '0', 10) - Number.parseInt(b?.serialnumber?.number ?? '0', 10)
    if (!warrantyA) return 1
    if (!warrantyB) return -1
    return warrantyA > warrantyB ? 1 : -1
  },
  matchesInput(item: StockPosition | Deposit | undefined, input: string) {
    if (!item || !item.code || !input || typeof input !== 'string') return false
    return item.code.trim().toUpperCase().startsWith(input.trim().toUpperCase())
  },
  getStockPositionsFromArticleAvailability(availability: ArticleDepositQuantity[] | undefined) {
    if (!availability) return []
    return availability.reduce<StockPositionAvailability[]>((total, item) => {
      if (item.stockpositionquantities && item.stockpositionquantities.length > 0) {
        total.push(...item.stockpositionquantities)
      }
      return total
    }, [])
  },
  getStockPositionsFromAvailability(availability: StockPositionAvailability[] | undefined) {
    if (!availability) return []
    return availability.filter(a => !!a.stockposition).map(a => a.stockposition) as StockPosition[]
  },
  castStockPositionsToArticleAvailability(article: ArticleDTO | undefined, stockPositions: StockPosition[] | undefined) {
    if (!stockPositions || !article) return undefined
    const deposits = Utils.keepUniques(stockPositions, item => item.deposit.id)?.map(item => item.deposit) ?? []
    const depositQuantities = deposits.map<ArticleDepositQuantity>(item => ({
      deposit: item,
      quantity: 0,
      quantityCommissioned: 0,
      quantityOrdered: 0,
      quantityPurchasing: 0,
      quantityFreeInputs: 0,
      stockpositionquantities: stockPositions.map<StockPositionAvailability>(stoPos => ({
        stockposition: stoPos,
        quantity: 0,
        quantityCommissioned: 0,
        article: article,
        serialnumber: undefined,
        deposit: item,
      })),
    }))
    return { article: article, depositquantities: depositQuantities, quantity: 0 } as ArticleAvailability
  },
  createStockPositionAvailability(
    article: ArticleDTO | undefined,
    deposit: Deposit | undefined,
    stockPosition: StockPosition | undefined,
    quantity: number | undefined,
    serialNumber?: SerialNumber
  ): StockPositionAvailability {
    return {
      article: article,
      deposit: deposit,
      stockposition: stockPosition,
      quantity: quantity ?? 0,
      serialnumber: serialNumber,
    } as StockPositionAvailability
  },
  getPositionIdentifier(item: StockPositionAvailability) {
    if (!item) return ''
    let id = item.deposit?.id ?? ''
    if (item.stockposition) {
      id += item.stockposition?.id ?? ''
    }
    return id
  },
  checkIfOnlyOneStockPositionIsAvailable(availability: StockPositionAvailability[], preferredDepositId?: string, preferredStockPositionId?: string) {
    if (!availability?.length) return undefined
    if (availability.length === 1) return availability[0]
    let filteredAvailability = availability
    if (preferredDepositId) {
      filteredAvailability = availability.filter(q => q.deposit?.id === preferredDepositId)
      if (filteredAvailability.length === 1) return filteredAvailability[0]
    }
    if (preferredStockPositionId) {
      filteredAvailability = filteredAvailability.filter(q => q.stockposition?.id === preferredStockPositionId)
      if (filteredAvailability.length === 1) return filteredAvailability[0]
    }
    return undefined
  },
  async getArticleStockPositionAvailabilities(articleId: string, depositId?: string) {
    if (!articleId) return []
    const availability = await api.getArticleAvailability({ articleId, depositId })
    return stockPositionUtils.getStockPositionsFromArticleAvailability(availability.depositquantities)
  },
  async getStockPositionsWithArticleAvailable(
    articleId: string,
    depositId?: string,
    requiredAvailability?: number,
    filterNotProposedInPackingLists?: boolean
  ): Promise<StockPosition[]> {
    //@ts-ignore StockPosition !== undefined is already checked by .filter()
    return (
      (await this.getArticleStockPositionAvailabilities(articleId, depositId))
        ?.filter(
          s =>
            !!s.stockposition &&
            (!filterNotProposedInPackingLists || !s.stockposition.notProposedInPackinglists) &&
            s.quantity &&
            s.quantity > 0 &&
            (!requiredAvailability || s.quantity >= requiredAvailability)
        )
        .map(s => s.stockposition) ?? []
    )
  },
  convertDepositAvailabilityToStockPosition(
    articleAvailability: ArticleAvailability | undefined,
    includeStockPositions?: boolean,
    includeDepositsWithoutStockPositions?: boolean
  ): StockPositionAvailability[] | undefined {
    if (!articleAvailability) return undefined
    return articleAvailability.depositquantities.reduce<StockPositionAvailability[]>((result, depositQuantity) => {
      if (!depositQuantity.deposit.isStockpositionActive || !includeStockPositions) {
        if (!includeDepositsWithoutStockPositions) return result
        result.push(this.createStockPositionAvailability(articleAvailability.article, depositQuantity.deposit, undefined, depositQuantity.quantity))
        return result
      }
      if (includeStockPositions) {
        result.push(...(depositQuantity.stockpositionquantities ?? []))
      }
      return result
    }, [])
  },
  reduceStockPositionAvailability(availability: StockPositionAvailability[] | undefined) {
    if (!availability) return []
    return availability.reduce<StockPositionAvailability[]>((result, current) => {
      const foundStockPosition = result.find(q => q.stockposition?.id === current.stockposition?.id)
      if (foundStockPosition) {
        foundStockPosition.quantity = (foundStockPosition.quantity ?? 0) + (current.quantity ?? 0)
        foundStockPosition.quantityCommissioned = (foundStockPosition.quantityCommissioned ?? 0) + (current.quantityCommissioned ?? 0)
      } else {
        result.push({ ...current, serialnumber: undefined })
      }
      return result
    }, [])
  },
  filterDepositQuantities(depositQuantities: ArticleDepositQuantity[] | undefined, stockPositions?: StockPosition[]) {
    if (!depositQuantities) return []
    return depositQuantities
      .map<ArticleDepositQuantity>(dpq => ({
        ...dpq,
        stockpositionquantities: dpq.stockpositionquantities.filter(
          stPos => !stockPositions?.find(posToFilter => posToFilter.id === stPos.stockposition?.id)
        ),
      }))
      .filter(q => q.stockpositionquantities.length || !q.deposit.isStockpositionActive)
  },
  getNetQuantity(item: StockPositionAvailability) {
    if (!item?.quantity || item.stockposition) return undefined
    return item.quantity - (item.quantityCommissioned ?? 0) - (item.quantityOrdered ?? 0)
  },
  getReservedQuantity(item: StockPositionAvailability) {
    if ((!item?.quantityCommissioned && !item?.quantityOrdered) || item.stockposition) return undefined
    return (item.quantityCommissioned ?? 0) + (item.quantityOrdered ?? 0)
  },
}

export default stockPositionUtils
