import { useLanguage } from '@infominds/react-native-components'
import React, { useRef } from 'react'

import api from '../../apis/apiCalls'
import { ArticleDTO, Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import SerialnumberCard from '../../cards/Serialnumber/SerialnumberCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import useAlert from '../../hooks/useAlert'
import { SelectionViewProps } from '../../types'
import { articleUtils } from '../../utils/articleUtils'
import packingListUtils from '../../utils/packingListUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'

interface SerialnumberQuantitySelectionViewProps {
  article: ArticleDTO | undefined
  reduceToNumber?: boolean
  acceptAnyInput?: boolean
  acceptAnyInputSerialCreator?: (serialNumber: string) => StockPositionAvailability
  deposit?: Deposit
  stockPosition?: StockPosition
  showAvailability?: boolean
  ignoreAvailability?: boolean
  allowNewSerialCreation?: boolean
  historicalSerialNumberMode?: {
    active: boolean
    deposit: Deposit | undefined
    defaultStockPosition?: StockPosition
    SerialNumberMustExist?: boolean
  }
  filterSerialNumbers?: SerialNumber[]
  filterNotProposedInPackingLists?: boolean //filter SN that are on StockPositions that are flagged as "NotProposedInPackinglists"
}

interface AvailabilityCache {
  articleId: string
  depositId?: string
  stockPositionId?: string
  availability?: StockPositionAvailability[]
}

export default function SerialnumberQuantitySelectionView(
  props: SerialnumberQuantitySelectionViewProps & SelectionViewProps<StockPositionAvailability>
) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const loader = useLoadingIndicator()
  const cachedListResult = useRef<AvailabilityCache | null>(null)
  const {
    article,
    reduceToNumber,
    acceptAnyInput,
    title,
    deposit,
    stockPosition,
    showAvailability,
    allowNewSerialCreation,
    ignoreAvailability,
    historicalSerialNumberMode,
    filterSerialNumbers,
    onSelected,
    ...inputProps
  } = props

  async function load(scannerInput: ScannerInputResult) {
    const serialInput = scannerInput.scannerInput ?? scannerInput.textInput ?? ''
    const filterNotProposedStockPositions = !!props.filterNotProposedInPackingLists && !serialInput
    //ff any input is accepted return input directly
    if (acceptAnyInput && serialInput) {
      if (props.acceptAnyInputSerialCreator) {
        return [props.acceptAnyInputSerialCreator(serialInput)]
      } else {
        return [serialnumberUtils.createAvailability(serialInput, 1e10, 0, 0, article, deposit, stockPosition)]
      }
    }
    //if historical serialnumber mode
    if (historicalSerialNumberMode?.active && serialInput) {
      let historicalSN: SerialNumber | string = serialInput
      if (historicalSerialNumberMode.SerialNumberMustExist) {
        const historicalSerialNumbers = await api.getSerialnumber({ articleId: article?.id, number: serialInput })
        if (!historicalSerialNumbers?.length) return []
        historicalSN = historicalSerialNumbers[0]
      }
      return packingListUtils.getHistoricalSerialNumberAvailability(
        historicalSN,
        article,
        historicalSerialNumberMode.deposit,
        historicalSerialNumberMode.defaultStockPosition
      )
    }

    //empty input (list button)
    if (!serialInput) {
      if (!props.allowEmptyInput) return []

      if (
        cachedListResult.current?.availability?.length &&
        cachedListResult.current.articleId === article?.id &&
        cachedListResult.current.depositId === deposit?.id &&
        cachedListResult.current.stockPositionId === stockPosition?.id
      ) {
        return serialnumberUtils.filterSerialNumberAvailabilities(
          cachedListResult.current.availability,
          filterSerialNumbers,
          filterNotProposedStockPositions
        )
      }
      const availability = await serialnumberUtils.getSerialNumberAvailabilitiesOnPosition(article?.id, deposit?.id, stockPosition?.id)
      cachedListResult.current = { availability, depositId: deposit?.id, stockPositionId: stockPosition?.id, articleId: article?.id ?? '' }
      return serialnumberUtils.filterSerialNumberAvailabilities(availability, filterSerialNumbers, filterNotProposedStockPositions)
    }

    const serialNumbers = await api.getSerialnumber({ articleId: article?.id, number: serialInput })
    if (!serialNumbers || serialNumbers.length === 0) {
      return []
    }
    if (ignoreAvailability) {
      return [stockPositionUtils.createStockPositionAvailability(article, deposit, stockPosition, 1, serialNumbers[0])]
    }
    return serialnumberUtils.getSerialnumberQuantities(article, serialNumbers[0], deposit, stockPosition, filterNotProposedStockPositions, true)
  }

  function resultReducer(result: StockPositionAvailability[]) {
    if (!reduceToNumber) return result
    return Utils.keepUniques(result, item => item.serialnumber?.number?.trim())
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleError(input?: string, error?: any) {
    //if SN was not found it can be created if the bit isSerialnumberOnlyForHistory is true
    if (!!error || !allowNewSerialCreation || !input || !props.article?.id || !props.article.isSerialnumberActive) return false
    loader.setLoading(true)
    handleCreateSerialNumber(input)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .catch(reason => alert.error(i18n.t('FailedToCreateSerialNumber'), reason.Message ?? ''))
      .finally(() => loader.setLoading(false))
    return true
  }

  async function handleCreateSerialNumber(input: string) {
    if (!input) return
    // ask if serialNumber should be created. (if SN ist only for history, then it will always be created automatically (todo 446748))
    if (!article?.isSerialnumberOnlyForHistory) {
      const yesNoResult = await alert.yesNo(i18n.t('SelectedSerialDoesNotExistCreateAlert'), input)
      if (!yesNoResult) return
    }
    const createdSerial = await api.postSerialnumber({ number: input, articleId: props.article?.id })

    if (!createdSerial) return
    onSelected(
      serialnumberUtils.createAvailability(serialnumberUtils.create(input, createdSerial, article), undefined, 0, 0, article, deposit, stockPosition)
    )
  }

  return (
    <MWS_Input<StockPositionAvailability>
      type="Serialnumber"
      title={title ?? i18n.t('SelectSerialnumber')}
      load={load}
      onSelected={onSelected}
      itemRenderer={item => (
        <SerialnumberCard
          serialnumber={item.serialnumber}
          stockPosition={item.stockposition}
          showIncomingDate
          quantity={showAvailability ? articleUtils.formatQuantity(item.quantity, item.article.measurementUnitCode) : undefined}
        />
      )}
      failedToLoadText={props.historicalSerialNumberMode?.active ? i18n.t('NoAvailability') : i18n.t('NoSerialnumberWithCode')}
      modalTitle={i18n.t('SelectSerialnumber')}
      modifyResult={resultReducer}
      handleError={handleError}
      {...inputProps}
    />
  )
}
