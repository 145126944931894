import { IM, useLanguage } from '@infominds/react-native-components'
import { AuthenticationContainer, Locale } from '@infominds/react-native-license'
import React, { PropsWithChildren } from 'react'
import { Alert, Platform } from 'react-native'
import BootSplash from 'react-native-bootsplash'

import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import versionsJSON from '../../versions.json'

export type AuthenticationWrapperProps = PropsWithChildren

export default function AuthenticationWrapper({ children }: AuthenticationWrapperProps) {
  const { language } = useLanguage()

  const alert = useAlert()

  const userSettings = useUserSettings()

  return (
    <AuthenticationContainer
      config={{
        moduleCode: 'APP',
        projectCode: 'APP-RXMWS',
        apiType: '.net',
        disableSilentLogin: 'ask',
        keepLicenseOnLogout: true,
        aad: false,
        debug: __DEV__,
        inactivityTimeout:
          userSettings?.activeMinutesForAutomaticLogout !== undefined && userSettings?.activeMinutesForAutomaticLogout !== 0
            ? { minutes: userSettings?.activeMinutesForAutomaticLogout }
            : undefined,
      }}
      locale={language as Locale}
      versionsFile={versionsJSON}
      onVersionCheckEnd={({ error }) => {
        if (!error) return
        if (Platform.OS === 'web') {
          alert.error(error.message)
          return
        }
        Alert.alert(error.title, error.message)
      }}>
      {authContext => {
        const authReady = authContext && authContext.isLoggedIn !== undefined

        authReady && BootSplash.hide().catch(console.error)

        if (authContext === undefined || authReady === undefined || authContext.isLoggedIn === undefined) {
          return <IM.LoadingSpinner isVisible />
        }

        return children
      }}
    </AuthenticationContainer>
  )
}
