import React from 'react'

import { Freighter } from '../apis/apiTypes'
import IMText from '../components/IMText'
import MWS_BaseCard, { MWSBaseCardProps } from './MWS_BaseCard'

export default function FreighterCard(props: { freighter: Freighter } & MWSBaseCardProps) {
  const { freighter, ...otherProps } = props

  const code = freighter?.code?.trim()
  const description = freighter?.description?.trim()

  return (
    <MWS_BaseCard {...otherProps} icon="truck-arrow-right" style={{ flexDirection: 'column' }}>
      {!!code && <IMText>{code}</IMText>}
      {!!description && <IMText>{description.trim() ?? ''}</IMText>}
    </MWS_BaseCard>
  )
}
