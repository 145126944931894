import { useApi } from '@infominds/react-api'
import { IMLayout, useLanguage, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'

import api from '../../apis/apiCalls'
import { PackingList } from '../../apis/apiTypes'
import { PackingListArticle } from '../../classes/PackingListCollection'
import ElusiveButton from '../../components/ElusiveButton'
import ListSpacer from '../../components/MWS/ListSpacer'
import Title from '../../components/MWS/Title'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import usePackingListConclusion from '../../hooks/packingLists/usePackingListConclusion'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import ProductionConsignmentCompletionModal from '../../modals/packingLists/ProductionConsignmentCompletionModal'
import { PackingListToConclude as PackingListToConcludeBase } from '../../types'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'
import PackingListCompletionView from './PackingListCompletionView'

export type PackingListCompletionListViewProps = { _?: never }

export type UpdatePackingList = Partial<PackingListToConclude> | ((prev: PackingListToConclude) => Partial<PackingListToConclude>)

export type PackingListToConclude = PackingListToConcludeBase & { conclude: boolean; isOpen: boolean }

/**
 * PackingList Completion depends on the type of consignment
 * PreConsignment -> conclude automatically (should already have happened in PackingListScreen, before navigating here)
 * ProductionConsignment -> show articles to unload before completion
 * Consignment -> for each packingList provide collo info
 */
export default function PackingListCompletionListView({}: PackingListCompletionListViewProps) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const articleListModal = useModalController<PackingList>()
  const {
    packingLists: allPackingLists,
    isProductionConsignment,
    movements,
    colloMode,
    multiPackingListConsignment,
    reloadPackingListMovements,
  } = usePackingList()

  function initPackingList(loadedMovements: PackingListArticle[]) {
    return (
      allPackingLists?.map(
        pl =>
          ({
            packingList: pl,
            conclude: allPackingLists.length === 1 && !isProductionConsignment,
            isOpen: allPackingLists.length === 1 && !isProductionConsignment,
            freighter: pl.freighter,
            colloCount: colloMode ? undefined : 1,
            collos: colloMode
              ? colloUtils.convertToColloPackingSelection(
                  packingListUtils.getCollosFromPackingListArticles(loadedMovements ?? [], true).filter(c => c.number)
                )
              : [],
          }) satisfies PackingListToConclude
      ) ?? []
    )
  }

  const [packingLists, setPackingLists] = useState<PackingListToConclude[]>(() => initPackingList(movements ?? []))

  const packingListsToConclude = useMemo(() => packingLists.filter(p => p.conclude), [packingLists])
  const [freighters, loadFreighter, loadingFreighters] = useApi(api.getFreighter, [])
  const canConclude = useMemo(
    () => !!packingListsToConclude.length && (packingLists.every(pl => !pl.isOpen) || !multiPackingListConsignment),
    [packingLists]
  )

  useEffect(() => {
    if (isProductionConsignment) {
      if (packingLists.length === 1) {
        articleListModal.show(packingLists[0].packingList)
      }
      return
    }
    loadFreighter().catch(console.error)
  }, [])

  function updatePackingList(id: string, update: UpdatePackingList) {
    setPackingLists(prev =>
      prev.map(p => {
        if (p.packingList.id !== id) return p
        if (typeof update === 'function') return { ...p, ...update(p) }
        return { ...p, ...update }
      })
    )
  }

  function handlePackingListPressed(packingList: PackingList) {
    if (isProductionConsignment) {
      articleListModal.show(packingList)
      return
    }
    updatePackingList(packingList.id, prev => ({ isOpen: !prev.isOpen }))
  }

  const { concludePackingLists } = usePackingListConclusion()

  function handleConclude(packingList?: PackingList) {
    if (packingList) {
      concludePackingLists([{ packingList }], { closeOnSuccess: packingLists.length <= 1 })
        .then(() => {
          setPackingLists(prev => prev.filter(pl => pl.packingList.id !== packingList.id))
        })
        .catch(console.error)
      return
    }

    if (!packingListsToConclude.length) return
    if (colloMode && packingListsToConclude.some(pl => pl.collos?.some(c => c.collo && !colloUtils.isComplete(c.collo)))) {
      alert.info(i18n.t('CONCLUDE_COLLOS_FIRST_ALERT'))
      return
    }
    concludePackingLists(packingListsToConclude, { closeOnSuccess: true }).catch(console.error)
  }

  function reloadMovements() {
    reloadPackingListMovements()
      .then(result => {
        setPackingLists(prev => {
          const newInit = initPackingList(result)
          return prev.map(p => {
            const foundNew = newInit.find(np => np.packingList.id === p.packingList.id)
            if (!foundNew) return p
            return { ...p, collos: foundNew.collos }
          })
        })
      })
      .catch(console.error)
  }

  return (
    <View style={[IMLayout.flex.f1]}>
      <ScrollView style={[IMLayout.flex.f1]}>
        {multiPackingListConsignment || isProductionConsignment ? <Title>{i18n.t('SELECT_PACKING_LIST_TO_CONCLUDE')}</Title> : <ListSpacer />}
        {packingLists?.map((pl, index) => (
          <PackingListCompletionView
            key={pl.packingList.id}
            packingList={pl}
            onPress={multiPackingListConsignment || isProductionConsignment ? () => handlePackingListPressed(pl.packingList) : undefined}
            onChange={value => updatePackingList(pl.packingList.id, value)}
            freighters={freighters ?? []}
            loadingFreighters={loadingFreighters}
            separator={index < packingLists.length - 1 && multiPackingListConsignment}
            reloadMovements={reloadMovements}
          />
        ))}
      </ScrollView>
      <ElusiveButton
        hide={!canConclude}
        title={
          packingListsToConclude.length > 1
            ? Utils.stringValueReplacer(i18n.t('CONCLUDE_N_PACKING_LISTS'), packingListsToConclude.length)
            : i18n.t('CONCLUDE_PACKING_LIST')
        }
        onPress={() => handleConclude()}
      />
      <ProductionConsignmentCompletionModal controller={articleListModal} onConclude={handleConclude} />
    </View>
  )
}
