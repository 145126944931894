import { useLanguage } from '@infominds/react-native-components'
import React, { useCallback } from 'react'

import api from '../../apis/apiCalls'
import { CustomersRequest } from '../../apis/apiRequestTypes'
import { Customer } from '../../apis/apiTypes'
import CustomerCard from '../../cards/ShoppingCart/CustomerCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import { SelectionViewProps } from '../../types'
import { customerUtils } from '../../utils/customerUtils'
import { Utils } from '../../utils/Utils'

export default function CustomerSelectionView(props: SelectionViewProps<Customer>) {
  const { i18n } = useLanguage()

  function getRequestFromInput(input: ScannerInputResult): CustomersRequest {
    const defaultRequest = { active: true } as CustomersRequest
    if (input.scannerInput) {
      return { ...defaultRequest, number: Number(input.scannerInput) }
    } else if (input.textInput) {
      if (Utils.isNumberString(input.textInput)) {
        return { ...defaultRequest, number: Number.parseInt(input.textInput, 10) }
      } else {
        return { ...defaultRequest, searchText: input.textInput }
      }
    }
    return {}
  }

  const RenderItem = useCallback((item: Customer) => <CustomerCard customer={item} />, [])

  return (
    <MWS_Input
      type={'Customer'}
      title={i18n.t('SelectCustomer')}
      style={{ flex: 1 }}
      load={input => api.getCustomers(getRequestFromInput(input))}
      sortResult={customerUtils.sort}
      itemRenderer={RenderItem}
      modalTitle={i18n.t('SelectCustomer')}
      history
      historyNameExtractor={item => customerUtils.getCustomerTitle(item, true)}
      loadFromHistory={item => api.getCustomers({ id: item.id })}
      overwriteLoadingSpinner={props.overwriteLoadingSpinner}
      {...props}
    />
  )
}
