import { Layout, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native'

import IMIcon from '../components/IMIcon'
import { Colors } from '../constants/Colors'
import { STYLE_CONSTANTS } from '../constants/Constants'
import Settings from '../screens/SettingsScreen'
import ModalBottom from './old/ModalBottom'
import Title from './old/Title'

export default function SettingsModal(props: { onSettingsChange?: () => void; isVisible: boolean; close: () => void }) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { i18n } = useLanguage()
  const { logout } = useAuthentication()

  const onLogoutClick = () => {
    logout().catch(console.error)
  }

  return (
    <ModalBottom
      isVisible={props.isVisible}
      close={props.close}
      backdropOpacity={STYLE_CONSTANTS.MODAL_BACKGROUND_OPACITY}
      keyboardAvoiding
      propagateSwipe
      swipeDirection={[]}
      style={[
        {
          height: 'auto',
          maxHeight: '90%',
          width: Layout.isSmallDevice ? '96%' : '70%',
          backgroundColor: Colors.grey,
          padding: 0,
        },
        Platform.OS !== 'web' && { marginLeft: Layout.isSmallDevice ? '2%' : '15%' },
      ]}
      content={
        <View
          style={[
            { height: 'auto' },
            colorScheme === 'dark' && {
              borderColor: Colors.grey,
              borderWidth: 1,
              borderBottomWidth: 0,
              borderTopLeftRadius: 22,
              borderTopRightRadius: 22,
            },
          ]}>
          <View
            style={{
              padding: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              backgroundColor: Colors.grey,
            }}>
            <TouchableOpacity onPress={onLogoutClick}>
              <IMIcon icon={'logout'} color={Colors.white} style={{ alignSelf: 'flex-end' }} size={25} />
            </TouchableOpacity>
            <Title style={{ color: Colors.white }}>{i18n.t('SETTINGS')}</Title>
            <TouchableOpacity onPress={props.close}>
              <IMIcon icon={'times'} color={Colors.white} style={{ alignSelf: 'flex-end' }} size={25} />
            </TouchableOpacity>
          </View>
          <ScrollView>
            <View style={{ height: 'auto', backgroundColor: theme.background }}>
              <Settings isSettingsOpen={props.isVisible} onSettingsChange={props.onSettingsChange} closeSettings={props.close} />
            </View>
          </ScrollView>
        </View>
      }
    />
  )
}
