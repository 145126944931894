import { ItemSelector, useItemSelector, useLanguage, Utils } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react'

import { Collo, Deposit, SerialNumber, StockPosition, StockPositionAvailability } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import { PackingListArticle, PackingListArticleMovement } from '../../classes/PackingListCollection'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useNotificationSound from '../../hooks/useNotificationSound'
import { PackingListStackParamList } from '../../navigation/types'
import { PackingListCollos } from '../../types/packingListTypes'
import { articleUtils } from '../../utils/articleUtils'
import colloUtils from '../../utils/colloUtils'
import packingListUtils from '../../utils/packingListUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { usePackingList } from './PackingListContext'

export type PackingListMovementContextType = {
  packingListArticle?: PackingListArticle
  scannedSerialNumber?: SerialNumber
  anyMovement?: PackingListArticleMovement
  multiPackingListConsignment: boolean
  article?: Article
  deposit?: Deposit
  stockPosition?: StockPosition
  colloSelector: ItemSelector<Collo>
  movementCollos: Collo[]
  totalQuantity: number
  selectedQuantity: number
  missingQuantity: number
  mandatoryScanningIndividualQuantities?: boolean
  hasUnsavedChanges: boolean
  addCollos: (quantity: number, fromDeposit?: Deposit, fromStockPosition?: StockPosition) => void
  addCollosWithSerialNumbers: (serialNumbers: StockPositionAvailability[]) => void
  availableCollos: PackingListCollos[]
  anyOpenCollosSelected: boolean
}

export const PackingListMovementContext = createContext<PackingListMovementContextType | null>(null)

export function PackingListMovementContextProvider({ children }: PropsWithChildren) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const { activeColloSelector } = usePackingList()
  const route = useRoute<RouteProp<PackingListStackParamList, 'PackingListMovement'>>()
  const packingListArticle = route.params?.movement
  const scannedSerialNumber = route.params?.scannedSerialNumber
  const multiPackingListConsignment = useMemo(
    () => Utils.keepUniques(packingListArticle.movements, m => m.packingList.id).length > 1,
    [packingListArticle]
  )
  const anyMovement = useMemo(() => packingListArticle?.movements.at(0), [packingListArticle])
  const { userSettings, ignoreHistoricalSN } = usePackingList()

  const article = useArticle(packingListArticle?.article, {
    useSalesUnit: userSettings?.isVpk1PickingActive,
    isHistoricalSerialNumberActive: userSettings?.isHistoricalSerialnumberActive && !ignoreHistoricalSN,
    useUnit: articleUtils.createMeasurementUnit(
      anyMovement?.movement?.unitCode,
      anyMovement?.movement?.unitId,
      packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement)
    ),
  })
  const movementCollos = useMemo(() => packingListUtils.getCollosFromPackingListMovements(packingListArticle?.movements.map(m => m.movement)), [])
  const colloSelector = useItemSelector<Collo>(movementCollos, colloUtils.compare)

  const availableCollos = useMemo(
    () => activeColloSelector.availableCollos?.filter(av => packingListArticle?.movements.some(m => m.packingList.id === av.packingList.id)) ?? [],
    [activeColloSelector.availableCollos, packingListArticle]
  )
  const anyOpenCollosSelected = useMemo(
    () =>
      !!Object.entries(activeColloSelector.activeCollos).filter(
        ([key, value]) => !!value && packingListArticle.movements.some(m => m.packingList.id === key)
      ).length,
    [activeColloSelector.activeCollos, packingListArticle.movements]
  )
  const hasUnsavedChanges = useMemo(() => colloSelector.items.some(c => !c.id), [colloSelector.items])
  const totalQuantity = useMemo(
    () =>
      Math.max(
        0,
        Utils.sum(packingListArticle?.movements, m => m.movement.quantity)
      ),
    [packingListArticle?.movements]
  )

  const selectedQuantity = useMemo(() => Utils.sum(colloSelector.items, item => item.quantity), [colloSelector.items])
  const missingQuantity = useMemo(() => Math.max(0, totalQuantity - selectedQuantity), [totalQuantity, selectedQuantity])

  // TODO remove these
  const deposit = Utils.getValueIfAllAreEqual(packingListArticle?.movements, m => m.movement.deposit?.id)?.movement.deposit
  const stockPosition = Utils.getValueIfAllAreEqual(packingListArticle?.movements, m => m.movement.stockposition?.id)?.movement.stockposition

  const mandatoryScanningIndividualQuantities = Utils.getValueIfAllAreEqual(
    packingListArticle?.movements,
    m => m.movement.mandatoryScanningIndividualQuantities
  )?.movement.mandatoryScanningIndividualQuantities

  const { playConfirmSound, playWarningSound } = useNotificationSound()

  useEffect(() => {
    if (!colloSelector.items.some(c => !c.id)) return // no new collos added
    if (selectedQuantity > totalQuantity) {
      playWarningSound()
    } else if (selectedQuantity === totalQuantity) {
      playConfirmSound()
    }
  }, [selectedQuantity, totalQuantity])

  function addCollos(quantity: number, fromDeposit?: Deposit, fromStockPosition?: StockPosition) {
    let remainingQuantityToAdd = quantity || 1
    const items = [...colloSelector.items]

    if (article?.isSerialNumberActive) return

    // add one coll for every packinglist. Even if remaining quantity is 0
    for (const movement of packingListArticle.movements) {
      // check if a collo of the same movement on the same position already exists
      const foundCollo = items.find(
        c =>
          !c.id &&
          c.packinglistmovementId === movement.movement.id &&
          stockPositionUtils.comparePosition(c.deposit, fromDeposit, c.stockposition, fromStockPosition)
      )
      if (foundCollo) {
        if (remainingQuantityToAdd > 0) {
          const quantityToAdd = Math.min(movement.movement.quantity - foundCollo.quantity, remainingQuantityToAdd)
          if (quantityToAdd > 0) {
            items.forEach(item => {
              if (item !== foundCollo) return
              item.quantity = item.quantity + quantityToAdd
            })
            remainingQuantityToAdd = Math.max(remainingQuantityToAdd - quantityToAdd, 0)
          }
        }
        continue
      }

      const existingQuantity = Utils.sum(
        items.filter(c => c.packinglistmovementId === movement.movement.id),
        c => c.quantity
      )
      const quantityToAdd = Math.min(Math.max(movement.movement.quantity - existingQuantity, 0), remainingQuantityToAdd)
      // add collo even if quantity is 0. Only this way user can still edit the quantity. The collos with quantity 0 will be filtered before sending to the server
      items.push(colloUtils.create(movement.movement, quantityToAdd, fromDeposit, fromStockPosition))
      remainingQuantityToAdd = Math.max(remainingQuantityToAdd - quantityToAdd, 0)
    }
    colloSelector.set(items)
  }

  function addCollosWithSerialNumbers(serialNumberAvailabilities: StockPositionAvailability[]) {
    if (!article?.isSerialNumberActive || !article || !serialNumberAvailabilities.length) return

    // check if SN is already selected
    const newSN = serialNumberAvailabilities.filter(
      serial =>
        !colloSelector.items
          .filter(i => !article.isLottoSerialNumber || !i.id)
          .some(
            i =>
              i.serialnumber?.id === serial.serialnumber?.id &&
              i.stockposition?.id === serial.stockposition?.id &&
              i.deposit?.id === serial.deposit?.id
          ) ||
        (!!article?.isHistoricalSerialNumber &&
          !colloSelector.items.find(
            i =>
              i.serialnumber?.number?.toLowerCase() === serial.serialnumber?.number?.toLowerCase() &&
              i.stockposition?.id === serial.stockposition?.id &&
              i.deposit?.id === serial.deposit?.id
          ))
    )

    if (newSN.length !== serialNumberAvailabilities.length) {
      alert.info(i18n.t('SerialnumberAlreadySelected'))
    }

    if (!newSN.length) return

    const items = [...colloSelector.items]

    for (const availability of newSN) {
      if (!availability.quantity || !availability.serialnumber) continue
      let addedAnything = false
      let remainingQuantityToAdd = article.isLottoSerialNumber ? availability.quantity : 1

      // check if SN belongs to an order of any movement
      const snOrder = packingListArticle.movements.find(m =>
        m.movement.orderSerialnumberquantities.find(q => q.serialnumber.id === availability.serialnumber?.id)
      )
      if (snOrder) {
        const existingQuantity = Utils.sum(
          items.filter(c => c.packinglistmovementId === snOrder.movement.id),
          c => c.quantity
        )
        let amount = 1
        if (article.isLottoSerialNumber) {
          const missingAmount =
            snOrder.movement.quantity * packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement?.movement) - existingQuantity
          amount = missingAmount
        }

        if (amount) {
          items.push(colloUtils.create(snOrder.movement, amount, availability.deposit, availability.stockposition, availability.serialnumber))
        }
        continue
      }

      // add serialNumber to movements with missing quantities. If nothing was added, then add a collo with quantity 1
      for (const movement of packingListArticle.movements) {
        const existingQuantity = Utils.sum(
          items.filter(c => c.packinglistmovementId === movement.movement.id),
          c => c.quantity
        )

        let quantityToAdd = article.isLottoSerialNumber ? availability.quantity : 1
        const missingAmount = movement.movement.quantity - existingQuantity
        quantityToAdd = Math.max(Math.min(quantityToAdd, missingAmount), 0)

        if (quantityToAdd <= 0) continue
        addedAnything = true
        items.push(colloUtils.create(movement.movement, quantityToAdd, availability.deposit, availability.stockposition, availability.serialnumber))
        remainingQuantityToAdd = Math.max(remainingQuantityToAdd - quantityToAdd, 0)
        if (remainingQuantityToAdd <= 0) break
      }

      // if nothing was added then add the serialnumber to the last movement with quantity 1
      if (!addedAnything) {
        const lastMovement = packingListArticle.movements.at(-1)?.movement
        if (lastMovement) items.push(colloUtils.create(lastMovement, 1, availability.deposit, availability.stockposition, availability.serialnumber))
      }
    }
    colloSelector.set(items)
  }

  return (
    <PackingListMovementContext.Provider
      value={{
        packingListArticle,
        scannedSerialNumber,
        anyMovement,
        multiPackingListConsignment,
        article,
        deposit,
        stockPosition,
        colloSelector,
        movementCollos,
        totalQuantity,
        selectedQuantity,
        missingQuantity,
        mandatoryScanningIndividualQuantities,
        hasUnsavedChanges,
        addCollos,
        addCollosWithSerialNumbers,
        availableCollos,
        anyOpenCollosSelected,
      }}>
      {children}
    </PackingListMovementContext.Provider>
  )
}

export function usePackingListMovement() {
  const context = useContext(PackingListMovementContext)

  if (!context) {
    throw new Error('usePackingListMovement must be used within a PackingListMovementContextProvider')
  }

  return context
}
