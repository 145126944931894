import React from 'react'

import { SerialNumber } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import useRouteParam from '../../hooks/useRouteParam'
import SerialnumberInputManufactorNumberView from '../../views/SerialNumber/SerialnumberInputManufactorNumberView'

export default function SerialnumberManufacturerNumberScreen() {
  const serialnumber = useRouteParam<SerialNumber>('serialnumber')

  return <MWS_Screen>{serialnumber && <SerialnumberInputManufactorNumberView serialnumber={serialnumber} />}</MWS_Screen>
}
