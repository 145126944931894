import { useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useMemo, useRef } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'

import { Item, SerialNumber } from '../../apis/apiTypes'
import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import IMRefreshControl from '../../components/IMRefreshControl'
import TitleWithInfoButton from '../../components/MWS/TitleWithInfoButton'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import useAlert from '../../hooks/useAlert'
import useModalController from '../../hooks/useModalController'
import PackingListAddItemModal from '../../modals/PackingListAddItemModal'
import { PackingListStackParamList } from '../../navigation/types'
import packingListUtils from '../../utils/packingListUtils'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import PackingListConcludeButtonsView from './PackingListConcludeButtonsView'

export default function PackingListView(props: { reload: (openMovementWithId?: string) => void }) {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const loader = useLoadingIndicator()

  const navigation = useNavigation<NavigationProp<PackingListStackParamList, 'PackingList'>>()
  const listRef = useRef<FlatList<PackingListArticle> | null>(null)

  const {
    packingLists,
    userSettings,
    movements,
    isPreConsignment,
    isProductionConsignment,
    ignoreHistoricalSN,
    forceScan,
    multiPackingListConsignment,
  } = usePackingList()
  const scannedSerialNumber = useRef<SerialNumber | undefined>()

  const infoText = useMemo(
    () =>
      packingLists
        ?.map(p => p.additionalinfos)
        .filter(Boolean)
        .join(' / '),
    [packingLists]
  )
  const addMovementModal = useModalController<Item>()

  function handleItemInput(item: Item) {
    if (loader.loading) return
    const foundMovement = findMatchingMovement(item)
    if (foundMovement) {
      handlePackingListMovementSelected(foundMovement, item.serialnumber)
      return
    }

    //item was not found in movements. If bit ask if its supposed to be added
    if (userSettings?.isPackingAddItemActive && !isPreConsignment && !isProductionConsignment && !multiPackingListConsignment) {
      alert
        .yesNo(i18n.t('PackingListArticleNotFoundAddAlert'), item.article.code)
        .then(result => {
          if (!result) return
          addMovementModal.show(item)
        })
        .catch(console.error)
      return
    }
    alert.error(i18n.t('PackingListArticleNotFound'), item.article.code)
  }

  function findMatchingMovement(item: Item) {
    const foundPackingListMovements = movements?.filter(m => m.article.id === item.article.id)
    if (!foundPackingListMovements?.length) return undefined
    //if only one movement matches the input navigate to that
    if (foundPackingListMovements?.length === 1) return foundPackingListMovements[0]
    //if movement has an order with the serialNumber (can only ever be 1)
    if (item.serialnumber) {
      const foundMovementWithSerialOrder = foundPackingListMovements.find(plm =>
        plm.movements?.find(m => m.movement.orderSerialnumberquantities?.find(sn => sn.serialnumber?.id === item.serialnumber?.id))
      )
      if (foundMovementWithSerialOrder) return foundMovementWithSerialOrder
    }

    const openMovements = foundPackingListMovements.filter(plm => packingListUtils.getIncompleteMovements([plm]).length > 0)
    const startedMovements = openMovements.filter(plm => plm.movements.find(m => m.movement.collonumbers?.find(c => c.quantity > 0)))
    //navigate to any already started, but unfinished movement
    if (startedMovements.length > 0) return startedMovements[0]
    //navigate to any matching open movement
    if (openMovements.length > 0) return openMovements[0]

    if (item.serialnumber) {
      const foundMovementWithSerial = foundPackingListMovements.find(om =>
        om.movements.find(m => m.movement.collonumbers?.find(c => c.serialnumber?.id === item.serialnumber?.id))
      )
      //navigate to movement with matching serialnumber
      if (foundMovementWithSerial) return foundMovementWithSerial
    }
    //navigate to any movement
    return foundPackingListMovements[0]
  }

  function handlePackingListMovementSelected(packingListArticle: PackingListArticle, serialNumber?: SerialNumber) {
    scannedSerialNumber.current = serialNumber
    if (packingLists != null || packingListArticle != null) {
      navigation.navigate('PackingListMovement', { movement: packingListArticle, scannedSerialNumber: serialNumber })
    }
  }

  return (
    <View style={{ flex: 1 }}>
      {!!packingLists && <TitleWithInfoButton infoText={infoText} text={packingListUtils.getPackingListsTitle(packingLists)} />}
      <ItemSelectionView onSelected={handleItemInput} showResultInModal fetchArticleAndSerial ignoreHistoricalSN={ignoreHistoricalSN} />
      <View style={styles.list}>
        <FlatList
          ref={listRef}
          data={movements}
          keyExtractor={item => item.id}
          onScrollToIndexFailed={console.error}
          renderItem={({ item }) => {
            const allowPress = !forceScan || item.movements?.find(m => m.movement.collonumbers?.find(c => c.quantity > 0))
            return (
              <PackingListArticleCard
                item={item}
                onPress={allowPress ? () => handlePackingListMovementSelected(item) : undefined}
                showAvailability
                showOrderSerialnumber
                showPackingLists={multiPackingListConsignment}
              />
            )
          }}
          refreshControl={<IMRefreshControl refreshing={false} onRefresh={props.reload} />}
        />
      </View>
      <PackingListConcludeButtonsView />
      <PackingListAddItemModal
        controller={addMovementModal}
        onDone={openMovementWithId => {
          addMovementModal.close()
          props.reload(openMovementWithId)
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  list: {
    marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    flex: 1,
  },
})
