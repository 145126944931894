import React, { useEffect } from 'react'
import { FlatList, StyleSheet, TouchableOpacity } from 'react-native'

import { IMIconName } from '../components/IMIconNames'
import IMModal from '../components/IMModal'
import ListSpacer from '../components/MWS/ListSpacer'
import useItemSelector from '../hooks/useItemSelector'

export default function SelectionModal<T>(props: {
  show: boolean
  title?: string
  items: T[]
  close: (item?: T | undefined) => void
  onMultiSelection?: (items: T[]) => void
  renderItem: (item: T, index: number, selected: boolean) => JSX.Element
  multiSelection?: boolean
  preSelection?: T[]
  onModalHide?: (() => void) | undefined
  closeIcon?: IMIconName
}) {
  const itemSelector = useItemSelector<T>([])

  useEffect(() => {
    if (!props.show) return
    itemSelector.set(props.preSelection ?? [])
  }, [props.show])

  function handleItemSelected(item: T) {
    if (!props.multiSelection) {
      props.close(item)
      return
    }
    itemSelector.toggle(item)
  }

  function handleModalClose() {
    if (props.multiSelection && props.onMultiSelection) props.onMultiSelection(itemSelector.items)
    props.close()
  }

  return (
    <IMModal isVisible={props.show} close={handleModalClose} closeIcon={props.closeIcon ?? 'check'} onModalHide={props.onModalHide}>
      <FlatList
        style={styles.modal}
        data={props.items}
        renderItem={({ item, index }) => (
          <TouchableOpacity
            onPress={() => {
              handleItemSelected(item)
            }}>
            {props.renderItem(item, index, !!props.multiSelection && itemSelector.includes(item))}
          </TouchableOpacity>
        )}
        ListHeaderComponent={<ListSpacer />}
        ListFooterComponent={<ListSpacer />}
      />
    </IMModal>
  )
}

const styles = StyleSheet.create({
  modal: {},
})
