import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { KeyboardAvoidingView, Platform, SafeAreaView, ScrollView, StatusBar, StyleSheet, View, ViewProps } from 'react-native'

import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import Screen from '../old/Screen'
import MWS_Header, { MWSHeaderProps } from './MWS_Header'

interface MWSScreenProps {
  noTopPadding?: boolean
  noStatusBarAvoidance?: boolean
  keyboardAvoiding?: boolean
  title?: string
}

export default function MWS_Screen(props: MWSScreenProps & MWSHeaderProps & ViewProps) {
  const { keyboardAvoiding, style, children, noTopPadding, noStatusBarAvoidance, ...otherProps } = props
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.background,
      paddingTop: noTopPadding ? 0 : STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    },
  })

  return (
    <Screen scrollable={false} style={{ padding: 0, margin: 0, backgroundColor: Colors.tint, flex: 1 }}>
      <SafeAreaView style={[style, { flex: 1, paddingTop: Platform.OS === 'android' && !noStatusBarAvoidance ? StatusBar.currentHeight : 0 }]}>
        <MWS_Header {...props} />
        {keyboardAvoiding ? (
          <ScrollView style={[styles.container, style]} {...otherProps}>
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={-30} style={{}}>
              {children}
            </KeyboardAvoidingView>
          </ScrollView>
        ) : (
          <View style={[styles.container, style]} {...otherProps}>
            {children}
          </View>
        )}
      </SafeAreaView>
    </Screen>
  )
}
