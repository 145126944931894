import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

import api from '../../apis/apiCalls'
import { Parcel } from '../../apis/apiTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { ScreenType } from '../../types'
import ParcelSelectionView from '../../views/Parcel/ParcelSelectionView'

export default function ParcelSelectionScreen() {
  const navigation = useNavigation()
  const loader = useLoadingIndicator()
  const { i18n } = useLanguage()

  function handleParcelSelected(parcel: Parcel) {
    loader.setLoading(true)
    api
      .getFreighter({ id: parcel?.freighterId })
      .then(result => {
        if (result && result.length > 0) {
          navigation.navigate(ScreenType.Parcel, { parcel: parcel, freighter: result[0] })
        }
      })
      .finally(() => loader.setLoading(false))
      .catch(console.error)
  }

  return <MWS_Screen title={i18n.t('SelectParcel')}>{<ParcelSelectionView onSelected={handleParcelSelected} />}</MWS_Screen>
}
