import { isEqual } from 'date-fns'
import React from 'react'
import DateTimePickerModal from 'react-native-modal-datetime-picker'

interface Props {
  date?: Date
  setDate: (date: Date) => void
  show: boolean
  close: () => void
}

export default function DatePicker({ date, setDate, close, show }: Props) {
  const onChange = (newDate: Date) => {
    close()

    if (!date || !isEqual(date, newDate)) {
      setDate(newDate)
    }
  }

  return (
    <>{show && <DateTimePickerModal date={date} isVisible={true} mode="date" onConfirm={newDate => onChange(newDate)} onCancel={() => close()} />}</>
  )
}
