import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { Production } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import ProductionData from '../../classes/ProductionData'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import useRouteParam from '../../hooks/useRouteParam'
import { ProductionType, ScreenType } from '../../types'
import ProductionInSelectionView from '../../views/Production/ProductionInSelectionView'
import ProductionOutSelectionView from '../../views/Production/ProductionOutSelectionView'

export default function ProductionSelectionScreen() {
  const navigation = useNavigation()
  const type = useRouteParam<ProductionType>('type')

  function handleProductionInSelected(production: Production, article?: Article) {
    navigation.navigate(ScreenType.ProductionIn, { production: production, article: article })
  }

  function handleProductionOutSelected(production: ProductionData) {
    navigation.navigate(ScreenType.ProductionOut, { productionHandler: production })
  }

  return (
    <MWS_Screen>
      {type === ProductionType.In && <ProductionInSelectionView onProductionSelected={handleProductionInSelected} />}
      {type !== ProductionType.In && (
        <ProductionOutSelectionView
          onProductionSelected={handleProductionOutSelected}
          isDefectiveMaterialProduction={type === ProductionType.DefectiveMaterial}
        />
      )}
    </MWS_Screen>
  )
}
