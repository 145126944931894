import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, StyleProp, View, ViewStyle } from 'react-native'

import ProductionPartCard from '../../cards/Production/ProductionPartCard'
import IMText from '../../components/IMText'
import ListSpacer from '../../components/MWS/ListSpacer'
import NoEntriesTag from '../../components/NoEntriesTag'
import SubmitButton from '../../components/SubmitButton'
import { ProductionHandler, ProductionMode } from '../../hooks/specific/useProductionHandler'

export default function ProductionPartsView(props: { handler: ProductionHandler; style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()

  return (
    <View style={[{ flex: 1 }, props.style]}>
      <IMText style={{ textAlign: 'center' }}>{i18n.t('ProductionList')}</IMText>
      <FlatList
        data={props.handler.filteredParts}
        renderItem={({ item }) => (
          <ProductionPartCard
            part={item}
            showQuantities
            isDefectiveMaterialProduction={props.handler.production?.isDefectiveMaterialProduction}
            showCompletionIndicator
            deposit={item.part.deposit ?? props.handler.production?.production.deposit}
            onPress={
              props.handler.productionMode === ProductionMode.processAmountSelection
                ? undefined
                : () => {
                    props.handler.setSelectedPart(item)
                  }
            }
          />
        )}
        ListFooterComponent={props.handler.filteredParts.length > 0 ? <ListSpacer /> : <NoEntriesTag />}
      />
      <SubmitButton
        hide={!props.handler.parts.items.find(p => !!p.selectedQuantities.find(q => q.quantity > 0))}
        hideFromKeyBoard
        title={i18n.t('Complete')}
        //disabled={!!props.production.production?.employeeTimeTypeId && !!props.production.parts.items.find((p) => !productionUtils.isPartFinished(p))}
        onPress={props.handler.completeProduction}
      />
    </View>
  )
}
