import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { FlatList, View } from 'react-native'

import PackingListArticleCard from '../../cards/Packinglist/PackingListArticleCard'
import { PackingListArticle } from '../../classes/PackingListCollection'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import Button from '../../components/old/Button'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'

type PackingLisIncompleteMovementsViewProps = {
  incompletePackingListMovements: PackingListArticle[]
  onContinue: () => void
}

export default function PackingLisIncompleteMovementsView({ onContinue, incompletePackingListMovements }: PackingLisIncompleteMovementsViewProps) {
  const { i18n } = useLanguage()

  const { multiPackingListConsignment } = usePackingList()

  return (
    <View style={{ flex: 1 }}>
      <IMTextWithIcon
        viewStyle={{ justifyContent: 'center', marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
        iconColor={Colors.info}
        icon={'exclamationTriangle'}
        text={i18n.t('PackingListIncompleteMovements')}
      />
      <View style={{ flex: 1 }}>
        <FlatList
          data={incompletePackingListMovements}
          renderItem={({ item }) => <PackingListArticleCard item={item} showPackingLists={multiPackingListConsignment} />}
        />
      </View>
      <Button title={i18n.t('PackingListContinueWithIncompleteMovements')} onPress={() => onContinue()} />
    </View>
  )
}
