import { useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Colors } from '../constants/Colors'
import IMStyles from '../constants/Styles'
import Text, { TextProps } from './old/Text'

export type IMTextProps = TextProps & {
  primary?: boolean
  secondary?: boolean
}

export default function IMText(props: IMTextProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { primary, secondary, style, ...textProps } = props

  return <Text {...textProps} style={[IMStyles.text, primary && IMStyles.primaryText, secondary && { color: theme.textDetail }, style]} />
}
