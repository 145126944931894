import React from 'react'
import { StyleSheet, View } from 'react-native'
import DefaultModal, { ModalProps } from 'react-native-modal'

export default function Modal(props: Partial<ModalProps>) {
  const { style, children, ...otherProps } = props

  return (
    <DefaultModal {...otherProps} style={styles.modal}>
      <View style={[styles.contentView, style]}>{children}</View>
    </DefaultModal>
  )
}

const styles = StyleSheet.create({
  modal: {
    maxHeight: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentView: {
    maxHeight: '100%',
    maxWidth: '100%',
    borderRadius: 10,
    backgroundColor: 'tran',
    overflow: 'hidden',
  },
})
