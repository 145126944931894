import React, { useMemo } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import { PackingListArticleMovement } from '../../classes/PackingListCollection'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import { articleUtils } from '../../utils/articleUtils'
import colloUtils from '../../utils/colloUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export type PackingListColloCardProps = {
  packingListMovements: PackingListArticleMovement[] | undefined
  collo: Collo | undefined
  style?: StyleProp<ViewStyle>
  onEdit?: ((collo: Collo) => void) | false
  onDelete?: (() => void) | false
  showSerialWarranty?: boolean
  showPackingList?: boolean
}

export default function PackingListColloCard({
  packingListMovements,
  collo,
  style,
  onEdit,
  onDelete,
  showSerialWarranty,
  showPackingList,
}: PackingListColloCardProps) {
  const anyMovement = useMemo(() => packingListMovements?.at(0)?.movement, [packingListMovements])
  const warrantyDate = !!collo?.serialnumber && showSerialWarranty ? Utils.parseDate(collo.serialnumber?.warrantySupplier) : undefined
  const totalQuantityText = useMemo(() => articleUtils.formatQuantity(collo?.quantity, anyMovement?.unitCode), [packingListMovements])
  const packingList = useMemo(
    () =>
      !!showPackingList &&
      !!packingListMovements?.length &&
      packingListMovements?.length > 1 &&
      packingListMovements?.find(plm => plm.movement.id === collo?.packinglistmovementId)?.packingList,
    [collo?.packinglistmovementId, packingListMovements]
  )

  const handleOnPress = useMemo(() => {
    if (!collo || collo.id || articleUtils.isSerialNumberActive(collo?.article) || !!collo?.article?.isLottoSerialnumber || !onEdit) return undefined

    return () => onEdit(collo)
  }, [collo?.id, collo?.article, packingListMovements])

  return (
    <MWS_BaseCard onPress={handleOnPress} onDelete={onDelete} icon={'cart-flatbed-boxes'} cardStyle={style} style={{ justifyContent: 'flex-start' }}>
      <View style={{ justifyContent: 'center', flexDirection: 'column', flex: 1 }}>
        {!!collo?.serialnumber && <IMTextWithIcon icon="barcode" text={serialnumberUtils.getSerialNumberString(collo.serialnumber, true)} />}
        {(!!collo?.stockposition || !!collo?.deposit) && (
          <IMTextWithIcon
            icon={collo.stockposition ? 'shelf' : 'warehouse-full'}
            text={stockPositionUtils.getTitle(collo?.stockposition, collo?.deposit)}
          />
        )}
        {!!collo?.number && collo?.number !== '0' && <IMTextWithIcon icon="box-taped" text={colloUtils.getTitle(collo)} />}
        {!!warrantyDate && <IMTextWithIcon icon="calendar" text={Utils.FormatDate(warrantyDate, 'DD.MM.YYYY') ?? ''} />}
        {!!packingList && <IMTextWithIcon secondary numberOfLines={1} icon="clipboard-check-list" text={packingList.number.trim()} />}
      </View>
      <View style={{ justifyContent: 'center' }}>
        <IMText>{totalQuantityText}</IMText>
      </View>
    </MWS_BaseCard>
  )
}
