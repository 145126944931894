import { Dispatch, SetStateAction, useState } from 'react'

export interface ItemSelector<T> {
  /**
   * List of items (to edit the list directly use set function)
   */
  items: T[]
  /**
   * Sets the list to the given value
   */
  set: Dispatch<SetStateAction<T[]>>
  /**
   * Adds or Removes the given item from the list, depending on whether or not it is already in the list.
   */
  toggle: (item: T) => void
  /**
   * Adds given items to the list, if they are not already included
   */
  add: (...item: T[]) => void
  /**
   * Removes the given item from the list.
   */
  remove: (item: T) => void
  /**
   * Clears the list
   */
  clear: () => void
  /**
   * Checks if item is in list. Uses the customCompareFunction if it was provided
   */
  includes: (item: T) => boolean
  /**
   * Count of items in list (length)
   */
  count: number
  /**
   * Is true if list contains at least 1 item
   */
  any: boolean
}
/**
 * Creates a list of type T and provides the most used functions for that list.
 * Every can only be added once to the list (equality determined though a === b or the customCompareFunction)
 * @param customCompareFunction Can be used to implement a custom compare function, used to determine whether two items in the list are identical. If omitted a === b will be used
 * @returns Object \{items, set(), toggle(), add(), remove(), clear(), includes(), count, any\}
 */
export default function useItemSelector<T>(defaultValue?: T[], customCompareFunction?: (a: T, b: T) => boolean): ItemSelector<T> {
  const [items, setItems] = useState<T[]>(defaultValue ?? [])

  function compareItems(a: T, b: T) {
    if (customCompareFunction) return customCompareFunction(a, b)
    return a === b
  }

  function includes(item: T) {
    if (!item) return false
    return !!items.find(f => compareItems(f, item))
  }

  function add(...itemsToAdd: T[]) {
    if (!itemsToAdd) return
    setItems(prev => {
      itemsToAdd.forEach(item => {
        if (!includes(item)) prev.push(item)
      })
      return [...prev]
    })
  }

  function remove(item: T) {
    if (!item) return
    setItems(prev => {
      if (!includes(item)) return prev
      return [...prev.filter(i => !compareItems(i, item))]
    })
  }

  function toggle(item: T) {
    if (!item) return
    if (includes(item)) remove(item)
    else add(item)
  }

  return {
    items: items,
    set: setItems,
    toggle: toggle,
    add: add,
    remove: remove,
    clear: () => setItems([]),
    includes: includes,
    count: items.length,
    any: items.length > 0,
  }
}
