import { ProductionOutgoingPostRequest, ProductionPostQuantity } from '../apis/apiRequestTypes'
import { Item, Production, ProductionPart, StockPosition, UserSettings } from '../apis/apiTypes'
import { Article } from '../classes/Article'
import { ProductionPartCollection } from '../classes/ProductionData'
import { ArticleAmountSelectionResult } from '../views/Article/ArticleAmountSelectorV2'
import { articleUtils } from './articleUtils'
import { Utils } from './Utils'

const productionUtils = {
  getTitle(production: Production | undefined) {
    if (!production?.code) return ''
    return production.code.trim()
  },
  createPartCollection(part: ProductionPart, setQuantities?: ArticleAmountSelectionResult[], userSettings?: UserSettings): ProductionPartCollection {
    const article = new Article(part.article, { isHistoricalSerialNumberActive: userSettings?.isHistoricalSerialnumberActive })
    article.setUsedUnit(part.unitId)
    return { part: part, article: article, selectedQuantities: setQuantities ?? [] }
  },
  async createProductionArticle(production: Production, isHistoricalSerialnumberActive?: boolean) {
    const article = new Article(production.article, { isHistoricalSerialNumberActive: isHistoricalSerialnumberActive })
    article.setUsedUnit(production.unitId)
    if (article.isSerialNumberActive) {
      if (production.orderSerialnumberquantities?.length) {
        article.serialNumbers = production.orderSerialnumberquantities.filter(o => o.serialnumber?.id).map(o => o.serialnumber)
      } else {
        await article.loadSerialNumbers()
      }
    }
    return article
  },
  generateOutgoingPostRequest(
    production: Production,
    parts: ProductionPartCollection[],
    isDefectiveMaterial?: boolean,
    isTransfer?: boolean
  ): ProductionOutgoingPostRequest {
    const quantities: ProductionPostQuantity[] = []
    parts.forEach(part => {
      part.selectedQuantities
        .filter(q => q.quantity > 0)
        .forEach(quantity => {
          quantities.push({
            articleId: part.article.info.id,
            productionPartId: part.part.id,
            quantity: quantity.quantity,
            serialnumberId: quantity.serialNumber?.id,
            storingpositionId: quantity.stockPosition?.id ?? '',
          })
        })
    })

    return { productionId: production?.id ?? '', isDefectiveMaterial: !!isDefectiveMaterial, isTransfer: !!isTransfer, quantities: quantities }
  },
  isPartFinished(part: ProductionPartCollection | undefined) {
    if (!part) return false
    return Utils.sum(part.selectedQuantities, item => item.quantity) >= part.part.quantity
  },
  getRemainingQuantity(part: ProductionPartCollection | undefined) {
    if (!part) return 0
    return Math.max(part.part.quantity - Utils.sum(part.selectedQuantities, q => q.quantity), 0)
  },
  partSorter(a: ProductionPartCollection, b: ProductionPartCollection) {
    const aFin = productionUtils.isPartFinished(a)
    const bFin = productionUtils.isPartFinished(b)
    if (aFin > bFin) return 1
    if (aFin === bFin) return articleUtils.sortByCode(a.article.info, b.article.info)
    return -1
  },
  createDummyPart(item: Item): ProductionPart | undefined {
    if (!item) return undefined
    return {
      article: item.article,
    } as ProductionPart
  },
  modifyPartsAfterProcessInput(
    parts: ProductionPartCollection[],
    quantityProduced: number,
    production: Production | undefined,
    employeeTimeTypeId: string | undefined,
    checkAvailability?: boolean
  ) {
    if (!production) return [...parts]
    const completeProduction = quantityProduced >= production.quantityOpen
    for (const part of parts) {
      //autocomplete all parts, that don't require a serialnumber and are either of the same productionProcess or the production is about to complete
      if (!part.article.isSerialNumberActive && (!!completeProduction || part.part.employeeTimeTypeId === employeeTimeTypeId)) {
        //if production is about the be completed, set quantity eq to remaining quantity
        const requiredQuantity = completeProduction ? part.part.quantity : Math.min(quantityProduced * part.part.quantityFactor, part.part.quantity)

        let stockPosition = part.part.stockposition
        //if stockPosition was not given but found by api, check if availability is sufficient
        if (
          !stockPosition &&
          part.part.stockpositionAvailable &&
          (!checkAvailability ||
            !part.article.isAvailabilityCheckEnabled() ||
            (part.part.quantityStockpositionAvailable && part.part.quantityStockpositionAvailable >= requiredQuantity))
        ) {
          stockPosition = part.part.stockpositionAvailable
        }
        if (stockPosition) {
          part.autoCompleted = true
          part.selectedQuantities = [
            {
              quantity: requiredQuantity,
              unit: part.article.getUsedUnit(),
              serialNumber: undefined,
              stockPosition,
              deposit: production.depositOutgoing,
            },
          ]
        }
      }
      //for SN Articles: modify required amount. Its either the selected amount or the total remaining amount, whichever is lesser
      if (part.article.isSerialNumberActive || (!part.part.stockposition && !completeProduction)) {
        part.part.quantity = Math.min(part.part.quantity, quantityProduced * part.part.quantityFactor)
      }
    }
    return [...parts]
  },
  getStockPositionFromPartSelectedQuantities(part: ProductionPartCollection | undefined) {
    if (!part?.selectedQuantities?.length) return undefined
    //@ts-ignore StockPosition !== undefined is already checked in filter
    const stockPositions: StockPosition[] = Utils.keepUniques(
      part.selectedQuantities.filter(q => !!q.stockPosition && q.quantity > 0).map(q => q.stockPosition),
      q => q?.id
    )
    if (stockPositions.length === 1) return stockPositions[0]
    return undefined
  },
}

export default productionUtils
