import api from '../apis/apiCalls'
import { OngoingInventoryPatchRequest, OngoingInventoryPostRequest } from '../apis/apiRequestTypes'
import { ArticleDTO, OngoingInventory, OngoingInventoryToConfirm, SerialNumber, StockPosition } from '../apis/apiTypes'
import { Utils } from './Utils'

const ongoingInventoryUtils = {
  getTitle(ongoingInventory?: OngoingInventoryToConfirm, ongoingInventoryTitle?: string, ongoingInventoryRecountingTitle?: string) {
    if (ongoingInventory) return ongoingInventoryRecountingTitle
    return ongoingInventoryTitle
  },
  getTextLastCount(noOngoingInventory?: string, lastOngoingInventory?: string, stockPositionOngoingInventory?: OngoingInventory | null) {
    if (!stockPositionOngoingInventory) return noOngoingInventory
    return `${lastOngoingInventory ?? ''}: ${Utils.FormatDate(stockPositionOngoingInventory.date, 'DD.MM.yyyy') ?? ''}`
  },
  getAvailabilityTolerance(ongoingInventoryToConfirm?: OngoingInventoryToConfirm, toleranceRunningInventory?: number) {
    if (ongoingInventoryToConfirm) return undefined //0.001 // return 0.001% tolerance to accept only "exact" quantity
    return toleranceRunningInventory
  },
  createPostRequest(
    stockPosition: StockPosition,
    article: ArticleDTO | undefined,
    serialNumber: SerialNumber | undefined,
    amount: number | undefined
  ) {
    return {
      stockpositionId: stockPosition.id,
      articleId: article?.id ?? '',
      serialnumberId: serialNumber?.id ?? '',
      quantity: amount ?? 0,
    } as OngoingInventoryPostRequest
  },
  createPatchRequest(
    stockPosition: StockPosition | undefined,
    article: ArticleDTO | undefined,
    date: string | undefined,
    serialNumber: SerialNumber | undefined,
    amount: number | undefined
  ) {
    return {
      stockpositionId: stockPosition?.id ?? '',
      articleId: article?.id ?? '',
      date: date,
      serialnumberId: serialNumber?.id ?? '',
      quantityRecounting: amount ?? 0,
    } as OngoingInventoryPatchRequest
  },
  async getOngoingInventory(stockPositionId?: string, articleId?: string, serialNumberId?: string, date?: string) {
    if (!stockPositionId && !articleId && !serialNumberId) return null
    try {
      const inventories = await api.getOngoingInventories({
        stockpositionId: stockPositionId,
        articleId: articleId,
        serialNumberId: serialNumberId,
        date: date,
      })
      if (inventories.length > 0) {
        return inventories[0]
      }
      return null
    } catch (exception) {
      console.error(exception)
      return null
    }
  },
}

export default ongoingInventoryUtils
