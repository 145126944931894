import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { Collo } from '../../apis/apiTypes'
import IMText from '../../components/IMText'
import { IMTextWithIcon } from '../../components/IMTextWithIcon'
import Text from '../../components/old/Text'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { usePackingListMovement } from '../../contexts/packingLists/PackingListMovementContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import { PackingListColloGroup, PackingListMovementCollo } from '../../hooks/packingLists/useGroupedCollos'
import { articleUtils } from '../../utils/articleUtils'
import colloUtils from '../../utils/colloUtils'
import { serialnumberUtils } from '../../utils/serialnumberUtils'
import stockPositionUtils from '../../utils/stockPositionUtils'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard, { MWSBaseCardProps } from '../MWS_BaseCard'

export type PackingListColloGroupCardProps = {
  group: PackingListColloGroup | undefined
  style?: StyleProp<ViewStyle>
  onEdit: (collo: Collo) => void
  onDelete: (collos: PackingListMovementCollo[]) => void
  onQuantityChanged: (quantity: number, collo: Collo) => void
  showSerialWarranty?: boolean
}

export default function PackingListColloGroupCard({
  group,
  style,
  onEdit,
  onDelete,
  showSerialWarranty,
  onQuantityChanged,
}: PackingListColloGroupCardProps) {
  const { theme } = useTheme()
  const anyMovement = useMemo(() => group?.items?.at(0)?.movement, [group?.items])
  const { colloSelector, article, multiPackingListConsignment } = usePackingListMovement()
  const [showPackingLists, setShowPackingLists] = useState(false)
  const totalQuantity = useMemo(() => Utils.sum(group?.items, c => c.collo.quantity), [group])

  const uniqueCollo = useMemo(() => (group?.items && group.items.length === 1 ? group?.items.at(0) : undefined), [group])

  const { quantityText, totalQuantityText } = useMemo(() => {
    if (!uniqueCollo) {
      return { quantityText: articleUtils.formatQuantity(totalQuantity, anyMovement?.unitCode) }
    }
    const quantity = articleUtils.formatQuantity(totalQuantity, anyMovement?.unitCode)
    const total = articleUtils.formatPartQuantity(totalQuantity, uniqueCollo.movement?.quantity, uniqueCollo.movement?.unitCode)

    if (!article?.isSerialNumberActive || !uniqueCollo || !multiPackingListConsignment) {
      return { quantityText: total }
    }
    if (article.isLottoSerialNumber) {
      return { quantityText: total }
    }

    const totalQuantityCommissioned = Utils.sum(
      colloSelector.items.filter(c => c.packinglistmovementId === uniqueCollo.collo.packinglistmovementId),
      c => c.quantity
    )
    const requiredQuantity = uniqueCollo.movement?.quantity ?? 0
    const packingListTotal = articleUtils.formatPartQuantity(totalQuantityCommissioned, requiredQuantity)

    return { quantityText: quantity, totalQuantityText: packingListTotal ? `(${packingListTotal})` : undefined }
  }, [totalQuantity, uniqueCollo, article, multiPackingListConsignment])

  const uniquePackingList = useMemo(() => {
    if (Utils.keepUniques(group?.items, c => c.movement?.id).length !== 1) {
      return undefined
    }
    return group?.items.at(0)?.packingList
  }, [group])

  useEffect(() => {
    if (!uniquePackingList || !showPackingLists) return
    setShowPackingLists(false)
  }, [uniquePackingList])

  const handleOnPress = useMemo(() => {
    if (group?.items?.length && group?.items?.length > 1) {
      return () => setShowPackingLists(q => !q)
    }
    if (!group?.items.length) return undefined
    const collo = group.items[0].collo
    if (collo.id || (!uniqueCollo && articleUtils.isSerialNumberActive(collo?.article) && !collo?.article?.isLottoSerialnumber) || !onEdit) {
      return undefined
    }

    return () => onEdit(collo)
  }, [group, uniqueCollo])

  const handleDelete = useMemo(() => {
    const deletableCollos = group?.items.filter(i => colloUtils.colloCanBeDeleted(i.collo))
    if (!deletableCollos?.length) return undefined

    return () => onDelete(deletableCollos)
  }, [group?.items])

  const color = useMemo(() => {
    if (!uniqueCollo) return undefined
    const totalQuantityCommissioned = Utils.sum(
      colloSelector.items.filter(c => c.packinglistmovementId === uniqueCollo.collo.packinglistmovementId),
      c => c.quantity
    )
    const requiredQuantity = uniqueCollo.movement?.quantity ?? 0

    if (totalQuantityCommissioned > requiredQuantity) return theme.error
    if (totalQuantityCommissioned === requiredQuantity) return theme.primary
    return undefined
  }, [uniqueCollo, colloSelector.items])

  return (
    <>
      <MWS_BaseCard
        onPress={handleOnPress}
        onDelete={handleDelete}
        icon={uniqueCollo ? 'cart-flatbed-boxes' : group?.stockPosition ? 'shelf' : 'warehouse-full'}
        cardStyle={style}
        style={{ justifyContent: 'flex-start' }}>
        <View style={{ justifyContent: 'center', flexDirection: 'column', flex: 1 }}>
          {(!!group?.stockPosition || !!group?.deposit) && (
            <>
              {!article?.isSerialNumberActive && <IM.Text children={stockPositionUtils.getTitle(group.stockPosition, group.deposit)} />}
              {!!article?.isSerialNumberActive && (
                <IMTextWithIcon
                  icon={group.stockPosition ? 'shelf' : 'warehouse-full'}
                  text={stockPositionUtils.getTitle(group.stockPosition, group.deposit)}
                />
              )}
            </>
          )}
          {!!uniqueCollo && <ColloInfo collo={uniqueCollo} showSerialWarranty={showSerialWarranty} />}
          {!!uniquePackingList && !showPackingLists && !!multiPackingListConsignment && (
            <IM.Text style={{ color }} secondary>
              {uniquePackingList.number}
            </IM.Text>
          )}
        </View>
        <View style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
          <IMText style={{ color }}>{quantityText}</IMText>
          {!!totalQuantityText && <IMText style={{ color }}>{totalQuantityText}</IMText>}
        </View>
      </MWS_BaseCard>
      {showPackingLists && (
        <IM.View style={{ marginLeft: 10 }} spacing={'bottom'}>
          {group?.items?.map(pl => (
            <PackingListCard
              key={`${pl.movement?.id}-${pl.collo.id}-${pl.collo.serialnumber?.id}`}
              collo={pl}
              showSerialWarranty={showSerialWarranty}
              onEdit={onEdit}
              onDelete={onDelete}
              onQuantityChanged={onQuantityChanged}
            />
          ))}
        </IM.View>
      )}
    </>
  )
}

type PackingListCardProps = {
  collo: PackingListMovementCollo
} & Omit<MWSBaseCardProps, 'onPress' | 'onDelete'> &
  Pick<PackingListColloGroupCardProps, 'showSerialWarranty' | 'onDelete' | 'onEdit' | 'onQuantityChanged'>
function PackingListCard({ collo, showSerialWarranty, onEdit, onDelete, onQuantityChanged, ...cardProps }: PackingListCardProps) {
  const { colloSelector, packingListArticle } = usePackingListMovement()
  const { theme } = useTheme()
  const title = useMemo(() => collo.packingList?.number?.trim(), [collo])

  const noQuantity = !collo.collo.quantity
  const quantityText = useMemo(
    () => articleUtils.formatPartQuantity(collo.collo.quantity, collo.movement?.quantity, collo.movement?.unitCode),
    [collo]
  )

  const color = useMemo(() => {
    const movement = packingListArticle?.movements.find(m => m.movement.id === collo.collo.packinglistmovementId)?.movement
    if (!movement) return undefined
    const totalQuantity = Utils.sum(
      colloSelector.items.filter(c => c.packinglistmovementId === collo.collo.packinglistmovementId),
      c => c.quantity
    )
    if (totalQuantity > movement.quantity && !collo.collo.id) return theme.error
    if (totalQuantity === movement.quantity) return theme.primary
    return undefined
  }, [collo])

  const handleOnPress = useMemo(() => {
    if (collo.collo.id) {
      return undefined
    }

    return () => onEdit(collo.collo)
  }, [collo])

  const handleOnDelete = useMemo(() => {
    if (collo.collo.id) {
      if (colloUtils.colloCanBeDeleted(collo.collo)) return () => onDelete([collo])
      return undefined
    }
    if (!collo.collo.quantity) {
      return undefined
    }
    return () => onQuantityChanged(0, collo.collo)
  }, [collo])

  return (
    <MWS_BaseCard style={styles.packingListCard} icon="cart-flatbed-boxes" onPress={handleOnPress} onDelete={handleOnDelete} {...cardProps}>
      <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.gap, styles.titleView]}>
        <IM.View style={[IMLayout.flex.f1]}>
          <IM.View style={[IMLayout.flex.row, styles.gap]}>
            <IM.View>
              <Text noStyle secondary={noQuantity} style={{ color: noQuantity ? undefined : color }}>
                {title}
              </Text>
            </IM.View>
          </IM.View>
          <ColloInfo noQuantity={noQuantity} collo={collo} showSerialWarranty={showSerialWarranty} />
        </IM.View>
        {!noQuantity && (
          <IM.Text style={{ color }} secondary={noQuantity}>
            {quantityText}
          </IM.Text>
        )}
      </IM.View>
    </MWS_BaseCard>
  )
}

type ColloInfoProps = {
  collo: PackingListMovementCollo
  showStockPosition?: boolean
  noQuantity?: boolean
} & Pick<PackingListColloGroupCardProps, 'showSerialWarranty'>
function ColloInfo({ collo, showSerialWarranty, showStockPosition, noQuantity }: ColloInfoProps) {
  const userSettings = useUserSettings()
  const { i18n } = useLanguage()
  const { colloMode, activeColloSelector } = usePackingList()

  const colloNumber = useMemo(() => {
    if (!colloMode) return undefined
    if (collo.collo.number && collo.collo.number !== '0') return colloUtils.getTitle(collo.collo)
    if (collo.packingList?.id && activeColloSelector.activeCollos[collo.packingList?.id]) {
      return activeColloSelector.activeCollos[collo.packingList?.id]
    }
    return i18n.t('NEW_COLLO')
  }, [collo, colloMode, activeColloSelector.activeCollos])

  const hasSerialNumber = useMemo(
    () => !!collo.collo.serialnumber && articleUtils.isSerialNumberActive(collo.collo.article, userSettings?.isHistoricalSerialnumberActive),
    [collo]
  )
  const serialNumber = useMemo(() => hasSerialNumber && collo.collo.serialnumber, [collo, hasSerialNumber])
  const warrantyDate = useMemo(
    () => (!!hasSerialNumber && showSerialWarranty ? Utils.parseDate(collo.collo.serialnumber?.warrantySupplier) : undefined),
    [collo.collo.serialnumber, hasSerialNumber]
  )

  return (
    <View>
      {!!serialNumber && <IMTextWithIcon icon="barcode" text={serialnumberUtils.getSerialNumberString(serialNumber, true)} />}
      {!!colloNumber && !noQuantity && <IMTextWithIcon icon="box-taped" text={colloNumber} />}
      {hasSerialNumber && !!showStockPosition && (!!collo.collo.stockposition || !!collo.collo.deposit) && (
        <IMTextWithIcon
          icon={collo.collo.stockposition ? 'shelf' : 'warehouse-full'}
          text={stockPositionUtils.getTitle(collo.collo.stockposition, collo.collo.deposit)}
        />
      )}

      {!!warrantyDate && <IMTextWithIcon secondary={noQuantity} icon="calendar" text={Utils.FormatDate(warrantyDate, 'DD.MM.YYYY') ?? ''} />}
    </View>
  )
}

const styles = StyleSheet.create({
  gap: {
    gap: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  titleView: {
    alignItems: 'center',
  },
  packingListCard: {
    minHeight: 35,
  },
})
