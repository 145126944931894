import { useLanguage } from '@infominds/react-native-components'
import { NavigationProp, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useMemo } from 'react'
import { Platform } from 'react-native'

import api from '../../apis/apiCalls'
import { TipType } from '../../apis/apiRequestTypes'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import { usePackingList } from '../../contexts/packingLists/PackingListContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import { PackingListStackParamList } from '../../navigation/types'
import packingListUtils from '../../utils/packingListUtils'
import PackingListView from '../../views/PackingList/PackingListView'

export default function PackingListScreen() {
  const { i18n } = useLanguage()
  const alert = useAlert()
  const navigation = useNavigation<NavigationProp<PackingListStackParamList, 'PackingList'>>()
  const route = useRoute<RouteProp<PackingListStackParamList, 'PackingList'>>()
  const initialRoute = useMemo(() => route.params, [])
  const { loadPackingListMovements, packingLists, isPreConsignment } = usePackingList()
  const userSettings = useUserSettings()

  useEffect(() => {
    const abortController = new AbortController()
    loadTip().catch(console.error)
    const packingListsToLock = userSettings?.setCurrentEmployeeAsPickerOfPickingList ? route.params.packingLists?.map(item => item.id) : undefined
    if (packingListsToLock?.length) {
      setCurrentEmployeeAsPickerOfPickingList(userSettings?.employeeId, packingListsToLock)
    }
    load(route.params.openMovementWithId, abortController)

    return () => {
      abortController.abort()
      if (packingListsToLock?.length) {
        setCurrentEmployeeAsPickerOfPickingList('', packingListsToLock)
      }
    }
  }, [route.params.openMovementWithId])

  function load(openMovementWithId?: string, abortController?: AbortController) {
    loadPackingListMovements(
      initialRoute.packingLists ?? [],
      !!initialRoute.isPreConsignment,
      !!initialRoute.isProductionConsignment,
      abortController
    )
      .then(movements => {
        const movement = openMovementWithId ? movements.find(m => m.movements.find(mm => mm.movement.id === openMovementWithId)) : undefined
        if (movement) navigation.navigate('PackingListMovement', { movement })
        navigation.setParams({ openMovementWithId: undefined })
      })
      .catch(reason => {
        if (abortController?.signal.aborted) return
        alert.error(
          i18n.t('FailedToLoadPackingListMovements'),
          packingListUtils.getPackingListsTitle(packingLists ?? []),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          reason?.Message ?? ''
        )
        navigation.goBack()
      })
  }

  async function loadTip() {
    if (!packingLists?.length) return

    const [tipTextCustomer, tipTextPackingList] = await Promise.all([
      api.getTip({ type: TipType.Customer, masterId: packingLists[0].customerId }),
      api.getTip({ type: TipType.Document, masterId: packingLists[0].documentId }),
    ])

    // Zusammenfassen und anzeigen
    let tipText = tipTextCustomer ? tipTextCustomer : ''
    if (tipTextPackingList) tipText = tipText ? `${tipText}\n${tipTextPackingList}` : tipTextPackingList
    if (tipText) alert.info(tipText)
  }

  function setCurrentEmployeeAsPickerOfPickingList(employeeId: string | undefined, packingListIds: string[]) {
    api
      .patchPackingList({
        packingListIds: packingListIds,
        EmployeeId_CurrentPicker: employeeId,
      })
      .catch(console.error)
  }

  return (
    <MWS_Screen
      title={isPreConsignment ? i18n.t('PreConsignment') : i18n.t('Consignment')}
      closeIcon={Platform.OS === 'web' ? 'refresh' : undefined}
      onClose={Platform.OS === 'web' ? () => load() : undefined}>
      {/* {unloadCollos && (
        <PackingListColloUnloadView
          onDone={reload => {
            setUnloadCollos(false)
            if (reload) load()
          }}
        />
      )} */}
      <PackingListView reload={openMovementWithId => load(openMovementWithId)} />
    </MWS_Screen>
  )
}
