import React from 'react'
import { View } from 'react-native'

import ProductionPartCard from '../../cards/Production/ProductionPartCard'
import Separator from '../../components/Separator'
import { IMLayout } from '../../constants/Styles'
import { ProductionHandler } from '../../hooks/specific/useProductionHandler'
import ProductionOutDetailInputView from './ProductionOutDetailInputView'
import ProductionPartSelectedQuantitiesView from './ProductionPartSelectedQuantitiesView'

export default function ProductionOutDetailView(props: { handler: ProductionHandler }) {
  const handler = props.handler
  return (
    <View style={IMLayout.flex.f1}>
      <ProductionPartCard
        part={handler.selectedPart}
        showQuantities
        isDefectiveMaterialProduction={handler.production?.isDefectiveMaterialProduction}
        deposit={handler.selectedPart?.part.deposit ?? handler.production?.production.deposit}
      />
      <Separator />
      <ProductionOutDetailInputView handler={handler} />
      <ProductionPartSelectedQuantitiesView handler={handler} />
    </View>
  )
}
