import { IM, OfflineNotice } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { LogBox, Platform, StyleSheet, useWindowDimensions, View } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'

import { languageResources } from './assets/languages/LanguageResources'
import { DoubleClickContextProvider } from './contexts/DoubleClickContext'
import { LoadingIndicatorContextProvider } from './contexts/LoadingIndicatorContext'
import { RuntimeStoringContextProvider } from './contexts/RuntimeStorageContext'
import { UserSettingsContextProvider } from './contexts/UserSettingsContext'
import Navigation from './navigation'

import './init'

import { ApiClientProvider } from '@infominds/react-api'
import { useBarcodeScanner } from '@infominds/react-native-barcode-scanner'
import { _INTERNAL_LicenseAuthenticationGlobals, _INTERNAL_LicenseDataGlobals, useStorageMigration } from '@infominds/react-native-license'

import ContextMigration from './components/ContextMigration'
import { TransferContextProvider } from './contexts/TransferContext'
import { themeExtension } from './ThemeExtension'
import AuthenticationWrapper from './views/login/AuthenticationWrapper'

export default function App() {
  const { fontScale } = useWindowDimensions()
  const migrated = useStorageMigration({ isExpensesApp: false })

  useBarcodeScanner(() => undefined) //required to cause initializing of Scanner, otherwise first usage will cause a short lag, before it can be used. DONT REFACTOR

  useEffect(() => {
    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
    }
  }, [])

  if (migrated === undefined) return <></>
  if (migrated === false) console.error('🛑 Migration failed!')

  return (
    <SafeAreaProvider key={fontScale}>
      <IM.LanguageProvider resources={languageResources}>
        <IM.LanguageContext.Consumer>
          {languageContext => {
            if (!languageContext?.initOk) return <IM.LoadingSpinner isVisible />

            return (
              <IM.ThemeProvider theme={themeExtension}>
                <IM.ThemeContext.Consumer>
                  {themeContext => (
                    <ContextMigration>
                      <UserSettingsContextProvider>
                        <AuthenticationWrapper>
                          <ApiClientProvider
                            globalAuthenticationData={_INTERNAL_LicenseAuthenticationGlobals}
                            globalConnectionData={_INTERNAL_LicenseDataGlobals}
                            urlPrefix="/api/"
                            debugLog
                            useEncoding>
                            <DoubleClickContextProvider>
                              <RuntimeStoringContextProvider>
                                <LoadingIndicatorContextProvider>
                                  <ToastProvider>
                                    <View style={[styles.screen, { backgroundColor: themeContext?.theme.background }]}>
                                      {Platform.OS !== 'web' ? <OfflineNotice text={languageContext.i18n.t('NO_INTERNET')} /> : <></>}
                                      <TransferContextProvider>
                                        <Navigation />
                                      </TransferContextProvider>
                                    </View>
                                  </ToastProvider>
                                </LoadingIndicatorContextProvider>
                              </RuntimeStoringContextProvider>
                            </DoubleClickContextProvider>
                          </ApiClientProvider>
                        </AuthenticationWrapper>
                      </UserSettingsContextProvider>
                    </ContextMigration>
                  )}
                </IM.ThemeContext.Consumer>
              </IM.ThemeProvider>
            )
          }}
        </IM.LanguageContext.Consumer>
      </IM.LanguageProvider>
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  screen: { flex: 1 },
})
