import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useMemo } from 'react'

import api from '../../apis/apiCalls'
import { PackingList } from '../../apis/apiTypes'
import PackingListCard from '../../cards/Packinglist/PackingListCard'
import MWS_Input from '../../components/MWS/Inputs/MWS_Input'
import { ScannerInputResult } from '../../components/MWS/Inputs/ScannerInput'
import useInputHistory from '../../hooks/useInputHistory'
import useModalController from '../../hooks/useModalController'
import PackingListSelectionFilterModal from '../../modals/PackingListSelectionFilterModal'
import { SelectionViewProps } from '../../types'
import packingListUtils from '../../utils/packingListUtils'

export type PackingListSelectionViewProps = {
  preConsignment?: boolean
  productionConsignment?: boolean
  allowPreloading?: boolean
  preLoadedData?: PackingList[]
  filterResultByOrder?: boolean
  loadList?: boolean
  onMultiSelect?: (packingLists: PackingList[]) => void
} & SelectionViewProps<PackingList>

export default function PackingListSelectionView({
  preConsignment,
  productionConsignment,
  allowPreloading,
  preLoadedData,
  filterResultByOrder,
  loadList,
  onSelected,
  onMultiSelect,
  ...inputProps
}: PackingListSelectionViewProps) {
  const { i18n } = useLanguage()
  const navigation = useNavigation()

  const { history, addMultiple: addMultipleHistoryItems } = useInputHistory<PackingList>(
    preConsignment ? 'PackingListPreConsignment' : productionConsignment ? 'PackingListProductionConsignment' : 'PackingList'
  )
  const lastUsed = useMemo(() => history.map(h => h.item), [history])

  const packingListSelectionModal = useModalController<PackingList[]>()

  useEffect(() => {
    if (!loadList) return
    api
      .getPackingList({
        preConsignment: preConsignment,
        productionConsignment: productionConsignment,
      })
      .then(result => {
        if (!result.length) return
        packingListSelectionModal.show(result)
      })
      .catch(console.error)
      .finally(() => navigation.setParams({ loadList: false }))
  }, [loadList])

  function load(input: ScannerInputResult) {
    if (preLoadedData) return Promise.resolve<PackingList[]>(preLoadedData ?? [])
    return new Promise<PackingList[]>((resolve, reject) => {
      api
        .getPackingList({
          id: input.scannerInput || input.textInput || '',
          preConsignment: preConsignment,
          productionConsignment: productionConsignment,
        })
        .then(result => {
          resolve((result ?? []).sort(packingListUtils.sortPackingLists))
        })
        .catch(reject)
    })
  }

  function handlePackingListSelection(selectedItems: PackingList[]) {
    if (!selectedItems.length) return
    addMultipleHistoryItems(selectedItems.map(item => ({ key: item.id, item })))
    if (onMultiSelect) {
      onMultiSelect(selectedItems)
      return
    }
    onSelected(selectedItems[0])
  }

  function handleShowListResult(result: PackingList[]) {
    if (!result.length) return

    packingListSelectionModal.show(result)
  }

  const RenderItem = useCallback((item: PackingList) => <PackingListCard packingList={item} showDate />, [])

  return (
    <>
      <MWS_Input
        onSelected={onSelected}
        type={'PackingList'}
        title={i18n.t('SelectPackingList')}
        load={load}
        clearResultsOnScreenFocus
        itemRenderer={RenderItem}
        handleShowListResult={filterResultByOrder ? handleShowListResult : undefined}
        preload={
          allowPreloading ? () => api.getPackingList({ preConsignment: preConsignment, productionConsignment: productionConsignment }) : undefined
        }
        {...inputProps}
      />
      <PackingListSelectionFilterModal
        controller={packingListSelectionModal}
        multiSelection={!!onMultiSelect}
        onSelected={handlePackingListSelection}
        lastUsed={lastUsed}
        type={preConsignment ? 'PreConsignment' : productionConsignment ? 'ProductionConsignment' : 'Consignment'}
        selectButtonText={onMultiSelect ? (preConsignment ? i18n.t('BeginPreConsignment') : i18n.t('BeginConsignment')) : undefined}
      />
    </>
  )
}
