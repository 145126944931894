import { IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native'

import { ItemStockPositionQuantity } from '../../apis/apiTypes'
import BaseTextInput from '../../components/BaseTextInput'
import IMText from '../../components/IMText'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard, { MWSBaseCardProps } from '../MWS_BaseCard'

export default function ItemStockPositionQuantityCard(
  props: {
    itemStockPositionQuantity: ItemStockPositionQuantity
    proposedQuantity?: number
    style?: StyleProp<ViewStyle>
    autoFocus?: boolean
    checkAvailability?: boolean
    confirmButtonCaption?: string
    disabled?: boolean
    onQuantityChanged: (quantity: number | undefined) => void
  } & MWSBaseCardProps
) {
  const { i18n } = useLanguage()

  const inputRef = useRef<TextInput>(null)

  const { itemStockPositionQuantity, ...otherProps } = props

  const [valueQuantity, setValueQuantity] = useState<string>(props.itemStockPositionQuantity?.quantity?.toString() ?? '')

  useEffect(() => {
    setValueQuantity(props.itemStockPositionQuantity?.quantity?.toString() ?? '')
  }, [props.itemStockPositionQuantity.quantity])

  const articleCode = itemStockPositionQuantity?.article?.code?.trim()
  const unitCode = itemStockPositionQuantity?.article?.measurementUnitCode?.trim()
  const serialNumber = itemStockPositionQuantity?.serialNumber?.number.trim()
  const description = itemStockPositionQuantity?.article?.searchtext?.trim()
  const lastOngoingInventoryDate = itemStockPositionQuantity?.lastOngoingInventoryDate
    ? Utils.parseDate(itemStockPositionQuantity?.lastOngoingInventoryDate)
    : undefined

  return (
    <MWS_BaseCard {...otherProps} icon={serialNumber ? 'barcode' : 'article'} noContentSpacing>
      <View style={styles.content}>
        {!!articleCode && <IMText primary>{`${i18n.t('Article')}: ${articleCode}`}</IMText>}
        {!!serialNumber && <IMText primary>{`${i18n.t('SerialNumberShort')}: ${serialNumber}`}</IMText>}
        {!!lastOngoingInventoryDate && (
          <IMText secondary>{`${i18n.t('LastOngoingInventory')}: ${Utils.FormatDate(lastOngoingInventoryDate, 'DD.MM.YYYY') ?? ''}`}</IMText>
        )}
        <IMText secondary style={{ fontSize: 12 }}>
          {description}
        </IMText>
      </View>
      <IMText style={styles.unitText}>{unitCode}</IMText>

      <View style={{ height: '100%' }}>
        <BaseTextInput
          inputRef={inputRef}
          viewStyle={[styles.noBorderLeft, { flex: 1, minWidth: 65 }]}
          style={{ textAlign: 'center' }}
          value={valueQuantity?.toString()}
          onChangeText={text => {
            setValueQuantity(text)
            if (!text) return
            const parsedValue = Utils.parseFloatFromText(text)
            if (Number.isNaN(parsedValue)) return
            props.onQuantityChanged(parsedValue)
          }}
          hideButtons
          keyboardType={'numeric'}
          onBlur={() => {
            const parsedValue = Utils.parseFloatFromText(valueQuantity)
            props.onQuantityChanged(Number.isNaN(parsedValue) ? undefined : parsedValue)
          }}
        />
      </View>
    </MWS_BaseCard>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN / 2,
    paddingRight: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
    paddingVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2,
  },
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  unitText: {
    marginRight: IMLayout.horizontalMargin / 2,
  },
})
