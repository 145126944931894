import { useIsMounted, useLanguage } from '@infominds/react-native-components'
import React, { useCallback, useEffect, useState } from 'react'
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'

import { STYLE_CONSTANTS } from '../../../constants/Constants'
import { useLoadingIndicator } from '../../../contexts/LoadingIndicatorContext'
import useAlert from '../../../hooks/useAlert'
import useInputHistory, { HistoryEntry } from '../../../hooks/useInputHistory'
import useModal from '../../../hooks/useModal'
import useOnScreenFocus from '../../../hooks/useOnScreenFocus'
import SelectionModalFullScreen from '../../../modals/SelectionModalFullScreen'
import { SelectionType } from '../../../types'
import ItemSelectionList from '../../ItemSelectionList'
import CardBasic from '../../old/CardBasic'
import Text from '../../old/Text'
import Title from '../Title'
import ScannerInput, { ScannerInputResult } from './ScannerInput'

interface MWSInputProps<T> {
  type: SelectionType
  onSelected: (item: T) => void
  load: (input: ScannerInputResult) => Promise<T> | Promise<T[]>
  modifyResult?: (values: T[], input?: T | string) => T[]
  sortResult?: (a: T, b: T) => number
  failedToLoadText?: string
  failedToLoadListText?: string
  style?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<ViewStyle>
  title?: string
  modalTitle?: string
  allowEmptyInput?: boolean
  history?: boolean
  hideHistory?: boolean
  historyNameExtractor?: (item: T) => string
  loadFromHistory?: (item: T) => Promise<T> | Promise<T[]>
  itemRenderer?: (item: T, index: number) => JSX.Element
  overwriteLoadingSpinner?: (value: boolean) => void
  hideSelectButton?: boolean
  showResultInModal?: boolean
  handleShowListButtonPressed?: () => void
  handleShowListResult?: (result: T[]) => void
  loadOnListButtonPressed?: () => Promise<T> | Promise<T[]>
  handleResult?: (result: T[] | T, input?: T | string) => void
  handleError?: (input?: string, error?: unknown) => boolean
  preload?: () => Promise<T> | Promise<T[]>
  dontStopLoadingSpinner?: boolean
  clearResultsOnScreenFocus?: boolean
  denyTextInput?: boolean
  loadOnMount?: boolean
  showListLoader?: boolean
  dontAutoSelectOnListShow?: boolean
}

export default function MWS_Input<T>(props: MWSInputProps<T>) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const history = useInputHistory<T>(props.type)
  const mounted = useIsMounted()

  const [items, setItems] = useState<T[]>([])
  const [savedItems, setSavedItems] = useState<T[]>([])
  const loadingIndicator = useLoadingIndicator()
  const [loading, setLoading] = useState(false)

  const historyItems = history.get()
  const showResultList = !props.showResultInModal && items && items.length > 0

  useOnScreenFocus(() => {
    if (props.clearResultsOnScreenFocus) setItems([])
  })

  const SelectorModal = useModal((show, hide) => (
    <SelectionModalFullScreen
      show={show && !!props.showResultInModal}
      title={props.modalTitle ?? i18n.t('SELECT')}
      items={Array.isArray(items) ? items : []}
      close={selectedItem => {
        hide()
        if (selectedItem) {
          selectItem(selectedItem)
        }
      }}
      renderItem={RenderItem}
    />
  ))

  useEffect(() => {
    if (props.preload) {
      load(props.preload(), '')
    }
  }, [props.preload])

  useEffect(() => {
    if (!props.loadOnMount) return
    load(props.load({ textInput: '' }), '')
  }, [])

  function selectItem(item: T) {
    addItemToHistory(item)

    props.onSelected(item)
  }

  function updateLoading(value: boolean, force?: boolean) {
    setLoading(value)
    if (!!props.dontStopLoadingSpinner && !force && !value) return
    if (props.overwriteLoadingSpinner) props.overwriteLoadingSpinner(value)
    else if (!props.showListLoader) loadingIndicator.setLoading(value)
  }

  function load(promise: Promise<T> | Promise<T[]>, input: string, inputItem?: T, saveResult?: boolean, useSavedResult?: boolean) {
    if (useSavedResult && savedItems && savedItems.length > 0) {
      handleLoadingResult(savedItems, '')
      return
    }
    setItems([])
    updateLoading(true)
    promise
      .then(result => handleLoadingResult(result, input, inputItem))
      .catch(error => {
        if (!mounted.current) return
        failedToLoad(input, error)
      })
      .finally(() => {
        if (!mounted.current) return
        updateLoading(false)
      })
  }

  function handleLoadingResult(result: T | T[], input: string, inputItem?: T) {
    if (!mounted.current) return
    if (!result) {
      failedToLoad(input)
      return
    }
    if (props.handleResult) {
      props.handleResult(result, inputItem ?? input)
      return
    }
    if (!Array.isArray(result)) {
      selectItem(result)
      return
    }
    setSavedItems(result ?? [])
    const modifiedResult = props.modifyResult ? props.modifyResult(result, inputItem ?? input) : [...result]
    if (props.sortResult) modifiedResult.sort(props.sortResult)
    if (modifiedResult.length === 0) {
      failedToLoad(input)
      return
    }
    if (props.handleShowListResult && !input) {
      props.handleShowListResult(result)
      return
    }
    if (modifiedResult.length === 1 && (result.length === 1 || !!input) && (!props.dontAutoSelectOnListShow || !!input)) {
      selectItem(modifiedResult[0])
      return
    }
    setItems(modifiedResult)
    SelectorModal.show()
  }

  function loadFromHistory(item: HistoryEntry<T>) {
    if (!props.loadFromHistory) {
      if (typeof item.item === 'string') {
        selectItem(item.item)
      }
      return
    }
    load(props.loadFromHistory(item.item), item.key, item.item)
  }

  function failedToLoad(input: string, error?: unknown) {
    updateLoading(false, true)
    setItems([])
    if (props.handleError && props.handleError(input, error)) return
    if (!input) alert.info(props.failedToLoadListText || i18n.t('NoEntriesFound'))
    else alert.info(props.failedToLoadText || i18n.t('NoItemWithCode'), input)
  }

  function addItemToHistory(item: T) {
    if (props.history) {
      if (props.historyNameExtractor) {
        history.add(props.historyNameExtractor(item), item)
      } else if (typeof item === 'string') {
        history.add(item, item)
      }
    }
  }

  const RenderItem = useCallback(
    (item: T, index: number) => {
      if (props.itemRenderer) {
        return props.itemRenderer(item, index)
      } else if (props.historyNameExtractor || typeof item === 'string') {
        return (
          <CardBasic style={styles.cards}>
            <Text numberOfLines={2} style={styles.renderItemText}>
              {props.historyNameExtractor ? props.historyNameExtractor(item) : item?.toString()}
            </Text>
          </CardBasic>
        )
      }
      return <></>
    },
    [props.itemRenderer, props.historyNameExtractor]
  )

  function handleInput(input: ScannerInputResult) {
    if (!loading) load(props.load(input), input.textInput ?? input.scannerInput ?? '')
  }

  function getShowListFunction() {
    if (props.loadOnListButtonPressed) {
      return () => (props.loadOnListButtonPressed ? load(props.loadOnListButtonPressed(), '', undefined, true, true) : {})
    }
    if (props.handleShowListButtonPressed) return props.handleShowListButtonPressed
    if (props.allowEmptyInput || props.handleShowListResult) return () => handleInput({})
    return undefined
  }

  const useFlex = (props.history && !props.hideHistory) || !props.showResultInModal
  return (
    <View style={[styles.main, useFlex && styles.flex, props.style]}>
      <SelectorModal.Component />
      <ScannerInput
        placeHolder={props.title}
        style={[styles.input, props.inputStyle]}
        useInput={handleInput}
        showList={getShowListFunction()}
        hideButton={props.hideSelectButton}
        onClearPressed={() => setItems([])}
        denyTextInput={props.denyTextInput}
        showListLoader={!!props.showListLoader && loading}
      />
      {showResultList && (
        <View style={styles.list}>
          <Title>{i18n.t('SearchResults')}</Title>
          <ItemSelectionList items={items} onSelected={selectItem} itemRenderer={RenderItem} />
        </View>
      )}
      {!showResultList && props.history && !props.hideHistory && (
        <View style={styles.list}>
          {historyItems.length > 0 && (
            <>
              <Title>{i18n.t('History')}</Title>
              <ItemSelectionList items={historyItems} onSelected={loadFromHistory} itemRenderer={(item, index) => RenderItem(item.item, index)} />
            </>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  main: {},
  flex: { flex: 1 },
  input: { marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN },
  cards: { margin: 0, marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN, marginBottom: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS },
  list: {
    marginTop: 10,
    flex: 1,
  },
  renderItemText: {
    margin: 0,
    textAlign: 'center',
  },
})
