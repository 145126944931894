import { Layout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { TextInput as DefaultTextInput, StyleSheet } from 'react-native'

import { Colors } from '../../constants/Colors'

export type TextInputProps = DefaultTextInput['props']

export default function Input(props: TextInputProps) {
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const { style, ...otherProps } = props

  return (
    <DefaultTextInput
      style={[
        styles.input,
        Layout.defaultComponentsStyles,

        {
          color: theme.text,
          backgroundColor: theme.inputBackground,
          borderColor: theme.inputBorder,
        },
        style,
      ]}
      placeholderTextColor={theme.textPlaceholder}
      {...otherProps}
    />
  )
}

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 8,
    padding: 12,
  },
})
