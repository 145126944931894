import { useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { InventoryEntry } from '../../apis/apiTypes'
import IMIcon from '../../components/IMIcon'
import IMText from '../../components/IMText'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { Utils } from '../../utils/Utils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function InventoryCard(props: {
  inventory: InventoryEntry
  style?: StyleProp<ViewStyle>
  onPress?: () => void
  onDelete?: () => void
}) {
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]

  return (
    <MWS_BaseCard onPress={props.onPress} onDelete={props.onDelete} icon={'box-circle-check'} style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, justifyContent: 'center', marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS / 2 }}>
        <IMText primary>{props.inventory.description}</IMText>
        <IMText secondary>{`${i18n.t('Deposit')}: ${props.inventory.deposit.code}`}</IMText>
      </View>
      <View style={{ justifyContent: 'center' }}>
        <IMText secondary>{`${Utils.FormatDate(props.inventory.dateFrom, 'DD/MM/yyyy') ?? ''}`}</IMText>
        <IMText secondary>{`${Utils.FormatDate(props.inventory.dateUntil, 'DD/MM/yyyy') ?? ''}`}</IMText>
      </View>
      <View style={{ justifyContent: 'center', marginLeft: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
        <IMIcon color={theme.textDetail} size={20} icon={'calendar'} />
      </View>
    </MWS_BaseCard>
  )
}
