import { useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDepositData, Deposit, Item, StockPosition } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import PositionCard from '../../cards/StockPosition/PositionCard'
import BaseTextInput from '../../components/BaseTextInput'
import IMIcon from '../../components/IMIcon'
import IMText from '../../components/IMText'
import UnitPicker from '../../components/MWS/Inputs/UnitPicker'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import CardBasic from '../../components/old/CardBasic'
import Separator from '../../components/Separator'
import SubmitButton from '../../components/SubmitButton'
import { Colors } from '../../constants/Colors'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { IMLayout } from '../../constants/Styles'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useOnScreenFocus from '../../hooks/useOnScreenFocus'
import { ScreenType } from '../../types'
import { Error_Utils } from '../../utils/ErrorUtils'
import DepositSelectorView from '../../views/Deposit/DepositSelectorView'
import ItemSelectionView from '../../views/InputViews/ItemSelectionView'
import StockPositionSelectionView from '../../views/InputViews/StockPositionSelectionView'

export default function ArticleDepositDataArticleSelectionScreen() {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const theme = Colors[colorScheme]
  const navigation = useNavigation()
  const route = useRoute()

  const [item, setItem] = useState<Item>()
  const article = useArticle(item?.article, { serialNumber: item?.serialnumber }, [item])
  const loader = useLoadingIndicator()
  const userSettings = useUserSettings()
  const inputRef = useRef<TextInput>(null)
  const [articleDepositDatas, setArticleDepositDatas] = useState<ArticleDepositData[] | undefined>()
  const [articleDepositData, setArticleDepositData] = useState<ArticleDepositData | undefined>()
  const [deposit, setDeposit] = useState<Deposit | undefined>()
  const [deposits, setDeposits] = useState<Deposit[] | undefined>()
  const [stockposition, setStockposition] = useState<StockPosition>()
  const [valueMinQuantity, setValueMinQuantity] = useState<number>()
  const [valueMaxQuantity, setValueMaxQuantity] = useState<number>()
  const showDepositSelector = !deposit && !stockposition
  const showStockPositionSelector = !!deposit && !!deposit.isStockpositionActive && !stockposition
  const showQuantitiesAndConfirmButton = !!deposit

  useOnScreenFocus(() => {
    setItem(undefined)
    navigation.setParams({ ...route.params, transferOk: undefined })
  })

  useEffect(() => {
    loadDeposit()
  }, [])

  useEffect(() => {
    if (!article) {
      handleArticleDepositDataDelete()
      return
    }
    load().catch(console.error)
  }, [article])

  useEffect(() => {
    if (!articleDepositDatas?.length) {
      handleArticleDepositDataDelete()
      return
    }
    const articleDepositDataFiltered = articleDepositDatas.filter(q => q.articleId === article?.info.id && q.deposit?.id === deposit?.id)
    if (!articleDepositDataFiltered?.length) {
      handleArticleDepositDataDelete()
      return
    }
    setArticleDepositData(articleDepositDataFiltered[0])
    setStockposition(articleDepositDataFiltered[0].stockposition)
    setValueMinQuantity(articleDepositDataFiltered[0].minQuantityDeposit)
    setValueMaxQuantity(articleDepositDataFiltered[0].maxQuantityDeposit)
  }, [articleDepositDatas, deposit])

  async function load() {
    if (!article) return
    try {
      loader.setLoading(true)
      const result = await api.getArticleDepositData({ articleId: article?.info.id })
      setArticleDepositDatas(result)
    } catch (ex) {
      console.error(ex)
      return
    } finally {
      loader.setLoading(false)
    }
  }

  function loadDeposit() {
    if (!deposits) {
      loader.setLoading(true)
      api
        .getDeposit({})
        .then(result => {
          setDeposits(result)
          if (result.length > 0 && !!userSettings?.depositId) {
            const userDeposit = result.find(q => q.id === userSettings.depositId)
            if (userDeposit) setDeposit(userDeposit)
          }
        })
        .catch(console.error)
        .finally(() => {
          loader.setLoading(false)
        })
    }
  }

  function showArticleInfoScreen() {
    navigation.navigate(ScreenType.ArticleAvailability, { item: item })
  }

  function handleItemSelection(selectedItem: Item) {
    setItem(selectedItem)
  }

  function handleDepositSelection(selectedItem: Deposit | undefined) {
    setDeposit(selectedItem)
  }

  function handleStockPositionSelection(selectedItem: StockPosition | undefined) {
    setStockposition(selectedItem)
  }

  function onTextChangeMinQuantity(text: string) {
    setValueMinQuantity(Utils.parseFloatFromText(text.replace(/([^0-9.,-])/g, '')))
  }

  function onTextChangeMaxQuantity(text: string) {
    setValueMaxQuantity(Utils.parseFloatFromText(text.replace(/([^0-9.,-])/g, '')))
  }

  async function checkAndSetArticleBarcode() {
    if (!article) return
    if (!deposit) return

    try {
      loader.setLoading(true)
      if (!articleDepositData) {
        await api.postArticleDepositData({
          articleId: article.info.id,
          depositId: deposit.id,
          storingPositionId: stockposition?.id,
          minQuantityDeposit: valueMinQuantity,
          maxQuantityDeposit: valueMaxQuantity,
        })
      } else {
        await api.patchArticleDepositData({
          id: articleDepositData.id,
          depositId: deposit.id,
          storingPositionId: stockposition?.id,
          minQuantityDeposit: valueMinQuantity,
          maxQuantityDeposit: valueMaxQuantity,
        })
      }
      navigation.goBack()
    } catch (reason) {
      alert.error(i18n.t('ArticleDepositData'), Error_Utils.extractErrorMessageFromException(reason))
    } finally {
      loader.setLoading(false)
    }
  }

  function handlePositionDelete() {
    setDeposit(undefined)
    setArticleDepositData(undefined)
    handleArticleDepositDataDelete()
  }

  function handleArticleDepositDataDelete() {
    setStockposition(undefined)
    setValueMinQuantity(undefined)
    setValueMaxQuantity(undefined)
  }

  function handlePositionDeleteStockPosition() {
    setStockposition(undefined)
    return
  }

  return (
    <MWS_Screen title={i18n.t('ArticleDepositData')}>
      {!article && (
        <>
          <ItemSelectionView onSelected={handleItemSelection} showResultInModal />
        </>
      )}
      {article && (
        <View style={{ flex: 1 }}>
          <ArticleCard
            article={article.info}
            serialNumber={article.serialNumber}
            onDelete={() => {
              setItem(undefined)
            }}
            style={{ marginBottom: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS }}
            onPress={showArticleInfoScreen}
          />
          {!!deposit && <PositionCard deposit={deposit} stockPosition={undefined} onDelete={handlePositionDelete} />}
          {showDepositSelector && <DepositSelectorView item={deposit} setItem={handleDepositSelection} getItemList={() => deposits ?? []} />}
          {showQuantitiesAndConfirmButton && (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                  marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
                }}>
                <IMText>{i18n.t('MinQuantity')}</IMText>
                <Separator style={{ marginHorizontal: 5 }} />
                <CardBasic style={{ margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}>
                  <IMIcon
                    style={{ justifyContent: 'center' }}
                    size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
                    icon="article"
                    color={theme.textDetail}
                  />
                </CardBasic>
                <BaseTextInput
                  inputRef={inputRef}
                  viewStyle={[IMLayout.flex.f1, styles.noBorderLeft, styles.noBorderRight]}
                  style={{ textAlign: 'center' }}
                  placeholder={i18n.t('Amount')}
                  value={valueMinQuantity?.toString()}
                  onChangeText={onTextChangeMinQuantity}
                  onClearPressed={() => setValueMinQuantity(0)}
                  keyboardType={'numeric'}
                  selectTextOnFocus
                />
                <UnitPicker
                  style={styles.unitPicker}
                  unit={article?.getUsedUnit()}
                  selectedCaptionExtractor={unit => unit.code?.trim()}
                  asCard
                  units={undefined}
                  onUnitSelected={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                />
              </View>
              <View style={{ flexDirection: 'row', margin: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN }}>
                <IMText>{i18n.t('MaxQuantity')}</IMText>
                <Separator style={{ marginHorizontal: 5 }} />
                <CardBasic style={{ margin: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0 }}>
                  <IMIcon
                    style={{ justifyContent: 'center' }}
                    size={STYLE_CONSTANTS.DEFAULT_ICON_BUTTON_SIZE}
                    icon="article"
                    color={theme.textDetail}
                  />
                </CardBasic>
                <BaseTextInput
                  inputRef={inputRef}
                  viewStyle={[IMLayout.flex.f1, styles.noBorderLeft, styles.noBorderRight]}
                  style={{ textAlign: 'center' }}
                  placeholder={i18n.t('Amount')}
                  value={valueMaxQuantity?.toString()}
                  onChangeText={onTextChangeMaxQuantity}
                  //onSubmitEditing={() => onDone()}
                  keyboardType={'numeric'}
                  onClearPressed={() => setValueMaxQuantity(0)}
                />
                <UnitPicker
                  style={styles.unitPicker}
                  unit={article?.getUsedUnit()}
                  selectedCaptionExtractor={unit => unit.code?.trim()}
                  asCard
                  units={undefined}
                  onUnitSelected={function (): void {
                    throw new Error('Function not implemented.')
                  }}
                />
              </View>
            </View>
          )}
          {!!stockposition && <PositionCard deposit={deposit} stockPosition={stockposition} onDelete={handlePositionDeleteStockPosition} />}
          {showStockPositionSelector && <StockPositionSelectionView depositId={deposit.id} onSelected={handleStockPositionSelection} />}
          {showQuantitiesAndConfirmButton && <SubmitButton onPress={checkAndSetArticleBarcode} title={i18n.t('Confirm')} />}
        </View>
      )}
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  main: {
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_HORIZONTAL_MARGIN,
  },
  noBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  noBorderRight: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  unitPicker: {
    justifyContent: 'center',
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
})
