import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Worksheet } from '../../apis/apiTypes'
import { Article } from '../../classes/Article'
import IMText from '../../components/IMText'
import worksheetUtils from '../../utils/WorksheetUtils'
import MWS_BaseCard from '../MWS_BaseCard'

export default function WorksheetCard(props: { handler: Worksheet | undefined; article: Article | undefined; style?: StyleProp<ViewStyle> }) {
  const { i18n } = useLanguage()

  if (!props.handler) return <></>

  return (
    <MWS_BaseCard icon={'conveyor-belt-box'} cardStyle={props.style} style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <IMText primary>
            {i18n.t('Worksheet')}: {worksheetUtils.getTitle(props.handler)}
          </IMText>
          <IMText numberOfLines={2}>
            {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
            {i18n.t('Article')}: {props.article?.info.code + ' - ' + props.article?.getDescription(i18n.t('ID'))}
          </IMText>
        </View>
      </View>
    </MWS_BaseCard>
  )
}
