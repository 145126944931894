import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'

import { Collo, PackingList, PackingListMovement } from '../apis/apiTypes'
import PackingListColloCard, { PackingListColloCardProps } from '../cards/Packinglist/PackingListColloCard'
import { Article } from '../classes/Article'
import PackingListSelector from '../components/MWS/PackingList/PackingListSelector'
import { STYLE_CONSTANTS } from '../constants/Constants'
import { usePackingListMovement } from '../contexts/packingLists/PackingListMovementContext'
import { ModalController } from '../hooks/useModalController'
import packingListUtils from '../utils/packingListUtils'
import AmountEditorModal from './AmountEditorModal'

type PackingListColloEditorModalProps = {
  article: Article | undefined
  controller: ModalController<Collo>
  canChangePackingList?: boolean
  onSubmit: (value: number, movement: PackingListMovement | undefined, collo: Collo) => void
} & Pick<PackingListColloCardProps, 'packingListMovements'>

export default function PackingListColloEditorModal(props: PackingListColloEditorModalProps) {
  const { i18n } = useLanguage()
  const { controller, article, onSubmit, packingListMovements } = props
  const anyMovement = packingListMovements?.at(0)?.movement

  const [selectedPackingListId, setSelectedPackingListId] = useState<string | undefined>(
    () => packingListMovements?.find(m => m.movement.id === controller.data?.packinglistmovementId)?.packingList.id
  )

  useEffect(() => {
    if (!controller.isShown) return
    setSelectedPackingListId(packingListMovements?.find(m => m.movement.id === controller.data?.packinglistmovementId)?.packingList.id)
  }, [controller.isShown])

  return (
    <AmountEditorModal
      title={i18n.t('EDIT_MOVEMENT')}
      show={controller.isShown}
      value={controller.data?.quantity}
      close={controller.close}
      usedUnit={article?.getUsedUnit()}
      disabled={!!article?.isSerialNumberActive && !article.isLottoSerialNumber}
      submitButtonDisabled={false}
      onSubmit={value => {
        controller.close()
        const movement = packingListMovements?.find(plm => plm.packingList.id === selectedPackingListId)?.movement
        if (controller.data) onSubmit(value, movement, controller.data)
      }}
      checkAvailability={{
        articleId: controller.data?.article.id ?? '',
        depositId: controller.data?.deposit?.id,
        stockPositionId: controller.data?.stockposition?.id,
        serialNumberId: article?.isHistoricalSerialNumber ? undefined : controller.data?.serialnumber?.id,
        conversion: packingListUtils.getMasterToUsedUnitConversionFactor(anyMovement),
        disabled: !article?.hasStock,
        zeroIsValidInput: true,
      }}
      headerRenderItem={
        controller.data ? (
          <Header {...props} selectedPackingListId={selectedPackingListId} onPackingListChanged={selected => setSelectedPackingListId(selected.id)} />
        ) : undefined
      }
    />
  )
}

function Header({
  packingListMovements,
  controller,
  canChangePackingList,
  onPackingListChanged,
  selectedPackingListId,
}: PackingListColloEditorModalProps & { selectedPackingListId: string | undefined; onPackingListChanged: (packingList: PackingList) => void }) {
  const { multiPackingListConsignment } = usePackingListMovement()
  const packingLists = useMemo(
    () => packingListMovements?.map(pl => pl.packingList).sort(packingListUtils.sortPackingListsById) ?? [],
    [packingListMovements]
  )
  canChangePackingList = !!canChangePackingList && !!onPackingListChanged && multiPackingListConsignment

  return (
    <IM.View>
      <PackingListColloCard
        packingListMovements={packingListMovements}
        collo={controller.data}
        style={{ marginTop: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS, marginBottom: 0 }}
        showPackingList={!!packingListMovements?.length && packingListMovements.length > 1 && !canChangePackingList}
      />
      {canChangePackingList && (
        <PackingListSelector
          cardStyle={{ marginTop: STYLE_CONSTANTS.VERTICAL_DISTANCE_BETWEEN_CARDS, marginBottom: 0 }}
          selectedPackingListId={selectedPackingListId}
          packingLists={packingLists}
          onSelected={onPackingListChanged}
        />
      )}
    </IM.View>
  )
}
