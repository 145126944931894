import React from 'react'

import { QualityCharacteristic, QualityCharacteristicType } from '../../apis/apiTypes'
import QualityControlLogicInput from '../../components/MWS/QualityControl/QualityControlLogicInput'
import QualityControlNumericInput from '../../components/MWS/QualityControl/QualityControlNumericInput'
import QualityControlSelectInput from '../../components/MWS/QualityControl/QualityControlSelectInput'
import QualityControlTextInput from '../../components/MWS/QualityControl/QualityControlTextInput'
import { QualityCharacteristicValue, QualityValidationResult } from '../../types'

export type QualityCharacteristicInputProps = {
  characteristic: QualityCharacteristic
  value: QualityCharacteristicValue
  setValue: (value: QualityCharacteristicValue) => void
  validationResult: QualityValidationResult | undefined
}

export default function QualityCharacteristicInput(props: QualityCharacteristicInputProps) {
  switch (props.characteristic.fieldTyp) {
    case QualityCharacteristicType.Text:
      return <QualityControlTextInput {...props} />
    case QualityCharacteristicType.Numeric:
      return <QualityControlNumericInput {...props} />

    case QualityCharacteristicType.Boolean:
      return <QualityControlLogicInput {...props} />

    case QualityCharacteristicType.Selection:
      return <QualityControlSelectInput {...props} />
  }
  console.warn('Invalid QualityCharacteristic fieldType:', props.characteristic.fieldTyp)
  return <></>
}
