import { BarCode, useBarcodeScanner } from '@infominds/react-native-barcode-scanner'
import { useLanguage, useModalController } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useEffect, useState } from 'react'
import { Alert, FlatList, StyleSheet, View } from 'react-native'

import api from '../../apis/apiCalls'
import { ArticleDepositQuantity, ArticleInfoPrice, Item } from '../../apis/apiTypes'
import ArticleCard from '../../cards/Article/ArticleCard'
import ArticleDepositAvailabilityCard from '../../cards/Article/ArticleDepositAvailabilityCard'
import ArticleUnitPicker from '../../components/MWS/Article/ArticleUnitPicker'
import ListSpacer from '../../components/MWS/ListSpacer'
import MWS_Screen from '../../components/MWS/MWS_Screen'
import NoEntriesTag from '../../components/NoEntriesTag'
import Button from '../../components/old/Button'
import { STYLE_CONSTANTS } from '../../constants/Constants'
import { useLoadingIndicator } from '../../contexts/LoadingIndicatorContext'
import { useUserSettings } from '../../contexts/UserSettingsContext'
import useAlert from '../../hooks/useAlert'
import useArticle from '../../hooks/useArticle'
import useRouteParam from '../../hooks/useRouteParam'
import SelectionModalFullScreen from '../../modals/SelectionModalFullScreen'
import { ScreenType } from '../../types'
import ArticleInfoView from '../../views/Article/ArticleInfoView'

export default function ArticleAvailabilityScreen() {
  const navigation = useNavigation()
  const { i18n } = useLanguage()
  const alert = useAlert()
  const userSettings = useUserSettings()
  const routeItem = useRouteParam<Item>('item')

  const [item, setItem] = useState(routeItem)
  const article = useArticle(item?.article, { useSalesUnit: userSettings?.isShowingVpk1Active, serialNumber: item?.serialnumber }, [item?.article.id])

  const [articlePrices, setArticlePrices] = useState<ArticleInfoPrice[]>([])
  const [articleDepositQuantities, setArticleDepositQuantities] = useState<ArticleDepositQuantity[]>([])
  const loader = useLoadingIndicator()
  const [imgUri, setImgUri] = useState('')
  const [configurableDescription, setConfigurableDescription] = useState('')
  const articleSelectionModal = useModalController<Item[]>()
  useBarcodeScanner(onScanResult)

  useEffect(() => {
    if (!article) {
      failedToLoad(i18n.t('FailedToLoadAvailability'), '')
      navigation.goBack()
      return
    }
    setArticleDepositQuantities([])
    loader.setLoading(true)
    article
      .loadConfigurableDescription()
      .then(description => {
        setConfigurableDescription(description)
      })
      .catch(console.error)
      .finally(() => {
        loader.setLoading(false)
      })
    article
      .loadAvailability(true)
      .then(() => {
        setArticleDepositQuantities(article.getConvertedDepositQuantities(true))
      })
      .catch(() => {
        if (article.serialNumber?.id && !article.isHistoricalSerialNumber) failedToLoad(i18n.t('NoSerialAvailability'), article.serialNumber?.number)
        else failedToLoad(i18n.t('FailedToLoadAvailability'), article.info.code)
      })
      .finally(() => {
        loader.setLoading(false)
      })
    article
      .loadPrices()
      .then(prices => {
        setArticlePrices(prices)
      })
      .catch(() => {
        failedToLoad(i18n.t('FAILEDTOLOADPRICES'), article.info.code)
      })
      .finally(() => {
        loader.setLoading(false)
      })
    article
      .loadImage()
      .then(result => {
        setImgUri(result)
      })
      .catch(console.error)
  }, [item?.article.id])

  function showArticleDescriptionAlert() {
    if (!article) return
    loader.setLoading(true)
    article
      .loadOfferDescription()
      .then(() => {
        Alert.alert(i18n.t('OrderText'), article?.getDescriptionForOffer(i18n.t('ID')))
      })
      .finally(() => {
        loader.setLoading(false)
      })
      .catch(console.error)
  }

  function onScanResult(result: BarCode) {
    loader.setLoading(true)
    api
      .getItem({ searchText: result.barCode, fetchArticleAndSN: true })
      .then(items => {
        if (!items.length) {
          loader.setLoading(false)
          return
        }
        if (items.length === 1) {
          loader.setLoading(false)
          setItem(items[0])
        } else {
          loader.setLoading(false)
          articleSelectionModal.show(items)
        }
      })
      .catch(err => {
        console.error(err)
        loader.setLoading(false)
      })
  }

  function failedToLoad(message: string, code: string) {
    alert.error(message, code)
  }

  function showOrders() {
    if (!article) return
    navigation.navigate(ScreenType.ArticleOrders, { article: article, serialNumber: item?.serialnumber })
  }

  const ArticleSelectionRenderItem = useCallback(
    (itemToRender: Item) => <ArticleCard article={itemToRender.article} serialNumber={itemToRender.serialnumber} maxLinesDescription={1} />,
    []
  )

  return (
    <MWS_Screen>
      {article && (
        <FlatList
          ListHeaderComponent={
            <>
              <ArticleInfoView
                article={article}
                prices={articlePrices}
                serialNumber={item?.serialnumber}
                imageUri={imgUri}
                configurableDescription={configurableDescription}
              />
              <View style={styles.buttonView}>
                <Button
                  style={styles.button}
                  title={i18n.t('OrderText')}
                  onPressOut={() => {
                    showArticleDescriptionAlert()
                  }}
                />
                <Button style={styles.button} title={i18n.t('Orders')} onPress={showOrders} />
              </View>
              <ArticleUnitPicker article={article} onChange={() => setArticleDepositQuantities(article.getConvertedDepositQuantities(true))} />
            </>
          }
          data={articleDepositQuantities}
          renderItem={({ item: renderItem }) => (
            <ArticleDepositAvailabilityCard article={article} depositQuantity={renderItem} defaultOpen quantitiesAlreadyConverted />
          )}
          ListFooterComponent={
            !loader.loading && (!articleDepositQuantities || articleDepositQuantities.length === 0) ? <NoEntriesTag /> : <ListSpacer />
          }
        />
      )}
      <SelectionModalFullScreen
        show={articleSelectionModal.isShown}
        title={i18n.t('SELECT')}
        items={articleSelectionModal.data ?? []}
        close={selectedItem => {
          articleSelectionModal.close()
          if (selectedItem) setItem(selectedItem)
        }}
        renderItem={ArticleSelectionRenderItem}
      />
    </MWS_Screen>
  )
}

const styles = StyleSheet.create({
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
    marginVertical: STYLE_CONSTANTS.DEFAULT_VERTICAL_SPACE_BETWEEN_COMPONENTS,
  },
  button: {
    flex: 1,
  },
})
