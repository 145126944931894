// getQualityManagementCharacteristics: (request: QualityControlCharacteristicsRequest) =>
//     client.GET<QualityCharacteristic[]>('qualityControl/characteristics', request),

import { ClientProvider } from '@infominds/react-api'

import { QualityControlCharacteristicsRequest, QualityControlPatchRequest, QualityControlPostRequest, QualityControlRequest } from './apiRequestTypes'
import { QualityCharacteristic, QualityControl } from './apiTypes'

export const quality = ClientProvider<
  QualityControl[],
  QualityControlRequest,
  string,
  QualityControlPostRequest,
  string,
  QualityControlPatchRequest,
  string,
  unknown,
  { id: string }
>('qualityControl')
export const qualityCharacteristic = ClientProvider<QualityCharacteristic[], QualityControlCharacteristicsRequest>('qualityControl/characteristics')
